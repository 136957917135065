import { Component, OnInit, ViewChild } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { PipeTransform, Pipe } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { PosService } from '../../services/pos.service';
import { InvoiceService } from '../../services/invoice.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class InvoicePatientListComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private router: Router,
    private invoiceService: InvoiceService,
    private datepipe: DatePipe) { }

  @ViewChild('RemoveConfirmModalClosebutton', { static: false }) closebutton;

  showErrorMessage: boolean;
  errorMessage: string;
  
  searchStartDate: any;
  searchEndDate: any;
  showLoadingMessage: boolean;
  invoicePatient:any = {};
  lastInvoiceNumber: number;
  

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
  }

  loadDate() {
    this.searchStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    this.searchEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.getDate(this.searchEndDate) < this.getDate(this.searchStartDate))
      errors.push("The End Date must be greater then Start Date!");

    if (this.lastInvoiceNumber == null || this.lastInvoiceNumber < 0)
      errors.push("Invalid Last Invoice Number!");


    return errors;
  }

  getDate(date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  loadFields() {    
    if (this.searchStartDate <= this.searchEndDate) {
      this.showLoadingMessage = true;
      var dateStartCoverted = Date.parse(this.searchStartDate.toString());
      var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');

      var dateEndCoverted = Date.parse(this.searchEndDate.toString());
      var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

      this.invoiceService.getInvoicePatient(this.sharedService.pharmacyId, this.sharedService.facilityId, dateStart, dateEnd, this.lastInvoiceNumber).subscribe(data => {
        this.invoicePatient = data;
        this.showLoadingMessage = false;
      },
        err => this.showLoadingMessage = false);
    } else {
      this.invoicePatient = {};
    }
  }

  search() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadFields();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  public exportToPdf() {
    this.showErrorMessage = false;
    if (this.invoicePatient.invoicePatientItems != null) {

      var dateStartCoverted = Date.parse(this.searchStartDate.toString());
      var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');

      var dateEndCoverted = Date.parse(this.searchEndDate.toString());
      var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

      this.router.navigate([]).then(result => { window.open('report/InvoicePatientList/' + this.sharedService.pharmacyId + '/' + this.sharedService.facilityId + '/' + dateStart + '/' + dateEnd + '/' + this.lastInvoiceNumber + '/' + 'PatientList', '_blank'); });
    }
    else {
      this.loadErrorMessage("Invalid Patient List!");
    }
  }
}
