import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-support-information',
  templateUrl: './support-information.component.html',
  styleUrls: ['./support-information.component.css']
})
export class SupportInformationComponent implements OnInit {

  @ViewChild('closeSupportInformationModal', { static: false }) closeSupportInformationModal: ElementRef;

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private supportInformationTypeId: number;
  private supportInformationTypeVos: any;
  private supportInformationTextInput: string;
  private supportInformationTypeTitle: string;
  private showErrorMessage: boolean;
  private errorMessage: string;

  ngOnInit() {
    this.supportInformationTypeId = 0;
    this.loadSupportInformationType();
    this.cleanSupportInformationInputFields();
  }

  private loadSupportInformationType() {
    this.medsCheckService.getMedsCheckSupprotInformationTypeVos().subscribe(supportInformationTypeVos => {
      this.supportInformationTypeVos = supportInformationTypeVos;
    });
  }

  private cleanSupportInformationInputFields() {
    this.supportInformationTypeId = 0;
    this.supportInformationTextInput = "";
    this.supportInformationTypeTitle = "";
  }

  private addSuportInformation() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateSupportInformationFormFields();

    if (resultFieldsValidation == "") {
      var findSuportInformationTypeTitle = this.supportInformationTypeVos.find(x => x.id == this.supportInformationTypeId);
      this.supportInformationTypeTitle = findSuportInformationTypeTitle.title;

      var newSupportInformationItem = {
        medsCheckSupportInformationTypeId: this.supportInformationTypeId,
        medsCheckSupportInformationTypeTitle: this.supportInformationTypeTitle,
        notes: this.supportInformationTextInput
      }

      this.medsCheckService.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.unshift(newSupportInformationItem);
      this.closeSupportInformationModal.nativeElement.click();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  private deleteSuportInformationItem(index) {
    this.medsCheckService.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.splice(index, 1);
  }

  private validateSupportInformationFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.supportInformationTypeId == 0)
      errors.push('Type');

    if (this.supportInformationTextInput.trim().length == 0)
      errors.push('Notes');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(" and ") + " are mandatories."
    }
    return message;
  }

  public supportInformationValidation(): any {
    var supportInformationErrorMessage: string = "";

    if (this.medsCheckService.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.length == 0)
      supportInformationErrorMessage = "At least one type must be registered"

    if (supportInformationErrorMessage.length == 0)
      return null;

    return {
      section: 'Support Information',
      message: supportInformationErrorMessage
    }
  }

  private loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }


}
