import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { ShippingService } from '../../services/shipping.service';
import { Observable, of } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class ShippingCreateComponent implements OnInit {
  dateEnd: any;
  dateStart: any;
  shippingTypeId: number = 1;
  notes: string;
  showOnlyNotSelectedItems: boolean = false;

  prescriptions: any[] = [];
  shippingItems: any[] = [];

  shippingId: number = 0;
  shippingStatusPendingId: number = 1;
  shippingStatusSentId: number = 2;

  showLoadingMessage: boolean = false;
  showErrorMessage: boolean;
  errorMessage: string;

  get editMode(): boolean {
    return this.isEditMode();
  }


  constructor(private sharedService: SharedService,
    private ShippingService: ShippingService,
    private route: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe) { }


  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();


    if (this.isEditMode()) {
      this.loadForm();
    } else {
      this.loadPrescriptions();
    }
  }

  isEditMode(): boolean {
    return this.route.snapshot.paramMap.get('id') != null;
  }

  loadForm() {
    this.showLoadingMessage = true;
    this.shippingId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.ShippingService.getShipping(this.shippingId).subscribe(model => {

      this.dateStart = model.startDate;
      this.dateEnd = model.endDate;

      this.shippingTypeId = model.shippingTypeId;
      this.notes = model.notes;
      this.sharedService.facilityUnitId = model.facilityUnitId;

      this.ShippingService.getShippingPrescriptions(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.facilityUnitId, this.shippingTypeId, model.startDate, model.endDate).subscribe(data => {
        this.prescriptions = data;
        for (let p of this.prescriptions) {
          var item = model.shippingItems.find(i => i.rxNum == p.rxNum);
          if (item != null) {
            p.selected = true;
            p.shippedAt = null;
          }
        }
      }, err => this.showLoadingMessage = false);

      this.showLoadingMessage = false;
    }, err => this.showLoadingMessage = false);
  }

  public search() {
    if (this.shippingId > 0) {
      this.loadForm();
    }

    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadPrescriptions();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  loadDate() {
    this.dateStart = new Date();
    this.dateEnd = new Date();
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEnd < this.dateStart)
      errors.push("The End Date must be greater then Start Date!")

    if (this.sharedService.facilityUnitId == 0)
      errors.push("Select a Facility Unit")

    return errors;
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  private loadPrescriptions() {
    this.showLoadingMessage = true;
    var startDateText = this.datepipe.transform(this.dateStart, 'yyyy-MM-dd');
    var endDateText = this.datepipe.transform(this.dateEnd, 'yyyy-MM-dd');

    this.ShippingService.getShippingPrescriptions(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.facilityUnitId, this.shippingTypeId, startDateText, endDateText).subscribe(data => {
      this.prescriptions = data;

      if (this.showOnlyNotSelectedItems)
        this.prescriptions = this.prescriptions.filter(p => p.shippedAt == null);

      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  selectAllEvent(selected) {
    this.prescriptions
      .filter(p => p.shippedAt == null)
      .forEach(p => { p.selected = selected });
  }

  onRowClick(rxNum, drugRecordBook) {
    if (!drugRecordBook) {
      var prescription = this.prescriptions.find(p => p.rxNum == rxNum);
      if (prescription != null && prescription.shippedAt == null)
        prescription.selected = !prescription.selected;
    }
  }

  redirectToShipping() {
    if (this.sharedService.facilityId) {
      this.router.navigate(['shipping/']);
    }
  }

  getModel(shippingStatusId): any {
    var shipping: any = {
      id: this.shippingId,
      pharmacyId: this.sharedService.pharmacyId,
      facilityId: this.sharedService.facilityId,
      facilityUnitId: this.sharedService.facilityUnitId,
      createdBy: this.sharedService.getUserId(),
      startDate: this.datepipe.transform(this.dateStart, 'yyyy-MM-dd'),
      endDate: this.datepipe.transform(this.dateEnd, 'yyyy-MM-dd'),
      shippingTypeId: Number.parseInt(this.shippingTypeId.toString()),
      shippingStatusId: shippingStatusId,
      notes: this.notes,
      shippingItems: this.prescriptions.filter(p => p.selected)
    };

    return shipping;
  }

  validateFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.sharedService.facilityUnitId == null || this.sharedService.facilityUnitId == 0)
      errors.push('Unit');

    if (this.dateStart == null || this.dateEnd == null)
      errors.push('Date');

    if (this.shippingTypeId == null || this.shippingTypeId == 0)
      errors.push('Type');

    if (this.sharedService.facilityId == null || this.sharedService.facilityId == 0
      || this.sharedService.pharmacyId == null || this.sharedService.pharmacyId == 0)
      errors.push('Invalid request, please try close and open it again.');

    if ((this.prescriptions == null || this.prescriptions.filter(p => p.selected).length == 0))
      errors.push('Prescriptions');


    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  save(shippingStatusId) {
    try {
      this.clearErrorMessage()
      var resultFieldsValidation = this.validateFields();
      var model = this.getModel(shippingStatusId);

      if (resultFieldsValidation == "") {
        this.ShippingService.save(model).subscribe
          (resultVo => {

            if (resultVo.success) {
              this.sharedService.showTempSuccessMessage();
              this.redirectToShipping();
            } else {
              this.loadErrorMessage(resultVo.message);
            }
          });
      }
      else {
        this.loadErrorMessage(resultFieldsValidation);
      }
    } catch (e) {
      this.loadErrorMessage(e);
    }
  }

  public exportToPdf() {
    var pageTitle = "Shipping";
    this.router.navigate([]).then(result => { window.open('report/Shipping/' + this.shippingId + '/' + pageTitle, '_blank'); });
  }

  public onShowOnlyNotSelectedItemsChanged() {

    if (this.showOnlyNotSelectedItems)
      this.prescriptions = this.prescriptions.filter(p => p.shippedAt == null);
    else {
      this.search();
    }
  }

}
