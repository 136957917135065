import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-patient-information',
  templateUrl: './patient-information.component.html',
  styleUrls: ['./patient-information.component.css']
})
export class PatientInformationComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  ngOnInit() { }

  private medsCheckStatusDraft: string = 'Draft';

  public patientInfoValidation(): any {
    var patientAddressErrors: string[] = [];
    var patientAddressErrorMessage: string = "";

    if (this.medsCheckService.medsCheckVo.patientVo.addressVo.streetName.trim().length == 0)
      patientAddressErrors.push("Street Name")

    if (this.medsCheckService.medsCheckVo.patientVo.addressVo.city.trim().length == 0)
      patientAddressErrors.push("City")

    if (this.medsCheckService.medsCheckVo.patientVo.addressVo.province.trim().length == 0)
      patientAddressErrors.push("Province")

    if (patientAddressErrors.length == 1) {
      patientAddressErrorMessage = "The field " + patientAddressErrors.join(", ") + " is mandatory."
    }

    else if (patientAddressErrors.length > 1) {
      patientAddressErrorMessage = "The fields " + patientAddressErrors.join(", ") + " are mandatories."
    }

    if (patientAddressErrors.length == 0)
      return null;

    return {
      section: 'Patient Information',
      message: patientAddressErrorMessage
    }
  }

}
