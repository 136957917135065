import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';

@Component({
  selector: 'app-general-diabetes-education',
  templateUrl: './general-diabetes-education.component.html',
  styleUrls: ['./general-diabetes-education.component.css']
})
export class GeneralDiabetesEducationComponent implements OnInit {

  constructor(private medsCheckService: MedscheckService) { }

  ngOnInit() { 
  }

}
