import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { MedscheckService } from '../../services/medscheck.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-annual-home',
  templateUrl: './annual-home.component.html',
  styleUrls: ['./annual-home.component.css']
})
export class AnnualHomeComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private medscheckService: MedscheckService,
    private router: Router,
    private route: ActivatedRoute) { }

  showErrorMessage: boolean;
  showErrorMessageMainPage: boolean;
  errorMessage: string;
  patientId: string;
  patientVo: any;
  reportTitle: string = "MEDSCHECK ANNUAL AT HOME";
  medsCheckTypeAnnualAtHome = 2;
  medsCheckVo: any;
  therapeuticIssueTypeVos: any[];
  therapeuticIssueTypeId: any;
  therapeuticIssueTypeTitle: string;
  outlineSymptomConditionNotAddressed: string;
  therapeuticIssuesNotes: string;
  suggestedTherapy: string;
  actionTaken: string;
  supportInformationTypeId: number;
  supportInformationTypeTitle: string;
  supportInformationTextInput: string;
  supportInformationTypeVos: any[];
  drug: object;
  medicationInput: string;
  medicationInputId: number;
  nonPrescriptionMedicationDirectionInput: string;
  otherPharmaciesMedicationInput: string;
  otherPharmaciesMedicationInputId: number;
  otherPharmaciesDirectionInput: string;

  @ViewChild('tabQuestions', { static: false }) tabQuestions: ElementRef;
  @ViewChild('tabMedicalHistory', { static: false }) tabMedicalHistory: ElementRef;
  @ViewChild('tabNonPrescriptions', { static: false }) tabNonPrescriptions: ElementRef;
  @ViewChild('tabRxOtherpharmacies', { static: false }) tabRxOtherpharmacies: ElementRef;
  @ViewChild('tabIssues', { static: false }) tabIssues: ElementRef;
  @ViewChild('tabTopics', { static: false }) tabTopics: ElementRef;
  @ViewChild('tabChecklist', { static: false }) tabChecklist: ElementRef;

  ngOnInit() {
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.supportInformationTypeId = 0;
    this.cleanTherapeuticIssueInputFields();
    this.cleanSupportInformationInputFields();
    this.cleanNonPrescriptionMedicationFields();
    this.cleanOtherPharmaciesMedicationFields();

    this.loadMedsCheckVo();
    this.loadtherapeuticIssueType();
    this.loadSupportInformationType();
  }

  cleanTherapeuticIssueInputFields() {
    this.therapeuticIssueTypeId = 0;
    this.therapeuticIssuesNotes = "";
    this.therapeuticIssueTypeTitle = "";
    this.outlineSymptomConditionNotAddressed = "";
    this.suggestedTherapy = "";
    this.actionTaken = "";
  }

  cleanSupportInformationInputFields() {
    this.supportInformationTextInput = "";
    this.supportInformationTypeTitle = "";
  }

  cleanNonPrescriptionMedicationFields() {
    this.medicationInputId = null;
    this.medicationInput = "";
    this.nonPrescriptionMedicationDirectionInput = "";
  }

  cleanOtherPharmaciesMedicationFields() {
    this.otherPharmaciesMedicationInputId = null;
    this.otherPharmaciesMedicationInput = "";
    this.otherPharmaciesDirectionInput = "";
  }

  loadMedsCheckVo() {
    //this.medscheckService.getMedsCheckVoBy(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.sharedService.getUserId(), this.medsCheckTypeAnnualAtHome).subscribe(medsCheckVo => {
    //  this.medsCheckVo = medsCheckVo;
    //  this.patientVo = medsCheckVo.patientVo;
    //});
  }

  loadtherapeuticIssueType() {
    this.medscheckService.getMedsCheckTherapeuticIssueTypeVos().subscribe(therapeuticIssueTypeVos => {
      this.therapeuticIssueTypeVos = therapeuticIssueTypeVos;
    });
  }

  loadSupportInformationType() {
    this.medscheckService.getMedsCheckSupprotInformationTypeVos().subscribe(supportInformationTypeVos => {
      this.supportInformationTypeVos = supportInformationTypeVos;
    });
  }

  addTherapeuticIssues() {
    var resultFieldsValidation = this.validateTherapeuticIssuesFormFields();

    if (resultFieldsValidation == "") {
      var findTherapeuticIssueTypeTitle = this.therapeuticIssueTypeVos.find(x => x.id == this.therapeuticIssueTypeId);
      this.therapeuticIssueTypeTitle = findTherapeuticIssueTypeTitle.title;

      if (this.therapeuticIssueTypeId == 9)
        this.therapeuticIssueTypeTitle = this.outlineSymptomConditionNotAddressed;

      var therapeuticIssue = {
        medsCheckTherapeuticIssueTypeId: this.therapeuticIssueTypeId,
        medsCheckTherapeuticIssueTypeTitle: this.therapeuticIssueTypeTitle,
        suggestedTherapy: this.suggestedTherapy,
        actionTaken: this.actionTaken,
        outlineSymptomConditionNotAddressed: this.outlineSymptomConditionNotAddressed
      }


      this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.push(therapeuticIssue);
      this.cleanTherapeuticIssueInputFields();

    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }

  }

  validateTherapeuticIssuesFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.therapeuticIssueTypeId == 0)
      errors.push('Type');

    if (this.therapeuticIssueTypeId == 9 && this.outlineSymptomConditionNotAddressed == "")
      errors.push('Outline symptom or condition not addressed');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  deleteTherapeuticIssues(issueId) {
    for (let i = 0; i < this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.length; i++) {
      if (this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos[i].medsCheckTherapeuticIssueTypeId === issueId)
        this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.splice(i, 1);
    }
  }

  addSuportInformation() {
    var resultFieldsValidation = this.validateSupportInformationFormFields();

    if (resultFieldsValidation == "") {
      var findSuportInformationTypeTitle = this.supportInformationTypeVos.find(x => x.id == this.supportInformationTypeId);
      this.supportInformationTypeTitle = findSuportInformationTypeTitle.title;

      var newSupportInformationItem = {
        medsCheckSupportInformationTypeId: this.supportInformationTypeId,
        medsCheckSupportInformationTypeTitle: this.supportInformationTypeTitle,
        notes: this.supportInformationTextInput
      }

      this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.unshift(newSupportInformationItem);
      this.cleanSupportInformationInputFields();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  validateSupportInformationFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.supportInformationTypeId == 0)
      errors.push('Type');

    if (this.supportInformationTextInput == "")
      errors.push('Notes');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  deleteSuportInformationItem(topicToDelete) {
    for (let i = 0; i < this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.length; i++) {
      if (this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos[i].notes === topicToDelete)
        this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.splice(i, 1);
    }
  }

  public loadNonPrescriptionMedicationSelected = (drug) => {
    this.drug = drug;
    this.medicationInputId = drug.id;
    this.medicationInput = drug.name;
  }

  public loadOtherPharmaciesMedication = (drug) => {
    this.drug = drug;
    this.otherPharmaciesMedicationInputId = drug.id;
    this.otherPharmaciesMedicationInput = drug.name;
  }

  addNonPrescriptionMedication() {
    var resultFieldsValidation = this.validateInputMedicationFormFields(this.medicationInput, this.nonPrescriptionMedicationDirectionInput);

    if (resultFieldsValidation == "") {
      var newMedication = {
        pharmacyId: this.sharedService.pharmacyId,
        drugId: this.medicationInputId,
        medication: this.medicationInput,
        directions: this.nonPrescriptionMedicationDirectionInput,

      }

      this.medsCheckVo.medsCheckNonPrescriptionMedicationVo.medsCheckNonPrescriptionMedicationItemVos.push(newMedication);
      this.cleanNonPrescriptionMedicationFields();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  deleteNonPrescriptionMedication(medicationToDelete) {
    for (let i = 0; i < this.medsCheckVo.medsCheckNonPrescriptionMedicationVo.medsCheckNonPrescriptionMedicationItemVos.length; i++) {
      if (this.medsCheckVo.medsCheckNonPrescriptionMedicationVo.medsCheckNonPrescriptionMedicationItemVos[i].medication === medicationToDelete)
        this.medsCheckVo.medsCheckNonPrescriptionMedicationVo.medsCheckNonPrescriptionMedicationItemVos.splice(i, 1);
    }
  }

  addPrescriptionOtherPharmacies() {
    var resultFieldsValidation = this.validateInputMedicationFormFields(this.otherPharmaciesMedicationInput, this.otherPharmaciesDirectionInput);
   
    if (resultFieldsValidation = "") {
      var newMedication = {
        pharmacyId: this.sharedService.pharmacyId,
        drugId: this.otherPharmaciesMedicationInputId,
        medication: this.otherPharmaciesMedicationInput,
        directions: this.otherPharmaciesDirectionInput,

      }

      this.medsCheckVo.medsCheckRxsFromOtherPharmacyVo.medsCheckRxsFromOtherPharmacyItemVos.push(newMedication);
      this.cleanOtherPharmaciesMedicationFields();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  deletePrescriptionOtherPharmacies(medicationToDelete) {
    for (let i = 0; i < this.medsCheckVo.medsCheckRxsFromOtherPharmacyVo.medsCheckRxsFromOtherPharmacyItemVos.length; i++) {
      if (this.medsCheckVo.medsCheckRxsFromOtherPharmacyVo.medsCheckRxsFromOtherPharmacyItemVos[i].medication === medicationToDelete)
        this.medsCheckVo.medsCheckRxsFromOtherPharmacyVo.medsCheckRxsFromOtherPharmacyItemVos.splice(i, 1);
    }
  }

  validateInputMedicationFormFields(medication, direction): string {
    var errors: string[] = [];
    var message: string = "";

    if (medication == "")
      errors.push('Medication');

    if (direction == "")
      errors.push('Directions');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  OnMedsCheckSubmit() {

    try {
      this.medscheckService.submitMedsCheck(this.medsCheckVo).subscribe
        (resultVo => {

          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.redirectToResident();
          } else {
            this.loadErrorMessage(resultVo.message);
          }

        });
    }
    catch (e){
      this.loadErrorMessage(e);
    }

  }

  redirectToResident() {
    this.router.navigate(['resident']);
  }

  nextClick(event) {
    var currentTabId = event.currentTarget.id;

    switch (currentTabId) {
      case 'btn-next-tab-questions':
        this.tabMedicalHistory.nativeElement.click();
        break;
      case 'btn-next-tab-medical-history':
        this.tabNonPrescriptions.nativeElement.click();
        break;
      case 'btn-next-tab-non-prescriptions':
        this.tabRxOtherpharmacies.nativeElement.click();
        break;
      case 'btn-next-tab-rx-otherpharmacies':
        this.tabIssues.nativeElement.click();        
        break;
      case 'btn-next-tab-issues':
        this.tabTopics.nativeElement.click();
        break;
      case 'btn-next-tab-topics':
        this.tabChecklist.nativeElement.click();
        break;
      default:
        this.tabQuestions.nativeElement.click();
    }

  }

  previousClick(event) {
    var currentTabId = event.currentTarget.id;

    switch (currentTabId) {
      case 'btn-previous-tab-checklist':
        this.tabTopics.nativeElement.click();
        break;
      case 'btn-previous-tab-topics':
        this.tabIssues.nativeElement.click();
        break;
      case 'btn-previous-tab-issues':
        this.tabRxOtherpharmacies.nativeElement.click();
        break;
      case 'btn-previous-tab-rx-otherpharmacies':
        this.tabNonPrescriptions.nativeElement.click();
        break;
      case 'btn-previous-tab-non-prescriptions':
        this.tabMedicalHistory.nativeElement.click();
        break;
      case 'btn-previous-tab-medical-history':
        this.tabQuestions.nativeElement.click();
        break;
      default:
        this.tabChecklist.nativeElement.click();
    }
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessageMainPage(message: any) {
    this.showErrorMessageMainPage = true;
    this.errorMessage = message.toString();
  }

  clearErrorMessageMainPage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  exportToPdf() {
    this.router.navigate([]).then(result =>
    {
      window.open('report/PharmacistsWorksheet/' + this.medsCheckVo.id, '_blank');
      window.open('report/PatientTakeHomeSummary/' + this.medsCheckVo.id, '_blank');
    });
  }
}
