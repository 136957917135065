import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { PatientService } from '../../services/patient.service';
import { ReorderService } from '../../services/reorder.service';
import { MedscheckService } from '../../services/medscheck.service';
import { EprescriberService } from '../../services/eprescriber.service';
import { IncidentReportService } from '../../services/incident-report.service';
import { PrescriptionReviewService } from '../../services/prescription-review.service';
import { EmergencyPharmacyService } from '../../services/emergency-pharmacy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class PatientProfileComponent implements OnInit {

  @ViewChild('closePrescriptionReviewButton', { static: false }) closePrescriptionReviewButton;
  @ViewChild('closeinsidentreportbutton', { static: false }) closeinsidentreportbutton;
  @ViewChild('closeReorderButton', { static: false }) closeReorderButton;
  @ViewChild('closeRemoveButtonMedsCheck', { static: false }) closeRemoveButtonMedsCheck;
  @ViewChild('closeModalEditReviewDate', { static: false }) closeModalEditReviewDate;
  @ViewChild('closeRemoveButtonIncidentReport', { static: false }) closeRemoveButtonIncidentReport;
  @ViewChild('modalOptionToReloadLastMedsCheck', { static: false }) modalOptionToReloadLastMedsCheck: ElementRef;
  @ViewChild('closeeprescriberbutton', { static: false }) closeEprescriberButton;
  @ViewChild('closeConfirmationDeleteEprescriberButton', { static: false }) closeConfirmationDeleteEprescriberButton;
  @ViewChild('closeConfirmationDeleteEmergencyPharmacyRequestButton', { static: false }) closeConfirmationDeleteEmergencyPharmacyRequestButton;

  constructor(private sharedService: SharedService,
    private reorderService: ReorderService,
    private eprescriberService: EprescriberService,
    private patientService: PatientService,
    private medscheckService: MedscheckService,
    private incidentReportService: IncidentReportService,
    private prescriptionReviewService: PrescriptionReviewService,
    private emergencyPharmacyService: EmergencyPharmacyService,
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe) { }

  public patientDto: any = { addressDto: {} };
  public showLoadingMessage: boolean = true;
  public activePrescriptions: any[] = [];

  private get patientId(): number {
    return Number.parseInt(this.route.snapshot.paramMap.get('patientId'));
  }
  private get endDate(): any {
    return new Date();
  }
  private get startDate(): any {
    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    return startDate;
  }

  //  #region Eprescriber Properties
  public eprescriberId: number = 0;
  public eprescriberVos: any[] = [];
  public eprescriberEndDate: Date;
  public eprescriberStartDate: Date;
  public showEprescriberErrorMessage: boolean;
  public eprescriberErrorMessage: any;
  public eprescriberStatusId: any = 0;
  //#endregion


  //  #region MedsCheck Properties
  public patientEmergencyPharmacyRequestId: number = 0;
  public patientEmergencyPharmacyRequestVos: any[] = [];
  public patientEmergencyPharmacyRequestStartDate: Date;
  public patientEmergencyPharmacyRequestEndDate: Date;
  //#endregion


  //  #region MedsCheck Properties
  public medsCheckVos: any[] = [];
  public medsCheckTypeVos: any[] = [];
  public medsCheckTypeId: number = 1;
  public medsCheckDefaultId: number = 0;
  public medsCheckId: number = 0;
  public medsCheckPatientAcknowledgementDate: Date;
  public medsCheckReviewDate: Date;
  public medsCheckReviewTime: any = {};
  public medsCheckErrorMessage: string;
  public showMedsCheckErrorMessage: boolean = false;
  private medsCheckVo: any = {};
  private lastSubmittedMedsCheckId: number = 0;
  private hasLastSubmittedMedsCheck: boolean = false;
  //#endregion

  // #region Reorder Properties

  public reorderVos: any[] = [];
  public reorderStartDate: Date;
  public reorderEndDate: Date;

  public reorderStatusId: any = 0;
  public reorderErrorMessage: string;
  public showReorderErrorMessage: boolean;

  //#endregion

  // #region Prescription Review Properties

  public prescriptionReviewVos: any[] = [];
  public prescriptionReviewFilterYears: any = [];
  public prescriptionReviewFilterYear: number = new Date().getFullYear();
  public prescriptionReviewStatusId: any = 0;
  public patGrpMast: string = "";

  // #endregion

  //#region Incident Report Properties
  public incidentReportVos: any[] = [];
  public showIncidentReportErrorMessage: boolean;
  public incidentReportErrorMessage: string;

  public incidentReportStartDate: Date;
  public incidentReportEndDate: Date;
  public incidentReportStatusId: any = 0;
  public incidentReportId: number;
  //#endregion

  //#region Forms Properties
  public formPhysicianOrderTypeId: number = 1;
  //#endregion

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadPatientDto();
    this.loadPatGrpMast();
  }

  private loadPatientDto() {
    this.showLoadingMessage = true;
    this.patientService.getPatient(this.sharedService.pharmacyId, this.patientId).subscribe(data => {
      this.patientDto = data;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  private validateFilterDate(dateEnd, dateStart): string[] {
    var errors: string[] = [];
    if (dateEnd < dateStart)
      errors.push("The End Date must be greater then Start Date!")
    return errors;
  }

  // #region Eprescriber Tab

  public loadEprescribersTab() {
    if (this.eprescriberStartDate == null || this.eprescriberEndDate == null) {
      this.eprescriberStartDate = this.getEprescriberStartDate();
      this.eprescriberEndDate = this.endDate;
    }

    if (this.eprescriberVos.length == 0) {
      this.loadEprescriberVos();
    }
  }

  private getEprescriberStartDate(): Date {
    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 90);
    return startDate;
  }

  private loadEprescriberVos() {
    this.showLoadingMessage = true;
    this.eprescriberService.getEprescribers(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.sharedService.getUserId(), this.eprescriberStatusId, this.sharedService.facilityUnitId, this.datepipe.transform(this.eprescriberStartDate, 'yyyy-MM-dd'), this.datepipe.transform(this.eprescriberEndDate, 'yyyy-MM-dd'))
      .subscribe(data => {
        this.eprescriberVos = data;
        this.showLoadingMessage = false;
      },
        err => this.showLoadingMessage = false);
  }

  public viewEprescriber(id) {
    this.setEprescriberPreviousPage();
    this.router.navigate(['eprescriber/approval/' + id]);
  }

  public editEprescriber(patientId, eprescriberId, eprescriberTypeId) {
    this.setEprescriberPreviousPage();
    this.router.navigate(['eprescriber/create/' + this.sharedService.pharmacyId + '/' + patientId + '/' + eprescriberTypeId + '/' + eprescriberId]);
  }

  public LoadEprescriberId(id) {
    this.eprescriberId = id;
  }

  public deleteEprescriber() {
    this.eprescriberService.removeEprescriber(this.eprescriberId, this.sharedService.getUserId()).subscribe(data => {
      this.sharedService.showSuccessMessage = data;
      this.closeConfirmationDeleteEprescriberButton.nativeElement.click();
      this.loadEprescriberVos();
    });
  }

  public newEprescriber(ePrescriberTypeId: number) {
    if (this.sharedService.facilityId > 0 && this.patientId > 0) {
      this.setEprescriberPreviousPage();
      this.router.navigate(['eprescriber/create/' + this.sharedService.pharmacyId + '/' + this.patientId + '/' + ePrescriberTypeId]);
    }
  }


  private setEprescriberPreviousPage() {
    localStorage.setItem('previousPage', 'profile');
  }

  public clearEprescriberErrorMessage() {
    this.showEprescriberErrorMessage = false;
    this.eprescriberErrorMessage = "";
  }

  public onActionChangeStatusEprescriber() {
    this.showEprescriberErrorMessage = false;
    var resultFieldsValidation = this.validateFilterDate(this.eprescriberEndDate, this.eprescriberStartDate);
    if (resultFieldsValidation.length == 0) {
      this.loadEprescriberVos();
      this.closeEprescriberButton.nativeElement.click();
      this.router.navigate(['profile']);
    }
    else {
      this.showEprescriberErrorMessage = true;
      this.eprescriberErrorMessage = resultFieldsValidation.join(" ");
    }
  }

  // #endregion

  // #region MedsCheck Tab

  public loadMedsCheckTab() {
    if (this.medsCheckTypeVos.length == 0) {
      this.medscheckService.getMedsCheckTypeVos().subscribe(medsCheckTypeVos => {
        this.medsCheckTypeVos = medsCheckTypeVos;
      });
    }
    if (this.medsCheckVos.length == 0) {
      this.loadMedsCheckVos();
    }
  }

  private loadMedsCheckVos() {
    this.showLoadingMessage = true;
    this.medscheckService.getMedsCheckVoIndexBy(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId).subscribe(medsCheckVos => {
      this.medsCheckVos = medsCheckVos;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  public loadActivePrescriptionsTab() {
    if (this.activePrescriptions.length == 0) {
      this.showLoadingMessage = true;
      this.patientService.getPrescriptionDtos(this.sharedService.pharmacyId, this.patientId).subscribe(data => {
        this.activePrescriptions = data;
        this.showLoadingMessage = false;
      },
        err => this.showLoadingMessage = false);
    }
  }

  public loadMedscheckVo(medscheckVo) {
    this.medsCheckVo = medscheckVo;
    this.medsCheckPatientAcknowledgementDate = medscheckVo.submittedDate;
    this.medsCheckReviewDate = medscheckVo.createdDate;
    this.medsCheckReviewTime = medscheckVo.createdDate.toString().substring(11, 16);
  }

  public loadMedsCheckId(id) {
    this.medsCheckId = id;
  }

  public deleteMedsCheck() {
    this.medscheckService.deleteMedsCheck(this.medsCheckId).subscribe(resultVo => {
      if (resultVo.success) {
        this.sharedService.showSuccessMessage = resultVo.success;
        this.loadMedsCheckVos();
      } else {
        this.showMedsCheckErrorMessage = true;
        this.eprescriberErrorMessage = resultVo.message;
      }
      this.closeRemoveButtonMedsCheck.nativeElement.click();
    });
  }

  public updateMedsCheckReviewDate() {
    this.medsCheckVo.submittedDate = this.medsCheckPatientAcknowledgementDate;
    this.medsCheckVo.createdDate = this.datepipe.transform(this.medsCheckReviewDate, 'yyyy-MM-dd') + "T" + this.medsCheckReviewTime;
    this.medscheckService.updateMedsCheckReviewDate(this.medsCheckVo).subscribe(resultVo => {
      if (resultVo.success) {
        this.sharedService.showSuccessMessage = resultVo.success;
        this.loadMedsCheckVos();
        this.closeModalEditReviewDate.nativeElement.click();
      } else {
        this.showMedsCheckErrorMessage = true;
        this.eprescriberErrorMessage = resultVo.message;
      }
    });
  }

  public createMedsCheck() {
    this.patientService.getPrescriptionDtos(this.sharedService.pharmacyId, this.patientId).subscribe(data => {
      var patientProfileDto = { PatientDto: this.patientDto, PrescriptionDtos: data };

      this.patientService.createPatient(patientProfileDto).subscribe(resultVo => {
        if (resultVo.success) {
          this.medscheckService.notExistDraftMedsCheck(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.medsCheckTypeId).subscribe(resultVo => {
            if (resultVo.success) {
              this.checkNotExistMedsCheckSubmitted();
              this.showMedsCheckErrorMessage = false;
            } else {
              this.showMedsCheckErrorMessage = true;
              this.eprescriberErrorMessage = resultVo.message;
            }
          });
        } else {
          this.showMedsCheckErrorMessage = true;
          this.eprescriberErrorMessage = resultVo.message;
        }
      });
    });
  }

  public reloadLastSubmittedMedsCheck(id: number) {
    this.lastSubmittedMedsCheckId = id;
    this.hasLastSubmittedMedsCheck = true;
    this.createNewMedsCheck();
  }

  public openMedsCheckBy(medsCheckTypeId: Number, medsCheckId: Number) {
    switch (medsCheckTypeId) {
      case 1:
        this.router.navigate(['medscheck/annual/' + medsCheckId]);
        break;
      case 3:
        this.router.navigate(['medscheck/annual-home/' + this.sharedService.pharmacyId + '/' + this.patientId]);
        break;
      case 2:
        this.router.navigate(['medscheck/annual-ltc/' + this.sharedService.pharmacyId + '/' + this.patientId]);
        break;
      case 4:
        this.router.navigate(['medscheck/annual-diabetes/' + medsCheckId]);
        break;
    }
  }

  public onChangeMedsCheckPrint(medsCheckPrintTypeId, medsCheckVo) {
    var printAllFormsTypeId = "1";
    var printPharmacistsWorksheetTypeId = "2";
    var printPatientTakeHomeSummaryTypeId = "3";
    var printPersonalMedicationRecordTypeId = "4";
    var printHealthCareProviderNotificationMedsCheckServicesTypeId = "5";
    var printPatientAcknowledgementTypeId = "6";
    var printDiabetesEducationChecklistTypeId = "7";
    var printDiabeteEducationPatientTakeHomeSummaryTypeId = "8";

    switch (medsCheckPrintTypeId) {
      case printAllFormsTypeId:
        this.openMedsCheckPharmacistsWorksheet(medsCheckVo.id);
        this.openMedsCheckPersonalMedicationRecord(medsCheckVo.id);
        this.openMedsCheckHealthCareProviderNotificationMedsCheckServices(medsCheckVo.id);
        this.openMedsCheckPatientTakeHomeSummary(medsCheckVo.id);
        this.openMedsCheckPatientAcknowledgement(medsCheckVo.id, medsCheckVo.isMedsCheckDiabete);

        if (medsCheckVo.isMedsCheckDiabete) {
          this.openMedsCheckDiabetesEducationChecklist(medsCheckVo.id);
          this.openMedsCheckDiabeteEducationPatientTakeHomeSummary(medsCheckVo.id);
        }
        break;
      case printPharmacistsWorksheetTypeId:
        this.openMedsCheckPharmacistsWorksheet(medsCheckVo.id);
        break;
      case printPatientTakeHomeSummaryTypeId:
        this.openMedsCheckPatientTakeHomeSummary(medsCheckVo.id);
        break;
      case printPersonalMedicationRecordTypeId:
        this.openMedsCheckPersonalMedicationRecord(medsCheckVo.id);
        break;
      case printHealthCareProviderNotificationMedsCheckServicesTypeId:
        this.openMedsCheckHealthCareProviderNotificationMedsCheckServices(medsCheckVo.id);
        break;
      case printPatientAcknowledgementTypeId:
        this.openMedsCheckPatientAcknowledgement(medsCheckVo.id, medsCheckVo.isMedsCheckDiabete);
        break;
      case printDiabetesEducationChecklistTypeId:
        this.openMedsCheckDiabetesEducationChecklist(medsCheckVo.id);
        break;
      case printDiabeteEducationPatientTakeHomeSummaryTypeId:
        this.openMedsCheckDiabeteEducationPatientTakeHomeSummary(medsCheckVo.id);
    }
  }

  private openMedsCheckPatientTakeHomeSummary(medsCheckVoId: number) {
    window.open('report/PatientTakeHomeSummary/' + medsCheckVoId + '/' + false + '/Patient Take-Home Summary', '_blank');
  }

  private openMedsCheckDiabeteEducationPatientTakeHomeSummary(medsCheckVoId: number) {
    window.open('report/PatientTakeHomeSummary/' + medsCheckVoId + '/' + true + '/Diabetes Education Patient Take-Home Summary', '_blank');
  }

  private openMedsCheckPatientAcknowledgement(medsCheckVoId: number, isMedsCheckDiabete: boolean) {
    window.open('report/PatientAcknowledgement/' + medsCheckVoId + '/' + isMedsCheckDiabete + '/Patient Acknowledgement', '_blank');
  }

  private openMedsCheckDiabetesEducationChecklist(medsCheckVoId: number) {
    window.open('report/DiabetesEducationChecklist/' + medsCheckVoId + '/Diabetes Education Checklist', '_blank');
  }

  private openMedsCheckHealthCareProviderNotificationMedsCheckServices(medsCheckVoId: number) {
    window.open('report/HealthCareProviderNotificationMedsCheckServices/' + medsCheckVoId + '/Healthcare Provider Notification', '_blank');
  }

  private openMedsCheckPersonalMedicationRecord(medsCheckVoId: number) {
    window.open('report/PersonalMedicationRecord/' + medsCheckVoId + '/Personal Medication Record', '_blank');
  }

  private openMedsCheckPharmacistsWorksheet(medsCheckVoId: number) {
    window.open('report/PharmacistsWorksheet/' + medsCheckVoId + '/Pharmacists Worksheet', '_blank');
  }

  private checkNotExistMedsCheckSubmitted() {
    this.medscheckService.notExistMedsCheckSubmitted(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.medsCheckTypeId).subscribe(resultVo => {
      if (resultVo.success) {
        this.hasLastSubmittedMedsCheck = false;
        this.createNewMedsCheck();
      } else {
        this.getLastSubmittedMedsCheck();
      }
    });
  }

  private createNewMedsCheck() {
    this.medscheckService.createMedsCheckBy(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.sharedService.getUserId(), this.medsCheckTypeId).subscribe(medsCheckId => {
      this.medsCheckId = medsCheckId;
      if (this.hasLastSubmittedMedsCheck)
        this.updateMedsCheckByLastSubmitted();
      else
        this.openMedsCheckBy(this.medsCheckTypeId, this.medsCheckId);
    });
  }

  private updateMedsCheckByLastSubmitted() {
    this.medscheckService.updateMedsCheckByLastSubmitted(this.medsCheckId, this.lastSubmittedMedsCheckId, this.medsCheckTypeId).subscribe(resultVo => {
      if (resultVo.success) {
        this.openMedsCheckBy(this.medsCheckTypeId, this.medsCheckId);
      }
      else {
        this.showMedsCheckErrorMessage = true;
        this.medsCheckErrorMessage = resultVo.message;
      }
    });
  }

  private getLastSubmittedMedsCheck() {
    this.medscheckService.getLastSubmittedMedsCheckBy(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.medsCheckTypeId).subscribe(
      medsCheckVo => {
        this.medsCheckVo = medsCheckVo;
        this.modalOptionToReloadLastMedsCheck.nativeElement.click();
      });
  }
  // #endregion

  // #region Reorder Tab

  public loadReorderTab() {

    if (this.reorderStartDate == null || this.reorderEndDate == null) {
      this.reorderStartDate = this.startDate;
      this.reorderEndDate = this.endDate;
    }

    if (this.reorderVos.length == 0) {
      this.loadReorderVos();
    }
  }

  private loadReorderVos() {
    var dateStart = this.datepipe.transform(this.reorderStartDate, 'yyyy-MM-dd');
    var dateEnd = this.datepipe.transform(this.reorderEndDate, 'yyyy-MM-dd');
    this.showLoadingMessage = true;
    this.reorderService.loadReordersBy(this.patientId, dateStart, dateEnd, this.reorderStatusId).subscribe(data => {
      this.reorderVos = data;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  public clearReorderErrorMessage() {
    this.showReorderErrorMessage = false;
    this.reorderErrorMessage = "";
  }

  public onActionChangeReorderFilters() {
    var resultFieldsValidation = this.validateFilterDate(this.reorderEndDate, this.reorderStartDate);
    if (resultFieldsValidation.length == 0) {

      this.loadReorderVos()
      this.closeReorderButton.nativeElement.click();
      this.router.navigate(['profile']);
    }
    else {
      this.showReorderErrorMessage = true;
      this.reorderErrorMessage = resultFieldsValidation.join(" ");
    }
  }

  // #endregion

  // #region Prescription Review Tab

  public loadPrescriptionReviewTab() {
    if (this.prescriptionReviewFilterYears.length == 0)
      this.loadPrescriptionReviewFilterYears();

    if (this.prescriptionReviewVos.length == 0)
      this.loadPrescriptionsReviewVos();
  }

  private loadPatGrpMast() {
    this.prescriptionReviewService.GetPatGrpMast(this.sharedService.pharmacyId, this.patientId)
      .subscribe(data => {
        this.patGrpMast = data;
      });
  }

  private loadPrescriptionsReviewVos() {
    this.prescriptionReviewService.GetPrescriptionReviewIndexVosByYear(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.prescriptionReviewFilterYear, this.sharedService.getUserId(), this.prescriptionReviewStatusId)
      .subscribe(data => {
        this.prescriptionReviewVos = data;
      });
  }

  public openPrescriptionReview(id: any, patientId: any) {
    this.router.navigate(['prescription-review/review/' + patientId + '/' + id]);
  }

  public onActionChangePrescriptionReviewFilters() {
    this.loadPrescriptionsReviewVos();
    this.closePrescriptionReviewButton.nativeElement.click();
    this.router.navigate(['profile']);
  }

  private loadPrescriptionReviewFilterYears() {
    var date = new Date();
    this.prescriptionReviewFilterYears.push({ id: date.getFullYear() - 2 });
    this.prescriptionReviewFilterYears.push({ id: date.getFullYear() - 1 });
    this.prescriptionReviewFilterYears.push({ id: date.getFullYear() });
  }

  // #endregion

  // #region Incident Report Tab
  public loadIncidentReportTab() {
    if (this.incidentReportStartDate == null || (this.incidentReportEndDate == null)) {
      this.incidentReportStartDate = this.startDate;
      this.incidentReportEndDate = this.endDate;
    }

    if (this.incidentReportVos.length == 0)
      this.loadIncidentReportVos();
  }

  private loadIncidentReportVos() {
    this.incidentReportService.getIncidentReportsByStatusId(this.sharedService.getUserId(), this.sharedService.facilityId, this.sharedService.pharmacyId, this.incidentReportStatusId, this.sharedService.facilityUnitId, this.patientId, this.datepipe.transform(this.incidentReportStartDate, 'yyyy-MM-dd'), this.datepipe.transform(this.incidentReportEndDate, 'yyyy-MM-dd'))
      .subscribe(data => {
        this.incidentReportVos = data;
      });
  }

  public reviewIncidentReport(id) {
    this.router.navigate(['/incident-report/review/' + id]);
  }

  public setIncidentReportId(id) {
    this.incidentReportId = id;
  }

  public clearIncidentReportErrorMessage() {
    this.showIncidentReportErrorMessage = false;
    this.incidentReportErrorMessage = "";
  }

  public onActionChangeIncidentReportFilter() {
    this.showIncidentReportErrorMessage = false;
    var resultFieldsValidation = this.validateFilterDate(this.incidentReportEndDate, this.incidentReportStartDate);
    if (resultFieldsValidation.length == 0) {

      this.loadIncidentReportVos();
      this.closeinsidentreportbutton.nativeElement.click();
      this.router.navigate(['profile']);
    }
    else {
      this.showIncidentReportErrorMessage = true;
      this.incidentReportErrorMessage = resultFieldsValidation.join(" ");
    }
  }

  removeIncidentReport() {
    this.incidentReportService.removeIncidentReport(this.incidentReportId).subscribe(data => {
      this.incidentReportErrorMessage = "Item removed";
      this.sharedService.showSuccessMessage = data;
      this.closeRemoveButtonIncidentReport.nativeElement.click();
      this.loadIncidentReportVos();
      window.scroll(0, 0);
    });
  }
  // #endregion

  // #region Forms Tab
  public openForm(formTypeId: number) {
    if (formTypeId == this.formPhysicianOrderTypeId)
      this.router.navigate([]).then(result => { window.open('report/PhysicianOrderForm/' + this.sharedService.pharmacyId + '/' + this.sharedService.facilityId + '/' + this.patientId + '/' + 'PhysicianOrderForm', '_blank'); });
  }
  // #endregion


  // #region EmergencyPharmacyRequest Tab
  openEmergencyPharmacyRequest() {
    var eprescriberTypeId = '1';
    if (this.sharedService.facilityId > 0 && this.patientId > 0) {
      this.router.navigate(['patient/emergency-pharmacy/' + this.sharedService.pharmacyId + '/' + this.patientId + '/' + eprescriberTypeId]);
    }
  }

  loadEmergencyPharmacyRequestTab() {
    if (this.patientEmergencyPharmacyRequestVos.length == 0) {
      this.loadEmergencyPharmacyRequestVos();
    }
  }

  private loadEmergencyPharmacyRequestVos() {
    this.emergencyPharmacyService.get(this.sharedService.pharmacyId, this.patientId)
      .subscribe(data => {
        this.patientEmergencyPharmacyRequestVos = data;
      });
  }

  public viewEmergencyPharmacyRequest(id) {
    this.router.navigate([]).then(result => { window.open('report/PatientEmergencyPharmacyRequest/' + id + '/' + 'PatientEmergencyPharmacyRequest', '_blank'); });
  }

  public LoadEmergencyPharmacyRequestId(id) {
    this.patientEmergencyPharmacyRequestId = id;
  }

  public deleteEmergencyPharmacyRequest() {
    this.emergencyPharmacyService.remove(this.patientEmergencyPharmacyRequestId, this.sharedService.getUserId()).subscribe(data => {
      this.sharedService.showTempSuccessMessage();
      this.closeConfirmationDeleteEmergencyPharmacyRequestButton.nativeElement.click();
      this.loadEmergencyPharmacyRequestVos();
    });
  }
  // #endregion

}
