import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserTrainingService } from '../../services/user-training.service';

@Component({
  selector: 'app-user-training-assessment',
  templateUrl: './user-training-assessment.component.html',
  styleUrls: ['./user-training-assessment.component.css']
})
export class UserTrainingAssessmentComponent implements OnInit {

  @ViewChild('openResultModal', { static: false }) openResultModal: ElementRef;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private trainingService: UserTrainingService) { }

  userTrainingId: number;
  userTrainingAssessmentId: number;
  userTrainingAssessmentVo: any = {};
  assessmentResult: string;
  showErrorMessage: boolean;
  errorMessage: string;

  trainningAssessmentAnswer: any;

  ngOnInit() {
    this.userTrainingAssessmentId = Number.parseInt(this.route.snapshot.paramMap.get('userTrainingAssessmentId'));
    this.userTrainingId = Number.parseInt(this.route.snapshot.paramMap.get('userTrainingId'));
    this.loadUserTrainingAssessment();
  }

  loadUserTrainingAssessment() {
    this.trainingService.getUserTrainingAssessmentVoBy(this.userTrainingAssessmentId).subscribe(
      data => {
        this.userTrainingAssessmentVo = data;
        this.loadTrainningAssessmentAnswerVo();
      }
    );
  }

  loadTrainningAssessmentAnswerVo() {
    this.userTrainingAssessmentVo.trainingAssessmentVo.trainingAssessmentQuestionVos
      .map(q => q.trainningAssessmentAnswerVos
        .map((a, index) => a.letter = String.fromCharCode(65 + index).toLowerCase())
      );
  }

  onChangeAnswer(selectedValue) {
    this.clearErrorMessage();
    var question = this.userTrainingAssessmentVo.trainingAssessmentVo.trainingAssessmentQuestionVos.find(q => q.id == selectedValue.trainingAssessmentQuestionId);
    for (var i = 0; i < question.trainningAssessmentAnswerVos.length; i++) {

      if (question.trainningAssessmentAnswerVos[i].id == selectedValue.id)
        question.trainningAssessmentAnswerVos[i].selected = true;
      else
        question.trainningAssessmentAnswerVos[i].selected = false;
    }
  }

  submitAssessment() {
    this.clearErrorMessage();
    if (!this.validadeUserTrainingAnswersVo()) {
      this.trainingService.submitUserTrainingAssessmentVo(this.userTrainingAssessmentVo).subscribe(
        resultVo => {
          if (resultVo.success) {
            this.openResultModal.nativeElement.click();
            this.assessmentResult = resultVo.message;
          }
        }
      );
    }
    else
      this.loadErrorMessage('Please, make sure you answered all question before submit.');

  }

  validadeUserTrainingAnswersVo() : boolean {
    var hasQuestionNotAnswered = this.userTrainingAssessmentVo.trainingAssessmentVo.trainingAssessmentQuestionVos
      .map(q => q.trainningAssessmentAnswerVos
        .some(a => a.selected)
    );

    return hasQuestionNotAnswered.some(a => a == false);
  }

  closeModal() {
    this.router.navigate(['training/']);
  }


  clearErrorMessage() {
    this.showErrorMessage = false;
  }

  loadErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }

}
