import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmergencyPharmacyService {
  private ApiUrl = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  get(pharmacyId, patientId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/PatientEmergencyPharmacy/GetPatientEmergencyPharmacies/' + pharmacyId + '/' + patientId);
  }

  save(emergencyPharmacy: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/PatientEmergencyPharmacy/';

    return this.http.post<any>(url, emergencyPharmacy, { 'headers': headers });
  }

  remove(id, userId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/PatientEmergencyPharmacy/Remove/' + id + '/' + userId);
  }

}
