import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
@Injectable({
  providedIn: 'root'
})
export class MedscheckService {

  private ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private sharedService: SharedService) {
    this.ApiUrl = baseUrl;
  }

  public medsCheckDiabeteVo: any;
  public medsCheckVo: any;
  public medsCheckReviewTime: string;
  public fieldDisabled = false;
  public medsCheckMedicalHistoryItemVos: any = [];

  public createMedsCheckBy(pharmacyId, facilityId, patientId, currentUserId, medsCheckTypeId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/medscheck/CreateMedsCheckBy/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + currentUserId + '/' + medsCheckTypeId);
  }

  public getMedsCheckVoById(medsCheckId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/medscheck/GetMedsCheckVoById/' + medsCheckId);
  }

  public getMedsCheckVoIndexBy(pharmacyId, facilityId, patientId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/medscheck/GetMedsCheckVoIndexBy/' + pharmacyId + '/' + facilityId + '/' + patientId);
  }

  public notExistDraftMedsCheck(pharmacyId, facilityId, patientId, medsCheckTypeId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/medscheck/NotExistDraftMedsCheck/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + medsCheckTypeId);
  }

  public notExistMedsCheckSubmitted(pharmacyId, facilityId, patientId, medsCheckTypeId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/medscheck/NotExistMedsCheckSubmitted/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + medsCheckTypeId);
  }

  public getLastSubmittedMedsCheckBy(pharmacyId, facilityId, patientId, medsCheckTypeId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/medscheck/GetLastSubmittedMedsCheckBy/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + medsCheckTypeId);
  }

  public updateMedsCheckByLastSubmitted(medsCheckId, lastSubmittedMedsCheckId, medsCheckTypeId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/medscheck/UpdateMedsCheckByLastSubmitted/' + medsCheckId + '/' + lastSubmittedMedsCheckId + '/' + medsCheckTypeId);
  }

  public getMedsCheckTherapeuticIssueTypeVos(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/medscheck/GetMedsCheckTherapeuticIssueTypeVos');
  }

  public getMedsCheckTypeVos(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/medscheck/GetMedsCheckTypeVos');
  }

  public getMedsCheckSupprotInformationTypeVos(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/medscheck/GetMedsCheckSupprotInformationTypeVos');
  }

  public getMedsCheckDoctorVosBy(pharmacyId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/medscheck/GetDoctorVosBy/' + pharmacyId);
  }

  public submitMedsCheck(medsCheckVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/medsCheck/SubmitMedsCheck';
    const body = JSON.stringify(medsCheckVo);

    return this.http.post<any>(url, medsCheckVo, { 'headers': headers });
  }

  public saveAsDraftMedsCheck(medsCheckVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/medsCheck/SaveAsDraftMedsCheck';
    const body = JSON.stringify(medsCheckVo);

    return this.http.post<any>(url, medsCheckVo, { 'headers': headers });
  }

  public deleteMedsCheck(medsCheckId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/medscheck/DeleteMedsCheck/' + medsCheckId);
  }

  get pharmacyId(): number {
    return Number.parseInt(localStorage.getItem('pharmacyId'));
  }

  public getDoctorsBy(searchText: string): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/Integration/GetDoctorsBy/' + this.pharmacyId + '/' + searchText);
  }

  public updateMedsCheckReviewDate(medsCheckVo) {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/medsCheck/updateMedsCheckReviewDate';
    return this.http.post<any>(url, medsCheckVo, { 'headers': headers });
  }

  public saveAsDraftMedsCheckDiabete(): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/medsCheck/SaveAsDraftMedsCheckDiabete';
    const body = JSON.stringify(this.medsCheckDiabeteVo);

    return this.http.post<any>(url, this.medsCheckDiabeteVo, { 'headers': headers });
  }

  public submitMedsCheckDiabete(): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/medsCheck/SubmitMedsCheckDiabete';
    const body = JSON.stringify(this.medsCheckDiabeteVo);

    return this.http.post<any>(url, this.medsCheckDiabeteVo, { 'headers': headers });
  }

  public loadMedsCheckDiabeteVoByMedsCheckId(medsCheckId) {
    this.http.get<any>(this.ApiUrl + 'api/medscheck/GetMedsCheckDiabeteVoByMedsCheckId/' + medsCheckId).subscribe(result => {
      this.medsCheckDiabeteVo = result;
      this.loadMedsCheckVoByMedsCheckDiabete();
      this.loadFieldDisabled();
      this.sharedService.showLoadingMessage = false;
    },
      err => this.sharedService.showLoadingMessage = false);
  }

  private loadMedsCheckVoByMedsCheckDiabete() {
    this.medsCheckVo = this.medsCheckDiabeteVo.medsCheckVo;
    this.medsCheckReviewTime = this.medsCheckVo.createdDate.toString().substring(11, 16);
    this.medsCheckMedicalHistoryItemVos = this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos;
  }

  private loadFieldDisabled() {
    if (this.sharedService.userPrivilegeVo != null) {
      var medsCheckStatusDraft = 'Draft';
      this.fieldDisabled = !this.sharedService.userPrivilegeVo.hasMedsCheckCreateAccess ||
        this.medsCheckVo.medsCheckStatusTitle != medsCheckStatusDraft;
    }
  }

  public loadMedsCheckDiabeteMedsCheckVo() {
    this.medsCheckDiabeteVo.medsCheckVo = this.medsCheckVo;
  }

  public loadMedsCheckService() {
    this.medsCheckDiabeteVo = {};
    this.medsCheckDiabeteVo.medsCheckVo = {};
    this.medsCheckVo = {};
    this.medsCheckVo.patientVo = {};
    this.medsCheckVo.patientVo.patientCaregiverVo = {};
    this.medsCheckVo.patientVo.addressVo = {};
    this.medsCheckVo.patientVo.doctorVo = {};
    this.medsCheckVo.medsCheckSignatureVo = {};
    this.medsCheckVo.medsCheckSupportInformationVo = {};
    this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos = [];

    this.medsCheckVo.medsCheckQuestionVo = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos = [];

    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos = [];

    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other = {};

    this.medsCheckVo.medsCheckPlanForFollowUpVo = {};
    this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos = [];

    this.medsCheckVo.medsCheckChecklistVo = {};
    this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos = [];

    this.medsCheckVo.medsCheckMedicalHistoryVo = {};
    this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos = [];

    this.medsCheckVo.medsCheckTherapeuticIssueVo = {};
    this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos = [];

    this.medsCheckDiabeteVo.medsCheckVo.patientVo = {};
    this.medsCheckDiabeteVo.medsCheckVo.patientVo.addressVo = {};
    this.medsCheckDiabeteVo.medsCheckDiabeteGeneralInformationVo = {};
    this.medsCheckDiabeteVo.medsCheckDiabeteGeneralDiabetesEducationVo = {};
    this.medsCheckDiabeteVo.medsCheckDiabeteSelfMonitoringBloodGlucoseVo = {};
    this.medsCheckDiabeteVo.medsCheckDiabeteSpecialtyTrainingVo = {};
    this.medsCheckDiabeteVo.medsCheckDiabeteReferralReasonVo = {};
    this.medsCheckDiabeteVo.medsCheckDiabeteResourcesProvidedVo = {};
    this.medsCheckDiabeteVo.medsCheckDiabeteSummaryGoalsVo = {};
    this.medsCheckDiabeteVo.medsCheckVo.patientVo.patientCaregiverVo = {};
  }
}
