import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';

@Component({
  selector: 'app-referrals-reason',
  templateUrl: './referrals-reason.component.html',
  styleUrls: ['./referrals-reason.component.css']
})
export class ReferralsReasonComponent implements OnInit {

  constructor(private medsCheckService: MedscheckService) { }

  ngOnInit() {
  }
}
