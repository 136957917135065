import { Component, OnInit, ViewChild } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { SharedService } from '../../services/shared.service';
import { PosService } from '../../services/pos.service';
import { InvoiceService } from '../../services/invoice.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class InvoiceViewComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private posService: PosService,
    private router: Router,
    private invoiceService: InvoiceService,
    private datepipe: DatePipe) { }

  @ViewChild('closeConfirmModalbutton', { static: false }) closeConfirmModalbutton;

  showErrorMessage: boolean;
  errorMessage: string;

  InvoiceId: number = 0;
  invoices: any = [];

  showLoadingMessage: boolean;
  residents: any = [];
  patientId: number = 0;
  invoiceNumber: string = "";


  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadResidents();
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  search() {
    this.invoices = [];
    if (this.patientId > 0 || this.invoiceNumber != "") {
      this.showLoadingMessage = true;

      this.invoiceService.getInvoices(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.invoiceNumber).subscribe(data => {
        if (data != null)
          this.invoices = data;
        else
          this.loadErrorMessage("Oops! The selected date range is quite extensive. Please narrow it down for more accurate results.");

        this.showLoadingMessage = false;
        this.clearErrorMessage();
      },
        err => this.showLoadingMessage = false);
    } else {
      this.loadErrorMessage("Please Select a Resident or Invoice Number.");
    }
  }

  loadResidents() {
    this.sharedService.getResidentsByFacilityId().subscribe(data => {
      this.residents = data;
      return of(this.residents);
    });
  }

  //Autocomplete Residents element
  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.getData();

    if (value == "") {
      this.patientId = 0;
    }

    this.toHighlight = value;

    var data = this.getData();
    if (data != null)
      return this.getData()
        .pipe(
          map(response => response.filter(option => {
            return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        )
    else
      return EMPTY;

  }

  getPosts(value) {
    var resident = this.residents.find(f => f.name == value);
    this.patientId = resident.id;

  }

  getData() {
    if (this.residents == null || this.residents.length == 0) {
      this.loadResidents();
      return of(this.residents);
    } else {
      return of(this.residents);
    }
  }

  openInvoice(invoiceId) {
    this.router.navigate([]).then(result => { window.open('report/invoice/' + invoiceId + '/' + 'Invoice', '_blank'); });
  }

  setInvoiceId(id) {
    this.InvoiceId = id;
  }

  removeInvoice() {
    this.invoiceService.deleteInvoice(this.InvoiceId, this.sharedService.getUserId()).subscribe(data => {
      this.sharedService.showTempSuccessMessage();
      this.closeConfirmModalbutton.nativeElement.click();
      this.search();
      window.scroll(0, 0);
    });
  }

}
