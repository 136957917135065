import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUserCreate } from '../interfaces/iuser-create';
import { IprivilegeGroupVo } from '../interfaces/iprivilege-group-vo';
import { IprescriptionReviewFrequency } from '../interfaces/iprescription-review-frequency';
import { ITrainingVo } from '../interfaces/training/itraining-vo';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public roleId: number;

  public ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  isUserNameExists(userId, userName) {
    return this.http.get<any>(this.ApiUrl + 'api/settings/IsUserNameExists/' + userId + '/' + userName);
  }

  saveUser(modifedByUserId, userCreate: IUserCreate): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/SaveUser/' + modifedByUserId;
    const body = JSON.stringify(userCreate);

    return this.http.post<any>(url, userCreate, { 'headers': headers });
  }

  getSettingsReviewers(facilityId, pharmacyId) {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetSettingsReviewers/' + facilityId + '/' + pharmacyId);
  }

  getSettingsPrescriptionReviewFrequency(facilityId, pharmacyId) {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetSettingsPrescriptionReviewFrequency/' + facilityId + '/' + pharmacyId);
  }

  addPrescriptionReviewFrequency(prescriptionReviewFrequency: IprescriptionReviewFrequency): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/AddPrescriptionReviewFrequency';
    const body = JSON.stringify(prescriptionReviewFrequency);
    return this.http.post<any>(url, prescriptionReviewFrequency, { 'headers': headers });
  }

  removePrescriptionReviewFrequency(prescriptionReviewFrequencyId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/RemovePrescriptionReviewFrequency/' + prescriptionReviewFrequencyId);
  }

  saveSettingsEprescriberReviewer(settingsEprescriberReviewersVos: any[]): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/SaveSettingsEprescriberReviewer';
    return this.http.post<any>(url, settingsEprescriberReviewersVos, { 'headers': headers });
  }

  removeSettingsEprescriberReviewerWorkflowsStep(pharmacyId: number, facilityId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/RemoveSettingsEprescriberReviewerWorkflowsStep/' + pharmacyId + '/' + facilityId);
  }

  removeSettingsPrescriptionReviewerWorkflowsStep(pharmacyId: number, facilityId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/RemoveSettingsPrescriptionReviewerWorkflowsStep/' + pharmacyId + '/' + facilityId);
  }

  saveSettingsPrescriptionReviewer(settingsPrescriptionReviewersVos: any[]): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/SaveSettingsPrescriptionReviewer';
    return this.http.post<any>(url, settingsPrescriptionReviewersVos, { 'headers': headers });
  }

  getUsers(pharmacyId: number, facilityId: number, roleId: number, searchedByUserId: string, userId: string): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetUsers/' + pharmacyId + '/' + facilityId + '/' + roleId + '/' + searchedByUserId + '/' + userId);
  }

  getRoles(): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetRoles/');
  }

  getUser(id, currentUserId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetUser/' + id + '/' + currentUserId);
  }

  getUserProfile(userId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetUserProfile/' + userId);
  }

  saveUserProfile(userProfile: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/SaveUserProfile';
    const body = JSON.stringify(userProfile);

    return this.http.post<any>(url, userProfile, { 'headers': headers });
  }

  removeUser(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/RemoveUser/' + id);
  }

  getRoleVos(): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/getRoleVos/');
  }

  getPrivilegeGroupVos(currentUserId: string): Observable<IprivilegeGroupVo[]> {
    return this.http.get<IprivilegeGroupVo[]>(this.ApiUrl + 'api/settings/GetPrivilegeGroupVos/' + currentUserId);
  }

  getRoleItemVoBy(id): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetRoleItemVoBy/' + id);
  }

  resetUserPassword(userId): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/ResetUserPassword/' + userId;
    return this.http.post<any>(url, userId, { 'headers': headers });
  }

  saveFacilityType(pharmacyId, facilityId, facilityTypeId): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/SaveFacilityType';
    return this.http.patch<any>(url,
      {
        pharmacyId: pharmacyId,
        facilityId: facilityId,
        facilityTypeId: Number.parseInt(facilityTypeId)
      },
      { 'headers': headers });
  }

  saveTraining(trainingVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/SaveTraining';
    const body = JSON.stringify(trainingVo);

    return this.http.post<any>(url, trainingVo, { 'headers': headers });
  }

  getTrainingVos(pharmacyId): Observable<ITrainingVo[]> {
    return this.http.get<ITrainingVo[]>(this.ApiUrl + 'api/settings/GetTrainingVos/' + pharmacyId);
  }

  deleteTrainingVo(trainingId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/DeleteTrainingVo/' + trainingId);
  }

  getTrainingVoBy(trainingId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetTrainingVoBy/' + trainingId);
  }


  isPharmacistRole(roleItemIds): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/IsPharmacistRole';
    const body = JSON.stringify(roleItemIds);

    return this.http.post<any>(url, roleItemIds, { 'headers': headers });
  }

  getModuleTypeVos(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/settings/GetModuleTypeVos/');
  }

  saveSettingsNotification(settingsNotificationModuleVos: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/SaveSettingsNotification';
    return this.http.post<any>(url, settingsNotificationModuleVos, { 'headers': headers });
  }

  getSettingsNotificationIndexVos(facilityId: number, pharmacyId: number): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetSettingsNotificationIndexVos/' + facilityId + '/' + pharmacyId);
  }

  getUserIpAddressHistoricVos(userId: string): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/settings/GetUserIpAddressHistoricVos/' + userId);
  }

  createUserIpAddress(userId: string, ipAddress: string): Observable<any> {
    var userIpAddressVo = { UserId: userId, IpAddress: ipAddress };
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/CreateUserIpAddress';
    return this.http.post<any>(url, userIpAddressVo, { 'headers': headers });
  }

  deleteUserIpAddress(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/DeleteUserIpAddress/' + id);
  }

  getUserIpAddressVos(userId: string): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/settings/GetUserIpAddressVos/' + userId);
  }

  getExistentUserTrainingsToResetBy(trainingId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/settings/GetExistentUserTrainingsToResetBy/' + trainingId);
  }

  getDoctorResidents(pharmacyId: number, facilityId: number): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetDoctorResidents/' + pharmacyId + '/' + facilityId);
  }

  getUserDoctors(pharmacyId: number, facilityId: number): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/GetUserDoctors/' + pharmacyId + '/' + facilityId);
  }

  saveUserResidents(userResident:any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/settings/saveUserResidents';
    return this.http.post<any>(url, userResident, { 'headers': headers });
  } 

}
