import { Component, ViewChild, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { NarcoticReconciliationService } from '../../services/narcotic-reconciliation.service';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { MatRadioChange, MatRadioButton } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-narcotic-reconciliation-purchase-others',
  templateUrl: './narcotic-reconciliation-purchase-others.component.html',
  styleUrls: ['./narcotic-reconciliation-purchase-others.component.css']
})

export class NarcoticReconciliationPurchaseOthersComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @ViewChild('closeDeleteConfirmationModal', { static: false }) closeDeleteConfirmationModal: ElementRef;

  constructor(private sharedService: SharedService,
    private narcoticReconciliationService: NarcoticReconciliationService, private datepipe: DatePipe) { }

  @Output()
  change: EventEmitter<MatRadioChange>

  showErrorMessage: boolean;
  errorMessage: string;
  showModalErrorMessage: boolean;
  modalErrorMessage: string;
  purchaseOtherItemVo: any = {};
  purchaseOtherItemVos: any[] = [];
  startDate: any;
  endDate: any;

  drug: any = {};
  drugs: any[] = [];
  invoiceDate: any;
  quantity: number;
  invoiceNumber: string;
  notes: string;
  drugPackId: number;
  hasDrugPack: boolean;
  isPacksizeChecked: boolean;
  isUpdateAction: boolean;
  isDefaultDrugPackMessage: boolean;
  maxDate: any;
  drugName: string;

  ngOnInit() {
    this.clearDrugInputField();
    this.loadDate();
    this.loadPurchaseOtherItemVos();
  }

  loadPurchaseOtherItemVos() {
    var dateStart = this.datepipe.transform(this.startDate, 'yyyy-MM-dd');
    var dateEnd = this.datepipe.transform(this.endDate, 'yyyy-MM-dd');

    this.narcoticReconciliationService.getNarcoticReconciliationPurchaseOtherItemVosBy(this.sharedService.pharmacyId, dateStart, dateEnd).subscribe(
      data => this.purchaseOtherItemVos = data);
  }

  hasDruPack(): boolean {
    return this.hasDrugPack = this.drug.drugPackVos.length > 0
  }

  onSelectDate() {
    this.maxDate = new Date();
  }


  clearDrugInputField() {
    this.drug = { id: 0 };
    this.drug.drugPackVos = [];
    this.hasDrugPack = false;
    this.isPacksizeChecked = false;
    this.purchaseOtherItemVo = {};
    this.drugName = '';
  }

  getDrugById(drugName, isUpdateAction: boolean) {
    this.isDefaultDrugPackMessage = false;

    this.getData().subscribe(drugs => {
      this.drug = drugs.find(f => f.name === drugName);
      this.hasDrugPack = false;
      this.drugName = this.drug.searchDrug;
      this.myControl.setValue(this.drug.name);

      if (isUpdateAction) {
        this.loadFormByModel();
      } else if (this.drug.drugPackVos.length > 0) {
        this.hasDrugPack = true;
        this.drug.drugPackVos[0].isDrugPackChecked = true;
        this.drugPackId = this.drug.drugPackVos[0].id;
        this.isPacksizeChecked = true;
      }
    });
  }

  onChangeDrugPack(drugPack: MatRadioChange) {
    let drugPackRadioButton: MatRadioButton = drugPack.source;
    this.drugPackId = drugPackRadioButton.value;
    this.isPacksizeChecked = drugPackRadioButton.checked;
  }

  loadModelByForm(): any {
    var validationMessage = this.validateFormFields();

    if (validationMessage == "") {
      return {
        id: this.purchaseOtherItemVo.id == null ? 0 : this.purchaseOtherItemVo.id,
        userId: this.sharedService.getUserId(),
        drugId: this.drug.id,
        drugPackId: parseInt(this.drugPackId.toString()),
        pharmacyId: this.sharedService.pharmacyId,
        invoiceDate: this.invoiceDate,
        quantity: this.quantity,
        notes: this.notes,
        invoiceNumber: this.invoiceNumber,
        createdDate: new Date()
      }
    }
    else {
      this.loadModalErrorMessage(validationMessage);
    }
  }

  validateFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.invoiceDate == null)
      errors.push('Invoice Date');

    if (this.drug.id == 0 || this.drugName == '')
      errors.push('Drug');

    if (this.quantity == null)
      errors.push('Quantity');

    if (this.drug.drugPackVos.length == 0 || !this.isPacksizeChecked)
      errors.push('Packsize');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  cleanFormFields() {
    this.invoiceDate = new Date();
    this.quantity = null;
    this.notes = '';
    this.invoiceNumber = '';
    this.clearDrugInputField();
    this.clearModalErrorMessage();
  }

  openPurchaseRecord() {
    this.drug = {};
    this.myControl.setValue("");
    this.isDefaultDrugPackMessage = true;
    this.isUpdateAction = false;
    this.hasDrugPack = false;
    this.cleanFormFields();
  }

  addPurchaseRecordOtherItemVo() {
    var purchaseOtherItemVo = this.loadModelByForm();
    this.narcoticReconciliationService.createNarcoticReconciliationPurchaseOtherItem(purchaseOtherItemVo).subscribe
      (resultVo => {
        if (resultVo.success) {
          this.closeModal.nativeElement.click();
          this.sharedService.showSuccessMessage;
          this.loadPurchaseOtherItemVos();
        } else {
          this.loadModalErrorMessage(resultVo.message);
        }
      });
  }

  openPurchaseOtherItemDeleteConfirmation(id) {
    this.narcoticReconciliationService.getNarcoticReconciliationPurchaseOtherItemVoBy(id).subscribe(
      data => this.purchaseOtherItemVo = data);
  }

  deletePurchaseOtherItemVo(id) {
    this.narcoticReconciliationService.deleteNarcoticReconciliationPurchaseOtherItemVo(id).subscribe(
      resultVo => {
        if (resultVo.success) {
          this.loadPurchaseOtherItemVos();
          this.closeDeleteConfirmationModal.nativeElement.click();
          this.sharedService.showSuccessMessage;
        } else {
          this.loadModalErrorMessage(resultVo.message);
        }
      });
  }

  editPurchaseOtherItemVo(id) {
    this.clearModalErrorMessage();
    this.isUpdateAction = true;
    this.isDefaultDrugPackMessage = false;
    this.hasDrugPack = false;
    this.narcoticReconciliationService.getNarcoticReconciliationPurchaseOtherItemVoBy(id).subscribe(
      data => {
        this.clearDrugInputField();
        this.purchaseOtherItemVo = data;
        this.drugPackId = this.purchaseOtherItemVo.drugPackId;
        this.getDrugById(this.purchaseOtherItemVo.drugName, this.isUpdateAction);
      });
  }

  loadFormByModel() {
    this.invoiceDate = this.purchaseOtherItemVo.invoiceDate;
    this.invoiceNumber = this.purchaseOtherItemVo.invoiceNumber;
    this.notes = this.purchaseOtherItemVo.notes;
    this.quantity = this.purchaseOtherItemVo.quantity;

    if (this.drug.drugPackVos.length > 0) {
      this.hasDrugPack = true;
      this.drugName = this.drug.name
      for (var i = 0; i < this.drug.drugPackVos.length; i++) {
        if (this.drug.drugPackVos[i].id == this.purchaseOtherItemVo.drugPackId) {
          this.drug.drugPackVos[i].isDrugPackChecked = true;
          this.isPacksizeChecked = true;
        }
      }
    }
  }

  onActionChangeDate() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadPurchaseOtherItemVos();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }

  }

  onActionChangeinvoiceDate(value) {
    this.invoiceDate = value;
  }

  updatePurchaseOtherItemVo() {
    var purchaseOtherItemVo = this.loadModelByForm();
    this.narcoticReconciliationService.updateNarcoticReconciliationPurchaseOtherItemVo(purchaseOtherItemVo).subscribe
      (resultVo => {
        if (resultVo.success) {
          this.closeModal.nativeElement.click();
          this.sharedService.showSuccessMessage;
          this.loadPurchaseOtherItemVos();
        } else {
          this.loadModalErrorMessage(resultVo.message);
        }
      });
  }

  loadDate() {
    this.maxDate = new Date();
    this.startDate = new Date();
    this.endDate = new Date();
    this.invoiceDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 30);
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.endDate < this.startDate)
      errors.push("The End Date must be greater then Start Date!")

    else {
      if (this.startDate == null)
        errors.push("Start date field is empty");

      if (this.endDate == null)
        errors.push("End date field is empty");
    }
    return errors;
  }

  loadModalErrorMessage(message: string) {
    this.showModalErrorMessage = true;
    this.modalErrorMessage = message;
  }

  clearModalErrorMessage() {
    this.showModalErrorMessage = false;
  }

  loadErrorMessage(message: string) {
    this.showErrorMessage = true;
    this.errorMessage = message;

  }

  savePurchaseOtherItemVo() {
    this.isUpdateAction ? this.updatePurchaseOtherItemVo() : this.addPurchaseRecordOtherItemVo();
  }


  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';



  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.toHighlight = value;

    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      return this.getData()
        .pipe(
          map(response => response.filter(option => {
            if (texts.length == 1)
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase()) && option.searchDrug.toLowerCase().includes(texts[1].toLowerCase())
          }))
        )
    }
    else
      return EMPTY;

  }

  getData(): Observable<any[]> {
    if (this.drugs == null || this.drugs.length == 0) {
      return this.sharedService.getDrugs().pipe(
        tap(data => this.drugs = data)
      );
    } else {
      return of(this.drugs);
    }
  }

}


//Highlight text in autocomplete options

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
