import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { SettingsService } from '../../../services/settings.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class SettingsUserProfileComponent implements OnInit {

  private showLoadingMessage: boolean;
  private userId: string;
  private userProfile: any = {};
  private registerForm: FormGroup;
  private showErrorMessage: boolean;
  private errorMessage: string;
  private showErrorMessageSignaturePad: boolean;
  private errorMessageSignaturePad: string;
  private signatureClass: string;
  private fileExtensionAccept: string = ".jpg, .png, .jpeg";

  constructor(private settingsService: SettingsService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router) { }

  @ViewChild('closeSignatureModal', { static: false }) closeSignatureModal: ElementRef;

  ngOnInit() {
    this.userId = this.sharedService.getUserId();
    this.sharedService.reloadUserPrivileges();
    this.getProfileUser();
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: [''],
      ocpNumber: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }

  private getProfileUser() {
    this.showLoadingMessage = true;
    this.settingsService.getUserProfile(this.userId).subscribe(data => {
      this.userProfile = data;
      this.loadDefaultImages();
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  private loadDefaultImages() {
    if (this.userProfile.pictureBase64 == null)
      this.userProfile.pictureBase64 = "../../../../assets/img/default-avatar.png";
  }

  private saveUserProfile() {
    if (!this.registerForm.invalid) {
      this.userProfile.phoneNumber = this.userProfile.phoneNumber ? this.userProfile.phoneNumber.toString() : '';
      this.userProfile.ocpNumber = this.userProfile.isPharmacistUser ? this.userProfile.ocpNumber : "";
      this.settingsService.saveUserProfile(this.userProfile).subscribe
        (resultVo => {
          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.redirectToHome();
          }
          else
            this.loadErrorMessage(resultVo.message);
        });
    } else
      this.createErrorMessage();
  }

  private redirectToHome() {
    this.spinnerService.hide();
    this.router.navigate(['/']);
  }

  private createErrorMessage() {
    var errors: string[] = [];
    this.errorMessage = "";

    if (this.registerForm.controls.email.invalid) {
      var x = 0;
    }
    if (this.registerForm.controls.firstName.invalid)
      errors.push(' First Name');

    if (this.registerForm.controls.lastName.invalid)
      errors.push('Last Name');

    if (this.registerForm.controls.email.invalid)
      errors.push('Email empty or Invalid');

    if (errors.length == 1) {
      this.errorMessage = "The field " + errors.join(", ") + " is mandatory."
      this.loadErrorMessage(this.errorMessage)
    }
    else if (errors.length > 1) {
      this.errorMessage = "The fields " + errors.join(", ") + " are mandatories."
      this.loadErrorMessage(this.errorMessage)
    }
  }

  public getSignatures = (signature) => {
    if (this.emptySignatureValidation(signature)) {
      this.loadErrorMessageSignaturePad('Please, draw your signature before SAVE.')
    }
    else {
      this.userProfile.signatureBase64 = signature;
      this.onCloseSignatureModal();
    }
  }

  private emptySignatureValidation(signature) {
    return signature.charAt(98) == '1' ? true : false;
  }

  private onCloseSignatureModal() {
    this.closeSignatureModal.nativeElement.click();
    this.clearErrorMessageSignaturePad();
  }

  private loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  private loadErrorMessageSignaturePad(message: string) {
    this.showErrorMessageSignaturePad = true;
    this.errorMessageSignaturePad = message;
  }

  private clearErrorMessageSignaturePad() {
    this.showErrorMessageSignaturePad = false;
    this.errorMessageSignaturePad = "";
  }

  private onSelectSignatureStyle(event) {
    var htmlElement: HTMLElement = event.srcElement;

    html2canvas(htmlElement).then(canvas => {
      this.userProfile.signatureBase64 = canvas.toDataURL();
    }, null);
    this.onCloseSignatureModal();
  }

  private uploadFile(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.userProfile.pictureBase64 = event.target.result;
    };
  }
}
