import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-checklist-completness',
  templateUrl: './checklist-completness.component.html',
  styleUrls: ['./checklist-completness.component.css']
})
export class ChecklistCompletnessComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private medsCheckStatusDraft: string = 'Draft';
  private medsCheckCheckListNoneAll: boolean;

  ngOnInit() {
  }

  private onchecklistNoneAll(checked) {
    this.medsCheckCheckListNoneAll = true;
    for (let i = 0; i < this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos.length; i++) {
      if (this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].title === "Other (Specify)")
        continue;

      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].checked = checked;

      if (checked) {
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notesEmpty = "";
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].listNotes = [];
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].listNotes.push("None");
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notes = "None";
      } else {
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notesEmpty = "";
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].listNotes = [];
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notes = "";
      }
    }
  }

  private onChecklistItemIsChecked(checked, index) {
    this.medsCheckCheckListNoneAll = false;
    if (!checked) {
      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty = "";
      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes = [];
      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notes = this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.join("; ");
    }
  }

  private onEnterMedsCheckQuestionChecklist(index) {
    let indexForNone: any;
    if (this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty.trim().length > 0) {
      if (this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.some(i => i == 'None')) {
        indexForNone = this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.findIndex(f => f == 'None');
        this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.splice(indexForNone, 1);
      }
      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.push(this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty.trim());
      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty = "";
      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notes = this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.join("; ");
      this.uncheckMedsCheckCheckListNoneAll(this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos);
    }
  }

  private onRemoveMedsCheckQuestionChecklist(indexForChecklistItemVos, indexForChecklistListNotes) {
    this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].listNotes.splice(indexForChecklistListNotes, 1);
    if (this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].listNotes.length == 0)
      this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].checked = false;

    this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].notes = this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].listNotes.join("; ");
    this.uncheckMedsCheckCheckListNoneAll(this.medsCheckService.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos);
  }

  private uncheckMedsCheckCheckListNoneAll(medscheckListNotes) {
    for (var i = 0; i < medscheckListNotes.length; i++) {
      if (medscheckListNotes[i].listNotes != "Notes")
        this.medsCheckCheckListNoneAll = false;
    }
  }
}
