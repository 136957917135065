import { Component, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { PatientService } from '../services/patient.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private patientService: PatientService,
    private router: Router) { }

  public patientDtos: any[] = [];
  public lastName: string = "";
  public firstName: string = "";
  public showLoadingMessage: boolean = false;
  public inactive: boolean = false;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadPatientDtos();
  }

  public searchPatient() {
    if (this.lastName == "" && this.firstName == "") {
      this.loadPatientDtos();
    } else {
      this.showLoadingMessage = true;
      this.patientService.getPatients(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.getUserId(), !this.inactive, this.lastName, this.firstName).subscribe(patients => {
        this.patientDtos = patients;
        this.showLoadingMessage = false;
      },
        err => this.showLoadingMessage = false);
    }
  }

  public openProfile(patientId: any) {
    this.router.navigate(['patient/profile/' + patientId]);
  }

  private loadPatientDtos() {
    this.showLoadingMessage = true;
    this.patientService.getPatientDtos(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.getUserId(), !this.inactive).subscribe(patientDtos => {
      this.patientDtos = patientDtos;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  onOnlyInactiveChanged() {
    this.loadPatientDtos();
  }

}
