import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { UserTrainingService } from '../services/user-training.service';

@Component({
  selector: 'app-user-training',
  templateUrl: './user-training.component.html',
  styleUrls: ['./user-training.component.css']
})
export class UserTrainingComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private trainingService: UserTrainingService,
    private router: Router) { }

  userTrainingIndexVos: any[] = [];
  showErrorMessage: boolean;
  errorMessage: string;
  userTrainingId: number;
  trainingId: number;
  userTrainingAssessmentId: number;
  trainingProgressStatusOpen: number = 1;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadUserTrainingIndexVos();
  }

  loadUserTrainingIndexVos() {
    this.trainingService.getUserTrainingIndexVos(this.sharedService.pharmacyId, this.sharedService.getUserId()).subscribe(
      data => {
        this.userTrainingIndexVos = data;
      }
    );
  }

  openClassroom(userTrainingIndexVo: any) {
    this.trainingId = userTrainingIndexVo.trainingId;
    this.userTrainingId = userTrainingIndexVo.userTrainingId;

    if (this.userTrainingId == 0)
      this.createUserTrainingVo();

    this.redirectToTrainingItem();
  }

  redirectToTrainingItem() {
    if (this.trainingId > 0 && this.userTrainingId > 0)
      this.router.navigate(['training/' + this.trainingId + '/' + this.userTrainingId]);
  }

  createTraining() {
    this.router.navigate(['settings/training/classroom/create']);
  }

  openAssessment(userTrainingIndexVo: any) {
    this.userTrainingId = userTrainingIndexVo.userTrainingId;
    this.userTrainingAssessmentId = userTrainingIndexVo.userTrainingAssessmentId;

    if (this.userTrainingId > 0)
      this.router.navigate(['assessment/' + this.userTrainingAssessmentId]);
  }

  loadUserTrainingVoForCreate(): any {
    var userTrainingVo = {
      userId: this.sharedService.getUserId(),
      trainingId: this.trainingId,
      trainingProgressStatusId: this.trainingProgressStatusOpen,
      userTrainingContentVos: []
    };

    return userTrainingVo;
  }

  createUserTrainingVo() {
    this.trainingService.createUserTrainingVo(this.loadUserTrainingVoForCreate()).subscribe(
      userTrainingId => {
        if (userTrainingId > 0) {
          this.userTrainingId = userTrainingId;
          this.redirectToTrainingItem();
        }
        else
          this.loadErrorMessage("Ops! Something went wrong.");
      });
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  openCertificate(userTrainingIndexVo: any) {
    var pageTitle = "Training Certificate";
    this.router.navigate([]).then(result => { window.open('report/TrainingCertificate/' + userTrainingIndexVo.userTrainingId + '/' + pageTitle, '_blank'); });
  }
}
