import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReorderService {

  public reorderStatusId: any;
  public reorders: any = [];
  private get reorderStatusShippedId() { return 3; }

  private ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getReorderById(reorderId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/reorder/GetReorderById/' + reorderId);
  }

  getReorderByPatientId(patientId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/reorder/GetReorderByPatientId/' + patientId);
  }

  loadReordersBy(patientId, dateStart, dateEnd, statusId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/reorder/LoadReordersBy/' + patientId + '/' + dateStart + '/' + dateEnd + '/' + statusId);
  }

  getReorders(pharmacyId, facilityId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/reorder/GetReorders/' + pharmacyId + '/' + facilityId);
  }

  getReordersByStatusId(pharmacyId, facilityId, reorderStatusId, dateStartReorder, dateEndReorder): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/reorder/GetReordersByStatusId/' + pharmacyId + '/' + facilityId + '/' + reorderStatusId + '/' + dateStartReorder + '/' + dateEndReorder);
  }

  getPrescription(pharmacyId: number, facilityId: number, nxNum: string): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/reorder/GetPrescription/' + pharmacyId + '/' + facilityId + '/' + nxNum);
  }

  createReorder(reorder: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/reorder/CreateReorder';
    return this.http.post<any>(url, reorder, { 'headers': headers });
  }

  public changeReoderStatusToShipped(reorderId): Observable<boolean> {
    return this.http.get<boolean>(this.ApiUrl + 'api/reorder/ChangeReoderStatusBy/' + reorderId + '/' + this.reorderStatusShippedId);
  }

  getTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/reorder/GetTypes/');
  }

  deleteReorder(reorderId: number, userId: string): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/reorder/DeleteReorder/' + reorderId + '/' + userId);
  }

  getReorderStatus(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/reorder/GetReorderStatus/');
  }
}
