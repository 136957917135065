import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPrescriptionReviewVo } from '../interfaces/iprescription-review-vo';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionReviewService {

  private ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  GetPrescriptionReview(prescriptionReviewId, userId): Observable<IPrescriptionReviewVo> {
    return this.http.get<any>(this.ApiUrl + 'api/PrescriptionReview/GetPrescriptionReview/' + prescriptionReviewId + '/' + userId);
  }

  GetPrescriptionsReviewIndex(pharmacyId, facilityId, patientId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/PrescriptionReview/GetPrescriptionsReviewIndex/' + pharmacyId + '/' + facilityId + '/' + patientId);
  }

  GetPrescriptionReviewPendingBy(pharmacyId, facilityId, userId, date): Observable<any[]> {
    var patientId = 0;
    var facilityUnitId = 0;
    var prescriptionReviewStatusId = 1;
    return this.GetPrescriptionReviewIndexVosByPatientId(pharmacyId, facilityId, date, userId, prescriptionReviewStatusId, facilityUnitId, patientId);
  }

  GetPrescriptionReviewIndexVosByPatientId(pharmacyId, facilityId, date, userId, prescriptionReviewStatusId, facilityUnitId, patientId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/PrescriptionReview/GetPrescriptionReviewIndexVosByDate/' + pharmacyId + '/' + facilityId + '/' + date + '/' + userId + '/' + prescriptionReviewStatusId + '/' + facilityUnitId + '/' + patientId);
  }

  GetPrescriptionReviewIndexVosByYear(pharmacyId, facilityId, patientId, year, userId, prescriptionReviewStatusId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/PrescriptionReview/GetPrescriptionReviewIndexVosByYear/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + year + '/' + userId + '/' + prescriptionReviewStatusId);
  }

  GetPatGrpMast(pharmacyId, patientId): Observable<string> {
    return this.http.get(this.ApiUrl + 'api/Integration/GetPatGrpMast/' + pharmacyId + '/' + patientId, { responseType: 'text' });
  }

  submitPrescriptionReview(prescriptionReviewVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/PrescriptionReview/SubmitPrescriptionReview/';
    const body = JSON.stringify(prescriptionReviewVo);
    return this.http.post<any>(url, prescriptionReviewVo, { 'headers': headers });
  }

  savePrescriptionReview(prescriptionReviewVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/PrescriptionReview/SavePrescriptionReview/';
    return this.http.post<any>(url, prescriptionReviewVo, { 'headers': headers });
  }

  savePharmacistReviewComment(prescriptionReviewVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/PrescriptionReview/SavePharmacistReviewComment/';
    return this.http.post<any>(url, prescriptionReviewVo, { 'headers': headers });
  }

  deletePrescriptionReview(userId, prescriptionReviewId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/PrescriptionReview/DeletePrescriptionReview/' + userId + '/' + prescriptionReviewId);
  }
}
