import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  private ApiUrl = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }


  getShipping(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/Shipping/GetShipping/' + id);
  }

  getShippings(pharmacyId, facilityId, facilityUnitId, shippingStausId, shippingTypeId, startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/Shipping/GetShippings/' + pharmacyId + '/' + facilityId + '/' + facilityUnitId + '/' + shippingStausId + '/' + shippingTypeId + '/' + startDate + '/' + endDate);
  }

  getShippingPrescriptions(pharmacyId, facilityId, facilityUnitId, shippingPrescriptionTypeId, startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/Shipping/GetShippingPrescriptions/' + pharmacyId + '/' + facilityId + '/' + facilityUnitId + '/' + shippingPrescriptionTypeId + '/' + startDate + '/' + endDate);
  }

  save(shipping: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/Shipping/';
    const body = JSON.stringify(shipping);

    return this.http.post<any>(url, shipping, { 'headers': headers });
  }

  remove(id, userId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/Shipping/Remove/' + id + '/' + userId);
  }

}
