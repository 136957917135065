import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reorder-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})


export class ReorderCreateComponent implements OnInit {
  condition: any;
  type: number;

  constructor(private sharedService: SharedService,
    private route: ActivatedRoute  ) { }

  ngOnInit() {
    this.type = Number.parseInt(this.route.snapshot.paramMap.get('type'));
    this.sharedService.reloadUserPrivileges();

  }

}
