import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private ApiUrl = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getPatients(pharmacyId, facilityId, userId: string, status: boolean, lastName, firstName): Observable<any[]> {
    if (lastName == "")
      lastName = " ";

    return this.http.get<any[]>(this.ApiUrl + 'api/Integration/GetPatients/' + pharmacyId + '/' + facilityId + '/' + userId + '/' + status + '/' + lastName + '/' + firstName);
  }

  getPatientDtos(pharmacyId, facilityId, userId: string, status): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/Integration/GetPatientDtos/' + pharmacyId + '/' + facilityId + '/' + userId + '/' + status);
  }

  getPatientsByDoctorUserId(pharmacyId, facilityId, userId: string, status, doctorUserId: string): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/Integration/GetPatientsByDoctorUserId/' + pharmacyId + '/' + facilityId + '/' + userId + '/' + status + '/' + doctorUserId);
  }

  getPatient(pharmacyId, patientId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/Integration/GetPatientDto/' + pharmacyId + '/' + patientId);
  }

  getPrescriptionDtos(pharmacyId, patientId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/Integration/GetPrescriptionDtos/' + pharmacyId + '/' + patientId);
  }

  createPatient(patientProfileDto): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/Patient/CreatePatient';
    return this.http.post<any>(url, patientProfileDto, { 'headers': headers });
  }

  getPatientDtosByFacilityRetail(pharmacyId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/Patient/getPatientDtosByFacilityRetail/' + pharmacyId);
  }
}
