import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-patient-acknowledgement',
  templateUrl: './patient-acknowledgement.component.html',
  styleUrls: ['./patient-acknowledgement.component.css']
})
export class PatientAcknowledgementComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private medsCheckStatusDraft = 'Draft';
  private signaturePath: string;
  private showErrorMessageSignaturePad: boolean = false;
  private errorMessageSignaturePad: string;
  private medsCheckReviewDate: string;
  private medsCheckReviewTime: string;

  ngOnInit() { }

  @ViewChild('closeSignatureModal', { static: false }) closeSignatureModal: ElementRef;

  public getSignatures = (signature) => {
    if (this.emptySignatureValidation(signature)) {
      this.loadErrorMessageSignaturePad('Please, draw your signature before SAVE.')
    } else {
      if (this.signaturePath == 'patient')
        this.medsCheckService.medsCheckVo.medsCheckSignatureVo.patientSignature = signature;
      if (this.signaturePath == 'pharmacist')
        this.medsCheckService.medsCheckVo.medsCheckSignatureVo.pharmacistSignature = signature;

      this.onCloseSignatureModal();
    }
  }

  private onCloseSignatureModal() {
    this.closeSignatureModal.nativeElement.click();
    this.clearErrorMessageSignaturePad();
  }

  private emptySignatureValidation(signature) {
    return signature.charAt(98) == '1' ? true : false;
  }

  private onAddSignature(event) {
    switch (event) {
      case 'patient-signature':
        this.signaturePath = 'patient';
        break;
      case 'pharmacist-signature':
        this.signaturePath = 'pharmacist';
        break;
    }
  }

  public acknowledgementValidation(): any {
    var signatureErrors: string[] = [];
    var signatureErrorMessage: string = "";

    if (this.medsCheckService.medsCheckVo.medsCheckSignatureVo.patientSignature == null || this.medsCheckService.medsCheckVo.medsCheckSignatureVo.pharmacistSignature == null)
      signatureErrors.push("Patient and Pharmacist Signature");

    if (this.medsCheckService.medsCheckVo.submittedDate == null || this.medsCheckService.medsCheckVo.createdDate == null)
      signatureErrors.push("Acknowledgement Date and MedsCheck Review Date");

    if (signatureErrors.length == 1) {
      signatureErrorMessage = "The field " + signatureErrors.join(", ") + " is mandatory."
    }

    else if (signatureErrors.length > 1) {
      signatureErrorMessage = "The fields " + signatureErrors.join(", ") + " are mandatories."
    }

    if (signatureErrors.length == 0)
      return null;

    return {
      section: 'Patient Acknowledgement' ,
      message: signatureErrorMessage
    }
  }

  private loadErrorMessageSignaturePad(message: string) {
    this.showErrorMessageSignaturePad = true;
    this.errorMessageSignaturePad = message;
  }

  private clearErrorMessageSignaturePad() {
    this.showErrorMessageSignaturePad = false;
    this.errorMessageSignaturePad = "";
  }

}
