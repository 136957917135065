import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-medication-seach-field',
  templateUrl: './medication-seach-field.component.html',
  styleUrls: ['./medication-seach-field.component.css']
})
export class MedicationSeachFieldComponent implements OnInit {

  constructor(private sharedService: SharedService) { }

  drugs: any[];
  drugId: number;
  drugName: string;
  myControl = new FormControl('', [Validators.required]);
  filteredMedications: Observable<any>;
  toHighlight: string = '';

  @Input() public medicationName: string;
  @Output() public onSelectMedication = new EventEmitter();
  @Output() public onTypeMedicationName = new EventEmitter();

  ngOnInit() {
    this.drugs = [];  
  }

  loadFilteredMedications() {
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.shareMedicationName(value);

    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value; 
      return this.sharedService.getDrugsByDefaultPharmacyId(value)
        .pipe(
          map(response => response.filter(option => {
            if (texts.length == 1) 
              return option.name.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.name.toLowerCase().includes(texts[0].toLowerCase()) && option.name.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }
  }

  shareMedicationName(value) {
    this.onTypeMedicationName.emit(value);
  }

  getMedications(value) {    
    this.sharedService.getDrugsByDefaultPharmacyId(value).subscribe(drug => {
      this.drugs = drug;

      var findInDrugs = this.drugs.find(d => d.name == value);
      
      this.onSelectMedication.emit(findInDrugs);
    });
  }
}
