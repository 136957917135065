import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  public ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getReportTrainingVos(pharmacyId, trainingId, userFacilityId, userRoleId, userTrainingProcessStatusId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/training/GetReportTrainingVos/' + pharmacyId + '/' + trainingId + '/' + userFacilityId + '/' + userRoleId + '/' + userTrainingProcessStatusId);
  }

  getTrainingVos(pharmacyId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/training/GetTrainingVos/' + pharmacyId);
  }

  getUserTrainingProcessStatusVos(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/training/GetUserTrainingProcessStatusVos');
  }

  getFacilityAdditionalReportTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/FacilityAdditionalReport/GetFacilityAdditionalReportTypes');
  }

  getAllFacilityAdditionalReports(pharmacyId: number, facilityId: number, facilityAdditionalReportTypeId: number): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/FacilityAdditionalReport/GetAllFacilityAdditionalReports/' + pharmacyId + '/' + facilityId + '/' + facilityAdditionalReportTypeId);
  }

  getFilterFacilityAdditionalReports(pharmacyId: number, facilityId: number, facilityAdditionalReportTypeId: number, filename: string): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/FacilityAdditionalReport/GetFilterFacilityAdditionalReports/' + pharmacyId + '/' + facilityId + '/' + facilityAdditionalReportTypeId + '/' + filename);
  }

  removeFacilityAdditionalReport(id: number, userId: string): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/FacilityAdditionalReport/RemoveFacilityAdditionalReport/' + id + '/' + userId);
  }

  createFacilityAdditionalReport(facilityAdditionalReport: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/FacilityAdditionalReport/CreateFacilityAdditionalReport';
    return this.http.post<any>(url, facilityAdditionalReport, { 'headers': headers });
  }

  getFacilityAdditionalReport(facilityAdditionalReportId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/FacilityAdditionalReport/GetFacilityAdditionalReport/' + facilityAdditionalReportId );
  }
}
