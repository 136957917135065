import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { Router } from '@angular/router';
import { IncidentReportService } from '../services/incident-report.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-incident-report',
  templateUrl: './incident-report.component.html',
  styleUrls: ['./incident-report.component.css']
})
export class IncidentReportComponent implements OnInit {
  
  

  constructor(private sharedService: SharedService,
    private router: Router,
    private incidentReport: IncidentReportService,
    private datepipe: DatePipe  ) { }

  @ViewChild('closebutton', { static: false }) closebutton;

  errorMessage: string;
  showErrorMessage: boolean;
  showSuccessMessage: boolean;
  status: any[];
  listIncidentReport: any[] = [];
  statusId: number;
  patientId: number;
  incidentReportId: number;
  dateStartIncidentReport: any;
  dateEndIncidentReport: any;


  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.showSuccessMessage = false;
    this.loadDate();
    this.loadStatus();
    this.statusId = 1;
    this.patientId = 0;
    if (this.sharedService.facilityId > 0) {
      this.loadIncidentReport();
    }
  }

  loadDate() {
    this.dateEndIncidentReport = new Date();
    this.dateStartIncidentReport = new Date();
    this.dateStartIncidentReport.setDate(this.dateStartIncidentReport.getDate() - 30);
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEndIncidentReport < this.dateStartIncidentReport)
      errors.push("The End Date must be greater then Start Date!")

    return errors;
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadStatus() {
    this.incidentReport.getStatus().subscribe(data => {
      this.status = data;
    })
  }

  openNewIncidentReport() {
    if (this.sharedService.facilityId > 0) {
      this.router.navigate(['/incident-report/create']);
    } else {
      this.showErrorMessage = true;
      this.errorMessage = "Missing select facility.";
      window.scroll(0, 0);
    }
  }

  onActionChangeFacility() {
    this.loadIncidentReport();
  }

  onActionFacilityUnit() {
    this.loadIncidentReport();
  }


  onActionChangeDate() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadIncidentReport();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
    
  }

  loadIncidentReport() {
    var dateStartCoverted = new Date(Date.parse(this.dateStartIncidentReport.toString()));
    var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');
    var dateEndCoverted = new Date(Date.parse(this.dateEndIncidentReport.toString()));
    var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

    this.incidentReport.getIncidentReportsByStatusId(this.sharedService.getUserId(), this.sharedService.facilityId, this.sharedService.pharmacyId, this.statusId, this.sharedService.facilityUnitId, this.patientId, dateStart, dateEnd )
      .subscribe(data => {
      this.listIncidentReport = data;
    });
  }

  onActionStatus() {
   this.loadIncidentReport();   
  }

  openReview(id) {
    this.router.navigate(['/incident-report/review/' + id]);
  }

  setIncidentReportId(id) {
    this.incidentReportId = id;
  }

  removeIncidentReport() {
    var id = this.incidentReportId;
    this.incidentReport.removeIncidentReport(id).subscribe(data => {
      this.errorMessage = "Item removed";
      this.showSuccessMessage = data;
      this.closebutton.nativeElement.click();
      this.loadIncidentReport();
      window.scroll(0, 0);
    });
  }
}
