import { Inject, ɵAPP_ID_RANDOM_PROVIDER } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public response: { dbPath: '' };
  private ApiUrl = "";
  directory: string;
  imagePath: string = "../../../assets/img/default-avatar.png";

  constructor(@Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  ngOnInit() {
    this.directory = 'User';
    this.imagePath = this.ApiUrl + 'StaticFiles/Uploads/User/lady.jpg';
  }

  public uploadFinished = (event) => {
    this.response = event;
    this.imagePath = this.ApiUrl + this.response.dbPath;
  }

  public createImgPath() {
    return this.ApiUrl+'/${serverPath}';
  }
}
