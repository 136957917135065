import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';

@Component({
  selector: 'app-summary-goals',
  templateUrl: './summary-goals.component.html',
  styleUrls: ['./summary-goals.component.css']
})
export class SummaryGoalsComponent implements OnInit {

  constructor(private medsCheckService: MedscheckService) { }

  ngOnInit() {
  }

}
