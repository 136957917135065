import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent implements OnInit {

  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;
  @Output() public onSaveSignature = new EventEmitter();
  @Output() public cancelSignature = new EventEmitter();
  signatureImg: string;

  private signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 940,
    'canvasHeight': 100
  };


  constructor() { }

  ngOnInit() {
  }

  onSignatureConfirmation() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    this.onSaveSignature.emit(this.signatureImg);
    this.clearSignature();
  }

  clearSignature() {
    if (this.signaturePad != null)
      this.signaturePad.clear();
  }

  onCancelSignature() {
    this.clearSignature();
    this.cancelSignature.emit();
  }

}
