import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.css']
})
export class MedicationComponent implements OnInit {

  @ViewChild('closeMedicalModal', { static: false }) closeMedicalModal: ElementRef;

  constructor(private sharedService: SharedService,
    private medsCheckService: MedscheckService, private datepipe: DatePipe) { }

  private medsCheckStatusDraft = 'Draft';
  private medsCheckMedicalHistoryRemoveItemsOlderThanId = "1";
  private drug: object;
  private medicationInput: string;
  private medicationInputId: number;
  private drugPharmacyId: number;
  private medicationDirectionInput: string;
  private schedule: number;
  private medsCheckMedicalHistoryItemTypeTitle: string;
  private dosageForm: string;
  private strength: string;
  private medicationSelected: boolean;
  private showErrorMessage: boolean;
  private errorMessage: string;

  ngOnInit() {
  }

  private addMedication() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateInputMedicationFormFields();

    if (resultFieldsValidation == "") {
      var firstFillDate = new Date();
      var newMedication = {
        id: 0,
        pharmacyId: this.drugPharmacyId,
        drugId: this.medicationInputId,
        medication: this.medicationInput,
        directions: this.medicationDirectionInput,
        dosageForm: this.dosageForm,
        strength: this.strength,
        firstFillDate: this.datepipe.transform(firstFillDate, 'yyyy-MM-dd'),
        medsCheckMedicalHistoryItemTypeId: this.schedule,
        medsCheckMedicalHistoryId: this.medsCheckService.medsCheckVo.medsCheckMedicalHistoryVo.id,
        medsCheckMedicalHistoryItemTypeTitle: this.getMedsCheckMedicalHistoryItemTypeTitle(this.schedule),
        comments: 'Patient is compliant to medication regimen',
        commentsForMedsCheckRecord: 'No further comments',
        notes: 'Medication providing adequate relief'

      }

      this.medsCheckService.medsCheckMedicalHistoryItemVos.unshift(newMedication);
      this.onChangeRemoveItemsOlderThan();
      this.medicationSelected = false;
      this.closeMedicalModal.nativeElement.click();
      this.cleanMedicationFields();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  private onAdherenceIsChecked(checked, index) {
    if (checked) {
      this.medsCheckService.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos[index].comments = "";
      this.medsCheckService.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos[index].notes = "";
      this.medsCheckService.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos[index].commentsForMedsCheckRecord = "";
    }
  }

  private deleteMedication(id) {
    var index = this.medsCheckService.medsCheckMedicalHistoryItemVos.findIndex(medsCheckMedicalHistoryItemVo => medsCheckMedicalHistoryItemVo.id == id);
    this.medsCheckService.medsCheckMedicalHistoryItemVos.splice(index, 1);
    this.onChangeRemoveItemsOlderThan();
  }

  private validateInputMedicationFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.schedule == null)
      errors.push('Schedule');

    if (this.medicationInput.trim().length == 0)
      errors.push('Medication');

    if (this.strength.trim().length == 0)
      errors.push('Strength');

    if (this.medicationDirectionInput.trim().length == 0)
      errors.push('Directions');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  private getMedsCheckMedicalHistoryItemTypeTitle(id) {
    switch (id) {
      case 1:
        return this.medsCheckMedicalHistoryItemTypeTitle = "RX";
      case 2:
        return this.medsCheckMedicalHistoryItemTypeTitle = "OTC";
      case 3:
        return this.medsCheckMedicalHistoryItemTypeTitle = "NHP";
    }

  }

  public loadMedicationSelected = (drug) => {
    this.medicationInputId = drug.id;
    this.medicationInput = (drug.brandName == drug.genericName) ? drug.brandName : drug.brandName + " - " + drug.genericName;
    this.drugPharmacyId = drug.pharmacyId;
    this.strength = drug.strength;
    this.dosageForm = drug.form;
    this.medicationSelected = true;
  }

  public loadMedicationTyped = (drug) => {
    if (!this.medicationSelected) {
      this.medicationInputId = 0;
      this.medicationInput = drug;
    }
  }

  public onChangeRemoveItemsOlderThan() {   
    if (this.medsCheckMedicalHistoryRemoveItemsOlderThanId == "0") {
      this.medsCheckService.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos = this.medsCheckService.medsCheckMedicalHistoryItemVos;
    } else {
      var date = new Date();
      date.setFullYear(date.getFullYear() - parseInt(this.medsCheckMedicalHistoryRemoveItemsOlderThanId));
      this.medsCheckService.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos = this.medsCheckService.medsCheckMedicalHistoryItemVos.filter(i => {
        let firstFillDate = new Date(i.firstFillDate);
        let lastFillDate = i.lastFillDate == undefined ? null : new Date(i.lastFillDate);
        let lastFillDateText = lastFillDate == null ? '0001-01-01T00:00:00' : i.lastFillDate.toString();
        return lastFillDateText != '0001-01-01T00:00:00' ? lastFillDate >= date : firstFillDate >= date;
      });
    }
  }

  private cleanMedicationFields() {
    this.medicationInputId = null;
    this.medicationInput = "";
    this.medicationDirectionInput = "";
    this.schedule = null;
    this.strength = "";
    this.dosageForm = "";
    this.clearErrorMessage();
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  private loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  private onChangeMedicationRequiredField(mandatoryInputType) {
    switch (mandatoryInputType) {
      case 'strength':
        if (this.strength.trim().length == 0)
          this.strength = "";
        break;
      case 'medicationDirectionInput':
        if (this.medicationDirectionInput.trim().length == 0)
          this.medicationDirectionInput = "";
        break;
    }
  }

}
