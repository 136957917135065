import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ITrainingVo } from '../../interfaces/training/itraining-vo';
import { SharedService } from '../../services/shared.service';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class SettingsTrainingComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private settingsService: SettingsService,
    private router: Router) { }

  private trainingVos: ITrainingVo[] = [];
  public deleteTrainingMessage: string;
  private deleteTrainingId: number;
  public showErrorMessage: boolean;
  public errorMessage: string;
  public showLoadingMessage: boolean;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadTraining();
  }

  private loadTraining() {
    this.showLoadingMessage = true;
    this.settingsService.getTrainingVos(this.sharedService.pharmacyId).subscribe(
      data => {
        this.trainingVos = data;
        this.showLoadingMessage = false;
      },
      err => this.showLoadingMessage = false
    );      
  }

  public loadDeleteTrainingConfirmation(trainingId) {
    var deleteTraining = this.trainingVos.find(t => t.id == trainingId);
    this.deleteTrainingMessage = deleteTraining.name;
    this.deleteTrainingId = deleteTraining.id;
  }
  
  public deleteTrainingVo() {
    this.settingsService.deleteTrainingVo(this.deleteTrainingId).subscribe(resultVo => {
      if (resultVo.success) {
        this.sharedService.showSuccessMessage = resultVo.success;
        this.loadTraining();
      } else {
        this.loadErrorMessage(resultVo.message);
      }
    });
  }

  public updateTrainingVo(trainingId) {
    this.router.navigate(['settings/training/classroom/update/' + trainingId]);    
  }

  public createTraining() {
    this.router.navigate(['settings/training/classroom/create']);
  }

  private loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }
}


