import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { FormControl } from '@angular/forms';
import { Observable, EMPTY, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.css']
})
export class SettingsDoctorComponent implements OnInit {

  public doctors: any[];
  usersFilter: any[];
  users: any[];
  userId: string;
  patientId: number = 0;
  residents: any = [];
  condition: boolean;
  showSuccessMessage: boolean;

  constructor(private settingsService: SettingsService,
    private router: Router,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.sharedService.facilityUnitId = 0;
    this.loadUsers();
    this.loadFilteredOptions();
  }

  loadUsers() {
    this.settingsService.getDoctorResidents(this.sharedService.pharmacyId, this.sharedService.facilityId)
      .subscribe(data => {
        this.users = data;
      });
  }

  openNew() {
    this.router.navigate(['/settings/doctors/edit']);
  }


  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.getData();

    this.toHighlight = value;

    var data = this.getData();
    if (data != null)
      return this.getData()
        .pipe(
          map(response => response.filter(option => {
            return option.fullName.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        )
    else
      return EMPTY;

  }

  getPosts(value) {
    var user = this.usersFilter.find(f => f.fullName == value);
    //this.patientId = resident.patientId;
    //this.loadEprescribers();
    this.condition = false;
    this.showSuccessMessage = false;
  }

  getData() {
    if (this.usersFilter == null || this.usersFilter.length == 0) {
      this.settingsService.getUserDoctors(this.sharedService.pharmacyId, this.sharedService.facilityId).subscribe(data => {
        this.usersFilter = data;
        return of(this.usersFilter);
      });
    } else {
      return of(this.usersFilter);
    }
  }

}

//Highlight text in autocomplete options

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
