import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { IncidentReportService } from '../../services/incident-report.service';
import { IincidentReportCreate } from '../../interfaces/iincident-report-create';
import { Observable } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { } from '@angular/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})

export class IncidentReportReviewComponent implements OnInit {
  incidentReportId: number;
  incidentReportReview: any;
  showErrorMessage: boolean;
  errorMessage: string;
  pageYoffset = 0;
  patientVo: object;
  descriptionTypeVosSaved: any;
  reportTitle: string = "INCIDENT REPORT";

  @ViewChild('onCloseDescriptionModal', { static: false }) onCloseDescriptionModal: ElementRef;
  @ViewChild('onCloseOutcomeModal', { static: false }) onCloseOutcomeModal: ElementRef;
  @ViewChild('onCloseOriginModal', { static: false }) onCloseOriginModal: ElementRef;
  @ViewChild('onCloseRiskModal', { static: false }) onCloseRiskModal: ElementRef;
  outcomeVosSaved: any;
  originIncidentVosSaved: any;
  riskFactorsVosSaved: any;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  constructor(private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private incidentReport: IncidentReportService,
    private scroll: ViewportScroller) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.incidentReportId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadFields();
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  loadFields() {
    this.incidentReport.getIncidentReport(this.sharedService.getUserId(), this.incidentReportId).subscribe(data => {
      this.incidentReportReview = data;
      this.descriptionTypeVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.descriptionTypeVos));
      this.outcomeVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.outcomeVos));
      this.originIncidentVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.originIncidentVos));
      this.riskFactorsVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.riskFactorsVos));
      this.patientVo = this.incidentReportReview.patientVo;
    });
  }

  SaveReview() {
    this.clearErrorMessageMainPage();

    if (this.sharedService.userPrivilegeVo.isPharmacist) {
      var resultFieldsValidation = this.pharmacistReviewValidation();
    }

    if (this.sharedService.userPrivilegeVo.isDoc) {
      var resultFieldsValidation = this.docReviewValidation();
    }

    if (resultFieldsValidation == "") {
      this.incidentReportReview.id = this.incidentReportId;
      this.incidentReportReview.userId = this.sharedService.getUserId(),
        this.incidentReport.reviewIncidentReport(this.incidentReportReview).subscribe
          (success => {
            if (success) {
              this.sharedService.showSuccessMessage = success;
              this.redirectToUsers();
            }
            else
              window.scroll(0, 0);
          });

    }
    else {
      this.loadErrorMessageMainPage(resultFieldsValidation);
      this.scrollToTop();
    }
  }

  pharmacistReviewValidation(): string {
    var message: string = "";

    if (this.incidentReportReview.pharmacyResponse == null || this.incidentReportReview.pharmacyResponse.trim().length == 0)
      message = "Pharmacist: Response/Action is mandatory!";

    return message;
  }


  docReviewValidation(): string {
    var message: string = "";

    if (this.incidentReportReview.action == null || this.incidentReportReview.action.trim().length == 0)
      message = "The field Health Wellness Manager: Response/Action is mandatory!";

    return message;
  }

  redirectToUsers() {
    this.router.navigate(['incident-report']);
  }

  onSaveDescription() {
    this.clearErrorMessage();

    if (!this.incidentReportReview.descriptionTypeVos.some(dt => dt.isChecked)) {
      this.loadErrorMessage("Description is required!");
    } else {
      this.descriptionTypeVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.descriptionTypeVos));
      this.onCloseDescriptionModal.nativeElement.click();
    }
  }

  onSaveOutcomes() {
    this.clearErrorMessage();

    if (!this.incidentReportReview.outcomeVos.some(dt => dt.isChecked)) {
      this.loadErrorMessage("Outcomes are required!");
    } else {
      this.outcomeVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.outcomeVos));
      this.onCloseOutcomeModal.nativeElement.click();
    }
  }

  onSaveOriginIncident() {
    this.clearErrorMessage();

    if (!this.incidentReportReview.originIncidentVos.some(dt => dt.isChecked)) {
      this.loadErrorMessage("Origin Incident is required!");
    } else {
      this.originIncidentVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.originIncidentVos));
      this.onCloseOriginModal.nativeElement.click();
    }
  }

  onSaveRiskFactor() {
    this.clearErrorMessage();

    if (!this.incidentReportReview.riskFactorsVos.some(dt => dt.isChecked)) {
      this.loadErrorMessage("Risk Factor is required!");
    } else {
      this.riskFactorsVosSaved = JSON.parse(JSON.stringify(this.incidentReportReview.riskFactorsVos));
      this.onCloseRiskModal.nativeElement.click();
    }
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  clearErrorMessageMainPage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessageMainPage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  onCloseModalOutcome() {
    this.incidentReportReview.outcomeVos = JSON.parse(JSON.stringify(this.outcomeVosSaved));
    this.clearErrorMessage();
  }

  onCloseModalDescription() {
    this.incidentReportReview.descriptionTypeVos = JSON.parse(JSON.stringify(this.descriptionTypeVosSaved));
    this.clearErrorMessage();
  }

  onCloseModalOrigin() {
    this.incidentReportReview.originIncidentVos = JSON.parse(JSON.stringify(this.originIncidentVosSaved));
    this.clearErrorMessage();
  }

  onCloseModalRisk() {
    this.incidentReportReview.riskFactorsVos = JSON.parse(JSON.stringify(this.riskFactorsVosSaved));
    this.clearErrorMessage();
  }

  public exportToPdf() {
    var pageTitle = "IncidentReport";
    this.router.navigate([]).then(result => { window.open('report/IncidentReportView/' + this.sharedService.getUserId() + '/' + this.incidentReportId + '/' + pageTitle, '_blank'); });
  }
}
