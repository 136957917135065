import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EprescriberService } from '../../services/eprescriber.service';
import { SharedService } from '../../services/shared.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SettingsService } from '../../services/settings.service';
import { IEprescriber } from '../../interfaces/ieprescriber';
import { IEprescriberReviewVo } from '../../interfaces/ieprescriber-review-vo';
import { IEprescriberReviewMedicationCheckVo } from '../../interfaces/ieprescriber-review-medication-check-vo';
import { findIndex } from 'rxjs/operators';


@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class EprescriberApprovalComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private sharedService: SharedService,
    private eprescriberService: EprescriberService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService) { }


  eprescriber: any = {
    patientVo: {}
  };

  MarTar: boolean;
  DrugRecordBook: boolean;
  ProductFromEdb: boolean;
  DirectionsChangedSticker: boolean;
  DcMedsPulled: boolean;
  TransmittedProgressNotes: boolean;
  CarePlan: boolean;
  Diet: boolean;
  Lab: boolean;
  XrayUltrasound: boolean;
  Referral: boolean;
  NotifyPOA: boolean;
  CopyAddedToChart: boolean;

  showErrorMessage: boolean;
  errorMessage: string;
  eprescriberId: number;
  ieprescriberReviewMedicationCheckVos: IEprescriberReviewMedicationCheckVo[];
  showSuccessMessage: boolean;
  eprescriberImage: string;
  showLoadingMessage: boolean;

  reportTitle: string = "EPRESCRIBER";
  notes: string = "";
  @ViewChild('openPrintOrderModal', { static: false }) openPrintOrderModalbutton;
  @ViewChild('closePrintOrderModalButton', { static: false }) closePrintOrderModalButton;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.ieprescriberReviewMedicationCheckVos = [];
    this.loadEprescriber();
  }

  loadEprescriber() {
    this.showLoadingMessage = true;
    this.eprescriberId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.eprescriberService.getEprescriberById(this.eprescriberId, this.sharedService.getUserId())
      .subscribe(data => {
        this.eprescriber = data;
        this.showLoadingMessage = false;

        if (data.eprescriberFileVos.length > 0) {
          this.eprescriberImage = data.eprescriberFileVos[0].pdfBase64File;
          if (this.eprescriberImage == null)
            this.eprescriberImage = this.settingsService.ApiUrl + data.eprescriberFileVos[0].fileName;
        }
      },
        err => this.showLoadingMessage = false);
  }

  save() {
    this.spinnerService.show();

    var resultValidation = this.validateMedicationCheck();
    if (resultValidation) {
      var ieprescriberReviewVo: IEprescriberReviewVo = {
        Id: this.eprescriberId,
        Notes: this.notes,
        ApprovedBy: this.sharedService.getUserId(),
        EprescriberReviewMedicationCheckVos: this.ieprescriberReviewMedicationCheckVos
      };

      this.eprescriberService.approveEprescriber(ieprescriberReviewVo).subscribe
        (success => {
          this.sharedService.showSuccessMessage = success;

          if (this.eprescriber.isTheCurrentUserNurse) {
            this.eprescriberService.isEprescriberReviewCompleted(this.eprescriberId).subscribe(completed => {
              if (completed) {
                this.openPrintOrderModalbutton.nativeElement.click();
              } else
                this.redirectToEprescriber();
            });
          } else
            this.redirectToEprescriber();
        });
    }
    else
      this.loadErrorMessage("Select at least one Medications Check.");

    this.spinnerService.hide();
  }

  closePrintOrderModal() {
    this.exportToPdf();
    this.closePrintOrderModalButton.nativeElement.click();
    this.redirectToEprescriber();
  }

  validateMedicationCheck() {
    if (this.eprescriber.enabledForCurrentUserReview) {
      if (this.eprescriber.nurseReviwer && this.eprescriber.isTheCurrentUserNurse) {
        return this.ieprescriberReviewMedicationCheckVos.length > 0;
      } else
        return true;
    }
    return false;
  }


  redirectToEprescriber() {
    this.spinnerService.hide();

    switch (localStorage.getItem('previousPage')) {
      case "home":
        this.router.navigate(['/']);
        break;
      case "profile":
        this.router.navigate(['patient/profile/' + this.eprescriber.patientId]);
        break;

      default:
        this.router.navigate(['eprescriber/1']);
    }

    if (localStorage.getItem('previousPage').toLowerCase() == 'home')
      this.router.navigate(['/']);
    else
      this.router.navigate(['eprescriber/1']);
  }

  checkBoxChanged(checked, id) {
    if (checked) {
      this.ieprescriberReviewMedicationCheckVos.push({ EprescriberMedicationCheckId: Number.parseInt(id) })
    } else {
      let index = this.ieprescriberReviewMedicationCheckVos.findIndex(i => i.EprescriberMedicationCheckId == id);
      this.ieprescriberReviewMedicationCheckVos.splice(index, 1);
    }
  }

  openReport(id) {
    this.showSuccessMessage = false;
    this.router.navigate(['eprescriber/report/' + id]);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  public exportToPdf() {
    var pageTitle = "ePrescriberReport";
    this.router.navigate([]).then(result => { window.open('report/eprescriber/' + this.eprescriberId + '/' + pageTitle, '_blank'); });
  }

  getSignature(signaturePath) {
    return this.sharedService.ApiUrl + signaturePath;
  }
}

