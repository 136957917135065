import { Component, Input, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';

@Component({
  selector: 'app-specialty-training',
  templateUrl: './specialty-training.component.html',
  styleUrls: ['./specialty-training.component.css']
})
export class SpecialtyTrainingComponent implements OnInit {

  constructor(private medsCheckService: MedscheckService) { }

  ngOnInit() {
  }

}
