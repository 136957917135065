import { Component, Input, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';

@Component({
  selector: 'app-resources-provided',
  templateUrl: './resources-provided.component.html',
  styleUrls: ['./resources-provided.component.css']
})
export class ResourcesProvidedComponent implements OnInit {

  constructor(private medsCheckService: MedscheckService) { }

  ngOnInit() {
  }

}
