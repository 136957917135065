import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { PatientService } from '../../../services/patient.service';
import { ReorderService } from '../../../services/reorder.service';
import { IPrescriptionVo } from '../../../interfaces/iprescription-vo';
import { IreorderCreateVo } from '../../../interfaces/ireorder-create-vo';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-create-reorder-prescription',
  templateUrl: './create-reorder-prescription.component.html',
  styleUrls: ['./create-reorder-prescription.component.css']
})
export class CreateReorderPrescriptionComponent implements OnInit {

  @ViewChild('closebutton', { static: false }) closebutton;
  @ViewChild('closenotesbutton', { static: false }) closenotesbutton;
  @ViewChild('inputMedication', { static: false }) inputMedication: ElementRef;

  @Input() public parentPageName: string;

  constructor(private sharedService: SharedService,
    private patientService: PatientService,
    private router: Router,
    private reorderService: ReorderService) { }

  public prescriptionList: any[] = [];
  private prescription: IPrescriptionVo = {} as IPrescriptionVo;
  public prescriptions: any[] = [];
  private reorder: IreorderCreateVo = {} as IreorderCreateVo;
  public rxNum: string = "";
  private patientId: number = 0;
  public showErrorMessage: boolean = false;
  public errorMessage: string = "";
  private inputIndex: number = 0;
  public inputNotes: string = "";
  public showRxNumSearchLoadingMessage: boolean = false;
  public residentfilteredOptions: Observable<any>;
  public sfResidentHighlight: string = '';

  private residents: any[] = [];
  private sfResidentFormControl = new FormControl();


  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.btAdd_click();
    }
  }

  ngOnInit() {
  }

  public loadSearchFieldResident() {

    this.residentfilteredOptions = this.sfResidentFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this.sfResident_filter(val || '')
      })
    )
  }

  public sfResident_onActionChange(value) {
    var resident = this.residents.find(f => f.fullName == value);
    this.patientId = resident.id;
    this.loadActivePrescriptions();
  }

  private sfResident_filter(value: string): Observable<any[]> {
    if (this.sfResidentFormControl.value === "") {
      this.patientId = 0;
    }

    this.sfResidentHighlight = value;

    var data = this.getResidents();
    if (data != null)
      return data
        .pipe(
          map(response => response.filter(option => {
            return option.fullName.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        )
    else
      return EMPTY;
  }

  private getResidents(): Observable<any[]> {
    if (this.residents == null || this.residents.length == 0) {
      this.sharedService.getResidentsByFacilityId().subscribe(data => {
        this.residents = data;
        return of(this.residents);
      });
    } else {
      return of(this.residents);
    }
  }

  public btAdd_click() {
    this.clearErrorMessage();
    if (this.rxNum == null || this.rxNum == "") {
      this.loadErrorMessage("Please, enter the RxNum for Reorder!");
    }
    else {
      this.reorderService.getPrescription(this.sharedService.pharmacyId, this.sharedService.facilityId, this.rxNum).subscribe
        (data => {
          this.prescription = data;

          if (this.prescription != null) {
            this.prescriptionList.push(this.prescription);

            if (this.prescription.isRecentReorder) {
              this.loadErrorMessage("Reorder made 3 days ago!");
            }
          }
          else {
            this.loadErrorMessage("RxNum not found!");
          }
        });
    }
    this.rxNum = "";
  }

  public onActionRxNumNotes(index, notes) {
    this.inputNotes = notes;
    this.inputIndex = index;

  }

  public onActionSaveNotes() {
    this.prescriptionList[this.inputIndex].notes = this.inputNotes;
    this.closenotesbutton.nativeElement.click();
  }

  public onActionRxNumDelete(rxNum) {
    for (let i = 0; i < this.prescriptionList.length; ++i) {
      if (this.prescriptionList[i].rxNum === rxNum)
        this.prescriptionList.splice(i, 1);
    }
  }

  public btSubmit_click() {
    if (this.prescriptionList.length == 0) {
      this.loadErrorMessage("Please, enter the RxNum for Reorder!");
    }
    else {
      this.reorder.pharmacyId = this.sharedService.pharmacyId;
      this.reorder.facilityId = this.sharedService.facilityId;
      this.reorder.createdBy = this.sharedService.getUserId();
      this.reorder.reorderTypeId = 1;
      this.reorder.prescriptions = this.prescriptionList;
      this.reorderService.createReorder(this.reorder).subscribe
        (resultVo => {
          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.prescriptionList = [];
            this.sharedService.loadReceivingAlert();

            if (this.parentPageName == "home")
              this.redirectToHome();
            else
              this.redirectToIndex();
          } else {
            this.loadErrorMessage(resultVo.message);
          }
        });
    }
  }

  private loadErrorMessage(message) {
    this.showErrorMessage = true;
    this.errorMessage = message;
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  private redirectToIndex() {
    this.router.navigate(['reorder']);
  }

  private redirectToHome() {
    this.router.navigate(['/']);
  }

  private loadActivePrescriptions() {
    this.showRxNumSearchLoadingMessage = true;
    this.patientService.getPrescriptionDtos(this.sharedService.pharmacyId, this.patientId).subscribe(data => {
      this.prescriptions = data;
      this.showRxNumSearchLoadingMessage = false;
    },
      err => this.showRxNumSearchLoadingMessage = false);
  }

  public selectAllEvent(checked) {
    this.prescriptions
      .forEach(p => { p.isChecked = checked });
  }

  public btCustomSearchAdd_click() {
    var selectedPrescriptions = this.prescriptions.filter(p => p.isChecked);
    selectedPrescriptions
      .forEach(p => {
        this.rxNum = p.rxNum;
        this.btAdd_click();
        p.isChecked = false;
      });
    this.closebutton.nativeElement.click();
  }

  public btCustomSearch_click() {
    this.loadSearchFieldResident();
    this.inputMedication.nativeElement.value = "";
    this.prescriptions = [];
  }

  public checkPrescription(rxNum) {
    var checkPrescription = this.prescriptions.find(p => p.rxNum == rxNum)
    checkPrescription.isChecked = true;
  }

  public cancelReoder() {
    if (this.parentPageName == "home")
      this.prescriptionList = [];
    else
      this.redirectToIndex();
  }
}
