import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../../services/settings.service';
import { Router } from '@angular/router';
import { SharedService } from '../../../services/shared.service';
import { PatientService } from '../../../services/patient.service';
import { FormControl } from '@angular/forms';
import { Observable, EMPTY, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class SettingsDoctorCreateComponent implements OnInit {

  public doctors: any[];
  usersFilter: any[];
  users: any[];
  userId: string = "";
  patientId: number = 0;
  residents: any = [];
  condition: boolean;
  showSuccessMessage: boolean;
  public showLoadingMessage: boolean = false;
  public patientDtos: any[] = [];
  errorMessage: string = "";
  showErrorMessage: boolean = false;


  constructor(private settingsService: SettingsService,
    private patientService: PatientService,
    private router: Router,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.sharedService.facilityUnitId = 0;
    this.loadFilteredOptions();
    this.loadPatientDtos();
  }


  private loadPatientDtos() {
    this.showLoadingMessage = true;
    this.patientService.getPatientsByDoctorUserId(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.getUserId(), true, this.userId).subscribe(patientDtos => {
      this.patientDtos = patientDtos;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }


  redirectToIndex() {
    this.router.navigate(['/settings/doctors']);
  }

  save() {
    var selecteds = this.patientDtos.filter(r => r.selected);
    var resultFieldsValidation = this.validateFields(selecteds);
    if (resultFieldsValidation == "") {


      var ids = selecteds.map(function (s) {
        return s.id;
      });

      var userResident: any = { userId: this.userId, residentIds: ids, pharmacyId: this.sharedService.pharmacyId };
      this.settingsService.saveUserResidents(userResident).subscribe(result => {
        if (result.success) {
          this.sharedService.showTempSuccessMessage();
          this.redirectToIndex();
        } else {
          this.showErrorMessage = false;
          this.errorMessage = "Something went wrong!";
        }
      });
    } else
      this.loadErrorMessage(resultFieldsValidation);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  validateFields(selecteds): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.userId == null || this.userId == "")
      errors.push('Doctor');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }


  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.getData();

    this.toHighlight = value;

    var data = this.getData();
    if (data != null)
      return this.getData()
        .pipe(
          map(response => response.filter(option => {
            return option.fullName.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        )
    else
      return EMPTY;

  }

  getPosts(value) {
    var user = this.usersFilter.find(f => f.fullName == value);
    this.userId = user.id;
    this.loadPatientDtos();
    this.condition = false;
    this.showSuccessMessage = false;
  }

  getData() {
    if (this.usersFilter == null || this.usersFilter.length == 0) {
      this.settingsService.getUserDoctors(this.sharedService.pharmacyId, this.sharedService.facilityId).subscribe(data => {
        this.usersFilter = data;
        return of(this.usersFilter);
      });
    } else {
      return of(this.usersFilter);
    }
  }

}

//Highlight text in autocomplete options

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
