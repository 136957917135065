import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-additional-report',
  templateUrl: './additional-report.component.html',
  styleUrls: ['./additional-report.component.css']
})
export class AdditionalReportComponent implements OnInit {
  isModalOpen = false;
  newFacilityAdditionalReport: any = {};
  fileToUpload: any;
  filename: string = null;
  newFacilityAdditionalReportTypeId: number = 0;
  newNote: string = "";

  showErrorMessage: boolean = false;
  errorMessage: string = "";

  facilityAdditionalReportId: number = 0;
  facilityAdditionalReportTypeId: number = 0;
  facilityAdditionalReportTypes: any[];
  facilityAdditionalReports: any[] = [];

  @ViewChild('closebutton', { static: false }) closebutton;
  @ViewChild('closeConfirmModalbutton', { static: false }) closeConfirmModalbutton;


  constructor(private sharedService: SharedService,
    private reportService: ReportService) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadFields();
  }

  private loadFields() {
    this.loadReportFacilityTypes();
    this.loadReportFacilities();
  }

  private loadReportFacilityTypes() {
    this.reportService.getFacilityAdditionalReportTypes().subscribe(data => {
      this.facilityAdditionalReportTypes = data;
    });
  }

  handleEnterKey() {
    if (this.filename && this.filename.trim() !== '') {
      this.onActionChangeFilterFacilityAdditionalReportType();
    } else {
      this.clearSearch();
    }
  }

  private loadReportFacilities() {
    this.reportService.getAllFacilityAdditionalReports(this.sharedService.pharmacyId, this.sharedService.facilityId, this.facilityAdditionalReportTypeId).subscribe(
      (data: any[]) => {
        this.facilityAdditionalReports = data;
      },
      (error) => {
        console.error('Error fetching report facilities:', error);
      }
    );
  }

  private loadFilterReportFacilities() {
    this.reportService.getFilterFacilityAdditionalReports(this.sharedService.pharmacyId, this.sharedService.facilityId, this.facilityAdditionalReportTypeId, this.filename).subscribe(
      (data: any[]) => {
        this.facilityAdditionalReports = data;
      },
      (error) => {
        console.error('Error fetching report facilities:', error);
      }
    );
  }

  clearSearch() {
    this.filename = "";
    this.facilityAdditionalReportTypeId = 0;
    this.loadReportFacilities();

  }
  public onActionChangeFacilityAdditionalReportType() {
    this.loadReportFacilities();
  }

  public onActionChangeFilterFacilityAdditionalReportType() {
    this.loadFilterReportFacilities();
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  openModal() {
    this.isModalOpen = true;
    this.newFacilityAdditionalReport = {};
    this.fileToUpload = null;
  }

  save() {
    var resultFieldsValidation = this.validateFields();

    if (resultFieldsValidation == "") {
      this.newFacilityAdditionalReport = {
        pharmacyId: this.sharedService.pharmacyId,
        facilityId: this.sharedService.facilityId,
        facilityAdditionalReportTypeId: Number(this.newFacilityAdditionalReportTypeId),
        CreatedBy: this.sharedService.getUserId(),
        note: this.newNote,
        attachment: this.fileToUpload,
        filename: this.filename
      };

      this.reportService.createFacilityAdditionalReport(this.newFacilityAdditionalReport)
        .subscribe(() => {
          this.loadReportFacilities();
          this.sharedService.showTempSuccessMessage();
          this.closebutton.nativeElement.click();
          this.cleanFields();
        });
    } else {
      this.loadErrorMessage(resultFieldsValidation);
    }
  }

  validateFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.newFacilityAdditionalReportTypeId == null || this.newFacilityAdditionalReportTypeId == 0)
      errors.push('Report Type');

    if (this.fileToUpload == null)
      errors.push('Attachment');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.fileToUpload = event.target.result;
      this.filename = file.name;
    };
  }

  cleanFields() {
    this.newFacilityAdditionalReportTypeId = 0;
    this.newNote = "";
    this.fileToUpload = null;
    this.filename = "";

    this.clearErrorMessage();
  }

  downloadAttachment(reportFacilityId: number): void {
    this.reportService.getFacilityAdditionalReport(reportFacilityId).subscribe(data => {
      const source = data.attachment;
      const link = document.createElement("a");
      link.href = source;
      link.download = data.filename;
      link.click();
    });
  }

  setFacilityAdditionalReportId(id) {
    this.facilityAdditionalReportId = id;
  }

  removeFacilityAdditionalReport() {
    this.reportService.removeFacilityAdditionalReport(this.facilityAdditionalReportId, this.sharedService.getUserId()).subscribe(data => {
      this.sharedService.showTempSuccessMessage();
      this.closeConfirmModalbutton.nativeElement.click();
      this.loadReportFacilities();
      window.scroll(0, 0);
    });
  }

}
