import { Component, Input, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-summary-error-message',
  templateUrl: './summary-error-message.component.html',
  styleUrls: ['./summary-error-message.component.css']
})
export class SummaryErrorMessageComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  @Input() private medsCheckVo: any;


  private showMedsCheckErrorMessage: boolean;
  private medsCheckValidationResult: boolean;
  private resultMedsCheckValidation: any[] = [];

  ngOnInit() {
  }

  public validateMedscheck(): boolean {
    this.clearErrorMessageMainPage();

    if (this.patientInfoValidation().length > 0)
      this.resultMedsCheckValidation.push({ tab: "Patient Info", error: this.patientInfoValidation(), errorMessage: "" });

    if (this.clinicalNeedValidation().length > 0)
      this.resultMedsCheckValidation.push({ tab: "Clinical Need for Services", error: this.clinicalNeedValidation(), errorMessage: "" });

    if (this.medicationValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Medication: ", error: {}, errorMessage: this.medicationValidation() });

    if (this.therapeuticIssueValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Therapeutic Issue: ", error: {}, errorMessage: this.therapeuticIssueValidation() });

    if (this.checklistValidation().length > 0)
      this.resultMedsCheckValidation.push({ tab: "Checklist: ", error: {}, errorMessage: this.checklistValidation() });

    if (this.supportInformationValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Support Info: ", error: {}, errorMessage: this.supportInformationValidation() });

    if (this.patientAcknowledgementValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Signature: ", error: {}, errorMessage: this.patientAcknowledgementValidation() });

    if (this.followUpValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Follow Up: ", error: {}, errorMessage: this.followUpValidation() });

    if (this.resultMedsCheckValidation.length > 0)
      this.loadMedsCheckErrorMessage();

    return this.medsCheckValidationResult;
  }

  private followUpValidation(): string {
    var followUpErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos.length == 0)
      followUpErrorMessage = "At least on healthcare Provider must be registered"

    return followUpErrorMessage;
  }

  private patientAcknowledgementValidation(): string {
    var signatureErrorMessage: string = "";

    if (this.medsCheckVo.submittedDate == null || this.medsCheckVo.createdDate == null)
      signatureErrorMessage += " Patient Acknowledgement Date and MedsCheck Review Date are mandatories."

    return signatureErrorMessage;
  }

  private supportInformationValidation(): string {
    var supportInformationErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.length == 0)
      supportInformationErrorMessage = "At least one type must be registered"

    return supportInformationErrorMessage;
  }

  private clinicalNeedValidation(): any {
    var clinicalNeedSummaryErrors: any[] = [];
    var clinicalNeedErrors: string[] = [];
    var clinicalNeedErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes == null || this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes.trim().length == 0)
      clinicalNeedErrors.push("Notes is a mandatory field");

    if (clinicalNeedErrors.length == 1) {
      clinicalNeedErrorMessage = clinicalNeedErrors.join(", ");
    }

    else if (clinicalNeedErrors.length > 1) {
      clinicalNeedErrorMessage = clinicalNeedErrors.join(", ");
    }

    if (clinicalNeedErrors.length > 0) {
      clinicalNeedSummaryErrors.push(
        {
          section: "Clinical Need: ",
          error: clinicalNeedErrorMessage
        });
    }

    if (this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos).length > 0) {
      clinicalNeedSummaryErrors.push(
        {
          section: "Sources Consulted: ",
          error: this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos)
        });
    }

    return clinicalNeedSummaryErrors;
  }

  private checklistValidation(): string {
    var checklistErrorMessage: string = "";

    if (this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos).length > 0)
      checklistErrorMessage = this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos);

    return checklistErrorMessage;
  }

  private patientInfoValidation(): any {
    var patientInfoErrors: any[] = [];
    var patientAddressErrors: string[] = [];
    var patientAddressErrorMessage: string = "";

    if (this.patientInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos).length > 0) {
      patientInfoErrors.push({
        section: "Lifestyle",
        error: this.patientInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos)
      });
    }

    if (this.medsCheckVo.patientVo.addressVo.streetName.trim().length == 0)
      patientAddressErrors.push("Street Name")

    if (this.medsCheckVo.patientVo.addressVo.city.trim().length == 0)
      patientAddressErrors.push("City")

    if (this.medsCheckVo.patientVo.addressVo.province.trim().length == 0)
      patientAddressErrors.push("Province")

    if (patientAddressErrors.length == 1) {
      patientAddressErrorMessage = "The field " + patientAddressErrors.join(", ") + " is mandatory."
    }

    else if (patientAddressErrors.length > 1) {
      patientAddressErrorMessage = "The fields " + patientAddressErrors.join(", ") + " are mandatories."
    }

    if (patientAddressErrors.length > 0) {
      patientInfoErrors.push({
        section: "Patient Address",
        error: patientAddressErrorMessage
      });
    }

    return patientInfoErrors;
  }

  private medicationValidation(): string {
    var medicationErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos.length < 3)
      medicationErrorMessage = "At least three medications are mandatories";

    return medicationErrorMessage;
  }

  private therapeuticIssueValidation(): string {
    var therapeuticIssueErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.length == 0)
      therapeuticIssueErrorMessage = "At least one type must be registered"

    return therapeuticIssueErrorMessage;
  }

  private basicInfoCheckBoxValidation(checkboxGroupToValidate): string {
    var message: string = "";
    var searchCheckedOptions = checkboxGroupToValidate.some(cgv => cgv.checked == true);
    var searchEmptyNotes = checkboxGroupToValidate.some(cgv => cgv.checked == true && (cgv.notes == null || cgv.listNotes.length == 0));

    if (!searchCheckedOptions)
      message = "Please select at least one Item";

    if (searchEmptyNotes)
      message = "Please include notes to the selected Item";

    return message;
  }

  private patientInfoCheckBoxValidation(checkboxGroupToValidate): string {
    var message: string = "";
    var searchCheckedOptions = checkboxGroupToValidate.some(cgv => cgv.chosen == true);
    var searchEmptyNotes = checkboxGroupToValidate.some(cgv => cgv.chosen == true && (cgv.notes == null || cgv.notes.trim().length == 0));

    if (!searchCheckedOptions)
      message = "Please select at least one Item";

    if (searchEmptyNotes)
      message = "Please include notes to the selected Item";

    return message;
  }

  private loadMedsCheckErrorMessage() {
    this.showMedsCheckErrorMessage = true;
    this.medsCheckValidationResult = false;
  }

  private clearErrorMessageMainPage() {
    this.medsCheckValidationResult = true;
    this.showMedsCheckErrorMessage = false;
    this.resultMedsCheckValidation = [];
  }
}
