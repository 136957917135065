import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-therapeutic-issues',
  templateUrl: './therapeutic-issues.component.html',
  styleUrls: ['./therapeutic-issues.component.css']
})
export class TherapeuticIssuesComponent implements OnInit {

  @ViewChild('closeTherapeuticIssueModal', { static: false }) closeTherapeuticIssueModal: ElementRef;

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private medsCheckStatusDraft: string = 'Draft';
  private errorMessage: string;
  private showErrorMessage: boolean;
  private therapeuticIssueTypeVos: any = {};
  private therapeuticIssueTypeTitle: string;
  private therapeuticIssueTypeId: number;
  private outlineSymptomConditionNotAddressed: string;
  private suggestedTherapy: string;
  private actionTaken: string;

  ngOnInit() {
    this.loadtherapeuticIssueType();
  }

  private loadtherapeuticIssueType() {
    this.medsCheckService.getMedsCheckTherapeuticIssueTypeVos().subscribe(therapeuticIssueTypeVos => {
      this.therapeuticIssueTypeVos = therapeuticIssueTypeVos;
    });
  }

  private addTherapeuticIssues() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateTherapeuticIssuesFormFields();

    if (resultFieldsValidation == "") {
      var findTherapeuticIssueTypeTitle = this.therapeuticIssueTypeVos.find(x => x.id == this.therapeuticIssueTypeId);
      this.therapeuticIssueTypeTitle = findTherapeuticIssueTypeTitle.title;

      if (this.therapeuticIssueTypeId == 9)
        this.therapeuticIssueTypeTitle = this.outlineSymptomConditionNotAddressed;

      var therapeuticIssue = {
        medsCheckTherapeuticIssueTypeId: this.therapeuticIssueTypeId,
        medsCheckTherapeuticIssueTypeTitle: this.therapeuticIssueTypeTitle,
        suggestedTherapy: this.suggestedTherapy,
        actionTaken: this.actionTaken,
        outlineSymptomConditionNotAddressed: this.outlineSymptomConditionNotAddressed
      }

      this.medsCheckService.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.push(therapeuticIssue);
      this.closeTherapeuticIssueModal.nativeElement.click();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  private validateTherapeuticIssuesFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.therapeuticIssueTypeId == 0)
      errors.push('Type');

    if (this.suggestedTherapy.trim().length == 0)
      errors.push('Suggested Therapy')

    if (this.actionTaken.trim().length == 0)
      errors.push('Action Taken')

    if (this.therapeuticIssueTypeId == 9 && this.outlineSymptomConditionNotAddressed.trim().length == 0)
      errors.push('Outline symptom or condition not addressed');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  private deleteTherapeuticIssues(index) {
    this.medsCheckService.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.splice(index, 1);
  }

  private loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  private cleanTherapeuticIssueInputFields() {
    this.therapeuticIssueTypeId = 0;
    this.therapeuticIssueTypeTitle = "";
    this.outlineSymptomConditionNotAddressed = "";
    this.suggestedTherapy = "";
    this.actionTaken = "";
  }

}
