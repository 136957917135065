import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { PosService } from '../../../services/pos.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-sales-payment',
  templateUrl: './sales-payment.component.html',
  styleUrls: ['./sales-payment.component.css']
})
export class SalesPaymentComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private posService: PosService,
    private datepipe: DatePipe) { }

  showErrorMessage: boolean;
  errorMessage: string;
  salesPaymentReportDto: any;
  searchStartDate: any;
  searchEndDate: any;
  showLoadingMessage: boolean;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
    this.loadFields();
  }

  loadDate() {
    this.searchEndDate = new Date();
    this.searchStartDate = new Date();
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.getDate(this.searchEndDate) < this.getDate(this.searchStartDate))
      errors.push("The End Date must be greater then Start Date!")
    return errors;
  }

  getDate(date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  loadFields() {
    this.showLoadingMessage = true;
    var dateStartCoverted = Date.parse(this.searchStartDate.toString());
    var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');

    var dateEndCoverted = Date.parse(this.searchEndDate.toString());
    var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

    this.posService.getSalesPaymentReport(this.sharedService.pharmacyId, dateStart, dateEnd).subscribe(data => {

      if (data != null)
        this.salesPaymentReportDto = data;
      else
        this.loadErrorMessage("Oops! The selected date range is quite extensive. Please narrow it down for more accurate results.");

      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  search() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadFields();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }
}
