import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { SharedService } from '../../services/shared.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-report-training',
  templateUrl: './report-training.component.html',
  styleUrls: ['./report-training.component.css']
})
export class ReportTrainingComponent implements OnInit {

  @ViewChild('filterUsers', { static: false }) filterUsers: ElementRef;

  constructor(private sharedService: SharedService,
    private reportService: ReportService,
    private settingsService: SettingsService,
    private router: Router) { }

  reportTrainingVos: any[] = [];
  showErrorMessage: boolean;
  errorMessage: string;
  userFormControl = new FormControl('');
  filteredUsers: Observable<any[]>;
  searchUser: string;
  trainingVos: any[] = [];
  facilityVos: any[] = [];
  userRoleVos: any[] = [];
  pharmacies: any = [];
  userTrainingProcessStatusVos: any[] = [];
  trainingId: number = 0;
  userFacilityId: number = 0;
  userRoleId: number = 0;
  userTrainingProcessStatusId: number = 0;

  ngOnInit() {
    this.loadFields();
  }

  loadFields() {
    this.sharedService.reloadUserPrivileges();
    setTimeout(() => this.filterUsers.nativeElement.focus(), 500);
    this.searchUser = '';
    this.userFacilityId = this.sharedService.facilityId;
    this.loadTrainingVos();
    this.loadFacilityVos();
    this.loadUserRoleVos();
    this.loadReportTrainingVos();
  }

  loadReportTrainingVos() {
    this.reportService.getReportTrainingVos(this.sharedService.pharmacyId, this.trainingId, this.userFacilityId, this.userRoleId, this.userTrainingProcessStatusId).subscribe(
      data => {
        this.reportTrainingVos = data;
        this.loadFilteredUsers();
      }
    );
  }

  loadTrainingVos() {
    this.reportService.getTrainingVos(this.sharedService.pharmacyId).subscribe(
      data => {
        this.trainingVos = data;
      }
    );
  }

  loadFacilityVos() {
    this.sharedService.getFacilitiesByPharmacyId().subscribe(
      data => {
        this.facilityVos = data;
      }
    );
  }

  loadUserRoleVos() {
    this.settingsService.getRoleVos().subscribe(
      data => {
        this.userRoleVos = data;
      });
  }

  loadFilteredUsers() {
    this.filteredUsers = this.userFormControl.valueChanges.pipe(
      startWith(''),
      map(u => this.reportTrainingVos.filter(r => r.userName.toLowerCase().includes(this.searchUser.toLowerCase())))
    );
  }

  onChangeSelectFilter() {
    this.loadReportTrainingVos();
  }

  openCertificate(userTrainingId: any) {
    var pageTitle = "Training Certificate";
    this.router.navigate([]).then(result => { window.open('report/TrainingCertificate/' + userTrainingId + '/' + pageTitle, '_blank'); });
  }
}
