import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { PosService } from '../../../services/pos.service';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.css']
})
export class PosBackupComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private posService: PosService) { }

  posBackups: any[] = [];
  showLoadingMessage: boolean = false;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadPosTokens();
  }


  loadPosTokens() {
    this.showLoadingMessage = true;
    this.posService.getBackups().subscribe(data => {
      this.posBackups = data;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  download(id) {
    this.showLoadingMessage = true;
    this.posService.getBackup(id).subscribe(data => {

      const source = "data:application/sql;base64," + data.file;
      const link = document.createElement("a");
      link.href = source;
      link.download = data.filename;
      link.click();

      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

}
