import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { EprescriberService } from '../../services/eprescriber.service';
import { SharedService } from '../../services/shared.service';
import { PrescriptionReviewService } from '../../services/prescription-review.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { IPrescriptionReviewMedication } from '../../interfaces/iprescription-review-medication';
import { IPrescriptionReviewVo } from '../../interfaces/iprescription-review-vo';
import { Direct } from 'protractor/built/driverProviders';
import { FormControl, Validators } from '@angular/forms';
import { Observable, EMPTY, of } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

interface PharmaAddress {
  name: string,
  address: string,
  phone: string
}


@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})

export class PrescriptionReviewReviewComponent implements OnInit {
  @ViewChild('inputMedication', { static: false }) inputMedication: ElementRef;
  @ViewChild('closebutton', { static: false }) closebutton;
  @ViewChild('inputDirections', { static: false }) inputDirections: ElementRef;
  @ViewChild('inputStrength', { static: false }) inputStrength: ElementRef;
  @ViewChild('inputEndDateTypeId', { static: false }) inputEndDateTypeId: ElementRef;
  @ViewChild('inputEndDateDuration', { static: false }) inputEndDateDuration: ElementRef;
  @ViewChild('inputEndDateDurationTypeId', { static: false }) inputEndDateDurationTypeId: ElementRef;
  @ViewChild('inputIndication', { static: false }) inputIndication: ElementRef;
  @ViewChild('cbSelectContinueAllPrescription', { static: false }) cbSelectContinueAllPrescription: ElementRef;
  @ViewChild('cbSelectContinueAllCheck', { static: false }) cbSelectContinueAllCheck: ElementRef;
  @ViewChild('cbSelectContinueAllNewOrder', { static: false }) cbSelectContinueAllNewOrder: ElementRef;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  public showLoadingMessage: boolean = false;
  pageYoffset = 0;
  prescriptionReviewStatusIdDraft = 5;
  prescriptionReviewTypeIdDiscontinue = 3;
  prescriptionReviewTypeIdHold = 2;
  prescriptionReviewTypeIdContinue = 1;
  newOrderTypeIdContinue = 1;
  checkTypeIdContinue
  showErrorMessage: boolean;
  errorMessage: string;
  showErrorMessageMedication: boolean;
  errorMessageMedication: string;
  residentVo: any;
  patientId: string;
  prescriptionReviewId: number;
  prescriptionReviewVo: IPrescriptionReviewVo;
  medication: string;
  drugId: number;
  directions: string;
  indication: string;
  strength: string;
  endDateTypeId: number;
  endDateDuration: number;
  endDateDurationTypeId: number;
  showDurationFields: boolean;
  reviewTypeContinue: number = 1;
  reviewTypeDiscontinue: number = 3;
  condition: string;
  notes: string;
  type: string;
  reportTitle: string = "PATIENT MEDICATION REVIEW";
  drugs: any[];
  prescriptionReviewMedicationTypeHeight: number = 7;
  prescriptionReviewMedicationTypeWeight: number = 6;
  prescriptionReviewMedicationTypeCreatinineCr: number = 8;
  validateQuantity: boolean = false;
  quantity: number = 0;
  totalQty: string;
  dosageFormId: number;
  dosageForm: string;
  dosageForms: any[];
  refill: number;
  daysInterval: number;
  isMedicationNarcotic: boolean;

  constructor(private sharedService: SharedService,
    private prescriptionReviewService: PrescriptionReviewService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private scroll: ViewportScroller,
    private eprescriberService: EprescriberService) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.prescriptionReviewId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadModel();
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  loadModel() {
    this.sharedService.getPatientVo(this.patientId)
      .subscribe(data => {
        this.showLoadingMessage = true;
        this.residentVo = data;
      },
        err => this.showLoadingMessage = false);

    var userId: any = this.sharedService.getUserId();
    this.prescriptionReviewService.GetPrescriptionReview(this.prescriptionReviewId, userId).subscribe(data => {
      this.showLoadingMessage = true;
      this.prescriptionReviewVo = data;
      if (this.prescriptionReviewVo.prescriptionReviewMedicationVos == null)
        this.prescriptionReviewVo.prescriptionReviewMedicationVos = [];

      this.loadDosageForms();
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
    this.drugs = [];
  }

  orderCreate(medication: string, strength: string, directions: string, indication: string) {
    this.cleanErrorMessageMedication();
    var resultFieldsValidation = this.validateAddMedication(medication, strength, directions);
    if (resultFieldsValidation == "") {

      var newMedication: IPrescriptionReviewMedication = {
        id: 0,
        type: "Prescription",
        prescriptionReviewMedicationTypeId: this.sharedService.getOrderTypeId("Prescription"),
        status: "Created",
        prescriptionReviewMedicationStatusId: this.sharedService.getOrderStatusId("Created"),
        prescriptionReviewId: this.prescriptionReviewId,
        medication: medication,
        drugId: this.drugId,
        strength: strength,
        direction: directions,
        indication: indication,
        createdBy: "",
        createdDate: "",
        reviewTypeId: null,
        endDateTypeId: null,
        endDateDuration: null,
        endDateDurationTypeId: null,
        refill: this.refill,
        drugDosageFormId: this.dosageFormId,
        drugDosageFormTitle: this.dosageForm,
        totalQty: this.totalQty,
        daysInterval: null,
        quantity: this.quantity
      }

      if (this.endDateDuration != null) {
        newMedication.endDateTypeId = Number.parseInt(this.endDateTypeId.toString());
        newMedication.endDateDuration = Number.parseInt(this.endDateDuration.toString());
        newMedication.endDateDurationTypeId = Number.parseInt(this.endDateDurationTypeId.toString());

        if (this.quantity != null && this.dosageFormId != null && this.refill != null) {
          newMedication.drugDosageFormId = this.dosageFormId;
          newMedication.drugDosageFormTitle = this.dosageForm;
          newMedication.quantity = this.quantity;
          newMedication.refill = this.refill;
          newMedication.totalQty = this.totalQty;
        }

        if (this.daysInterval)
          newMedication.daysInterval = this.daysInterval;

      }
      this.prescriptionReviewVo.prescriptionReviewMedicationVos.push(newMedication);
      this.cleanFormFields();
      this.closebutton.nativeElement.click();
    } else {
      this.loadErrorMessageMedication(resultFieldsValidation)
    }
  }

  validateOrderModify(patientInfo) {
    var message: string = "";
    if (patientInfo == "" || patientInfo == null)
      message = "The input field is empty";
    return message;
  }

  orderModify(type: string, direction: any) {
    var resultFieldsValidation = this.validateOrderModify(direction);
    if (resultFieldsValidation == "") {

      var item = this.prescriptionReviewVo.prescriptionReviewMedicationVos.find(prm => prm.type == type)
      if (item != null)
        item.direction = direction;
      else {
        var newMedication: IPrescriptionReviewMedication = {
          id: 0,
          type: type,
          status: "Modified",
          prescriptionReviewId: this.prescriptionReviewId,
          medication: "",
          drugId: null,
          strength: "",
          direction: String(direction),
          indication: "",
          createdBy: "",
          createdDate: "",
          reviewTypeId: null,
          endDateTypeId: null,
          endDateDuration: null,
          endDateDurationTypeId: null,
          daysInterval: null,
          quantity: null,
          refill: null,
          totalQty: null,
          drugDosageFormId: null,
          drugDosageFormTitle: null,
          prescriptionReviewMedicationTypeId: this.sharedService.getOrderTypeId(type),
          prescriptionReviewMedicationStatusId: this.sharedService.getOrderStatusId("Modified")
        }
        this.prescriptionReviewVo.prescriptionReviewMedicationVos.push(newMedication);
        this.loadCreatinineCrCl();
      }


      let el = document.getElementById("Orders");
      el.scrollIntoView();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation);
    }
  }

  cleanFormFields() {
    this.inputMedication.nativeElement.value = "";
    this.inputStrength.nativeElement.value = "";
    this.inputDirections.nativeElement.value = "";
    this.showDurationFields = false;
    this.endDateTypeId = 1;
    this.endDateDuration = null;
    this.endDateDurationTypeId = 1;
    this.quantity = 0;
    this.validateQuantity = false;
    this.indication = "";
    this.refill = 0;
    this.daysInterval = null;
    this.totalQty = null;
    this.drugId = null;
  }

  validateAddMedication(medication: string, strength: string, directions: string): string {
    var errors: string[] = [];
    var message: string = "";

    if (medication == "")
      errors.push('Medication');

    if (strength == "")
      errors.push('Strength');

    if (directions == "")
      errors.push('Directions');

    if (this.endDateTypeId == 2 && this.endDateDuration == null)
      errors.push('Duration');

    if ((this.daysInterval == null || this.daysInterval == 0) && this.residentVo.isFacilityTypeRetail && this.isMedicationNarcotic)
      errors.push("Days Interval");

    if ((this.quantity == null || this.quantity == 0) && this.residentVo.isFacilityTypeRetail)
      errors.push('Quantity');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  submitPrescriptionReview() {
    try {
      this.clearErrorMessage()
      this.spinnerService.show();
      var resultFieldsValidation = this.validateSaveFields();
      if (resultFieldsValidation == "") {

        this.prescriptionReviewVo.submitted = true;
        this.prescriptionReviewVo.approvedBy = this.sharedService.getUserId();
        this.prescriptionReviewService.submitPrescriptionReview(this.prescriptionReviewVo).subscribe
          (resultVo => {
            if (resultVo.success) {
              this.sharedService.showSuccessMessage = resultVo.success;
              this.redirectToPrescriptionReviewIndex();
            } else {
              this.loadErrorMessage(resultVo.message);
            }
          });

      }
      else {
        this.loadErrorMessage(resultFieldsValidation);
      }
    } catch (e) {
      this.loadErrorMessage(e);
    }
    this.spinnerService.hide();
  }

  savePrescriptionReview() {
    try {
      this.clearErrorMessage()
      this.spinnerService.show();

      this.prescriptionReviewVo.approvedBy = this.sharedService.getUserId();
      this.prescriptionReviewService.savePrescriptionReview(this.prescriptionReviewVo).subscribe
        (resultVo => {
          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.redirectToPrescriptionReviewIndex();
          } else {
            this.loadErrorMessage(resultVo.message);
          }
        });

    } catch (e) {
      this.loadErrorMessage(e);
    }
    this.spinnerService.hide();
  }

  validateSaveFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.hasReviewerCheckPending() || this.hasPhysicianCheckPending())
      message = "All Prescriptions should be reviewed."

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  hasReviewerCheckPending(): boolean {
    return this.prescriptionReviewVo.prescriptionReviewPrescriptionVos.some(prp => prp.prescriptionReviewPrescriptionWorkflowVos.some(prpw => prpw.enabled && !prpw.approved));
  }

  hasPhysicianCheckPending(): boolean {

    if (this.prescriptionReviewVo.isPhysicianReviwer) {

      var hasPrescriptionCheckPending = this.prescriptionReviewVo.prescriptionReviewPrescriptionVos.some(prp => prp.reviewTypeId == null || prp.reviewTypeId == 0);
      var hasMedicationCheckPending = this.prescriptionReviewVo.prescriptionReviewMedicationVos.some(prm => prm.reviewTypeId == 0);
      var hasCheckPending = this.prescriptionReviewVo.prescriptionReviewCheckVos.some(prc => prc.reviewTypeId == 0);

      return hasPrescriptionCheckPending || hasMedicationCheckPending || hasCheckPending;

    } else
      return false;
  }

  redirectToPrescriptionReviewIndex() {
    this.spinnerService.hide();
    this.router.navigate(['prescription-review']);
  }

  loadCreatinineCrCl() {
    this.prescriptionReviewVo.patientVo.creatinineCrCl = ((140 - this.prescriptionReviewVo.patientVo.age) * parseFloat(this.prescriptionReviewVo.patientVo.weight) * this.prescriptionReviewVo.patientVo.creatinineCrClConstant / parseFloat(this.prescriptionReviewVo.patientVo.creatinineCr)).toFixed(2);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    this.scroll.scrollToPosition([0, 0]);
  }

  loadErrorMessageMedication(message: any) {
    this.showErrorMessageMedication = true;
    this.errorMessageMedication = message.toString();
  }

  cleanErrorMessageMedication() {
    this.showErrorMessageMedication = false;
    this.errorMessageMedication = "";
  }

  deletePrescriptionReviewMedicationRow(medication: string, direction: string) {
    for (let i = 0; i < this.prescriptionReviewVo.prescriptionReviewMedicationVos.length; ++i) {
      if (this.prescriptionReviewVo.prescriptionReviewMedicationVos[i].medication === medication
        && this.prescriptionReviewVo.prescriptionReviewMedicationVos[i].direction === direction) {
        this.prescriptionReviewVo.prescriptionReviewMedicationVos.splice(i, 1);
      }
    }
  }

  PrescriptionReviewPrescriptionRadioChange(prescriptionReviewPrescriptionId) {
    var prp: any = this.prescriptionReviewVo.prescriptionReviewPrescriptionVos.find(prp => prp.id == prescriptionReviewPrescriptionId);
    var prpw = prp.prescriptionReviewPrescriptionWorkflowVos.find(prpw => prpw.enabled);
    if (prp.reviewTypeId != this.prescriptionReviewTypeIdContinue)
      this.cbSelectContinueAllPrescription.nativeElement.checked = false;
    prpw.approved = true;
  }

  checkRadioChange(checkId) {
    var crc: any = this.prescriptionReviewVo.prescriptionReviewCheckVos.find(prp => prp.id == checkId);
    if (crc.reviewTypeId != this.checkTypeIdContinue)
      this.cbSelectContinueAllCheck.nativeElement.checked = false;
  }

  newOrdersRadioChange(id) {
    var crc: any = this.prescriptionReviewVo.prescriptionReviewMedicationVos.find(prp => prp.id == id);
    if (crc.reviewTypeId != this.newOrderTypeIdContinue)
      this.cbSelectContinueAllNewOrder.nativeElement.checked = false;
  }

  selectAllEvent(checked) {
    this.prescriptionReviewVo.prescriptionReviewPrescriptionVos
      .forEach(prp => prp.prescriptionReviewPrescriptionWorkflowVos
        .filter(prpw => prpw.enabled)
        .forEach(prpw => { prpw.approved = checked }));
  }

  selectContinueAllEvent(checked) {
    if (checked) {
      this.prescriptionReviewVo.prescriptionReviewPrescriptionVos
        .forEach(prp => {
          if (prp.active)
            prp.reviewTypeId = this.reviewTypeContinue
          else
            prp.reviewTypeId = this.reviewTypeDiscontinue
        });
    }
    else {
      this.prescriptionReviewVo.prescriptionReviewPrescriptionVos
        .forEach(prp => {
          if (prp.active)
            prp.reviewTypeId = null
          else
            prp.reviewTypeId = this.reviewTypeDiscontinue
        });
    }
    this.selectAllEvent(checked);
  }

  selectMedicationAllEvent(checked) {
    if (checked) {
      this.prescriptionReviewVo.prescriptionReviewMedicationVos
        .forEach(prp => { prp.reviewTypeId = this.reviewTypeContinue });
    }
    else {
      this.prescriptionReviewVo.prescriptionReviewMedicationVos
        .forEach(prp => { prp.reviewTypeId = null });
    }
  }

  selectCheckAllEvent(checked) {
    if (checked) {
      this.prescriptionReviewVo.prescriptionReviewCheckVos
        .forEach(prp => { prp.reviewTypeId = this.reviewTypeContinue });
    }
    else {
      this.prescriptionReviewVo.prescriptionReviewCheckVos
        .forEach(prp => { prp.reviewTypeId = null });
    }
  }

  getSignature(signaturePath) {
    return this.sharedService.ApiUrl + signaturePath;
  }

  onChangeEndDate(endDateId) {
    this.showDurationFields = endDateId == "2";
  }

  public ExportToPdf() {
    var pageTitle = "PmrReport";
    this.router.navigate([]).then(result => { window.open('report/prescriptionreview/' + this.prescriptionReviewId + '/' + pageTitle, '_blank'); });
  }


  pharmacyInfo: PharmaAddress[] = [{
    name: "LMP Advantage care",
    address: "252-1885 Clements Road, Pickering ON L1W 3V4",
    phone: "(289) 660-2357"
  }];

  //Autocomplete Medication element
  myControl = new FormControl('', [Validators.required]);
  filteredMedications: Observable<any>;
  toHighlight: string = '';
  toggle = true;

  public checkClass = {
    "inputAutoRed": this.toggle,
    "inputAutoGreen": !this.toggle,
  }

  loadFilteredMedications() {
    this.toggle = true;
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }


  private _filter(value: string): Observable<any[]> {
    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;

      return this.sharedService.getDrugsBy(value)
        .pipe(
          map(response => response.filter(option => {

            if (texts.length == 1)
              return option.name.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.name.toLowerCase().includes(texts[0].toLowerCase()) && option.name.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }
    return EMPTY;
  }

  getMedications(value) {
    (async () => {
      this.getDrugs(value).subscribe(data => {
        this.drugs = data;

        var findInDrugs = this.drugs.find(d => d.name == value);
        this.strength = findInDrugs.strength;
        this.inputMedication.nativeElement.value = findInDrugs.nameWithoutStrength;
        this.drugId = findInDrugs.id;
        this.isMedicationNarcotic = findInDrugs.isNarcotic;

        this.dosageForm = findInDrugs.form;
        var findInDosageForms = this.dosageForms.find(d => d.title == this.dosageForm);
        this.dosageFormId = findInDosageForms.id;

        this.onQuantityChange();
      });
    })();
  }

  getDrugs(value): Observable<any[]> {
    return this.sharedService.getDrugsBy(value);
  }

  onQuantityChange() {
    this.quantityValidation();

    this.totalQty = (this.quantity * (this.refill + 1)).toString();

    if (this.dosageForm != null)
      this.totalQty += " " + this.dosageForm;
  }

  quantityValidation() {
    this.validateQuantity = this.quantity != 0;
  }

  onDosageFormChange(target) {
    let selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.dosageForm = selectElementText;

    this.onQuantityChange();
  }

  loadDosageForms() {
    if (this.residentVo.isFacilityTypeRetail) {
      this.eprescriberService.getDrugDosageForms(this.sharedService.pharmacyId)
        .subscribe(result => {
          this.dosageForms = result;
          this.dosageFormId = 278; //table
          this.dosageForm = "TAB (Tablet)";
          this.quantity = 0;
          this.refill = 0;
          this.onQuantityChange();
        });
    }
  }

  savePharmacistReviewComment() {
    try {
      this.clearErrorMessage()
      this.spinnerService.show();
      this.prescriptionReviewVo.pharmacistReviewBy = this.sharedService.getUserId();

      this.prescriptionReviewService.savePharmacistReviewComment(this.prescriptionReviewVo).subscribe
        (resultVo => {
          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.redirectToPrescriptionReviewIndex();
          } else {
            this.loadErrorMessage(resultVo.message);
          }
        });

    } catch (e) {
      this.loadErrorMessage(e);
    }
    this.spinnerService.hide();

  }
}
