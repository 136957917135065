import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { PosService } from '../../services/pos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class PosTokenComponent implements OnInit {
  @ViewChild('closeNewTokenButton', { static: false }) closeNewTokenButton: ElementRef;

  constructor(private sharedService: SharedService,
    private posService: PosService) { }

  posTokens: [] = [];
  posClientKey: string = "";
  posClient: string = "";
  newClient: string = "";
  showErrorMessage: boolean = false;
  errorMessage: string = "";
  showLoadingMessage: boolean = false;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadPosTokens();
    this.clearFields();
  }

  loadPosTokens() {
    this.showLoadingMessage = true;
    this.posService.getTokens().subscribe(data => {
      this.posTokens = data;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  openResetTokenComputerKeyConfirmation(posClient, posClientKey) {
    this.posClient = posClient;
    this.posClientKey = posClientKey;
  }

  resetTokenComputerKey(posClientKey) {
    this.posService.resetTokenComputerKey(this.posClientKey).subscribe(resultVo => {
      if (resultVo.success)
        this.sharedService.showTempSuccessMessage();
    });
  }

  onCreateToken() {
    if (this.newClient != "") {
      this.posService.createToken(this.newClient).subscribe(resultVo => {
        if (resultVo.success) {
          this.closeNewTokenButton.nativeElement.click();
          this.sharedService.showTempSuccessMessage();
          this.loadPosTokens();
          this.clearFields();
        } else {
          this.loadErrorMessage(resultVo.message);
        }
      });
    } else {
      this.loadErrorMessage("Client Name field is empty");
    }
  }

  loadErrorMessage(message: string) {
    this.showErrorMessage = true;
    this.errorMessage = message;
  }

  clearFields() {
    this.showErrorMessage = false;
    this.newClient = "";
  }
}
