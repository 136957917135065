import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { FormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-pharmacy',
  templateUrl: './pharmacy.component.html',
  styleUrls: ['./pharmacy.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavMenuPharmacyComponent implements OnInit {

  constructor(
    private service: SharedService,
    private router: Router) { }

  pharmacies: any = [];
  hierarchyIdSelected: any;

  ngOnInit(): void {
    this.refreshFacilities();
    this.service.loadIsIpAddressRestriction();
  }

  refreshFacilities() {
    this.service.getPharmacies().subscribe(data => {
      this.pharmacies = data;

      this.service.loadPharmacyId(data[0].id);
      this.service.loadPharmacy(data[0].name);
      this.service.loadFacilityId(data[0].facilityVos[0].id);
      this.service.loadIsFacilityPosOnly(data[0].facilityVos[0].facilityTypeId);
      this.service.loadHasPharmacyPos(data[0].hasPos);
      this.service.loadFacilities();
      this.loadHierarchyIdSelected();
    });
  }

  onFacilityChange(hierarchyId: any) {
    var hierarchy = hierarchyId.split(".");
    this.service.loadPharmacyId(hierarchy[0]);

    var facilityId = hierarchy[1];
    this.service.loadFacilityId(facilityId);

    var pharmacy = this.pharmacies.find(p => p.id == this.service.pharmacyId);
    this.service.loadPharmacy(pharmacy.name);
    this.service.loadHasPharmacyPos(pharmacy.hasPos);

    var facility = pharmacy.facilityVos.find(f => f.id == facilityId);
    this.service.loadIsFacilityPosOnly(facility.facilityTypeId);
    this.service.loadReceivingAlert();

    this.router.navigate(['/']);
  }

  loadHierarchyIdSelected() {
    this.hierarchyIdSelected = this.service.pharmacyId + '.' + this.service.facilityId;
  }
}

