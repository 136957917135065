import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { SettingsService } from '../../services/settings.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { IprescriptionReviewFrequency } from '../../interfaces/iprescription-review-frequency';
import { ISettingsEprescriberReviewers } from '../../interfaces/isettings-eprescriber-reviewers';
import { ISettingsPrescriptionReviewers } from '../../interfaces/isettings-prescription-reviewers';
import { ISettingsReviewers } from '../../interfaces/isettings-reviewers';
import { ISettingsRoleItem } from '../../interfaces/isettings-role-item';
import { ISettingsRole } from '../../interfaces/isettings-role';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.css']
})

export class SettingsFacilityComponent implements OnInit {

  @ViewChild('closeNotificationModal', { static: false }) closeNotificationModal: ElementRef;

  settingsReviewers: ISettingsReviewers;
  settingsPrescriptionReviewFrequency: IprescriptionReviewFrequency;
  settingsRole: ISettingsRole[];
  reviewerTypeId: number;
  facilities: any = [];
  showApproverList: boolean;
  facilityId: number;
  pharmacyId: number;
  eprescriberRole: any;
  prescriptionReviewRole: any;
  reviewerTypeIdEprescriber = 1;
  reviewerTypeIdPrescriptionReview = 2;
  showErrorMessage: boolean;
  errorMessage: string;
  facility: any;
  rFacilityTypeId: string;
  localFacilityId: number;
  localPharmacyId: number;

  notificationModuleErrorMessage: string;
  showNotificationModuleErrorMessage: boolean;
  settingsNotificationIndexVos: any[] = [];
  settingsNotificationItemVos: any[] = [];
  notificationCheckedItems: any[] = [];
  notificationErrorMessage: string;
  showNotificationErrorMessage: boolean;
  userNotificationModule: any;
  eventTypeCreate: number = 1;
  showLoadingMessage: boolean;
  checkAllNotificationItem: boolean;
  userVo: any;
  resultVo: any;

  constructor(private sharedService: SharedService,
    private settingsService: SettingsService,
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit(): void {
    this.sharedService.reloadUserPrivileges();
    this.showApproverList = false;
    this.settingsPrescriptionReviewFrequency = {} as IprescriptionReviewFrequency;
    this.facilityId = this.sharedService.facilityId;
    this.rFacilityTypeId = "0";
  }

  loadNotificationTab() {
    this.loadSettingsNotificationIndex();
    this.loadModuleTypeVo();
  }

  loadReviewersTab() {
    this.loadRole();
    this.loadReviewers();
  }

  loadFacilityType() {
    if (this.facilityId > 0) {
      this.facility = this.sharedService.facilities.find(f => f.id == this.facilityId);
      this.rFacilityTypeId = this.facility.facilityTypeId.toString();
    }
    else {
      this.facility = [];
      this.rFacilityTypeId = "0";
    }
  }

  onActionChange() {
    this.loadReviewers();
    this.loadPrescriptionReviewFrequency();
    this.loadFacilityType();
    this.loadSettingsNotificationIndex();
  }

  loadRole() {
    this.settingsService.getRoleVos()
      .subscribe(data => {
        this.settingsRole = data;
        this.showApproverList = true;
      });
  }

  loadReviewers() {
    this.settingsService.getSettingsReviewers(this.facilityId, this.sharedService.pharmacyId)
      .subscribe(data => {
        this.settingsReviewers = data;
      });
  }

  loadPrescriptionReviewFrequency() {
    if (this.facilityId == 0)
      this.pharmacyId = 0;
    else
      this.pharmacyId = this.sharedService.pharmacyId;

    this.settingsService.getSettingsPrescriptionReviewFrequency(this.facilityId, this.pharmacyId)
      .subscribe(data => {
        this.settingsPrescriptionReviewFrequency = data;
      });
  }

  dropEprescriber(event: CdkDragDrop<ISettingsEprescriberReviewers[]>) {
    moveItemInArray(this.settingsReviewers.settingsEprescriberReviewersVos, event.previousIndex, event.currentIndex);
    this.orderReviewerSeq(this.settingsReviewers.settingsEprescriberReviewersVos);
  }

  dropPrescriptionReview(event: CdkDragDrop<ISettingsPrescriptionReviewers[]>) {
    moveItemInArray(this.settingsReviewers.settingsPrescriptionReviewersVos, event.previousIndex, event.currentIndex);
    this.orderReviewerSeq(this.settingsReviewers.settingsPrescriptionReviewersVos);
  }

  orderReviewerSeq(array) {
    for (var i = 0; i <= array.length; i++) {
      array[i].sequence = i + 1;
    }
  }

  addEprescriber() {
    this.reviewerTypeId = this.reviewerTypeIdEprescriber;
    var findRoleId = this.settingsRole.find(f => f.name == this.eprescriberRole);
    this.pushArray(this.eprescriberRole, this.reviewerTypeId, findRoleId.id);
  }

  addPrescriptionReviewFrequency() {
    this.showErrorMessage = false;
    if (this.facilityId == 0) {
      this.errorMessage = "Default frequency can't be removed!";
      this.showErrorMessage = true;
    }
    else {
      this.settingsPrescriptionReviewFrequency.pharmacyId = this.sharedService.pharmacyId;
      this.settingsPrescriptionReviewFrequency.facilityId = this.facilityId;
      this.settingsService.addPrescriptionReviewFrequency(this.settingsPrescriptionReviewFrequency).subscribe(success => {
        if (success) {
          this.sharedService.showSuccessMessage = success;
          this.loadPrescriptionReviewFrequency();
          window.scroll(0, 0);
        }
        else {
          this.errorMessage = "Something went wrong!";
          this.showErrorMessage = true;
          window.scroll(0, 0);
        }
      });
    }
  }

  addPrescriptionReview() {
    this.reviewerTypeId = this.reviewerTypeIdPrescriptionReview;
    var findRoleId = this.settingsRole.find(f => f.name == this.prescriptionReviewRole);
    this.pushArray(this.prescriptionReviewRole, this.reviewerTypeId, findRoleId.id);
  }

  removePrescriptionReviewFrequency() {
    if (this.settingsPrescriptionReviewFrequency.facilityId == null) {
      this.errorMessage = "Default frequency can't be removed!";
      this.showErrorMessage = true;
    }
    else {
      this.settingsService.removePrescriptionReviewFrequency(this.settingsPrescriptionReviewFrequency.id).subscribe(success => {
        if (success) {
          this.sharedService.showSuccessMessage = success;
          window.scroll(0, 0);
          this.facilityId = 0;
          this.loadPrescriptionReviewFrequency();

        }
        else {
          this.errorMessage = "Something went wrong!";
          this.showErrorMessage = true;
          window.scroll(0, 0);
        }
      });
    }

  }

  checkNullInFacility(facilityIdCheck) {
    let localId: number;
    if (facilityIdCheck == 0) {
      localId = null;
    }
    else {
      localId = facilityIdCheck;
    }
    return localId;
  }

  pushArray(approver, reviewerTypeId, roleId) {
    this.localFacilityId = this.checkNullInFacility(this.facilityId);

    if (this.localFacilityId == null) {
      this.localPharmacyId = null;
    }
    else {
      this.localPharmacyId = this.sharedService.pharmacyId;
    }

    if (reviewerTypeId == this.reviewerTypeIdEprescriber) {
      let seq = this.settingsReviewers.settingsEprescriberReviewersVos.length + 1;
      this.settingsReviewers.settingsEprescriberReviewersVos.push(
        {
          id: 0,
          facilityId: this.localFacilityId,
          pharmacyId: this.localPharmacyId,
          sequence: seq,
          approver: approver,
          roleId: Number.parseInt(roleId)
        })
    }
    if (reviewerTypeId == this.reviewerTypeIdPrescriptionReview) {
      let seq = this.settingsReviewers.settingsPrescriptionReviewersVos.length + 1;
      this.settingsReviewers.settingsPrescriptionReviewersVos.push(
        {
          id: 0,
          facilityId: this.localFacilityId,
          pharmacyId: this.localPharmacyId,
          sequence: seq,
          approver: approver,
          roleId: Number.parseInt(roleId)
        })
    }
  }

  deleteOption(index, reviewerTypeId) {
    if (reviewerTypeId == this.reviewerTypeIdEprescriber) {
      this.settingsReviewers.settingsEprescriberReviewersVos.splice(index, 1);
      this.orderReviewerSeq(this.settingsReviewers.settingsEprescriberReviewersVos);
    }
    if (reviewerTypeId == this.reviewerTypeIdPrescriptionReview) {
      this.settingsReviewers.settingsPrescriptionReviewersVos.splice(index, 1);
      this.orderReviewerSeq(this.settingsReviewers.settingsPrescriptionReviewersVos);
    }
  }

  saveEprescriberReviewers() {
    if (this.settingsReviewers.settingsEprescriberReviewersVos.length != 0) {
      this.settingsService.saveSettingsEprescriberReviewer(this.settingsReviewers.settingsEprescriberReviewersVos).subscribe
        (success => {
          this.sharedService.showSuccessMessage = success;
          this.eprescriberRole = '0';
        });
    } else {
      this.settingsService.removeSettingsEprescriberReviewerWorkflowsStep(this.sharedService.pharmacyId, this.facilityId).subscribe(success => {
        if (success) {
          this.sharedService.showSuccessMessage = success;
          window.scroll(0, 0);
        }
        else {
          this.errorMessage = "Something went wrong!";
          this.showErrorMessage = true;
          window.scroll(0, 0);
        }
      });

    }
  }

  savePrescriptionReviewers() {
    if (this.settingsReviewers.settingsPrescriptionReviewersVos.length != 0) {
      this.settingsService.saveSettingsPrescriptionReviewer(this.settingsReviewers.settingsPrescriptionReviewersVos).subscribe
        (success => {
          this.sharedService.showSuccessMessage = success;
          this.prescriptionReviewRole = '0';
        });
    } else {
      this.settingsService.removeSettingsPrescriptionReviewerWorkflowsStep(this.sharedService.pharmacyId, this.facilityId).subscribe(success => {
        if (success) {
          this.sharedService.showSuccessMessage = success;
          window.scroll(0, 0);
        }
        else {
          this.errorMessage = "Something went wrong!";
          this.showErrorMessage = true;
          window.scroll(0, 0);
        }
      });

    }
  }

  saveFacilityType() {
    this.settingsService.saveFacilityType(this.sharedService.pharmacyId, this.facilityId, this.rFacilityTypeId).subscribe(success => {
      if (success)
        this.sharedService.showSuccessMessage = success;
      this.sharedService.loadFacilities();
    });
  }

  loadSettingsNotificationIndex() {
    this.settingsService.getSettingsNotificationIndexVos(this.facilityId, this.sharedService.pharmacyId).subscribe(
      data => {
        this.settingsNotificationIndexVos = data;
      });
  }

  editNotificationModule(userId: string) {
    this.clearSettingsModuleTypeVos();
    this.userVo = this.settingsNotificationIndexVos.find(u => u.userId == userId);
    this.mapperUserSettingsNotificationItemVos();
  }

  onCheckAllNotificationItem() {
    this.settingsNotificationItemVos.map(
      settingsNotificationItem => settingsNotificationItem.isChecked = this.checkAllNotificationItem);
  }

  mapperUserSettingsNotificationItemVos() {
    this.settingsNotificationItemVos.map(
      settingsNotificationItem => {
        settingsNotificationItem.isChecked = this.userVo.settingsNotificationItemVos.some(
          userNotificationItem => userNotificationItem.moduleTypeId == settingsNotificationItem.moduleTypeId)
      });

    this.checkAllNotificationItem = !this.settingsNotificationItemVos.some(ni => ni.isChecked == false);
  }

  loadModuleTypeVo() {
    this.settingsService.getModuleTypeVos().subscribe(
      data => this.settingsNotificationItemVos = data);
  }

  loadNotificationModuleModel(): any {
    this.notificationCheckedItems = [];
    var settingsNotificationIndexVo = {
      userId: this.userVo.userId,
      pharmacyId: this.sharedService.pharmacyId,
      facilityId: this.facilityId,
      settingsNotificationItemVos: [],
    }

    this.notificationCheckedItems = this.settingsNotificationItemVos.filter(settingsNotificationItem => settingsNotificationItem.isChecked);

    this.notificationCheckedItems.map((notificationCheckedItem) => {
      settingsNotificationIndexVo.settingsNotificationItemVos.push(
        {
          moduleTypeId: notificationCheckedItem.moduleTypeId,
          moduleTitle: notificationCheckedItem.moduleTitle,
          eventTypeId: notificationCheckedItem.eventTypeId,
        })
    })

    return settingsNotificationIndexVo;
  }

  saveUserNotificationModule() {
    this.settingsService.saveSettingsNotification(this.loadNotificationModuleModel()).subscribe(
      resultVo => {
        if (resultVo.success) {
          this.sharedService.showSuccessMessage = true;
          this.loadSettingsNotificationIndex();
          this.closeNotificationModal.nativeElement.click();
        }
        else
          this.loadErrorMessage(resultVo.message);
      });
  }

  clearSettingsModuleTypeVos() {
    this.clearNotificationErrorMessage();
    this.checkAllNotificationItem = false;
    this.settingsNotificationItemVos.map(n => n.isChecked = false);
  }

  loadNotificationErrorMessage(message: any) {
    this.notificationErrorMessage = message.toString();
    this.showNotificationErrorMessage = true;
  }

  clearNotificationErrorMessage() {
    this.showNotificationErrorMessage = false;
  }

  loadErrorMessage(message: any) {
    this.errorMessage = message.toString();
    this.showErrorMessage = true;
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
  }

}



