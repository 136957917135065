import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MedscheckService } from '../../services/medscheck.service';
import { ViewportScroller } from '@angular/common';
import { DatePipe } from '@angular/common';
import { PatientInformationComponent } from '../../shared-components/medscheck/patient-information/patient-information.component';
import { PatientAcknowledgementComponent } from '../../shared-components/medscheck/patient-acknowledgement/patient-acknowledgement.component';
import { SupportInformationComponent } from '../../shared-components/medscheck/support-information/support-information.component';
import { MedicationComponent } from '../../shared-components/medscheck/medication/medication.component';
import { SummaryErrorMessageComponent } from '../../shared-components/medscheck/summary-error-message/summary-error-message.component';

@Component({
  selector: 'app-annual-diabetes',
  templateUrl: './annual-diabetes.component.html',
  styleUrls: ['./annual-diabetes.component.css']
})
export class AnnualDiabetesComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private medsCheckService: MedscheckService,
    private router: Router,
    private route: ActivatedRoute,
    private scroll: ViewportScroller,
    private datepipe: DatePipe) { }

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  @ViewChild(PatientInformationComponent, { static: false }) patientInformationComponent: PatientInformationComponent;
  @ViewChild(PatientAcknowledgementComponent, { static: false }) patientAcknowledgementComponent: PatientAcknowledgementComponent;
  @ViewChild(SupportInformationComponent, { static: false }) supportInformationComponent: SupportInformationComponent;
  @ViewChild(MedicationComponent, { static: false }) medicationComponent: MedicationComponent;
  @ViewChild(SummaryErrorMessageComponent, { static: false }) summaryErrorMessageComponent: SummaryErrorMessageComponent;

  @ViewChild('tabPatientInfo', { static: false }) tabPatientInfo: ElementRef;
  @ViewChild('tabGeneralInfo', { static: false }) tabGeneralInfo: ElementRef;
  @ViewChild('tabGeneralDiabetesEducation', { static: false }) tabGeneralDiabetesEducation: ElementRef;
  @ViewChild('tabSelfMonitoringBloodGlucose', { static: false }) tabSelfMonitoringBloodGlucose: ElementRef;
  @ViewChild('tabSpecialtyTraining', { static: false }) tabSpecialtyTraining: ElementRef;
  @ViewChild('tabResourcesProvided', { static: false }) tabResourcesProvided: ElementRef;
  @ViewChild('tabReferralReason', { static: false }) tabReferralReason: ElementRef;
  @ViewChild('tabSupportInformation', { static: false }) tabSupportInformation: ElementRef;
  @ViewChild('tabSummaryGoals', { static: false }) tabSummaryGoals: ElementRef;
  @ViewChild('tabSignature', { static: false }) tabSignature: ElementRef;
  @ViewChild('tabClinicalNeedServices', { static: false }) tabClinicalNeedServices: ElementRef;
  @ViewChild('tabMedication', { static: false }) tabMedication: ElementRef;
  @ViewChild('tabTherapeuticIssues', { static: false }) tabTherapeuticIssues: ElementRef;
  @ViewChild('tabChecklist', { static: false }) tabChecklist: ElementRef;
  @ViewChild('tabFollowUp', { static: false }) tabFollowUp: ElementRef;

  @ViewChild('tabPatientInfoBottom', { static: false }) tabPatientInfoBottom: ElementRef;
  @ViewChild('tabGeneralInfoBottom', { static: false }) tabGeneralInfoBottom: ElementRef;
  @ViewChild('tabGeneralDiabetesEducationBottom', { static: false }) tabGeneralDiabetesEducationBottom: ElementRef;
  @ViewChild('tabSelfMonitoringBloodGlucoseBottom', { static: false }) tabSelfMonitoringBloodGlucoseBottom: ElementRef;
  @ViewChild('tabSpecialtyTrainingBottom', { static: false }) tabSpecialtyTrainingBottom: ElementRef;
  @ViewChild('tabResourcesProvidedBottom', { static: false }) tabResourcesProvidedBottom: ElementRef;
  @ViewChild('tabReferralReasonBottom', { static: false }) tabReferralReasonBottom: ElementRef;
  @ViewChild('tabSupportInformationBottom', { static: false }) tabSupportInformationBottom: ElementRef;
  @ViewChild('tabSummaryGoalsBottom', { static: false }) tabSummaryGoalsBottom: ElementRef;
  @ViewChild('tabSignatureBottom', { static: false }) tabSignatureBottom: ElementRef;
  @ViewChild('tabClinicalNeedServicesBottom', { static: false }) tabClinicalNeedServicesBottom: ElementRef;
  @ViewChild('tabMedicationBottom', { static: false }) tabMedicationBottom: ElementRef;
  @ViewChild('tabTherapeuticIssuesBottom', { static: false }) tabTherapeuticIssuesBottom: ElementRef;
  @ViewChild('tabChecklistBottom', { static: false }) tabChecklistBottom: ElementRef;
  @ViewChild('tabFollowUpBottom', { static: false }) tabFollowUpBottom: ElementRef;

  private reportTitle: string = "MEDSCHECK DIABETES ANNUAL";
  private showErrorMessage: boolean;
  private errorMessage: string;
  private patientId: string;
  private patientVo: any = {};
  private medsCheckId: string;
  private pageYoffset: number = 0;
  private printPharmacistsWorksheet: boolean = true;
  private printPatientTakeHomeSummary: boolean = true;
  private printPersonalMedicationRecord: boolean = true;
  private printHealthcareProviderNotificationOfMedsCheckServices: boolean = true;
  private printPatientAcknowledgement: boolean = true;
  private printDiabetesEducationChecklist: boolean = true;
  private printDiabetesEducationPatientTakeHomeSummary: boolean = true;
  private isMedsCheckDiabete: boolean = true;
  private medsCheckErrorMessages: any[] = [];
  private showMedsCheckErrorMessage: boolean;

  ngOnInit() {
    this.sharedService.showLoadingMessage = true;
    this.sharedService.reloadUserPrivileges();
    this.medsCheckId = this.route.snapshot.paramMap.get('medsCheckId');
    this.medsCheckService.loadMedsCheckService();
    this.loadMedsCheckDiabeteVo();
    this.scroll.scrollToPosition([0, 0]);
  }

  private loadMedsCheckDiabeteVo() {
    this.medsCheckService.loadMedsCheckDiabeteVoByMedsCheckId(this.medsCheckId);
  }

  private redirectToResidentProfile() {
    this.router.navigate(['patient/profile/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckVo.patientVo.id]);
  }

  private saveAsDraftMedsCheckDiabeteAndRedirect() {
    this.saveAsDraftMedsCheckDiabete(true)
  }

  private saveAsDraftMedsCheckDiabete(redirect: boolean) {
    this.medsCheckService.loadMedsCheckDiabeteMedsCheckVo();
    this.updateMedsCheckVoCreatedDate();
    this.medsCheckService.saveAsDraftMedsCheckDiabete().subscribe(
      resultVo => {
        if (resultVo.success) {
          this.sharedService.showTempSuccessMessage();
          redirect ? this.redirectToResidentProfile() : this.exportToPdf();
        }
        else {
          this.loadErrorMessage(resultVo.message);
        }
      }
    )
  }

  private submitMedsCheckDiabeteAndRedirect() {
    if (this.summaryErrorMessageComponent.validateMedscheck()) {
      this.medsCheckService.loadMedsCheckDiabeteMedsCheckVo();
      this.updateMedsCheckVoCreatedDate();

      this.medsCheckService.loadMedsCheckDiabeteMedsCheckVo();
      this.medsCheckService.submitMedsCheckDiabete().subscribe(
        resultVo => {
          if (resultVo.success) {
            this.sharedService.showTempSuccessMessage();
            this.redirectToResidentProfile();
          }
          else {
            this.loadErrorMessage(resultVo.message);
          }
        });
    }
    else
      this.scroll.scrollToPosition([0, 0]);
  }

  private updateMedsCheckVoCreatedDate() {
    this.medsCheckService.medsCheckVo.createdDate = this.datepipe.transform(this.medsCheckService.medsCheckVo.createdDate, 'yyyy-MM-dd') + "T" + this.medsCheckService.medsCheckReviewTime;
  }

  private loadErrorMessage(message: string) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    this.scroll.scrollToPosition([0, 0]);
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  private loadMedsCheckErrorMessage(message: any) {
    this.showMedsCheckErrorMessage = true;
    this.medsCheckErrorMessages = message;
    this.scroll.scrollToPosition([0, 0]);
  }

  private clearMedsCheckErrorMessage() {
    this.showMedsCheckErrorMessage = false;
    this.medsCheckErrorMessages = [];
  }

  private saveAndExportToPdf() {
    this.saveAsDraftMedsCheckDiabete(false);
  }

  private exportToPdf() {
    this.router.navigate([]).then(result => {
      if (this.printPharmacistsWorksheet)
        window.open('report/PharmacistsWorksheet/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckVo.id + '/Pharmacists Worksheet', '_blank');
      if (this.printPatientTakeHomeSummary)
        window.open('report/PatientTakeHomeSummary/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckVo.id + '/' + this.isMedsCheckDiabete + '/Patient Take Home Summary', '_blank');
      if (this.printPersonalMedicationRecord)
        window.open('report/PersonalMedicationRecord/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckVo.id + '/Personal Medication Record', '_blank');
      if (this.printHealthcareProviderNotificationOfMedsCheckServices)
        window.open('report/HealthCareProviderNotificationMedsCheckServices/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckVo.id + '/Healthcare Provider Notification', '_blank');
      if (this.printPatientAcknowledgement)
        window.open('report/PatientAcknowledgement/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckVo.id + '/' + this.isMedsCheckDiabete + '/Patient Acknowledgement', '_blank');
      if (this.printDiabetesEducationPatientTakeHomeSummary)
        window.open('report/PatientTakeHomeSummary/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckId + '/' + this.isMedsCheckDiabete + '/Diabetes Education Patient Take Home Summary', '_blank');
      if (this.printDiabetesEducationChecklist)
        window.open('report/DiabetesEducationChecklist/' + this.medsCheckService.medsCheckDiabeteVo.medsCheckId + '/Diabetes Education Checklist', '_blank');
    });
  }

  private onClickTabOption(id) {
    this.clearErrorMessage();
    switch (id) {
      case 'tabPatientInfo':
        this.tabPatientInfo.nativeElement.click();
        this.tabPatientInfoBottom.nativeElement.click();
        break;
      case 'tabClinicalNeedServices':
        this.tabClinicalNeedServices.nativeElement.click();
        this.tabClinicalNeedServicesBottom.nativeElement.click();
        break;
      case 'tabMedication':
        this.tabMedication.nativeElement.click();
        this.tabMedicationBottom.nativeElement.click();
        this.medicationComponent.onChangeRemoveItemsOlderThan();
        break;
      case 'tabTherapeuticIssues':
        this.tabTherapeuticIssues.nativeElement.click();
        this.tabTherapeuticIssuesBottom.nativeElement.click();
        break;
      case 'tabChecklist':
        this.tabChecklist.nativeElement.click();
        this.tabChecklistBottom.nativeElement.click();
        break;
      case 'tabGeneralInfo':
        this.tabGeneralInfo.nativeElement.click();
        this.tabGeneralInfoBottom.nativeElement.click();
        break;
      case 'tabGeneralDiabetesEducation':
        this.tabGeneralDiabetesEducation.nativeElement.click();
        this.tabGeneralDiabetesEducationBottom.nativeElement.click();
        break;
      case 'tabSelfMonitoringBloodGlucose':
        this.tabSelfMonitoringBloodGlucose.nativeElement.click();
        this.tabSelfMonitoringBloodGlucoseBottom.nativeElement.click();
        break;
      case 'tabSpecialtyTraining':
        this.tabSpecialtyTraining.nativeElement.click();
        this.tabSpecialtyTrainingBottom.nativeElement.click();
        break;
      case 'tabResourcesProvided':
        this.tabResourcesProvided.nativeElement.click();
        this.tabResourcesProvidedBottom.nativeElement.click();
        break;
      case 'tabReferralReason':
        this.tabReferralReason.nativeElement.click();
        this.tabReferralReasonBottom.nativeElement.click();
        break;
      case 'tabSupportInformation':
        this.tabSupportInformation.nativeElement.click();
        this.tabSupportInformationBottom.nativeElement.click();
        break;
      case 'tabSummaryGoals':
        this.tabSummaryGoals.nativeElement.click();
        this.tabSummaryGoalsBottom.nativeElement.click();
        break;
      case 'tabFollowUp':
        this.tabFollowUp.nativeElement.click();
        this.tabFollowUpBottom.nativeElement.click();
        break;
      case 'tabSignature':
        this.tabSignature.nativeElement.click();
        this.tabSignatureBottom.nativeElement.click();
        break;
    }
  }

}
