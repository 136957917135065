import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { SharedService } from '../services/shared.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  constructor(private authorizeService: AuthorizeService,
              private sharedService: SharedService,
              private router: Router) { }
  isAuthenticated: boolean;

  ngOnInit(): void {
    this.authorizeService.isAuthenticated().subscribe(data => { this.isAuthenticated = data; });
  }

  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  handleClick() {
    const url = this.sharedService.ApiUrl + "Identity/Account/Manage/ChangePassword";
    window.location.href = url;
  }

  downloadPos() {
    window.open("https://fileservices.solvoit.ca/SolvoPos.zip", "_blank");
  }

  downloadDatabase() {
    window.open("https://fileservices.solvoit.ca/img.zip", "_blank");
  }

  public isActiveItemMenu(page): boolean {
    return this.router.url.includes(page);
  }
}
