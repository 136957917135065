import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { SharedService } from '../../services/shared.service';
import { SettingsService } from '../../services/settings.service';
import { NarcoticReconciliationService } from '../../services/narcotic-reconciliation.service';
import * as XLSX from 'xlsx';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ViewportScroller, DatePipe } from '@angular/common';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-narcotic-reconciliation-item',
  templateUrl: './narcotic-reconciliation-item.component.html',
  styleUrls: ['./narcotic-reconciliation-item.component.css']
})
export class NarcoticReconciliationItemComponent implements OnInit {

  @ViewChild('closeDateRangeModal', { static: false }) closeDateRangeModal: ElementRef;
  @ViewChild('closeUploadModal', { static: false }) closeUploadModal: ElementRef;
  @ViewChild('uploadFileInput', { static: false }) uploadFileInput: ElementRef;
  @ViewChild('closeItemNoteModal', { static: false }) closeItemNoteModal: ElementRef;
  @ViewChild('endPage', { static: false }) _endPage: ElementRef;
  @ViewChild('dinInput', { static: true }) _dinInput: ElementRef;
  @ViewChild('inputDrug', { static: false }) _inputDrug: ElementRef;
  @ViewChild('onHandInput', { static: false }) _onHandInput: ElementRef;
  @ViewChild('saveButton', { static: false }) _saveButton: ElementRef;
  @ViewChild('closeOnHandModalForm', { static: false }) _closeOnHandModalForm: ElementRef;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  constructor(private router: Router,
    private settingsService: SettingsService,
    private sharedService: SharedService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private scroll: ViewportScroller,
    private narcoticReconciliationService: NarcoticReconciliationService,
    private datepipe: DatePipe,
    @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  private ApiUrl = "";
  directory: string = "Purchased";
  dispenserTypeIdKroll: number = 1;
  uploadErrorMessage: string = "";
  dispenserKrollVo: any;
  purchaseVo: any;
  rangeDateId: number;
  purchaseTypeId: number = 2;
  fileName: string;
  modalTitle: string;
  empyString: string = "";
  dateStart: any;
  dateEnd: any;
  showDateErrorMessage: boolean;
  dateErrorMessage: string;
  narcotics: any[] = [];
  isPuchaseUpload: boolean;
  dispenserKrollVos: any[] = [];
  purchaseVos: any[] = [];
  drugsWithPackSize: any[] = [];
  showLoadingMessage: boolean;
  showErrorMessage: boolean;
  errorMessage: string;
  resultVo: any;
  narcoticReconciliationId: number;
  narcoticReconciliationItemId: number;
  narcoticReconciliation: any;
  itemNoteModalErrorMessage: string;
  itemNoteErrorMessage: boolean;
  notes: string;
  myControl = new FormControl('');
  filteredMedications: Observable<any[]>;
  pageYoffset = 0;
  onHandCountValidationMessage: string = "";
  activePositiveDifferenceClass: boolean;
  activeNegativeDifferenceClass: boolean;
  activeNoDifferenceClass: boolean;
  purchaseTypeIdMcKesson = 1;
  purchaseTypeIdKohlFrisch = 2;
  headerRow: number = 8;
  narcoticReconciliationNoteTypeId: number;
  narcoticReconciliationStatusPending: string = 'Pending';
  purchaseDataModalTitle: string = 'Purchase Data';
  showUploadErrorMessage: boolean;
  hasDispenserKrollData: boolean = false;
  hasPurchaseFileUploaded: boolean = false;
  isDinSortingAscending: boolean = false;
  isDrugSortingAscending: boolean = false;
  isDifferenceSortingAscending: boolean = false;
  searchDrugInput: string;
  isOnHandCountFromForm: boolean;
  drugPacks: any = [];
  narcoticReconciliationItemVoId: number;
  narcoticReconciliationOnHandCount: number;
  narcoticReconciliationDin: number;
  showOnHandCountFormErrorMessage: boolean;
  onHandCountFormErrorMessage: string;
  isInputDinEmpty: boolean;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.rangeDateId = 0;
    this.narcoticReconciliationNoteTypeId = 1;
    this.loadNarcoticReconciliationItem();
    this._dinInput.nativeElement.focus();
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  scrollToBottom() {
    this._endPage.nativeElement.scrollIntoView();
  }

  onEnterOnHandCount() {
    this._inputDrug.nativeElement.focus();
    this.searchDrugInput = '';
  }

  loadDispenserItemVos() {
    this.narcoticReconciliationService.getDispenserKrollVos(this.narcoticReconciliationId).subscribe(result => {
      this.dispenserKrollVos = result;
      this.hasDispenserKrollData = this.dispenserKrollVos.length > 0;
    });
  }

  loadPurchaseVos() {
    this.narcoticReconciliationService.getPurchaseVos(this.narcoticReconciliationId).subscribe(result => {
      this.purchaseVos = result;
        this.hasPurchaseFileUploaded = this.purchaseVos.length > 0;
    });
  }

  loadNarcoticReconciliationItem() {
    this.showLoadingMessage = true;
    this.narcoticReconciliationId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.narcoticReconciliationService.getNarcoticReconciliationById(this.sharedService.pharmacyId, this.narcoticReconciliationId).subscribe(result => {
      this.narcoticReconciliation = result;
      this.loadFilteredMedications();
      this.loadNarcoticReconciliationDifferenceCountStyle(this.narcoticReconciliation.narcoticReconciliationItemVos.length);
      this.showLoadingMessage = false;
    }, err => this.showLoadingMessage = false);
  }

  cancelOnHandCount() {
    this.clearOnHandCountFormErrorMessage();
  }

  saveOnHandCount(narcoticReconciliationItemId: number) {
    this.isOnHandCountFromForm = true;
    for (var index = 0; index < this.narcoticReconciliation.narcoticReconciliationItemVos.length; index++) {
      if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].id == narcoticReconciliationItemId) {
        this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCount = this.narcoticReconciliationOnHandCount;
      }
    }
    this.onOnHandCountChange(narcoticReconciliationItemId);
    this.clearOnHandCountModalFormFields();
  }

  clearOnHandCountModalFormFields() {
    this.isInputDinEmpty = true;
    this.narcoticReconciliationDin = null;
    this.narcoticReconciliationOnHandCount = null;
    setTimeout(() => this._dinInput.nativeElement.focus(), 500);
  }

  onOnHandCountChange(narcoticReconciliationItemId: number) {
    for (var index = 0; index < this.narcoticReconciliation.narcoticReconciliationItemVos.length; index++) {
      if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].id == narcoticReconciliationItemId) {
        if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCount == null)
          this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCount = 0;

        var currentOnHandCount = Number(this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCount);
        var currentInitalCount = Number(this.narcoticReconciliation.narcoticReconciliationItemVos[index].initialCount);
        var currentTotalPurchased = Number(this.narcoticReconciliation.narcoticReconciliationItemVos[index].purchasedTotal);
        var currentTotalDispensed = Number(this.narcoticReconciliation.narcoticReconciliationItemVos[index].dispensedKroll);

        if (this.isOnHandCountFromForm) {
          currentOnHandCount = this.narcoticReconciliationOnHandCount;
          this.isOnHandCountFromForm = false;
        }

        var onHandCountInputValidationResult: string = this.onHandCountInputValidation(narcoticReconciliationItemId, currentOnHandCount);
        if (onHandCountInputValidationResult == "") {
          if (currentOnHandCount == 0) {
            this.narcoticReconciliation.narcoticReconciliationItemVos[index].difference = (currentInitalCount - currentTotalDispensed + currentTotalPurchased);
          } else {
            this.narcoticReconciliation.narcoticReconciliationItemVos[index].difference = currentOnHandCount - (currentInitalCount - currentTotalDispensed + currentTotalPurchased);
          }

          this.narcoticReconciliationService.updateNarcoticReconciliationOnHandCount(narcoticReconciliationItemId, currentOnHandCount).subscribe(result => {
            this.resultVo = result;
            this.checkNarcoticReconciliationDifferenceCountById(narcoticReconciliationItemId);
          })
        }
      }
    }
    this.clearFormNarcoticReconciliationItemByDin();
  }

  loadNarcoticReconciliationItemBy(narcoticReconciliationItemId: number, narcoticReconciliationItemDin: number) {
    this.isInputDinEmpty = false;
    var narcoticReconciliationItemVo = this.narcoticReconciliation.narcoticReconciliationItemVos.find(narcoticReconciliationItemVo => narcoticReconciliationItemVo.id == narcoticReconciliationItemId);
    this.drugPacks = this.getNarcoticReconciliationItemVosByDin(narcoticReconciliationItemDin);
    var index = this.drugPacks.findIndex(i => i.id == narcoticReconciliationItemId);

    this.drugPacks[index].isPacksizeChecked = true;
    this.narcoticReconciliationOnHandCount = narcoticReconciliationItemVo.onHandCount;
    this.narcoticReconciliationItemVoId = narcoticReconciliationItemVo.id;
    this.narcoticReconciliationDin = narcoticReconciliationItemVo.din;
    setTimeout(() => this._onHandInput.nativeElement.focus(), 500);
  }

  getNarcoticReconciliationItemVosByDin(narcoticReconciliationItemDin: number): any[] {
    return this.narcoticReconciliation.narcoticReconciliationItemVos
      .filter(narcoticReconciliationItemVo => narcoticReconciliationItemVo.din == narcoticReconciliationItemDin)
      .map(item => {
        item.isPacksizeChecked = false;
        return item;
      })
      .sort((first, last) => first.size - last.size);
  }

  onChangeDrugPack(narcoticReconciliationItemId) {
    this.narcoticReconciliationItemVoId = parseInt(narcoticReconciliationItemId.toString());
    this.narcoticReconciliationOnHandCount = this.narcoticReconciliation.narcoticReconciliationItemVos.find(narcoticReconciliationItemVo => narcoticReconciliationItemVo.id == this.narcoticReconciliationItemVoId).onHandCount;
  }

  loadNarcoticReconciliationItemForm(narcoticReconciliationItemDin: number) {
    this.drugPacks = [];
    this.drugPacks = this.getNarcoticReconciliationItemVosByDin(narcoticReconciliationItemDin);
    if (this.drugPacks.length > 0)
      this.isInputDinEmpty = false;
    else {
      var message = 'Din number not Found.'
      this.loadOnHandCountFormErrorMessage(message);
      this._dinInput.nativeElement.focus();
    }

    this.drugPacks[0].isPacksizeChecked = true;
    this.narcoticReconciliationOnHandCount = this.drugPacks[0].onHandCount;
    this.narcoticReconciliationItemVoId = this.drugPacks[0].id;
  }

  enterDinInput(narcoticReconciliationItemDin: number) {
    this.clearOnHandCountFormErrorMessage();
    var dinInputValidation = this.validateOnHandCountForm(narcoticReconciliationItemDin);

    if (dinInputValidation.length == 0) {
      this._dinInput.nativeElement.blur();
      this._onHandInput.nativeElement.focus();
      this.loadNarcoticReconciliationItemForm(narcoticReconciliationItemDin);
    }
    else
      this.loadOnHandCountFormErrorMessage(dinInputValidation);
  }

  enterOnHandCount(narcoticReconciliationItemOnHandCount: number) {
    this.clearOnHandCountFormErrorMessage();
    var onHandCountValidation = this.validateOnHandCountForm(narcoticReconciliationItemOnHandCount);

    if (onHandCountValidation.length == 0) {
      this._onHandInput.nativeElement.blur();
      this._saveButton.nativeElement.focus();
    }
    else
      this.loadOnHandCountFormErrorMessage(onHandCountValidation);
  }

  validateOnHandCountForm(inputValue: number): string {
    var message = "";
    if (inputValue != null && inputValue == 0)
      message = "Field cannot be empty."

    return message;
  }

  clearFormNarcoticReconciliationItemByDin() {
    this._dinInput.nativeElement.focus();
  }

  loadNarcoticReconciliationDifferenceCountStyle(narcoticReconciliationItems) {
    for (var i = 0; i < narcoticReconciliationItems; i++) {
      this.loadNarcoticReconciliationDifferenceCountByIndex(i);
    }
  }

  loadNarcoticReconciliationDifferenceCountByIndex(index) {
    if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].difference == 0) {
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activePositiveDifferenceClass = false;
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activeNegativeDifferenceClass = false;
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activeNoDifferenceClass = true;
    }
    if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].difference < 0) {
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activePositiveDifferenceClass = false;
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activeNegativeDifferenceClass = true;
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activeNoDifferenceClass = false;
    }
    if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].difference > 0) {
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activePositiveDifferenceClass = true;
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activeNegativeDifferenceClass = false;
      this.narcoticReconciliation.narcoticReconciliationItemVos[index].activeNoDifferenceClass = false;
    }
  }

  checkNarcoticReconciliationDifferenceCountById(narcoticReconciliationItemId) {
    for (var index = 0; index < this.narcoticReconciliation.narcoticReconciliationItemVos.length; index++) {
      if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].id == narcoticReconciliationItemId) {
        this.loadNarcoticReconciliationDifferenceCountByIndex(index);
      }
    }
  }

  onHandCountInputValidation(narcoticReconciliationItemId: number, input: number): string {
    for (var index = 0; index < this.narcoticReconciliation.narcoticReconciliationItemVos.length; index++) {
      if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].id == narcoticReconciliationItemId) {
        if (input < 0) {
          this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCountValidation = true;
          this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCount = 0;
          this.onHandCountValidationMessage = "Invalid Input!"
        }
        else {
          this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCountValidation = false;
        }
        return this.onHandCountValidationMessage;
      }
    }
  }

  clearOnHandCountValidationMessage(narcoticReconciliationItemId: number) {
    for (var index = 0; index < this.narcoticReconciliation.narcoticReconciliationItemVos.length; index++) {
      if (this.narcoticReconciliation.narcoticReconciliationItemVos[index].id == narcoticReconciliationItemId) {
        this.narcoticReconciliation.narcoticReconciliationItemVos[index].onHandCountValidation = false;
      }
    }
  }

  replaceAll(str, find, replace): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  isUploadFileModalPurchase(): boolean {
    return this.modalTitle == this.purchaseDataModalTitle;
  }

  isPurchaseTypeKohlFrisch(): boolean {
    return this.purchaseTypeId == this.purchaseTypeIdKohlFrisch;
  }

  isPurchaseTypeMcKesson(): boolean {
    return this.purchaseTypeId == this.purchaseTypeIdMcKesson;
  }

  isValidLayoutPurchaseMcKesson(data): boolean {
    var body = JSON.stringify(data);
    return body.indexOf('Invoice &#x23;') !== -1;
  }

  isValidLayoutPurchaseKohlFrisch(data): boolean {
    var body = JSON.stringify(data);
    return body.indexOf('Din/NPN Number') !== -1;
  }

  isvalidLayoutDispenserKroll(data): boolean {
    var body = JSON.stringify(data);
    return body.indexOf('Decreases') !== -1;
  }

  clearUploadFileField() {
    this.uploadFileInput.nativeElement.value = "";
  }

  clearUploadErrorMessage() {
    this.showUploadErrorMessage = false;
    this.uploadFileInput.nativeElement.value = "";
  }

  onCancelUpload() {
    this.clearUploadErrorMessage();
    this.clearUploadFileField();
    this.clearUploadModalFields();
  }

  onFileChange(event: any) {
    this.uploadErrorMessage = "";
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    var filename = target.files[0].name;
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      var binarystr: string = e.target.result;

      if (this.isUploadFileModalPurchase() && this.isPurchaseTypeKohlFrisch()) {
        binarystr = this.replaceAll(binarystr, "Content-type: application/vnd.ms-excel    xls", "");
        binarystr = this.replaceAll(binarystr, "Expires: 0", "");
        binarystr = this.replaceAll(binarystr, "<!--<?xml version='1.0'?>  -->", "");
      }

      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      var data = XLSX.utils.sheet_to_json(ws);

      if (this.isUploadFileModalPurchase()) {

        this.purchaseVo = {
          filename: filename,
          purchaseTypeId: Number.parseInt(this.purchaseTypeId.toString()),
          narcoticReconciliationId: this.narcoticReconciliationId,
          createdBy: this.sharedService.getUserId()
        };

        if (this.isPurchaseTypeMcKesson()) {
          if (this.isValidLayoutPurchaseMcKesson(data)) {
            this.purchaseVo.purchaseMcKessonVos = data;
          } else {
            this.uploadErrorMessage = "Invalid layout for Mc Kesson.";
            this.clearUploadFileField();
          }
        }

        if (this.isPurchaseTypeKohlFrisch()) {
          data = XLSX.utils.sheet_to_json(ws, {
            range: this.headerRow - 1
          });

          if (this.isValidLayoutPurchaseKohlFrisch(data)) {
            this.purchaseVo.purchaseKohlFrischVos = data;
          } else {
            this.uploadErrorMessage = "Invalid layout for Kohl Frisch.";
            this.clearUploadFileField();
          }
        }
      } else {
        if (this.isvalidLayoutDispenserKroll(data)) {
          this.dispenserKrollVo = {
            filename: filename,
            dispenserTypeId: this.dispenserTypeIdKroll,
            narcoticReconciliationId: this.narcoticReconciliationId,
            createdBy: this.sharedService.getUserId(),
            status: true,
            dispenserKrollItemVos: data
          };

        } else {
          this.uploadErrorMessage = "Invalid layout for Kroll.";
          this.clearUploadFileField();
        }
      }
    };
  }

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this.http.post(this.ApiUrl + 'api/upload/UploadFile/' + this.directory, formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.Response) {

        }
      });
  }

  onActionChangeDate() {
    var dateValidation = this.validateFilteredDate(this.dateEnd, this.dateStart)
    if (dateValidation == "") {
      this.showDateErrorMessage = false;
    }
    else {
      this.showDateErrorMessage = true;
      this.loadDateErrorMessage(dateValidation);
    }
  }

  loadDateErrorMessage(message: any,) {
    this.dateErrorMessage = message.toString();
  }

  clearDateErrorMessage() {
    this.showDateErrorMessage = false;
  }

  clearItemNoteModalErrorMessage() {
    this.itemNoteModalErrorMessage = '';
    this.itemNoteErrorMessage = false;
  }

  validateFilteredDate(dateEnd, dateStart): string {
    var dateErrorMessage = "The End Date must be greater then Start Date!";
    if (dateEnd > dateStart)
      return this.empyString

    return dateErrorMessage;
  }

  openNarcoticReconciliationPurchaseItem(purchaseId) {
    this.router.navigate(['purchase-item/' + purchaseId]);
  }

  showNarcoticConciliationReportBy(index) {
    console.log(this.narcotics[index]);
    this.router.navigate(['narcotic-reconciliation-item']);
  }

  uploadDataType(id) {
    this.uploadErrorMessage = "";
    if (id == "upload-purchase") {
      this.isPuchaseUpload = true;
      this.modalTitle = 'Purchase Data';
    }
  }

  uploadData() {
    var errorMessageFileAlreadyExist: string = "The file already exist";
    var errorMessageSelectOneFile: string = "Please, select one file.";

    if (this.dispenserKrollVo != null || this.purchaseVo != null) {
      this.clearUploadErrorMessage();
      if (this.modalTitle == "Purchase Data") {
        this.showLoadingMessage = true;

        if (this.purchaseTypeId == this.purchaseTypeIdMcKesson) {
          this.narcoticReconciliationService.uploadPurchaseMcKesson(this.purchaseVo).subscribe(result => {
            if (result.success) {
              this.closeUploadModal.nativeElement.click();
              this.loadPurchaseVos();
              this.loadNarcoticReconciliationItem();
            } else {
              this.loadUploadErrorMessage(errorMessageFileAlreadyExist);
            }
            this.clearUploadFileField();
            this.showLoadingMessage = false;
          }, err => this.showLoadingMessage = false);
        }

        if (this.purchaseTypeId == this.purchaseTypeIdKohlFrisch) {
          this.narcoticReconciliationService.uploadPurchaseKohlFrisch(this.purchaseVo).subscribe(result => {
            if (result.success) {
              this.closeUploadModal.nativeElement.click();
              this.loadPurchaseVos();
              this.loadNarcoticReconciliationItem();
            } else {
              this.loadUploadErrorMessage(errorMessageFileAlreadyExist);
            }
            this.clearUploadFileField();
            this.showLoadingMessage = false;
          }, err => this.showLoadingMessage = false);
        }

      }
    } else {
      this.loadUploadErrorMessage(errorMessageSelectOneFile);
    }
  }

  loadUploadErrorMessage(message: string) {
    this.showUploadErrorMessage = true;
    this.uploadErrorMessage = message;
  }

  loadOnHandCountFormErrorMessage(message: string) {
    this.showOnHandCountFormErrorMessage = true;
    this.onHandCountFormErrorMessage = message;
  }

  clearOnHandCountFormErrorMessage() {
    this.showOnHandCountFormErrorMessage = false;
    this.onHandCountFormErrorMessage = "";
  }

  clearUploadModalFields() {
    this.dispenserKrollVo = null;
    this.isPuchaseUpload = false;
    this.rangeDateId = 0;
  }

  loadDrugsWithPack() {
    this.showLoadingMessage = true;
    this.narcoticReconciliationService.getDrugsWithPack().subscribe(data => {
      this.drugsWithPackSize = data;
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: string) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    this.scroll.scrollToPosition([0, 0]);
  }

  onSelectNarcoticReconciliationItemDetail(narcoticReconciliationId, narcoticReconciliationItemId) {
    this._closeOnHandModalForm.nativeElement.click();
    this.router.navigateByUrl('/narcotic-reconciliation-item-detail/' + narcoticReconciliationId + '/' + narcoticReconciliationItemId);
  }

  onOpenNotesModal(narcoticReconciliationItemId: number) {
    var narcoticReconciliationItemVo: any;
    this.clearItemNoteModalErrorMessage();
    this.narcoticReconciliationItemId = narcoticReconciliationItemId;
    this.narcoticReconciliationService.getNarcoticReconciliationItemById(narcoticReconciliationItemId).subscribe(
      data => {
        narcoticReconciliationItemVo = data;
        this.notes = narcoticReconciliationItemVo.notes;
      });
  }

  onSaveNotes() {
    let model = {
      id: this.narcoticReconciliationItemId,
      narcoticReconciliationId: this.narcoticReconciliationId,
      notes: this.notes
    };

    this.narcoticReconciliationService.updateNarcoticReconcilitionItemNotes(model, this.narcoticReconciliationItemId).subscribe(result => {
      if (result.success)
        this.closeItemNoteModal.nativeElement.click();
    });

  }

  redirectToNarcoticReconciliationIndex() {
    this.router.navigate(['narcotic-reconciliation']);
  }

  submitNarcoticReconciliation() {
    this.showLoadingMessage = true;
    this.narcoticReconciliationService.submitNarcoticReconciliation(this.narcoticReconciliationId).subscribe(
      resultVo => {
        if (resultVo.success) {
          this.showLoadingMessage = false;
          this.sharedService.showSuccessMessage = resultVo.success;
          this.redirectToNarcoticReconciliationIndex();
        } else {
          this.loadErrorMessage(resultVo.message);
          this.showLoadingMessage = false;
        }
      },
      err => this.showLoadingMessage = false);
  }

  onDeleteNarcoticReconciliationPurchaseItem(purchaseId) {
    this.narcoticReconciliationService.deleteNarcoticReconciliationPurchase(this.narcoticReconciliationId, purchaseId).subscribe(
      data => {
        this.resultVo = data;
        this.loadPurchaseVos();
        this.loadNarcoticReconciliationItem();
      });
  }

  onDeleteNarcoticReconciliationDispensedItem(dispensedId) {
    this.narcoticReconciliationService.deleteNarcoticReconciliationDispenser(this.narcoticReconciliationId, dispensedId).subscribe(
      data => {
        this.resultVo = data;
        this.loadDispenserItemVos();
        this.loadNarcoticReconciliationItem();
      });
  }

  loadFilteredMedications() {
    this.searchDrugInput = '';
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  loadDispenseIntegration() {
    this.showLoadingMessage = true;
    let startDate = this.datepipe.transform(this.narcoticReconciliation.startDate, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(this.narcoticReconciliation.endDate, 'yyyy-MM-dd');

    this.narcoticReconciliationService.createNarcotiReconciliationDispenserByIntegration(this.sharedService.getUserId(),
      this.narcoticReconciliationId,
      this.sharedService.pharmacyId,
      startDate,
      endDate)
      .subscribe(result => {
        if (result.success) {
          this.loadPurchaseVos();
          this.loadNarcoticReconciliationItem();
        } else {
          this.loadUploadErrorMessage(result.message);
        }
        this.showLoadingMessage = false;
      }, err => this.showLoadingMessage = false);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.narcoticReconciliation.narcoticReconciliationItemVos.filter(option => option.searchDrug.toLowerCase().includes(filterValue));
  }

  exportNarcoticReconciliationReport() {
    this.router.navigate([]).then(result => {
      window.open('report/NarcoticReconciliation/' + this.narcoticReconciliationId + '/Narcotic Reconciliation Report', '_blank');
    });
  }

  sortNarcoticReconciliationItemBy(filterType) {
    this.searchDrugInput = '';
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._sort(filterType)),
    );
  }

  private _sort(filterType): string[] {
    if (filterType == "sort-din") {
      this.isDinSortingAscending = !this.isDinSortingAscending;
      if (this.isDinSortingAscending)
        return this.narcoticReconciliation.narcoticReconciliationItemVos.sort((first, last) => first.din - last.din);
      return this.narcoticReconciliation.narcoticReconciliationItemVos.sort((first, last) => last.din - first.din);
    }

    if (filterType == "sort-drug") {
      this.isDrugSortingAscending = !this.isDrugSortingAscending;
      return this.narcoticReconciliation.narcoticReconciliationItemVos.sort((first, last) => {
        const drugFirst = first.drug.toUpperCase();
        const drugLast = last.drug.toUpperCase();
        if (this.isDrugSortingAscending)
          return drugFirst < drugLast ? -1 : 1;

        if (!this.isDrugSortingAscending)
          return drugFirst < drugLast ? 1 : -1;
      });
    }

    if (filterType == "sort-difference") {
      this.isDifferenceSortingAscending = !this.isDifferenceSortingAscending;
      if (this.isDifferenceSortingAscending)
        return this.narcoticReconciliation.narcoticReconciliationItemVos.sort((first, last) => first.difference - last.difference);
      return this.narcoticReconciliation.narcoticReconciliationItemVos.sort((first, last) => last.difference - first.difference);
    }
  }
}
