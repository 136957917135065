import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.css']
})
export class GeneralInformationComponent implements OnInit {

  constructor(private medsCheckService: MedscheckService) { }

  private medsCheckDiabeteGeneralInformationTypeInsulinUse: number = 1;
  private medsCheckDiabeteGeneralInformationTypeOralHypoglycemicMedications: number = 2;

  ngOnInit() {}

}
