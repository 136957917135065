import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { IincidentReportCreate } from '../interfaces/iincident-report-create';

@Injectable({
  providedIn: 'root'
})
export class IncidentReportService {
  private ApiUrl = "";

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,) {
    this.ApiUrl = baseUrl;
  }

  getStatus(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetStatus/');
  }

  getNotificationTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetNotificationTypes/');
  }

  getCaseTypeGroups(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetCaseTypeGroups/');
  }

  getDescriptionType(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetDescriptionType/');
  }

  getOutcomeType(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetOutcomeType/');
  }

  getOriginIncident(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetOriginIncident/');
  }

  getRiskFactors(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetRiskFactors/');
  }

  getHypoglycemiaTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetHypoglycemiaTypes/');
  }

  getRouteAdministrationTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetRouteAdministrationTypes/');
  }

  getFacilityUsers(facilityId, pharmacyId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetFacilityUsers/' + facilityId + '/' + pharmacyId);
  }

  getIncidentReports(userId, facilityId, pharmacyId, facilityUnitId, patientId, dateStart, dateEnd): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetIncidentReports/' + userId + '/' + facilityId + '/' + pharmacyId + '/' + facilityUnitId + '/' + patientId + '/' + dateStart + '/' + dateEnd);
  }

  getIncidentReportsByStatusId(userId, facilityId, pharmacyId, statusId, facilityUnitId, patientId, startDate, endDate): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/incidentReport/GetIncidentReportsByStatusId/' + userId + '/' + facilityId + '/' + pharmacyId + '/' + statusId + '/' + facilityUnitId + '/' + patientId + '/' + startDate + '/' + endDate);
  }

  getIncidentReport(userId, incidentReportId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/incidentReport/GetIncidentReport/' + userId + '/' + incidentReportId);
  }

  createIncidentReport(incidentReportCreate: IincidentReportCreate): Observable<number> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/incidentReport/CreateIncidentReport';
    const body = JSON.stringify(incidentReportCreate);

    return this.http.post<number>(url, incidentReportCreate, { 'headers': headers });
  }

  reviewIncidentReport(incidentReportReview: IincidentReportCreate): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/incidentReport/ReviewIncidentReport';

    return this.http.post<any>(url, incidentReportReview, { 'headers': headers });
  }

  getIncidentReportStatisticVos(pharmacyId, facilityId, userId, startDate, endDate): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/incidentReport/GetIncidentReportStatisticVos/' + pharmacyId + '/' + facilityId + '/' + userId + '/' + startDate + '/' + endDate);
  }

  removeIncidentReport(incidentReportId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/incidentReport/removeIncidentReport/' + incidentReportId);
  }
}
