import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { PipeTransform, Pipe } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { PosService } from '../services/pos.service';
import { InvoiceService } from '../services/invoice.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private posService: PosService,
    private router: Router,
    private invoiceService: InvoiceService,
    private datepipe: DatePipe) { }

  showErrorMessage: boolean;
  errorMessage: string;
  invoices: any = [];
  searchStartDate: any;
  searchEndDate: any;
  showLoadingMessage: boolean;
  residents: any = [];
  patientId: number = 0;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
    this.loadResidents();
  }

  loadDate() {
    this.searchStartDate = new Date(2023, 8, 1);
    this.searchEndDate = new Date(2023, 8, 30);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.getDate(this.searchEndDate) < this.getDate(this.searchStartDate))
      errors.push("The End Date must be greater then Start Date!")

    return errors;
  }

  getDate(date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  loadFields() {
    this.showLoadingMessage = true;
    var dateStartCoverted = Date.parse(this.searchStartDate.toString());
    var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');

    var dateEndCoverted = Date.parse(this.searchEndDate.toString());
    var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');



  }

  loadResidents() {
    this.sharedService.getResidentsByFacilityId().subscribe(data => {
      this.residents = data;
    });
  }


  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.getData();

    if (value == "") {
      this.patientId = 0;
    }

    this.toHighlight = value;

    var data = this.getData();
    if (data != null)
      return this.getData()
        .pipe(
          map(response => response.filter(option => {
            return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        )
    else
      return EMPTY;

  }

  getPosts(value) {
    var resident = this.residents.find(f => f.name == value);
    this.patientId = resident.patientId;

  }
  getData() {
    if (this.residents == null || this.residents.length == 0) {
      this.sharedService.getResidentsByFacilityId().subscribe(data => {
        this.residents = data;
        return of(this.residents);
      });
    } else {
      return of(this.residents);
    }
  }

  public openNewInvoice() {
      this.router.navigate(['invoice/create']);
  }

}

//Highlight text in autocomplete options
@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
