import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  public ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getDrugStatisticsReportVo(pharmacyId, facilityId, monthText): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/statistics/GetDrugStatisticsReportVo/' + pharmacyId + '/' + facilityId + '/' + monthText);
  }

  getDrugStatisticsMonthVos(pharmacyId, facilityId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/statistics/GetDrugStatisticsMonthVos/' + pharmacyId + '/' + facilityId);
  }

  
}
