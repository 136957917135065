import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';
import { SharedService } from '../../services/shared.service';
import { IncidentReportService } from '../../services/incident-report.service';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class IncidentReportStatisticsComponent implements OnInit {
  dateStart: string;
  dateStartInput: Date;
  dateEnd: string;
  dateEndInput: Date;
  test: Date;
  events: string[] = [];
  dateStartForm = new FormControl(moment());
  dateEndForm = new FormControl(moment());
  showErrorMessage: boolean;
  errorMessage: string;
  facilityId: number;

  incidentReportStatisticVos: any[];

  constructor(private sharedService: SharedService,
    private incidentReportService: IncidentReportService,
    private router: Router,
    public datepipe: DatePipe) { }

  ngOnInit() {
    this.facilityId = 0;
    this.sharedService.reloadUserPrivileges();
    this.loadPeriod();
    this.loadIncidentReportStatisticVos();
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  onActionFacilityChange() {
    this.loadIncidentReportStatisticVos()
  }

  chosenYearHandlerStartDate(normalizedYear: Moment) {
    const ctrlValue = this.dateStartForm.value;
    ctrlValue.year(normalizedYear.year());
    this.dateStartForm.setValue(ctrlValue);
  }

  chosenMonthHandlerStartDate(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.dateStartForm.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateStartForm.setValue(ctrlValue);
    datepicker.close();
    this.dateStartInput = new Date(Date.parse(this.dateStartForm.value.toString()));
    this.dateStart = this.datepipe.transform(this.dateStartInput, 'yyyy-MM');
    this.loadIncidentReportStatisticVos();
  }

  chosenYearHandlerEndDate(normalizedYear: Moment) {
    const ctrlValue = this.dateEndForm.value;
    ctrlValue.year(normalizedYear.year());
    this.dateEndForm.setValue(ctrlValue);
  }

  chosenMonthHandlerEndDate(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.dateEndForm.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateEndForm.setValue(ctrlValue);
    datepicker.close();
    this.dateEndInput = new Date(Date.parse(this.dateEndForm.value.toString()));
    this.dateEnd = this.datepipe.transform(this.dateEndInput, 'yyyy-MM');
    this.loadIncidentReportStatisticVos();
  }

  loadIncidentReportStatisticVos() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.incidentReportService.getIncidentReportStatisticVos(this.sharedService.pharmacyId, this.facilityId, this.sharedService.getUserId(), this.dateStart, this.dateEnd).subscribe(data => {
        this.incidentReportStatisticVos = data;
      });
    } else
      this.loadErrorMessage(resultFieldsValidation.join(" "));
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEnd < this.dateStart)
      errors.push("The End Date must be greater then Start Date!")
    return errors;
  }

  loadPeriod() {
    var today = new Date();
    this.dateStartInput = new Date(today.getFullYear(), today.getMonth(), 1);
    this.dateStart = this.datepipe.transform(this.dateStartInput, 'yyyy-MM');
    this.dateEndInput = today;
    this.dateEnd = this.datepipe.transform(this.dateEndInput, 'yyyy-MM');
  }

  public exportToPdf() {
    var pageTitle = "IncidentReport";
    this.router.navigate([]).then(result => { window.open('report/IncidentReport/' + this.dateStart + '/' + this.dateEnd + '/' + pageTitle, '_blank'); });
  }
}
