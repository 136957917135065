import { Component, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { ReorderService } from '../services/reorder.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-reorder',
  templateUrl: './reorder.component.html',
  styleUrls: ['./reorder.component.css']
})
export class ReorderComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private reorderService: ReorderService,
    private datepipe: DatePipe) { }

  public showErrorMessage: boolean;
  public errorMessage: string;
  public reorders: any[];
  public dateStartReorder: Date;
  public dateEndReorder: Date;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
    if (this.sharedService.facilityId > 0) {
      this.loadReorders();
    }
  }

  private loadDate() {
    this.dateEndReorder = new Date();
    this.dateStartReorder = new Date();
    this.dateStartReorder.setDate(this.dateStartReorder.getDate() - 7);
  }

  private validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEndReorder < this.dateStartReorder)
      errors.push("The End Date must be greater then Start Date!")
    return errors;
  }

  public onActionChangeDate() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadReorders();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  private loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  public clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  public onActionChangeStatus() {
    this.loadReorders();
  }

  private loadReorders() {
    if (this.reorderService.reorderStatusId == null)
      this.reorderService.reorderStatusId = 0;

    this.reorderService.getReordersByStatusId(this.sharedService.pharmacyId, this.sharedService.facilityId, this.reorderService.reorderStatusId, this.datepipe.transform(this.dateStartReorder, 'yyyy-MM-dd'), this.datepipe.transform(this.dateEndReorder, 'yyyy-MM-dd'))
      .subscribe(data => {
        this.reorders = data;
      });
  }
}
