import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { MedscheckService } from '../../services/medscheck.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-annual',
  templateUrl: './annual.component.html',
  styleUrls: ['./annual.component.css']
})
export class AnnualComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private medsCheckService: MedscheckService,
    private router: Router,
    private route: ActivatedRoute,
    private scroll: ViewportScroller,
    private datepipe: DatePipe) { }

  medsCheckMedicalHistoryItemVos: any[];
  medsCheckMedicalHistoryRemoveItemsOlderThanId = "1";
  showErrorMessage: boolean = false;
  showErrorMessageMainPage: boolean;
  showLoadingMessage: boolean = true;
  errorMessage: string;
  errorMessageMainPage: string;
  medsCheckId: string;
  reportTitle: string = "MEDSCHECK ANNUAL";
  medsCheckTypeAnnual = 1;
  medsCheckVo: any = {};
  therapeuticIssueTypeVos: any[] = [];
  therapeuticIssueTypeId: any;
  therapeuticIssueTypeTitle: string;
  outlineSymptomConditionNotAddressed: string;
  suggestedTherapy: string;
  actionTaken: string;
  supportInformationTypeId: number;
  supportInformationTypeTitle: string;
  supportInformationTextInput: string;
  supportInformationTypeVos: any[];
  drug: object;
  medicationInput: string;
  medicationInputId: number;
  drugPharmacyId: number;
  medicationDirectionInput: string;
  schedule: number;
  medsCheckMedicalHistoryItemTypeTitle: string;
  healthCareProviderLastName: string;
  healthCareProviderFirstName: string;
  healthCareProviderPhone: string;
  healthCareProviderEmail: string;
  healthCareProviderFax: string;
  healthCareProviderSpecialty: string;

  printPharmacistsWorksheet: boolean = true;
  printPatientTakeHomeSummary: boolean = true;
  printPersonalMedicationRecord: boolean = true;
  printHealthcareProviderNotificationOfMedsCheckServices: boolean = true;
  printPatientAcknowledgement: boolean = true;

  doctorVos: any[] = [];
  signaturePath: string;
  medsCheckStatusDraft: string = 'Draft';
  pageYoffset = 0;
  showMedsCheckErrorMessage: boolean;
  medsCheckValidationResult: boolean;
  resultMedsCheckValidation: any[] = [];
  medicationSelected: boolean;
  strength: string;
  dosageForm: string;
  isCaregiverPatientDisable: boolean;
  //Moved to share component
  chronicMedicationControl = new FormControl();
  multipleAcuteConditionsControl = new FormControl();
  nonPrescriptionMedicationsControl = new FormControl();
  naturalHealthProductsControl = new FormControl();
  symptomsUnaddressedControl = new FormControl();
  potentialDrugTherapyProblemControl = new FormControl();
  multiplePrescribersControl = new FormControl();
  issuesRelatingEarlyControl = new FormControl();
  nonAdherenceControl = new FormControl();
  patientSeemsConfusedControl = new FormControl();
  medicationRoutineLaboratoryMonitoringControl = new FormControl();
  abnormalLabResultsControl = new FormControl();
  plannedAdmissionHospitalControl = new FormControl();
  dischargeTransitionFromHospitalControl = new FormControl();
  initiatingComplianceControl = new FormControl();
  knownRenalFunctionControl = new FormControl();
  knownLiverFunctionControl = new FormControl();
  otherControl = new FormControl();
  //Moved to share component
  chronicMedicationOptions: Observable<string[]>;
  multipleAcuteConditionsOptions: Observable<string[]>;
  nonPrescriptionMedications: Observable<string[]>;
  naturalHealthProducts: Observable<string[]>;
  symptomsUnaddressed: Observable<string[]>;
  potentialDrugTherapyProblem: Observable<string[]>;
  multiplePrescribers: Observable<string[]>;
  issuesRelatingEarly: Observable<string[]>;
  nonAdherence: Observable<string[]>;
  patientSeemsConfused: Observable<string[]>;
  medicationRoutineLaboratoryMonitoring: Observable<string[]>;
  abnormalLabResults: Observable<string[]>;
  plannedAdmissionHospital: Observable<string[]>;
  dischargeTransitionFromHospital: Observable<string[]>;
  initiatingCompliance: Observable<string[]>;
  knownRenalFunction: Observable<string[]>;
  knownLiverFunction: Observable<string[]>;
  other: Observable<string[]>;

  showErrorMessageSignaturePad: boolean;
  errorMessageSignaturePad: string;

  medsCheckCheckListNoneAll: boolean;
  medsCheckLifestyleNoneAll: boolean;

  primaryCares: any[] = [];
  primaryCareControl = new FormControl('', [Validators.required]);
  healthcareProviderControl = new FormControl('', [Validators.required]);
  filteredPrimaryCares: Observable<any>;
  filteredHealthcareProvider: Observable<any>;
  toHighlight: string = '';

  medsCheckReviewDate: any;
  medsCheckReviewTime: any;
  isMedsCheckDiabete: boolean;

  @ViewChild('closeMedicalModal', { static: false }) closeMedicalModal: ElementRef;
  @ViewChild('closeSignatureModal', { static: false }) closeSignatureModal: ElementRef;

  @ViewChild('tabFollowUpBottom', { static: false }) tabFollowUpBottom: ElementRef;
  @ViewChild('tabSignatureBottom', { static: false }) tabSignatureBottom: ElementRef;
  @ViewChild('tabSupportInformationBottom', { static: false }) tabSupportInformationBottom: ElementRef;
  @ViewChild('tabTherapeuticIssuesBottom', { static: false }) tabTherapeuticIssuesBottom: ElementRef;
  @ViewChild('tabMedicationsBottom', { static: false }) tabMedicationsBottom: ElementRef;
  @ViewChild('tabPatientInfoBottom', { static: false }) tabPatientInfoBottom: ElementRef;
  @ViewChild('tabBasicInfoBottom', { static: false }) tabBasicInfoBottom: ElementRef;

  @ViewChild('tabFollowUpTop', { static: false }) tabFollowUpTop: ElementRef;
  @ViewChild('tabSignatureTop', { static: false }) tabSignatureTop: ElementRef;
  @ViewChild('tabSupportInformationTop', { static: false }) tabSupportInformationTop: ElementRef;
  @ViewChild('tabTherapeuticIssuesTop', { static: false }) tabTherapeuticIssuesTop: ElementRef;
  @ViewChild('tabMedicationsTop', { static: false }) tabMedicationsTop: ElementRef;
  @ViewChild('tabPatientInfoTop', { static: false }) tabPatientInfoTop: ElementRef;
  @ViewChild('tabBasicInfoTop', { static: false }) tabBasicInfoTop: ElementRef;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();

    this.medsCheckId = this.route.snapshot.paramMap.get('medsCheckId');
    this.supportInformationTypeId = 0;
    this.loadMedsCheckVos();
    this.cleanTherapeuticIssueInputFields();
    this.cleanSupportInformationInputFields();
    this.cleanMedicationFields();
    this.cleanHealthCareProvidersField();
    this.loadMedsCheckVo();
    this.loadtherapeuticIssueType();
    this.loadSupportInformationType();
    this.schedule = 1;
    this.loadMedsCheckDoctorVo();
    this.scroll.scrollToPosition([0, 0]);
  }

  private loadMedsCheckVos() {
    this.loadMedsCheckPatientVo();
    this.loadMedsCheckQuestionVo();
    this.medsCheckVo.medsCheckChecklistVo = {};
    this.medsCheckVo.medsCheckTherapeuticIssueVo = {};
    this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos = [];
    this.medsCheckVo.medsCheckSupportInformationVo = {};
    this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos = [];
    this.medsCheckVo.medsCheckSignatureVo = {};
    this.medsCheckVo.medsCheckPlanForFollowUpVo = {};
    this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos = [];

  }

  private loadMedsCheckPatientVo() {
    this.medsCheckVo.patientVo = {}
    this.medsCheckVo.patientVo.addressVo = {}
    this.medsCheckVo.patientVo.patientCaregiverVo = {}
    this.medsCheckVo.patientVo.doctorVo = {};
  }

  private loadMedsCheckQuestionVo() {
    this.medsCheckVo.medsCheckQuestionVo = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other = {};
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo = {};
  }

  cleanTherapeuticIssueInputFields() {
    this.therapeuticIssueTypeId = 0;
    this.therapeuticIssueTypeTitle = "";
    this.outlineSymptomConditionNotAddressed = "";
    this.suggestedTherapy = "";
    this.actionTaken = "";
  }

  cleanSupportInformationInputFields() {
    this.supportInformationTypeId = 0;
    this.supportInformationTextInput = "";
    this.supportInformationTypeTitle = "";
  }

  cleanMedicationFields() {
    this.medicationInputId = null;
    this.medicationInput = "";
    this.medicationDirectionInput = "";
    this.schedule = null;
    this.strength = "";
    this.dosageForm = "";
    this.clearErrorMessage();
  }

  cleanHealthCareProvidersField() {
    this.healthCareProviderLastName = "";
    this.healthCareProviderFirstName = "";
    this.healthCareProviderEmail = "";
    this.healthCareProviderPhone = "";
    this.healthCareProviderFax = "";
    this.healthCareProviderSpecialty = "";
  }

  loadMedsCheckDoctorVo() {
    this.medsCheckService.getMedsCheckDoctorVosBy(this.sharedService.pharmacyId)
      .subscribe(doctorVo => {
        this.doctorVos = doctorVo;
      });
  }

  loadMedsCheckVo() {
    this.medsCheckService.getMedsCheckVoById(this.medsCheckId).subscribe(medsCheckVo => {
      this.medsCheckVo = medsCheckVo;
      this.loadMedsCheckReviewDateAndTime();
      this.onChangeRemoveItemsOlderThan(this.medsCheckMedicalHistoryRemoveItemsOlderThanId);
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  //moved to share component
  loadMedsCheckReviewDateAndTime() {
    this.medsCheckReviewDate = this.medsCheckVo.createdDate;
    this.medsCheckReviewTime = this.medsCheckVo.createdDate.toString().substring(11, 16);
  }

  updateMedsCheckVoCreatedDate() {
    this.medsCheckVo.createdDate = this.datepipe.transform(this.medsCheckReviewDate, 'yyyy-MM-dd') + "T" + this.medsCheckReviewTime;
  }
  //moved to share component
  loadtherapeuticIssueType() {
    this.medsCheckService.getMedsCheckTherapeuticIssueTypeVos().subscribe(therapeuticIssueTypeVos => {
      this.therapeuticIssueTypeVos = therapeuticIssueTypeVos;
    });
  }
  //moved to share component
  loadSupportInformationType() {
    this.medsCheckService.getMedsCheckSupprotInformationTypeVos().subscribe(supportInformationTypeVos => {
      this.supportInformationTypeVos = supportInformationTypeVos;
    });
  }
  //Moved to share component
  onActionSelectDoctor(doctorName) {
    var doctorVo = this.doctorVos.find(d => d.name == doctorName);
    if (doctorVo == null) {
      this.medsCheckVo.patientVo.doctorVo = {};
      this.medsCheckVo.patientVo.doctorVo.Id = 0;
      this.medsCheckVo.patientVo.doctorVo.lastName = doctorName;
    } else
      this.medsCheckVo.patientVo.doctorVo = doctorVo;

  }
  //Moved to share component
  onActionSelectHealthCareProvider(healthCareProviderName) {
    var findHealthCareProvider = this.doctorVos.find(d => d.name == healthCareProviderName);
    this.healthCareProviderLastName = findHealthCareProvider.lastName;
    this.healthCareProviderFirstName = findHealthCareProvider.firstName;
    this.healthCareProviderEmail = findHealthCareProvider.email;
    this.healthCareProviderPhone = findHealthCareProvider.telephone;
    this.healthCareProviderFax = findHealthCareProvider.fax;
    this.healthCareProviderSpecialty = findHealthCareProvider.designation;
  }
  //Moved to share component
  addTherapeuticIssues() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateTherapeuticIssuesFormFields();

    if (resultFieldsValidation == "") {
      var findTherapeuticIssueTypeTitle = this.therapeuticIssueTypeVos.find(x => x.id == this.therapeuticIssueTypeId);
      this.therapeuticIssueTypeTitle = findTherapeuticIssueTypeTitle.title;

      if (this.therapeuticIssueTypeId == 9)
        this.therapeuticIssueTypeTitle = this.outlineSymptomConditionNotAddressed;

      var therapeuticIssue = {
        medsCheckTherapeuticIssueTypeId: this.therapeuticIssueTypeId,
        medsCheckTherapeuticIssueTypeTitle: this.therapeuticIssueTypeTitle,
        suggestedTherapy: this.suggestedTherapy,
        actionTaken: this.actionTaken,
        outlineSymptomConditionNotAddressed: this.outlineSymptomConditionNotAddressed
      }


      this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.push(therapeuticIssue);
      this.cleanTherapeuticIssueInputFields();

    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }

  }
  //Moved to share component
  validateTherapeuticIssuesFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.therapeuticIssueTypeId == 0)
      errors.push('Type');

    if (this.suggestedTherapy.trim().length == 0)
      errors.push('Suggested Therapy')

    if (this.actionTaken.trim().length == 0)
      errors.push('Action Taken')

    if (this.therapeuticIssueTypeId == 9 && this.outlineSymptomConditionNotAddressed.trim().length == 0)
      errors.push('Outline symptom or condition not addressed');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }
  //Moved to share component
  deleteTherapeuticIssues(index) {
    this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.splice(index, 1);
  }
  //Moved to share component
  addSuportInformation() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateSupportInformationFormFields();

    if (resultFieldsValidation == "") {
      var findSuportInformationTypeTitle = this.supportInformationTypeVos.find(x => x.id == this.supportInformationTypeId);
      this.supportInformationTypeTitle = findSuportInformationTypeTitle.title;

      var newSupportInformationItem = {
        medsCheckSupportInformationTypeId: this.supportInformationTypeId,
        medsCheckSupportInformationTypeTitle: this.supportInformationTypeTitle,
        notes: this.supportInformationTextInput
      }

      this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.unshift(newSupportInformationItem);
      this.cleanSupportInformationInputFields();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }
  //Moved to share component
  validateSupportInformationFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.supportInformationTypeId == 0)
      errors.push('Type');

    if (this.supportInformationTextInput.trim().length == 0)
      errors.push('Notes');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(" and ") + " are mandatories."
    }
    return message;
  }
  //Moved to share component
  deleteSuportInformationItem(index) {
    this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.splice(index, 1);
  }
  //Moved to share component
  public loadMedicationSelected = (drug) => {
    this.medicationInputId = drug.id;
    this.medicationInput = (drug.brandName == drug.genericName) ? drug.brandName : drug.brandName + " - " + drug.genericName;
    this.drugPharmacyId = drug.pharmacyId;
    this.strength = drug.strength;
    this.dosageForm = drug.form;
    this.medicationSelected = true;
  }
  //Moved to share component
  public loadMedicationTyped = (drug) => {
    if (!this.medicationSelected) {
      this.medicationInputId = 0;
      this.medicationInput = drug;
    }
  }
  //Moved to share component
  addMedication() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateInputMedicationFormFields();

    if (resultFieldsValidation == "") {
      var firstFillDate = new Date();
      var newMedication = {
        id: 0,
        pharmacyId: this.drugPharmacyId,
        drugId: this.medicationInputId,
        medication: this.medicationInput,
        directions: this.medicationDirectionInput,
        dosageForm: this.dosageForm,
        strength: this.strength,
        firstFillDate: this.datepipe.transform(firstFillDate, 'yyyy-MM-dd'),
        medsCheckMedicalHistoryItemTypeId: this.schedule,
        medsCheckMedicalHistoryId: this.medsCheckVo.medsCheckMedicalHistoryVo.id,
        medsCheckMedicalHistoryItemTypeTitle: this.getMedsCheckMedicalHistoryItemTypeTitle(this.schedule)
      }

      this.medsCheckMedicalHistoryItemVos.unshift(newMedication);
      this.onChangeRemoveItemsOlderThan(this.medsCheckMedicalHistoryRemoveItemsOlderThanId);
      this.medicationSelected = false;
      this.closeMedicalModal.nativeElement.click();
      this.cleanMedicationFields();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }
  //Moved to share component
  getMedsCheckMedicalHistoryItemTypeTitle(id) {
    switch (id) {
      case 1:
        return this.medsCheckMedicalHistoryItemTypeTitle = "RX";
      case 2:
        return this.medsCheckMedicalHistoryItemTypeTitle = "OTC";
      case 3:
        return this.medsCheckMedicalHistoryItemTypeTitle = "NHP";
    }

  }
  //Moved to share component
  deleteMedication(id) {
    var index = this.medsCheckMedicalHistoryItemVos.findIndex(medsCheckMedicalHistoryItemVo => medsCheckMedicalHistoryItemVo.id == id);
    this.medsCheckMedicalHistoryItemVos.splice(index, 1);
    this.onChangeRemoveItemsOlderThan(this.medsCheckMedicalHistoryRemoveItemsOlderThanId);

  }
  //Moved to share component
  validateInputMedicationFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.schedule == null)
      errors.push('Schedule');

    if (this.medicationInput.trim().length == 0)
      errors.push('Medication');

    if (this.strength.trim().length == 0)
      errors.push('Strength');

    if (this.medicationDirectionInput.trim().length == 0)
      errors.push('Directions');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  addHealthCareProviders() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateHealthCareProviderFormFields();

    if (resultFieldsValidation == "") {

      var newHealthCareProviderItem = {
        firstName: this.healthCareProviderFirstName,
        lastName: this.healthCareProviderLastName,
        phone: this.healthCareProviderPhone,
        email: this.healthCareProviderEmail,
        fax: this.healthCareProviderFax,
        healthRelatedSpecialty: this.healthCareProviderSpecialty,
        medsCheckPlanForFollowUpId: this.medsCheckVo.medsCheckPlanForFollowUpVo.id,
        pharmacyId: this.sharedService.pharmacyId
      }

      if (this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos == null)
        this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos = [];

      this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos.push(newHealthCareProviderItem);
      this.cleanHealthCareProvidersField();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  validateHealthCareProviderFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.healthCareProviderLastName.trim().length == 0)
      errors.push('Last Name');

    if (this.healthCareProviderFirstName.trim().length == 0)
      errors.push('First Name');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  medsCheckValidation() {
    this.clearErrorMessageMainPage();

    if (this.basicInfoValidation().length > 0)
      this.resultMedsCheckValidation.push({ tab: "Basic Info ", error: this.basicInfoValidation(), errorMessage: "" });

    if (this.patientInfoValidation().length > 0)
      this.resultMedsCheckValidation.push({ tab: "Patient Info ", error: this.patientInfoValidation(), errorMessage: "" });

    if (this.medicationValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Medication: ", error: {}, errorMessage: this.medicationValidation() });

    if (this.therapeuticIssueValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Therapeutic Issue: ", error: {}, errorMessage: this.therapeuticIssueValidation() });

    if (this.supportInformationValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Support Information: ", error: {}, errorMessage: this.supportInformationValidation() });

    if (this.signatureValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Signature: ", error: {}, errorMessage: this.signatureValidation() });

    if (this.followUpValidation() != "")
      this.resultMedsCheckValidation.push({ tab: "Follow Up: ", error: {}, errorMessage: this.followUpValidation() });

    if (this.resultMedsCheckValidation.length > 0) {
      this.loadMedsCheckErrorMessage();
    } else {
      this.medsCheckValidationResult = true;
    }

    return this.medsCheckValidationResult;

  }

  followUpValidation(): string {
    var followUpErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos.length == 0)
      followUpErrorMessage = "At least on healthcare Provider must be registered"

    return followUpErrorMessage;
  }

  signatureValidation(): string {
    var signatureErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckSignatureVo.patientSignature == null || this.medsCheckVo.medsCheckSignatureVo.pharmacistSignature == null)
      signatureErrorMessage = "Patient and Pharmacist Signature are mandatories."

    if (this.medsCheckVo.submittedDate == null || this.medsCheckVo.createdDate == null)
      signatureErrorMessage += " Patient Acknowledgement Date and MedsCheck Review Date are mandatories."


    return signatureErrorMessage;
  }

  supportInformationValidation(): string {
    var supportInformationErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckSupportInformationVo.medsCheckSupportInformationItemVos.length == 0)
      supportInformationErrorMessage = "At least one type must be registered"

    return supportInformationErrorMessage;
  }

  basicInfoValidation(): any {
    var basicInfoErrors: any[] = [];
    var clinicalNeedErrors: string[] = [];
    var clinicalNeedErrorMessage: string = "";

    if (this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos).length > 0) {
      basicInfoErrors.push(
        {
          section: "Sources Consulted",
          error: this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos)
        });
    }

    if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes == null || this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes.trim().length == 0)
      clinicalNeedErrors.push("Notes is a mandatory field");

    if (clinicalNeedErrors.length == 1) {
      clinicalNeedErrorMessage = clinicalNeedErrors.join(", ");
    }

    else if (clinicalNeedErrors.length > 1) {
      clinicalNeedErrorMessage = clinicalNeedErrors.join(", ");
    }

    if (clinicalNeedErrors.length > 0) {
      basicInfoErrors.push(
        {
          section: "Clinical Need",
          error: clinicalNeedErrorMessage
        });
    }


    if (this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos).length > 0) {
      basicInfoErrors.push(
        {
          section: "Checklist",
          error: this.basicInfoCheckBoxValidation(this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos)
        });
    }

    return basicInfoErrors;
  }

  patientInfoValidation(): any {
    var patientInfoErrors: any[] = [];
    var patientAddressErrors: string[] = [];
    var patientAddressErrorMessage: string = "";

    if (this.patientInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos).length > 0) {
      patientInfoErrors.push({
        section: "Lifestyle",
        error: this.patientInfoCheckBoxValidation(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos)
      });
    }

    if (this.medsCheckVo.patientVo.addressVo.streetName.trim().length == 0)
      patientAddressErrors.push("Street Name")

    if (this.medsCheckVo.patientVo.addressVo.city.trim().length == 0)
      patientAddressErrors.push("City")

    if (this.medsCheckVo.patientVo.addressVo.province.trim().length == 0)
      patientAddressErrors.push("Province")

    if (patientAddressErrors.length == 1) {
      patientAddressErrorMessage = "The field " + patientAddressErrors.join(", ") + " is mandatory."
    }

    else if (patientAddressErrors.length > 1) {
      patientAddressErrorMessage = "The fields " + patientAddressErrors.join(", ") + " are mandatories."
    }

    if (patientAddressErrors.length > 0) {
      patientInfoErrors.push({
        section: "Patient Address",
        error: patientAddressErrorMessage
      });
    }

    return patientInfoErrors;
  }

  medicationValidation(): string {
    var medicationErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos.length < 3)
      medicationErrorMessage = "At least three medications are mandatories";

    return medicationErrorMessage;
  }

  therapeuticIssueValidation(): string {
    var therapeuticIssueErrorMessage: string = "";

    if (this.medsCheckVo.medsCheckTherapeuticIssueVo.medsCheckTherapeuticIssueItemVos.length == 0)
      therapeuticIssueErrorMessage = "At least one type must be registered"

    return therapeuticIssueErrorMessage;
  }

  basicInfoCheckBoxValidation(checkboxGroupToValidate): string {
    var message: string = "";
    var searchCheckedOptions = checkboxGroupToValidate.some(cgv => cgv.checked == true);
    var searchEmptyNotes = checkboxGroupToValidate.some(cgv => cgv.checked == true && (cgv.notes == null || cgv.listNotes.length == 0));

    if (!searchCheckedOptions)
      message = "Please select at least one Item";

    if (searchEmptyNotes)
      message = "Please include notes to the selected Item";

    return message;
  }

  patientInfoCheckBoxValidation(checkboxGroupToValidate): string {
    var message: string = "";
    var searchCheckedOptions = checkboxGroupToValidate.some(cgv => cgv.chosen == true);
    var searchEmptyNotes = checkboxGroupToValidate.some(cgv => cgv.chosen == true && (cgv.notes == null || cgv.notes.trim().length == 0));

    if (!searchCheckedOptions)
      message = "Please select at least one Item";

    if (searchEmptyNotes)
      message = "Please include notes to the selected Item";

    return message;
  }

  submit() {
    if (this.medsCheckValidation()) {
      try {
        this.updateMedsCheckVoCreatedDate();
        this.medsCheckService.submitMedsCheck(this.medsCheckVo).subscribe
          (resultVo => {

            if (resultVo.success) {
              this.sharedService.showSuccessMessage = resultVo.success;
              this.redirectToResidentProfile();
            } else {
              this.loadErrorMessageMainPage(resultVo.message);
            }

          });
      }
      catch (e) {
        this.loadErrorMessageMainPage(e);
      }
    }
  }

  saveAsDraftAndRedirect() {
    this.saveAsDraft(true)
  }

  private saveAndExportToPdf() {
    this.saveAsDraft(false);
  }

  saveAsDraft(redirect: boolean) {
    try {
      this.updateMedsCheckVoCreatedDate();
      this.medsCheckService.saveAsDraftMedsCheck(this.medsCheckVo).subscribe(
        resultVo => {
          if (resultVo.success) {
            if (redirect) {
              this.sharedService.showTempSuccessMessage();
              this.redirectToResidentProfile();
            }
            else
              this.exportToPdf();
          }
          else
            this.loadErrorMessageMainPage(resultVo.message);
        }
      );
    }
    catch (e) {
      this.loadErrorMessageMainPage(e);
    }
  }

  deleteHealthCareProviders(index) {
    this.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos.splice(index, 1);
  }

  redirectToResidentProfile() {
    this.router.navigate(['patient/profile/' + this.medsCheckVo.patientVo.id]);
  }

  loadMedsCheckErrorMessage() {
    this.showMedsCheckErrorMessage = true;
    this.medsCheckValidationResult = false;
    this.scroll.scrollToPosition([0, 0]);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessageMainPage(message: string) {
    this.showErrorMessageMainPage = true;
    this.errorMessageMainPage = message;
  }

  clearErrorMessageMainPage() {
    this.showErrorMessageMainPage = false;
    this.showMedsCheckErrorMessage = false;
    this.resultMedsCheckValidation = [];
  }

  exportToPdf() {
    this.router.navigate([]).then(result => {
      if (this.printPharmacistsWorksheet)
        window.open('report/PharmacistsWorksheet/' + this.medsCheckVo.id + '/Pharmacists Worksheet', '_blank');
      if (this.printPatientTakeHomeSummary)
        window.open('report/PatientTakeHomeSummary/' + this.medsCheckVo.id + '/' + this.isMedsCheckDiabete + '/Patient Take Home Summary', '_blank');
      if (this.printPersonalMedicationRecord)
        window.open('report/PersonalMedicationRecord/' + this.medsCheckVo.id + '/Personal Medication Record', '_blank');
      if (this.printHealthcareProviderNotificationOfMedsCheckServices)
        window.open('report/HealthCareProviderNotificationMedsCheckServices/' + this.medsCheckVo.id + '/Healthcare Provider Notification', '_blank');
      if (this.printPatientAcknowledgement)
        window.open('report/PatientAcknowledgement/' + this.medsCheckVo.id + '/' + this.isMedsCheckDiabete + '/Patient Acknowledgement', '_blank');
    });
  }
  //Moved to share component
  public getSignatures = (signature) => {
    if (this.emptySignatureValidation(signature)) {
      this.loadErrorMessageSignaturePad('Please, draw your signature before SAVE.')
    } else {

      if (this.signaturePath == 'patient')
        this.medsCheckVo.medsCheckSignatureVo.patientSignature = signature;
      if (this.signaturePath == 'pharmacist')
        this.medsCheckVo.medsCheckSignatureVo.pharmacistSignature = signature;

      this.onCloseSignatureModal();
    }
  }
  //Moved to share component
  emptySignatureValidation(signature) {
    return signature.charAt(98) == '1' ? true : false;
  }
  //Moved to share component
  onAddSignature(event) {
    switch (event) {
      case 'patient-signature':
        this.signaturePath = 'patient';
        break;
      case 'pharmacist-signature':
        this.signaturePath = 'pharmacist';
        break;
    }
  }
  //Moved to share component
  loadErrorMessageSignaturePad(message: string) {
    this.showErrorMessageSignaturePad = true;
    this.errorMessageSignaturePad = message;
  }
  //Moved to share component
  clearErrorMessageSignaturePad() {
    this.showErrorMessageSignaturePad = false;
    this.errorMessageSignaturePad = "";
  }
  //Moved to share component
  onCloseSignatureModal() {
    this.closeSignatureModal.nativeElement.click();
    this.clearErrorMessageSignaturePad();
  }
  //Moved to share component
  onchecklistNoneAll(checked) {
    this.medsCheckCheckListNoneAll = true;
    for (let i = 0; i < this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos.length; i++) {
      if (this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].title === "Other (Specify)")
        continue;

      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].checked = checked;

      if (checked) {
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notesEmpty = "";
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].listNotes = [];
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].listNotes.push("None");
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notes = "None";
      } else {
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notesEmpty = "";
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].listNotes = [];
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[i].notes = "";
      }
    }
  }
  //Moved to share component
  onCaregiverPatientNoneAll(checked) {

    if (checked) {
      this.medsCheckVo.patientVo.patientCaregiverVo.lastName = "";
      this.medsCheckVo.patientVo.patientCaregiverVo.firstName = "";
      this.medsCheckVo.patientVo.patientCaregiverVo.phone = "";
      this.medsCheckVo.patientVo.patientCaregiverVo.email = "";
      this.medsCheckVo.patientVo.patientCaregiverVo.notes = "";
      this.isCaregiverPatientDisable = true;
    } else {
      this.isCaregiverPatientDisable = false;
    }
  }
  //Moved to share component
  onLifestyleNoneAll(checked) {
    this.medsCheckLifestyleNoneAll = true;
    for (let i = 0; i < this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos.length; i++) {
      if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].title === "Other (Specify)")
        continue;

      this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].chosen = checked;

      if (checked) {
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].title === "Smoking Cessation Status") {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].notes = "N/A";
        } else {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].notes = "None";
        }
      } else {
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].notes = "";
      }
    }
  }
  //Moved to share component
  onAdherenceIsChecked(checked, index) {
    if (checked) {
      this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos[index].comments = "";
      this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos[index].notes = "";
      this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos[index].commentsForMedsCheckRecord = "";
    }
  }
  //Moved to share component
  onSourcesConsultedItemIsChecked(checked, index) {
    if (checked) {
      this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedDefaultNotes[index];
      this.onEnterMedsCheckQuestionSourcesConsulted(index);
    } else {
      this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty = "";
      this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].listNotes = [];
      this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].listNotes.join("; ");
    }
  }
  //Moved to share component
  onChecklistItemIsChecked(checked, index) {
    this.medsCheckCheckListNoneAll = false;
    if (!checked) {
      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty = "";
      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes = [];
      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notes = this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.join("; ");
    }
  }
  //Moved to share component
  onLifestyleItemIsChecked(checked, index) {
    this.medsCheckLifestyleNoneAll = false;
    if (!checked) {
      this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[index].notes = "";
    }
  }
  //Moved to share component
  onLifestyleItemChange() {
    this.medsCheckLifestyleNoneAll = false;
  }
  //Moved to share component
  loadChronicMedicationOptions() {
    this.chronicMedicationOptions = this.chronicMedicationControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadMultipleAcuteConditionOptions() {
    this.multipleAcuteConditionsOptions = this.multipleAcuteConditionsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadNonPrescriptionMedicationsOptions() {
    this.nonPrescriptionMedications = this.nonPrescriptionMedicationsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadNonNaturalHealthProductsOptions() {
    this.naturalHealthProducts = this.naturalHealthProductsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadSymptomsUnaddressedOptions() {
    this.symptomsUnaddressed = this.symptomsUnaddressedControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadPotentialDrugTherapyProblemOptions() {
    this.potentialDrugTherapyProblem = this.potentialDrugTherapyProblemControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadMultiplePrescribersOptions() {
    this.multiplePrescribers = this.multiplePrescribersControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadIssuesRelatingEarlyOptions() {
    this.issuesRelatingEarly = this.issuesRelatingEarlyControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadNonAdherenceOptions() {
    this.nonAdherence = this.nonAdherenceControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadPatientSeemsConfusedOptions() {
    this.patientSeemsConfused = this.patientSeemsConfusedControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadMedicationRoutineLaboratoryMonitoringOptions() {
    this.medicationRoutineLaboratoryMonitoring = this.medicationRoutineLaboratoryMonitoringControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadAbnormalLabResultsOptions() {
    this.abnormalLabResults = this.abnormalLabResultsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadPlannedAdmissionHospitalOptions() {
    this.plannedAdmissionHospital = this.plannedAdmissionHospitalControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadDischargeTransitionFromHospitalOptions() {
    this.dischargeTransitionFromHospital = this.dischargeTransitionFromHospitalControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadInitiatingComplianceOptions() {
    this.initiatingCompliance = this.initiatingComplianceControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadKnownRenalFunctionOptions() {
    this.knownRenalFunction = this.knownRenalFunctionControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadKnownLiverFunctionOptions() {
    this.knownLiverFunction = this.knownLiverFunctionControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.options.filter(option => option.toLowerCase())),
    );
  }
  //Moved to share component
  loadOtherOptions() {
    this.other = this.otherControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.options.filter(option => option.toLowerCase())),
    );
  }

  onClickNavButton(id) {
    this.clearErrorMessage();
    switch (id) {
      case 'tabBasicInfo':
        this.tabBasicInfoTop.nativeElement.click();
        this.tabBasicInfoBottom.nativeElement.click();
        break;
      case 'tabPatientInfo':
        this.tabPatientInfoTop.nativeElement.click();
        this.tabPatientInfoBottom.nativeElement.click();
        break;
      case 'tabMedications':
        this.tabMedicationsTop.nativeElement.click();
        this.tabMedicationsBottom.nativeElement.click();
        break;
      case 'tabTherapeuticIssues':
        this.tabTherapeuticIssuesTop.nativeElement.click();
        this.tabTherapeuticIssuesBottom.nativeElement.click();
        break;
      case 'tabSupportInformation':
        this.tabSupportInformationTop.nativeElement.click();
        this.tabSupportInformationBottom.nativeElement.click();
        break;
      case 'tabSignature':
        this.tabSignatureTop.nativeElement.click();
        this.tabSignatureBottom.nativeElement.click();
        break;
      case 'tabFollowup':
        this.tabFollowUpTop.nativeElement.click();
        this.tabFollowUpBottom.nativeElement.click();
        break;
    }
  }
  //Moved to share component
  onEnterMedsCheckQuestionClinicalNeed(clinicalNeedTypeTitle) {
    switch (clinicalNeedTypeTitle) {
      case 'chronicMedications':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notesEmpty.trim().trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.join("; ");
        }
        break;
      case 'multipleAcuteConditions':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.join("; ");
        }
        break;
      case 'nonPrescriptionMedications':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.join("; ");
        }
        break;
      case 'naturalHealthProducts':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.join("; ");
        }
        break;
      case 'symptomsUnaddressed':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.join("; ");
        }
        break;
      case 'potentialDrugTherapyProblem':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.join("; ");
        }
        break;
      case 'multiplePrescribers':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.join("; ");
        }
        break;
      case 'issuesRelatingEarly':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.join("; ");
        }
        break;
      case 'nonAdherence':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.join("; ");
        }
        break;
      case 'patientSeemsConfused':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.join("; ");
        }
        break;
      case 'medicationRoutineLaboratoryMonitoring':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.join("; ");
        }
        break;
      case 'abnormalLabResults':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.join("; ");
        }
        break;
      case 'plannedAdmissionHospital':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.join("; ");
        }
        break;
      case 'dischargeTransitionFromHospital':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.join("; ");
        }
        break;
      case 'initiatingCompliance':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.join("; ");
        }
        break;
      case 'knownRenalFunction':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.join("; ");
        }
        break;
      case 'knownLiverFunction':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.join("; ");
        }
        break;
      case 'other':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.join("; ");
        }
        break;
      case 'clinicalNotes':
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty.trim().length > 0) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.join("; ");
        }
        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty.trim().length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty = "";
        break;
    }
  }
  //Moved to share component
  onRemoveMedsCheckQuestionClinicalNeed(clinicalNeedTypeTitle, index) {
    switch (clinicalNeedTypeTitle) {
      case 'chronicMedications':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.checked = false;
        break;

      case 'multipleAcuteConditions':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.checked = false;
        break;

      case 'nonPrescriptionMedications':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.checked = false;
        break;

      case 'naturalHealthProducts':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.checked = false;
        break;

      case 'symptomsUnaddressed':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.checked = false;
        break;

      case 'potentialDrugTherapyProblem':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.checked = false;
        break;

      case 'multiplePrescribers':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.checked = false;
        break;

      case 'issuesRelatingEarly':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.checked = false;
        break;

      case 'nonAdherence':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.checked = false;
        break;

      case 'patientSeemsConfused':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.checked = false;
        break;

      case 'medicationRoutineLaboratoryMonitoring':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.checked = false;
        break;

      case 'abnormalLabResults':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.checked = false;
        break;

      case 'plannedAdmissionHospital':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.checked = false;
        break;

      case 'dischargeTransitionFromHospital':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.checked = false;
        break;

      case 'initiatingCompliance':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.checked = false;
        break;

      case 'knownRenalFunction':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.checked = false;
        break;

      case 'knownLiverFunction':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.checked = false;
        break;

      case 'other':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.join("; ");

        if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.length == 0)
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.checked = false;
        break;

      case 'clinicalNotes':
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.splice(index, 1);
        this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.join("; ");
        break;

      default:
        break;
    }

  }
  //Moved to share component
  onChangeRemoveItemsOlderThan(value) {
    if (this.medsCheckMedicalHistoryItemVos == null)
      this.medsCheckMedicalHistoryItemVos = Object.assign([], this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos);

    if (value == "0") {
      this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos = this.medsCheckMedicalHistoryItemVos;
    } else {
      var date = new Date();
      date.setFullYear(date.getFullYear() - value);
      this.medsCheckVo.medsCheckMedicalHistoryVo.medsCheckMedicalHistoryItemVos = this.medsCheckMedicalHistoryItemVos.filter(i => {
        let firstFillDate = new Date(i.firstFillDate);
        let lastFillDate = i.lastFillDate == undefined ? null : new Date(i.lastFillDate);
        let lastFillDateText = lastFillDate == null ? '0001-01-01T00:00:00' : i.lastFillDate.toString();
        return lastFillDateText != '0001-01-01T00:00:00' ? lastFillDate >= date : firstFillDate >= date;
      });
    }
  }
  //Moved to share component
  onEnterMedsCheckQuestionSourcesConsulted(index) {
    if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty.trim().length > 0) {
      for (var i = 0; i < this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos.length; i++) {
        if (i == index) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[i].listNotes.push(this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty.trim());
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[i].notesEmpty = "";
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[i].notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].listNotes.join("; ");
        }
      }
    }
  }
  //Moved to share component
  onRemoveMedsCheckQuestionSourcesConsulted(indexForSourcesConsultedItemVos, indexForlistNotes) {
    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].listNotes.splice(indexForlistNotes, 1);
    if (this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].listNotes.length == 0)
      this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].checked = false;

    this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].listNotes.join("; ");
  }
  //Moved to share component
  onEnterMedsCheckQuestionChecklist(index) {
    let indexForNone: any;
    if (this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty.trim().length > 0) {
      if (this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.some(i => i == 'None')) {
        indexForNone = this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.findIndex(f => f == 'None');
        this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.splice(indexForNone, 1);
      }
      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.push(this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty.trim());
      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notesEmpty = "";
      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].notes = this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[index].listNotes.join("; ");
      this.uncheckMedsCheckCheckListNoneAll(this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos);
    }
  }
  //Moved to share component
  onRemoveMedsCheckQuestionChecklist(indexForChecklistItemVos, indexForChecklistListNotes) {
    this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].listNotes.splice(indexForChecklistListNotes, 1);
    if (this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].listNotes.length == 0)
      this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].checked = false;

    this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].notes = this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos[indexForChecklistItemVos].listNotes.join("; ");
    this.uncheckMedsCheckCheckListNoneAll(this.medsCheckVo.medsCheckChecklistVo.medsCheckChecklistItemVos);
  }
  //Moved to share component
  uncheckMedsCheckCheckListNoneAll(medscheckListNotes) {
    for (var i = 0; i < medscheckListNotes.length; i++) {
      if (medscheckListNotes[i].listNotes != "Notes")
        this.medsCheckCheckListNoneAll = false;
    }
  }

  onChangePatientInfoRequiredField(mandatoryInputType) {
    switch (mandatoryInputType) {
      case 'streetName':
        if (this.medsCheckVo.patientVo.addressVo.streetName.trim().length == 0)
          this.medsCheckVo.patientVo.addressVo.streetName = "";
        break;
      case 'city':
        if (this.medsCheckVo.patientVo.addressVo.city.trim().length == 0)
          this.medsCheckVo.patientVo.addressVo.city = "";
        break;
      case 'province':
        if (this.medsCheckVo.patientVo.addressVo.province.trim().length == 0)
          this.medsCheckVo.patientVo.addressVo.province = "";
        break;
    }
  }

  onChangeTherapeuticIssueRequiredField(mandatoryInputType) {
    switch (mandatoryInputType) {
      case 'outlineSymptomConditionNotAddressed':
        if (this.outlineSymptomConditionNotAddressed.trim().length == 0)
          this.outlineSymptomConditionNotAddressed = "";
        break;
      case 'suggestedTherapy':
        if (this.suggestedTherapy.trim().length == 0)
          this.suggestedTherapy = "";
        break;
      case 'actionTaken':
        if (this.actionTaken.trim().length == 0)
          this.actionTaken = "";
        break;
    }
  }
  //Moved to share component
  onChangeMedicationRequiredField(mandatoryInputType) {
    switch (mandatoryInputType) {
      case 'strength':
        if (this.strength.trim().length == 0)
          this.strength = "";
        break;
      case 'medicationDirectionInput':
        if (this.medicationDirectionInput.trim().length == 0)
          this.medicationDirectionInput = "";
        break;
    }
  }
  //Moved to share component
  onCheckClinicalNeedItem(clinicalNeedTypeTitle) {
    switch (clinicalNeedTypeTitle) {
      case 'cbClinicalNeed-chronicMedications':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-multipleAcuteCondition':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-nonPrescriptionMedications':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-naturalHealthProducts':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-symptomsUnaddressed':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-potentialDrugTherapyProblem':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-multiplePrescribers':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-issuesRelatingEarly':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-nonAdherence':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-patientSeemsConfused':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-medicationRoutineLaboratoryMonitoring':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-abnormalLabResults':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-plannedAdmissionHospital':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-dischargeTransitionFromHospital':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-initiatingCompliance':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-knownRenalFunction':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-knownLiverFunction':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-other':
        if (!this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.checked) {
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes = [];
          this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notes = this.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.join("; ");
        }
        break;
    }
  }
  //Moved to share component
  loadFilteredPrimaryCares() {
    this.primaryCares = [];
    this.filteredPrimaryCares = this.primaryCareControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }
  //Moved to share component
  loadFilteredHealthcareProviders() {
    this.primaryCares = [];
    this.filteredHealthcareProvider = this.healthcareProviderControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }
  //Moved to share component
  private _filter(value: string): Observable<any[]> {
    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;
      return this.medsCheckService.getDoctorsBy(value)
        .pipe(
          map(response =>
            response.filter(option => {
              this.primaryCares.push(option);
              if (texts.length == 1) {
                return option.lastName.toLowerCase().includes(texts[0].toLowerCase());
              }
              else {
                return option.lastName.toLowerCase().includes(texts[0].toLowerCase()) && option.lastName.toLowerCase().includes(texts[1].toLowerCase());
              }
            })));
    }
  }
  //Moved to share component
  getPrimaryCareProvider(primaryCareProviderId) {
    var selectedDoctor = this.primaryCares.find(p => p.id == primaryCareProviderId);
    this.medsCheckVo.patientVo.doctorVo = selectedDoctor;
  }
  //Moved to share component
  getHealthcareProvider(healthcareProviderId) {
    var selectedDoctor = this.primaryCares.find(p => p.id == healthcareProviderId);
    this.healthCareProviderLastName = selectedDoctor.lastName;
    this.healthCareProviderFirstName = selectedDoctor.firstName;
    this.healthCareProviderSpecialty = selectedDoctor.designation;
    this.healthCareProviderEmail = selectedDoctor.email;
    this.healthCareProviderPhone = selectedDoctor.telephone;
    this.healthCareProviderFax = selectedDoctor.fax;
  }

}

