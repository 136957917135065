import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-clinical-need-services',
  templateUrl: './clinical-need-services.component.html',
  styleUrls: ['./clinical-need-services.component.css']
})
export class ClinicalNeedServicesComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  ngOnInit() {
  }

  private medsCheckStatusDraft: string = 'Draft';

  private chronicMedicationControl = new FormControl();
  private multipleAcuteConditionsControl = new FormControl();
  private nonPrescriptionMedicationsControl = new FormControl();
  private naturalHealthProductsControl = new FormControl();
  private symptomsUnaddressedControl = new FormControl();
  private potentialDrugTherapyProblemControl = new FormControl();
  private multiplePrescribersControl = new FormControl();
  private issuesRelatingEarlyControl = new FormControl();
  private nonAdherenceControl = new FormControl();
  private patientSeemsConfusedControl = new FormControl();
  private medicationRoutineLaboratoryMonitoringControl = new FormControl();
  private abnormalLabResultsControl = new FormControl();
  private plannedAdmissionHospitalControl = new FormControl();
  private dischargeTransitionFromHospitalControl = new FormControl();
  private initiatingComplianceControl = new FormControl();
  private knownRenalFunctionControl = new FormControl();
  private knownLiverFunctionControl = new FormControl();
  private otherControl = new FormControl();

  private chronicMedicationOptions: Observable<string[]>;
  private multipleAcuteConditionsOptions: Observable<string[]>;
  private nonPrescriptionMedications: Observable<string[]>;
  private naturalHealthProducts: Observable<string[]>;
  private symptomsUnaddressed: Observable<string[]>;
  private potentialDrugTherapyProblem: Observable<string[]>;
  private multiplePrescribers: Observable<string[]>;
  private issuesRelatingEarly: Observable<string[]>;
  private nonAdherence: Observable<string[]>;
  private patientSeemsConfused: Observable<string[]>;
  private medicationRoutineLaboratoryMonitoring: Observable<string[]>;
  private abnormalLabResults: Observable<string[]>;
  private plannedAdmissionHospital: Observable<string[]>;
  private dischargeTransitionFromHospital: Observable<string[]>;
  private initiatingCompliance: Observable<string[]>;
  private knownRenalFunction: Observable<string[]>;
  private knownLiverFunction: Observable<string[]>;
  private other: Observable<string[]>;

  private onCheckClinicalNeedItem(clinicalNeedTypeTitle) {
    switch (clinicalNeedTypeTitle) {
      case 'cbClinicalNeed-chronicMedications':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-multipleAcuteCondition':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-nonPrescriptionMedications':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-naturalHealthProducts':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-symptomsUnaddressed':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-potentialDrugTherapyProblem':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-multiplePrescribers':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-issuesRelatingEarly':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-nonAdherence':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-patientSeemsConfused':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-medicationRoutineLaboratoryMonitoring':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-abnormalLabResults':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-plannedAdmissionHospital':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-dischargeTransitionFromHospital':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-initiatingCompliance':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-knownRenalFunction':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-knownLiverFunction':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.join("; ");
        }
        break;

      case 'cbClinicalNeed-other':
        if (!this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.checked) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes = [];
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.join("; ");
        }
        break;
    }
  }

  private onEnterMedsCheckQuestionClinicalNeed(clinicalNeedTypeTitle) {
    switch (clinicalNeedTypeTitle) {
      case 'chronicMedications':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notesEmpty.trim().trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.join("; ");
        }
        break;
      case 'multipleAcuteConditions':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.join("; ");
        }
        break;
      case 'nonPrescriptionMedications':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.join("; ");
        }
        break;
      case 'naturalHealthProducts':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.join("; ");
        }
        break;
      case 'symptomsUnaddressed':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.join("; ");
        }
        break;
      case 'potentialDrugTherapyProblem':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.join("; ");
        }
        break;
      case 'multiplePrescribers':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.join("; ");
        }
        break;
      case 'issuesRelatingEarly':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.join("; ");
        }
        break;
      case 'nonAdherence':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.join("; ");
        }
        break;
      case 'patientSeemsConfused':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.join("; ");
        }
        break;
      case 'medicationRoutineLaboratoryMonitoring':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.join("; ");
        }
        break;
      case 'abnormalLabResults':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.join("; ");
        }
        break;
      case 'plannedAdmissionHospital':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.join("; ");
        }
        break;
      case 'dischargeTransitionFromHospital':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.join("; ");
        }
        break;
      case 'initiatingCompliance':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.join("; ");
        }
        break;
      case 'knownRenalFunction':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.join("; ");
        }
        break;
      case 'knownLiverFunction':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.join("; ");
        }
        break;
      case 'other':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.join("; ");
        }
        break;
      case 'clinicalNotes':
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty.trim().length > 0) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.join("; ");
        }
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty.trim().length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notesEmpty = "";
        break;
    }
  }

  private onRemoveMedsCheckQuestionClinicalNeed(clinicalNeedTypeTitle, index) {
    switch (clinicalNeedTypeTitle) {
      case 'chronicMedications':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.chronicMedication.checked = false;
        break;

      case 'multipleAcuteConditions':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.checked = false;
        break;

      case 'nonPrescriptionMedications':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.checked = false;
        break;

      case 'naturalHealthProducts':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.checked = false;
        break;

      case 'symptomsUnaddressed':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.checked = false;
        break;

      case 'potentialDrugTherapyProblem':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.checked = false;
        break;

      case 'multiplePrescribers':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.checked = false;
        break;

      case 'issuesRelatingEarly':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.checked = false;
        break;

      case 'nonAdherence':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.checked = false;
        break;

      case 'patientSeemsConfused':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.checked = false;
        break;

      case 'medicationRoutineLaboratoryMonitoring':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.checked = false;
        break;

      case 'abnormalLabResults':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.checked = false;
        break;

      case 'plannedAdmissionHospital':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.checked = false;
        break;

      case 'dischargeTransitionFromHospital':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.checked = false;
        break;

      case 'initiatingCompliance':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.checked = false;
        break;

      case 'knownRenalFunction':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.checked = false;
        break;

      case 'knownLiverFunction':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.checked = false;
        break;

      case 'other':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.join("; ");

        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.listNotes.length == 0)
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.checked = false;
        break;

      case 'clinicalNotes':
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.splice(index, 1);
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.listNotes.join("; ");
        break;

      default:
        break;
    }

  }

  private loadMultipleAcuteConditionOptions() {
    this.multipleAcuteConditionsOptions = this.multipleAcuteConditionsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multipleAcuteCondition.options.filter(option => option.toLowerCase())),
    );
  }

  private loadNonPrescriptionMedicationsOptions() {
    this.nonPrescriptionMedications = this.nonPrescriptionMedicationsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonPrescriptionMedications.options.filter(option => option.toLowerCase())),
    );
  }

  private loadNonNaturalHealthProductsOptions() {
    this.naturalHealthProducts = this.naturalHealthProductsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.naturalHealthProducts.options.filter(option => option.toLowerCase())),
    );
  }

  private loadSymptomsUnaddressedOptions() {
    this.symptomsUnaddressed = this.symptomsUnaddressedControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.symptomsUnaddressed.options.filter(option => option.toLowerCase())),
    );
  }

  private loadPotentialDrugTherapyProblemOptions() {
    this.potentialDrugTherapyProblem = this.potentialDrugTherapyProblemControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.potentialDrugTherapyProblem.options.filter(option => option.toLowerCase())),
    );
  }

  private loadMultiplePrescribersOptions() {
    this.multiplePrescribers = this.multiplePrescribersControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.multiplePrescribers.options.filter(option => option.toLowerCase())),
    );
  }

  private loadIssuesRelatingEarlyOptions() {
    this.issuesRelatingEarly = this.issuesRelatingEarlyControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.issuesRelatingEarly.options.filter(option => option.toLowerCase())),
    );
  }

  private loadNonAdherenceOptions() {
    this.nonAdherence = this.nonAdherenceControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.nonAdherence.options.filter(option => option.toLowerCase())),
    );
  }

  private loadPatientSeemsConfusedOptions() {
    this.patientSeemsConfused = this.patientSeemsConfusedControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.patientSeemsConfused.options.filter(option => option.toLowerCase())),
    );
  }

  private loadMedicationRoutineLaboratoryMonitoringOptions() {
    this.medicationRoutineLaboratoryMonitoring = this.medicationRoutineLaboratoryMonitoringControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.medicationRoutineLaboratoryMonitoring.options.filter(option => option.toLowerCase())),
    );
  }

  private loadAbnormalLabResultsOptions() {
    this.abnormalLabResults = this.abnormalLabResultsControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.abnormalLabResults.options.filter(option => option.toLowerCase())),
    );
  }

  private loadPlannedAdmissionHospitalOptions() {
    this.plannedAdmissionHospital = this.plannedAdmissionHospitalControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.plannedAdmissionHospital.options.filter(option => option.toLowerCase())),
    );
  }

  private loadDischargeTransitionFromHospitalOptions() {
    this.dischargeTransitionFromHospital = this.dischargeTransitionFromHospitalControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.dischargeTransitionFromHospital.options.filter(option => option.toLowerCase())),
    );
  }

  private loadInitiatingComplianceOptions() {
    this.initiatingCompliance = this.initiatingComplianceControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.initiatingCompliance.options.filter(option => option.toLowerCase())),
    );
  }

  private loadKnownRenalFunctionOptions() {
    this.knownRenalFunction = this.knownRenalFunctionControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownRenalFunction.options.filter(option => option.toLowerCase())),
    );
  }

  private loadKnownLiverFunctionOptions() {
    this.knownLiverFunction = this.knownLiverFunctionControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.knownLiverFunction.options.filter(option => option.toLowerCase())),
    );
  }

  private loadOtherOptions() {
    this.other = this.otherControl.valueChanges.pipe(
      startWith(''),
      map(value => this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionClinicalNeedVo.other.options.filter(option => option.toLowerCase())),
    );
  }

  private onEnterMedsCheckQuestionSourcesConsulted(index) {
    if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty.trim().length > 0) {
      for (var i = 0; i < this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos.length; i++) {
        if (i == index) {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[i].listNotes.push(this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty.trim());
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[i].notesEmpty = "";
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[i].notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].listNotes.join("; ");
        }
      }
    }
  }

  private onRemoveMedsCheckQuestionSourcesConsulted(indexForSourcesConsultedItemVos, indexForlistNotes) {
    this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].listNotes.splice(indexForlistNotes, 1);
    if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].listNotes.length == 0)
      this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].checked = false;

    this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[indexForSourcesConsultedItemVos].listNotes.join("; ");
  }

  private onSourcesConsultedItemIsChecked(checked, index) {
    if (checked) {
      this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedDefaultNotes[index];
      this.onEnterMedsCheckQuestionSourcesConsulted(index);
    } else {
      this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notesEmpty = "";
      this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].listNotes = [];
      this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].notes = this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionSourcesConsultedVo.medsCheckQuestionSourcesConsultedItemVos[index].listNotes.join("; ");
    }
  }


}
