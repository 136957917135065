import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { EprescriberService } from '../../services/eprescriber.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IEprescriber } from '../../interfaces/ieprescriber';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormControl, Validators } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EmergencyPharmacyService } from '../../services/emergency-pharmacy.service';



@Component({
  selector: 'app-emergency-pharmacy',
  templateUrl: './emergency-pharmacy.component.html',
  styleUrls: ['./emergency-pharmacy.component.css']
})
export class EmergencyPharmacyComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private eprescriberService: EprescriberService,
    private emergencyPharmacyService: EmergencyPharmacyService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService) { }

  public showLoadingMessage: boolean = false;

  patientEmergencyPharmacy: any;
  patientEmergencyPharmacyItems: any[] = [];
  patientEmergencyPharmacyItemIndex: number = 0;

  patientId: string;

  requestDate: string;
  patientHealthCard: string;
  patientCreditCard: string;
  emergencyPharmacy: string;
  facilityName: string;
  deliveryAddress: string;
  odp: string;
  notes: string;
  prescriberName: string = "";
  pharmacist: string = "";

  prescription: any;
  prescriptions: any[];

  drugs: any;
  drugId: number;

  index: number;
  rxNum: string;
  strength: string;
  nameWithoutStrength: string;
  sig: string;
  prescriptionNotes: string;
  quantity: string;




  showErrorMessage: boolean;
  errorMessage: string;

  showErrorMessageAddPrescription: boolean;
  errorMessageAddPrescription: string;

  patientVo: any = {};

  reportTitle: string = "EMERGENCY PHARMACY REQUEST";
  
  @ViewChild('inputStrength', { static: false }) inputStrength: ElementRef;
  @ViewChild('inputMedication', { static: false }) inputMedication: ElementRef;
  @ViewChild('inputQuantity', { static: false }) inputQuantity: ElementRef;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.patientId = this.route.snapshot.paramMap.get('patientId');

    this.loadModel();
    this.cleanFormFields();
  }

  cleanFormFields() {
    this.index = 0;
    this.rxNum = "";
    this.strength = "";
    this.sig = "";
    this.prescriptionNotes = "";
    this.quantity = "";
    this.nameWithoutStrength = "";

    if (this.inputMedication != null && this.inputStrength != null) {
      this.inputMedication.nativeElement.value = "";
      this.inputStrength.nativeElement.value = "";
    }

    if (this.inputQuantity != null) {
      this.inputQuantity.nativeElement.value = "";
    }
    this.clearErrorMessageAddPrescription();
  }

  loadModel() {
    const today = new Date();
    this.requestDate = today.toISOString().split('T')[0];

    this.sharedService.getPatientVo(this.patientId).subscribe(data => {
      this.patientVo = data;

      this.facilityName = this.patientVo.facility;
      if (data.addressVo != null)
        this.deliveryAddress = data.addressVo.fullAddress;

      this.patientHealthCard = data.healthcard;

      this.odp = data.facilityOdp;
    });

    this.eprescriberService.getPrescriptionDtos(this.sharedService.pharmacyId, this.patientId).subscribe(data => {
      this.prescriptions = data;
    });
  }

  onActionAddPrecription(actionTypeId, rxNum: string) {
    this.cleanFormFields();
    this.prescription = this.prescriptions.find(x => x.rxNum == rxNum);
    var actionTypeModified = "2";

    switch (actionTypeId) {
      case actionTypeModified: {
        this.index = this.getPatientEmergencyPharmacyItemIndex();
        this.rxNum = rxNum.toString();
        this.sig = this.prescription.sig;
        this.inputMedication.nativeElement.value = this.prescription.drugBrandName + " - " + this.prescription.drugGenericName;
        this.nameWithoutStrength = this.prescription.drugBrandName + " - " + this.prescription.drugGenericName;
        this.inputStrength.nativeElement.value = this.prescription.drugStrength;
        this.drugId = this.prescription.drugId;

        break;
      }
    }
  }

  addPrescription() {
    var resultFieldsValidation = this.validateAddPrescriptionFields();
    if (resultFieldsValidation == "") {

      this.strength = this.inputStrength.nativeElement.value;
      this.quantity = this.inputQuantity.nativeElement.value;

      if (this.drugId != null && this.nameWithoutStrength != this.inputMedication.nativeElement.value) {
        this.drugId = null;
      }

      var prescription = this.patientEmergencyPharmacyItems.find(ei => (ei.RxNum != "" && ei.RxNum == this.rxNum) || (ei.Index > 0 && ei.Index == this.index));
      if (prescription != null) {

        prescription.Medication = this.inputMedication.nativeElement.value;
        prescription.Strength = this.strength;
        prescription.Directions = this.sig;
        prescription.RxNum = this.rxNum.toString();
        prescription.Quantity = this.quantity;
        prescription.Notes = this.prescriptionNotes;
      }
      else {
        var item: any = {
          Index: this.getPatientEmergencyPharmacyItemIndex(),
          RxNum: this.rxNum.toString(),
          Medication: this.inputMedication.nativeElement.value,
          Strength: this.strength,
          Directions: this.sig,
          Notes: this.prescriptionNotes,
          Quantity: this.quantity,
        }
        this.patientEmergencyPharmacyItems.push(item);
      }

      this.cleanFormFields();

    } else {
      this.loadErrorMessageAddPrescription(resultFieldsValidation);
    }
  }

  deleteNewPrescriptionsRow(type, directions, rxNum: string) {
    for (let i = 0; i < this.patientEmergencyPharmacyItems.length; ++i) {
      if (rxNum != "") {
        if (this.patientEmergencyPharmacyItems[i].RxNum === rxNum)
          this.patientEmergencyPharmacyItems.splice(i, 1);
      } else {
        if (this.patientEmergencyPharmacyItems[i].Type === type && this.patientEmergencyPharmacyItems[i].Directions === directions)
          this.patientEmergencyPharmacyItems.splice(i, 1);
      }
    }
  }

  save() {
    try {
      this.clearErrorMessage()
      var resultFieldsValidation = this.validateFields();
      var model = this.loadModelByForm();

      if (resultFieldsValidation == "") {
        this.emergencyPharmacyService.save(model).subscribe
          (resultVo => {

            if (resultVo.success) {
              this.sharedService.showTempSuccessMessage();
              this.redirectToProfile();
            } else {
              this.loadErrorMessage(resultVo.message);
            }
          });
      }
      else {
        this.loadErrorMessage(resultFieldsValidation);
      }
    } catch (e) {
      this.loadErrorMessage(e);
    }
  }

  redirectToProfile() {
    this.spinnerService.hide();
    this.router.navigate(['patient/profile/' + this.patientId]);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessageAddPrescription(message: any) {
    this.showErrorMessageAddPrescription = true;
    this.errorMessageAddPrescription = message.toString();
  }

  clearErrorMessageAddPrescription() {
    this.showErrorMessageAddPrescription = false;
    this.errorMessageAddPrescription = "";
  }

  validateFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.requestDate == null)
      errors.push('Date');

    if (this.patientHealthCard == null || this.patientHealthCard == "")
      errors.push('Health Card');

    if (this.emergencyPharmacy == null || this.emergencyPharmacy == "")
      errors.push('Emergency Pharmacy');

    if (this.facilityName == null || this.facilityName == "")
      errors.push('Home');

    if (this.deliveryAddress == null || this.deliveryAddress == "")
      errors.push('Delivery Address');

    if (this.prescriberName == null || this.prescriberName == "")
      errors.push('Prescriber');

    if (this.pharmacist == null || this.pharmacist == "")
      errors.push('Pharmacist');
        
    if (this.sharedService.facilityId == null || this.sharedService.facilityId == 0
      || this.patientId == ""
      || this.sharedService.pharmacyId == null || this.sharedService.pharmacyId == 0)
      errors.push('Invalid request, please try close and open it again.');

    if ((this.patientEmergencyPharmacyItems == null || this.patientEmergencyPharmacyItems.length == 0))
      errors.push('Prescriptions list');


    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  validateAddPrescriptionFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.inputMedication.nativeElement.value == "")
      errors.push('Medication');

    if (this.strength == "Create" && this.isEmptyOrSpaces(this.strength))
      errors.push('Strength');

    if (this.isEmptyOrSpaces(this.sig))
      errors.push('Directions');

    if (this.inputQuantity.nativeElement.value == "")
      errors.push('Quantity');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }

  loadModelByForm(): any {
    var model: any = {
      facilityId: this.sharedService.facilityId,
      patientId: Number.parseInt(this.patientId),
      pharmacyId: this.sharedService.pharmacyId,
      createdBy: this.sharedService.getUserId(),
      requestDate: this.requestDate,
      notes: this.notes,
      prescriber: this.prescriberName,
      pharmacist: this.pharmacist,
      patientHealthCard: this.patientHealthCard,
      patientCreditCard: this.patientCreditCard,
      emergencyPharmacy: this.emergencyPharmacy,
      facilityName: this.facilityName,
      deliveryAddress: this.deliveryAddress,
      odp: this.odp,
      patientEmergencyPharmacyItems: this.patientEmergencyPharmacyItems
    }

    return model;
  }

  //Autocomplete Medication element
  myControl = new FormControl('', [Validators.required]);
  filteredMedications: Observable<any[]>;
  toHighlight: string = '';


  loadFilteredMedications() {
    this.drugId = null;
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }

  private _filter(value: string): Observable<any[]> {

    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;

      return this.sharedService.getDrugsBy(value)
        .pipe(
          map(response => response.filter(option => {

            if (texts.length == 1)
              return option.name.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.name.toLowerCase().includes(texts[0].toLowerCase()) && option.name.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }
    return EMPTY;
  }

  getMedications(value) {
    (async () => {
      this.getDrugs(value).subscribe(data => {
        this.drugs = data;

        var findInDrugs = this.drugs.find(d => d.name == value);
        this.inputStrength.nativeElement.value = this.strength = findInDrugs.strength;
        this.inputMedication.nativeElement.value = findInDrugs.nameWithoutStrength;
        this.nameWithoutStrength = findInDrugs.nameWithoutStrength;
        this.drugId = findInDrugs.id;

        this.inputQuantity.nativeElement.value = this.quantity = "";
      });
    })();
  }

  getDrugs(value): Observable<any[]> {
    return this.sharedService.getDrugsBy(value);
  }


  getPatientEmergencyPharmacyItemIndex(): number {
    this.patientEmergencyPharmacyItemIndex++;

    return this.patientEmergencyPharmacyItemIndex;
  }

  redirectToResidentProfile() {
    this.router.navigate(['patient/profile/' + this.patientVo.id]);
  }

}

