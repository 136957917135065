import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedService } from '../services/shared.service';
import { DrugRecordBookService } from '../services/drug-record-book.service';
import { Observable, of } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-drug-record-book',
  templateUrl: './drug-record-book.component.html',
  styleUrls: ['./drug-record-book.component.css']
})
export class DrugRecordBookComponent implements OnInit {
  dateEnd: any;
  getDateEnd: string;
  dateStart: any;
  getDateStart: string;
  drugBookRecords: any[];
  patientId: number;
  residents: any[];
  dd: string;
  mm: string;
  yyyy: string;
  mmS: string;
  ddS: string;
  yyyyS: string;
  showErrorMessage: boolean;
  errorMessage: string;


  constructor(private sharedService: SharedService,
    private drugBookRecordService: DrugRecordBookService,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private datepipe: DatePipe) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();

    if (this.sharedService.facilityId > 0) {
      this.loadFields();
    }

  }

  loadDate() {
    this.dateEnd = new Date();
    this.dateStart = new Date();
    this.dateStart.setDate(this.dateStart.getDate() - 30);
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEnd < this.dateStart)
      errors.push("The End Date must be greater then Start Date!")

    return errors;
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  loadFields() {
    this.loadResidents();
    this.loadDrugRecordBook();

  }

  onActionChangeFacility() {
    this.loadFields();
  }

  onActionChangeResident() {
    this.loadDrugRecordBook();
  }

  onActionChangeDate() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadDrugRecordBook();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  loadDrugRecordBook() {
    var dateStartCoverted = new Date(Date.parse(this.dateStart.toString()));
    var dateDrugRecordBookStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');
    var dateEndCoverted = new Date(Date.parse(this.dateEnd.toString()));
    var dateDrugRecordBookEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

    this.drugBookRecordService.GetDrugRecord(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, dateDrugRecordBookStart, dateDrugRecordBookEnd).subscribe(data => {
      this.drugBookRecords = data;
    });
  }

  loadResidents() {
    this.patientId = 0;
    this.sharedService.getResidentsByFacilityId().subscribe(data => { this.residents = data; });
  }

  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    if (value == "") {
      this.patientId = 0;
      this.loadDrugRecordBook();
    }

    this.toHighlight = value;

    return this.getData()
      .pipe(
        map(response => response.filter(option => {
          return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        }))
      )
  }

  getResidents(value) {
    var resident = this.residents.find(f => f.name == value);
    this.patientId = resident.patientId;
    this.loadDrugRecordBook();
  }


  getData() {
    return of(this.residents);
  }

  @ViewChild('printDrugRecordBook', { static: false }) el!: ElementRef;



  public exportToPdf() {
    var pageTitle = "DrugRecordBookReport";
    var dateStartCoverted = new Date(Date.parse(this.dateStart.toString()));
    var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');
    var dateEndCoverted = new Date(Date.parse(this.dateEnd.toString()));
    var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');
    this.router.navigate([]).then(result => {
      window.open('report/DrugRecordBook/' + dateStart + '/' + dateEnd + '/' + this.patientId + '/' + this.sharedService.pharmacyId + '/' + this.sharedService.facilityId + '/' + pageTitle, '_blank');
    });
  }
}
