import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { IUserCreate } from '../../../interfaces/iuser-create';
import { SettingsService } from '../../../services/settings.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})

export class SettingsUserCreateComponent implements OnInit {

  roleControl = new FormControl();
  facilityControl = new FormControl();
  roleVos: any[];
  registerForm: FormGroup;
  userCreate: IUserCreate;
  user: any;
  currentUserId: string;
  id: any;
  directory: string;
  facilities: any = [];
  roleForm: any;
  showErrorMessage: boolean;
  errorMessage: string;
  items: any = [];
  condition: boolean;
  hasPrivilegeChecked: boolean;
  pharmacies: any = [];
  hasPharmacistRole: boolean;
  actionTitle: string = '';

  constructor(private settingsService: SettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.hasPrivilegeChecked = false;
    this.condition = true;
    this.currentUserId = this.sharedService.getUserId();
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: [''],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      roleControl: ['', Validators.required],
      facilityControl: ['', Validators.required],
      ocpNumber: ['']
    });

    this.userCreate = {} as IUserCreate;

    this.userCreate.facilityIds = [];
    this.userCreate.roleIds = [];
    this.userCreate.phoneNumber = '';
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadPharmacies();
    this.loadFacilities();
    this.loadRoleVos();

    if (this.id != null) {
      this.actionTitle = "Edit";
      this.settingsService.getUser(this.id, this.currentUserId).subscribe(data => {
        this.userCreate = data;
        this.isPharmacistRole();
        this.onFacilityChange();

        if (this.userCreate.signatureImage == null)
          this.userCreate.signatureImage = "../../../../assets/img/signature.png";
        if (this.userCreate.pictureImage == null)
          this.userCreate.pictureImage = "../../../../assets/img/default-avatar.png";
      });
    } else {
      this.actionTitle = "Create";
      this.settingsService.getPrivilegeGroupVos(this.currentUserId).subscribe(data => {
        this.userCreate.privilegeGroupVos = data;
      });
    }

    this.directory = 'User';
    this.userCreate.signatureImage = "../../../../assets/img/signature.png";
    this.userCreate.pictureImage = "../../../../assets/img/default-avatar.png";

  }

  privilegeGroupChange(privilegeGroupId: any, checked: any) {

    var privilegeGroupVo = this.userCreate.privilegeGroupVos.find(pg => pg.id == privilegeGroupId);
    privilegeGroupVo.checked = checked;

    for (let i = 0; i < privilegeGroupVo.privilegeVos.length; ++i) {
      privilegeGroupVo.privilegeVos[i].checked = checked;
    }

  }

  privilegeChange(privilegeGroupId: any, privilegeId: any, checked: any) {
    var privilegeGroupVo = this.userCreate.privilegeGroupVos.find(pg => pg.id == privilegeGroupId);
    var privilegeVo = privilegeGroupVo.privilegeVos.find(p => p.id == privilegeId);
    privilegeVo.checked = checked;


    var unchecked = privilegeGroupVo.privilegeVos.find(pg => !pg.checked);
    privilegeGroupVo.checked = unchecked == null;
  }

  get f() { return this.registerForm.controls; }


  isUserNameExists() {
    this.settingsService.isUserNameExists(this.userCreate.id, this.userCreate.userName).subscribe
      (result => {
        this.condition = result
        if (this.condition)
          this.createUser();
        else
          this.loadErrorMessage("UserName already exists!");

      });
  }

  onFacilityChange() {
    this.userCreate.userFacilityVos = [];
    for (var i = 0; i < this.userCreate.hierarchyIds.length; i++) {
      let hierarchyId = this.userCreate.hierarchyIds[i].split(".");
      this.userCreate.userFacilityVos[i] = { facilityId: Number(hierarchyId[1]), pharmacyId: Number(hierarchyId[0]) };
    }
  }

  createUser() {
    this.checkPrivilege();

    if (!this.registerForm.invalid && this.hasPrivilegeChecked) {
      this.userCreate.PharmacyId = this.sharedService.pharmacyId;
      this.userCreate.phoneNumber = this.userCreate.phoneNumber ? this.userCreate.phoneNumber.toString() : '';
      this.userCreate.ocpNumber = this.hasPharmacistRole ? this.userCreate.ocpNumber : "";
      this.settingsService.saveUser(this.sharedService.getUserId(), this.userCreate).subscribe
        (resultVo => {
          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.redirectToUsers();
          }
          else
            this.loadErrorMessage(resultVo.message);

        });
    } else
      this.createErrorMessage();
  }

  createErrorMessage() {
    var errors: string[] = [];
    this.errorMessage = "";

    if (this.registerForm.controls.email.invalid) {
      var x = 0;
    }
    if (this.registerForm.controls.firstName.invalid)
      errors.push(' First Name');

    if (this.registerForm.controls.lastName.invalid)
      errors.push('Last Name');

    if (this.registerForm.controls.username.invalid)
      errors.push('Username');

    if (this.registerForm.controls.email.invalid)
      errors.push('Email empty or Invalid');

    if (this.userCreate.roleIds.length < 1)
      errors.push('Role');

    if (this.userCreate.facilityIds.length < 1)
      errors.push('Facility');

    if (!this.hasPrivilegeChecked)
      errors.push('Privilege must be checked');

    if (errors.length == 1) {
      this.errorMessage = "The field " + errors.join(", ") + " is mandatory."
      this.loadErrorMessage(this.errorMessage)
    }
    else if (errors.length > 1) {
      this.errorMessage = "The fields " + errors.join(", ") + " are mandatories."
      this.loadErrorMessage(this.errorMessage)
    }
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  checkPrivilege() {
    var privilegeCheckedList = this.userCreate.privilegeGroupVos.filter(p => p.privilegeVos.find(p => p.checked));
    if (privilegeCheckedList.length > 0)
      this.hasPrivilegeChecked = true;
  }

  redirectToUsers() {
    this.spinnerService.hide();
    this.router.navigate(['settings/users']);
  }

  loadFacilities() {
    this.sharedService.getFacilitiesByPharmacyId().subscribe(data => {
      this.facilities = data;
    });
  }

  loadPharmacies() {
    this.sharedService.getPharmacies().subscribe(data => {
      this.pharmacies = data;
    });
  }

  getItems(id: number) {
    this.settingsService.getRoleItemVoBy(id).subscribe(data => {
      this.items = data;
    });
  }

  loadRoleVos() {
    this.settingsService.getRoleVos().subscribe(data => {
      this.roleVos = data;
    });
  }

  isPharmacistRole() {
    this.settingsService.isPharmacistRole(this.userCreate.roleIds).subscribe(roleId => this.hasPharmacistRole = roleId);
  }

  public uploadSignature = (event) => {
    this.userCreate.signaturePath = event.dbPath;
    this.userCreate.signatureImage = this.settingsService.ApiUrl + event.dbPath;
  }

  public uploadPicture = (event) => {
    this.userCreate.picturePath = event.dbPath;
    this.userCreate.pictureImage = this.settingsService.ApiUrl + event.dbPath;
  }

}
