import { Component, OnInit, Pipe, Input, PipeTransform, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { ReorderService } from '../../../services/reorder.service';
import { IreorderCustomVo } from '../../../interfaces/ireorder-custom-vo';
import { IreorderCreateVo } from '../../../interfaces/ireorder-create-vo';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-create-reorder-custom',
  templateUrl: './create-reorder-custom.component.html',
  styleUrls: ['./create-reorder-custom.component.css']
})
export class CreateReorderCustomComponent implements OnInit {
  types: any[];
  showFacility: boolean;
  facilityName: string = "";
  reorderCustomTypeId: number;
  id: number;
  showResident: boolean;
  myControl = new FormControl();
  patientId: number;
  residents: any = [];
  toHighlight: string = '';
  customList: any[];
  reorderCustom: IreorderCustomVo;
  reorder: IreorderCreateVo;
  showErrorMessage: boolean;
  errorMessage: string;
  reorderCustomTypeFacility: number = 1;
  reorderCustomTypeResident: number = 2;
  residentName: string;

  constructor(private sharedService: SharedService,
    private router: Router,
    private reorderService: ReorderService) { }

  @ViewChild('inputResidents', { static: false }) inputResidents: ElementRef;

  @Output() public onSubmitCustomReorder = new EventEmitter();
  @Input() public isRedirectToIndex: boolean;

  ngOnInit() {
    this.id = 0;
    this.patientId = 0;
    this.residentName = "";
    this.reorderCustom = {} as IreorderCustomVo;
    this.reorder = {} as IreorderCreateVo;
    this.customList = [];
    this.showFacility = false;
    this.reorderCustomTypeId = this.reorderCustomTypeResident;
    this.showResident = true;
    this.loadFields();
    this.isRedirectToIndex = true;

  }

  loadFields() {
    this.loadTypes();
    this.loadFacility();
    this.loadResidents();

  }

  loadTypes() {
    if (this.sharedService.getUserId() != null)
      this.reorderService.getTypes().subscribe(data => {
        this.types = data;
      })
  }

  loadReorderCustomType() {
    var type = this.types.find(f => f.id == this.reorderCustomTypeId);
    this.reorderCustom.reorderCustomTypeText = type.title;
    this.reorderCustom.reorderCustomTypeId = this.reorderCustomTypeId;
  }

  loadFacility() {
    if (this.sharedService.facilities != null) {
      var facility = this.sharedService.facilities.find(f => f.id == this.sharedService.facilityId);
      this.facilityName = facility.name;
    }
  }

  loadReorderFieldsByReorderCustomTypeId() {
    this.loadReorderCustomType();

    this.showFacility = this.reorderCustomTypeId == this.reorderCustomTypeFacility;
    this.showResident = this.reorderCustomTypeId == this.reorderCustomTypeResident;

    this.loadReorderCustomDefaultFields();
  }

  onActionType() {
    this.loadReorderFieldsByReorderCustomTypeId();
  }

  loadResidents() {
    this.sharedService.getResidentsByFacilityId().subscribe(data => {
      this.residents = data;
    });
  }

  getResidents(value) {
    var resident = this.residents.find(f => f.name == value);
    this.reorderCustom.patientId = resident.patientId;
    this.reorderCustom.patientName = value;
    this.showErrorMessage = false;
  }

  filteredResidents: Observable<any>;
  loadFilteredResidents() {

    this.filteredResidents = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {

    if (value == "") {
      this.patientId = 0;
    }

    this.toHighlight = value;

    return this.getData()
      .pipe(
        map(response => response.filter(option => {
          return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        }))
      )
  }

  getData() {
    return of(this.residents);
  }

  loadReorderCustomDefaultFields() {
    this.reorderCustom = {} as IreorderCustomVo;

    if (this.showResident)
      this.residentName = "";
  }

  addCustom() {
    this.showErrorMessage = false;
    this.validadeFields();

    if (!this.showErrorMessage) {
      this.loadReorderCustomType();

      this.reorderCustom.pharmacyId = this.sharedService.pharmacyId;
      if (this.reorderCustomTypeId == this.reorderCustomTypeFacility) {
        this.reorderCustom.facilityId = this.sharedService.facilityId;
        this.reorderCustom.facilityText = this.facilityName;
      }
      this.reorderCustom.id = this.id++;
      this.customList.push(this.reorderCustom);

      this.loadReorderCustomDefaultFields();
    }
  }

  onActionDelete(id) {
    for (let i = 0; i < this.customList.length; ++i) {
      if (this.customList[i].id === id)
        this.customList.splice(i, 1);
    }

  }

  validadeFields() {

    if (!this.reorderCustom.description || this.reorderCustom.description.trim().length === 0) {
      this.showErrorMessage = true;
      this.errorMessage = "Please, enter the Description for Reorder!";
    }

    if (!this.reorderCustom.medication || this.reorderCustom.medication.trim().length === 0) {
      this.showErrorMessage = true;
      this.errorMessage = "Please, enter the Product for Reorder!";
    }

    if (!this.residentName && this.reorderCustomTypeId == this.reorderCustomTypeResident) {
      this.showErrorMessage = true;
      this.errorMessage = "Please, enter the Resident for Reorder!";
    }

    if (this.reorderCustom.patientId == undefined && this.reorderCustomTypeId == this.reorderCustomTypeResident) {
      this.showErrorMessage = true;
      this.errorMessage = "Please, Select the Resident first!";
      this.residentName = "";
    }

  }

  createReoder() {
    if (this.customList.length == 0) {
      this.showErrorMessage = true;
      this.errorMessage = "Please, no Reorder to Submit!";
    }
    else {
      this.reorder.pharmacyId = this.sharedService.pharmacyId;
      this.reorder.facilityId = this.sharedService.facilityId;
      this.reorder.createdBy = this.sharedService.getUserId();
      this.reorder.reorderTypeId = 2;
      this.reorder.customs = this.customList;
      this.reorderService.createReorder(this.reorder).subscribe
        (resultVo => {
          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.onSubmitCustomReorder.emit();
            this.customList = [];
            this.reorderCustom.reorderCustomTypeId = this.reorderCustomTypeResident;
            this.sharedService.loadReceivingAlert();
            if (this.isRedirectToIndex)
              this.redirectToIndex();

          } else {
            this.loadErrorMessage(resultVo.message);
          }
        });
    }
  }

  onCancelReorder() {
    this.onSubmitCustomReorder.emit();
    this.redirectToIndex();
  }

  loadErrorMessage(message) {
    this.showErrorMessage = false;
    this.errorMessage = message;
  }

  redirectToIndex() {
    this.router.navigate(['reorder']);
  }

}

//Highlight text in autocomplete options
@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
