import { Component, OnInit } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { StatisticsService } from '../services/statistics.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  constructor(private service: SharedService,
    private statisticsService: StatisticsService,
    private router: Router) { }

  drugStatisticsReportVo: any;
  drugStatisticsMonthVo: any;
  drugStatisticsMonthVos: any[];
  expand: boolean;
  showLoadingMessage: boolean = true;

  ngOnInit() {
    this.service.reloadUserPrivileges();
    this.loadDrugStatisticsMonthVos();
  }

  loadDrugStatisticsMonthVos() {
    this.statisticsService.getDrugStatisticsMonthVos(this.service.pharmacyId, this.service.facilityId).subscribe(data => {
      this.drugStatisticsMonthVos = data;
      this.drugStatisticsMonthVo = data[0].value;
      this.loadDrugStatisticsReportVo();
    });
  }

  loadDrugStatisticsReportVo() {
    this.statisticsService.getDrugStatisticsReportVo(this.service.pharmacyId, this.service.facilityId, this.drugStatisticsMonthVo).subscribe(
      data => {
        this.drugStatisticsReportVo = data;
        this.showLoadingMessage = false;
    },
      err  => this.showLoadingMessage = false);
  }

  public exportToPdf() {
    var pageTitle = "StatisticsDrugReport";
    this.router.navigate([]).then(result => { window.open('report/StatisticsMedication/' + this.service.pharmacyId + '/' + this.service.facilityId + '/' + this.drugStatisticsMonthVo + '/' + pageTitle, '_blank'); });
  }

  onActionChangeMonth() {
    this.showLoadingMessage = true;
    this.loadDrugStatisticsReportVo();
  }
}
