import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { NarcoticReconciliationService } from '../../services/narcotic-reconciliation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';



@Component({
  selector: 'app-purchase',
  templateUrl: './narcotic-reconciliation-purchase-item.component.html',
  styleUrls: ['./narcotic-reconciliation-purchase-item.component.css']
})
export class NarcoticReconciliationPurchaseItemComponent implements OnInit {

  @ViewChild('closeDrugModal', { static: false }) closeDrugModal: ElementRef;

  constructor(private sharedService: SharedService, private narcoticReconciliationService: NarcoticReconciliationService,
    private activatedRoute: ActivatedRoute, private router: Router,) { }

  showLoadingMessage: boolean;
  purchaseVo: any;
  myControl = new FormControl('');
  filteredMedications: Observable<any[]>;
  purchaseTypeMcKesson: number = 1;
  purchaseTypeKohlFrisch: number = 2;
  medicationName: string;
  drug: any = {};
  filteredDrugs: Observable<any[]>;
  myDrugControl = new FormControl('');
  toHighlight: string = '';
  drugPackId: number;
  purchaseItemId: number;
  purchaseItemMatchPackSize: string;
  searchDrugField: string;
  showErrorMessageModal: boolean;
  errorMessageModal: string;
  warningMessage: boolean;
  noPackSizeFoundMessage: boolean;
  noMedicationFound: boolean;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadNarcoticReconciliationPurchaseVo();

  }

  loadNarcoticReconciliationPurchaseVo() {
    this.showLoadingMessage = true;
    this.narcoticReconciliationService.getNarcoticReconciliationPurchaseVo(Number.parseInt(this.activatedRoute.snapshot.paramMap.get('id'))).subscribe(
      purchaseVo => {
        this.purchaseVo = purchaseVo;
        this.loadFilteredMedications();
        this.showLoadingMessage = false;
        this.warningMessage = this.purchaseVo.purchaseItemVos.some(p => p.matchPackSize == 0);
      });
  }

  loadFilteredMedications() {
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.purchaseVo.purchaseItemVos.filter(option => option.searchDrug.toLowerCase().includes(filterValue));
  }

  onEditPurchaseItem(purchaseItem) {
    this.clearErrorMessageModal();
    this.purchaseItemMatchPackSize = purchaseItem.matchPackSize;
    this.purchaseItemId = purchaseItem.id;
    this.getDrug(purchaseItem.din);
  }

  onChangeDrugPack(drugPack) {
    this.drugPackId = Number(drugPack.id);
  }

  submitPackSize() {
    this.narcoticReconciliationService.updateNarcoticReconcilitionDrugPack(this.drugPackId, this.purchaseItemId).subscribe(
      resultVo => {
        if (resultVo.success) {
          this.sharedService.showSuccessMessage = resultVo.success;
          this.closeDrugModal.nativeElement.click();
          this.searchDrugField = '';
          this.loadNarcoticReconciliationPurchaseVo();
        } else {
          this.loadErrorMessage(resultVo.message);
        }
      });
  }

  clearErrorMessageModal() {
    this.showErrorMessageModal = false;
  }

  loadErrorMessage(message: string) {
    this.showErrorMessageModal = true;
    this.errorMessageModal = message;
  }

  loadFilteredDrugs() {
    this.filteredDrugs = this.myDrugControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filterDrug(val)
      })
    )
  }

  private _filterDrug(value: string): Observable<any[]> {
    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;
      return this.sharedService.getDrugsByDefaultPharmacyId(value)
        .pipe(
          map(response => response.filter(option => {

            if (texts.length == 1)
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase()) && option.searchDrug.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }
  }


  getDrug(text) {    
    this.sharedService.getDrugByDefaultPharmacyId(text).subscribe(drug => {
      this.drug = drug;
      this.noPackSizeFoundMessage = false;
      this.noMedicationFound = false;
      if (this.drug.drugPackVos.length > 0) {
        for (var i = 0; i < this.drug.drugPackVos.length; i++) {
          if (this.drug.drugPackVos[i].size == this.purchaseItemMatchPackSize) {
            this.drug.drugPackVos[i].isDrugPackChecked = true;
            this.drugPackId = this.drug.drugPackVos[i].id;
          }
        }
      } else {
        this.noPackSizeFoundMessage = true;
      }         
    }, err => this.noMedicationFound = true);
    this.medicationName = '';
  }

  getDrugById(drugId) {
    this.sharedService.getDrugById(drugId).subscribe(drug => {
      this.drug = drug;
      this.noPackSizeFoundMessage = false;
      this.noMedicationFound = false;
      if (this.drug.drugPackVos.length > 0) {
        for (var i = 0; i < this.drug.drugPackVos.length; i++) {
          if (this.drug.drugPackVos[i].size == this.purchaseItemMatchPackSize) {
            this.drug.drugPackVos[i].isDrugPackChecked = true;
            this.drugPackId = this.drug.drugPackVos[i].id;
          }
        }
      } else {
        this.noPackSizeFoundMessage = true;
      }
    }, err => this.noMedicationFound = true);
    this.medicationName = '';
  }

  redirectToNarcoticReconciliationItem() {
    this.router.navigateByUrl('/narcotic-reconciliation-item/' + this.purchaseVo.narcoticReconciliationId);
  }

  redirectToSettingsDrug() {
    this.closeDrugModal.nativeElement.click();
    this.router.navigate(['settings/drugs']);
  }
}
