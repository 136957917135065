import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NarcoticReconciliationService {

  private ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getNarcoticReconciliation(pharmacyId, narcoticReconciliationStatusId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliation/' + pharmacyId + '/' + narcoticReconciliationStatusId);
  }

  getDispenserKrollVos(narcoticReconciliationId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/NarcoticReconciliation/GetDispenserKrollVos/' + narcoticReconciliationId);
  }

  getPurchaseVos(narcoticReconciliationId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/NarcoticReconciliation/GetPurchaseVos/' + narcoticReconciliationId);
  }
  getNarcoticReconciliationPurchaseVo(purchaseId): Observable<any> {
    return this.http.get<any[]>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationPurchaseVo/' + purchaseId);
  }

  
  createNarcoticReconciliation(obj): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/CreateNarcoticReconciliation';
    return this.http.post<any>(url, obj, { 'headers': headers });
  }

  uploadPurchaseMcKesson(obj): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/UploadPurchaseMcKesson';

    var body = JSON.stringify(obj);
    body = this.replaceAll(body, "Invoice &#x23;", "Invoice");
    body = this.replaceAll(body, "DIN &#x23;", "Din");
    body = this.replaceAll(body, "Unit Price &#x28;&#x24;&#x29;", "UnitPrice");
    body = this.replaceAll(body, "Total Price &#x28;&#x24;&#x29;", "TotalPrice");

    return this.http.post<any>(url, body, { 'headers': headers });
  }

  uploadDispenserKroll(dispenserKrollVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/uploadDispenserKroll';


    var body = JSON.stringify(dispenserKrollVo);
    body = this.replaceAll(body, "Drug Name", "DrugName");
    body = this.replaceAll(body, "Opening Inventory", "OpeningInventory");
    body = this.replaceAll(body, "Pack Size", "PackSize");

    return this.http.post<any>(url, body, { 'headers': headers });
  }

  replaceAll(str, find, replace): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  getDrugsWithPack(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/NarcoticReconciliation/GetDrugsWithPack/');
  }

  getInitialCountDates(pharmacyId, narcoticReconciliationInitialCountStatusId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/NarcoticReconciliation/GetInitialCountDates/' + pharmacyId + '/' + narcoticReconciliationInitialCountStatusId);
  }

  getInitialCountDatesAvailableOptions(pharmacyId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/NarcoticReconciliation/GetInitialCountDatesAvailableOptions/' + pharmacyId);
  }


  createInitialCount(initialCountVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/CreateInitialCount/';
    const body = JSON.stringify(initialCountVo);

    return this.http.post<any>(url, initialCountVo, { 'headers': headers });
  }

  getNarcoticReconciliationById(pharmacyId, narcoticReconciliationId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationById/' + pharmacyId + '/' + narcoticReconciliationId);
  }
  getNarcoticReconciliationItemById(narcoticReconciliationItemId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconcilitionItemById/' + narcoticReconciliationItemId);
  }

  updateNarcoticReconcilitionDrugPack(drugPackId: number, purchaseItemId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/UpdateNarcoticReconcilitionDrugPack/' + drugPackId + '/' + purchaseItemId);
  }

  updateNarcoticReconciliationOnHandCount(narcoticReconciliationItemId: number, currentOnHandCount: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/UpdateOnHandCountBy/' + narcoticReconciliationItemId + '/' + currentOnHandCount);
  }

  getNarcoticReconciliationItemDetailById(pharmacyId, narcoticReconciliationItemId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationItemDetailById/' + pharmacyId + '/' + narcoticReconciliationItemId);
  }

  updateNarcoticReconcilitionItemNotes(narcoticReconciliationItemVo, narcoticReconciliationItemId: number): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/UpdateNarcoticReconcilitionItemNotes/' + narcoticReconciliationItemId;
    const body = JSON.stringify(narcoticReconciliationItemVo);

    return this.http.post<any>(url, narcoticReconciliationItemVo, { 'headers': headers });
  }

  updateNarcoticReconciliationInitialCountItemBy(narcoticReconciliationItemId: number, currentInitialCountItem: number): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/UpdateNarcoticReconciliationInitialCountItemBy/' + narcoticReconciliationItemId + '/' + currentInitialCountItem);
  }

  deleteNarcoticReconciliationPurchase(narcoticReconciliationId, purchaseId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/DeleteNarcoticReconciliationPurchase/' + narcoticReconciliationId + '/' + purchaseId);
  }

  deleteNarcoticReconciliationDispenser(narcoticReconciliationId, dispensedId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/DeleteNarcoticReconciliationDispenser/' + narcoticReconciliationId + '/' + dispensedId);
  }

  deleteNarcoticConciliation(narcoticReconciliationId) {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/DeleteNarcoticReconciliation/' + narcoticReconciliationId);
  }

  deleteNarcoticConciliationInitialCount(narcoticReconciliationId, initialCountId) {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/DeleteNarcoticConciliationInitialCount/' + narcoticReconciliationId + '/' + initialCountId);
  }

  getNarcoticReconciliationInitialCountBy(narcoticReconciliationInitialCountId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationInitialCountBy/' + narcoticReconciliationInitialCountId);
  }

  createNarcotiReconciliationDispenserByIntegration(createdBy, narcoticReconciliationId, pharmacyId, startDate, endDate) {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/CreateNarcotiReconciliationDispenserByIntegration/' + createdBy + '/' + narcoticReconciliationId + '/' + pharmacyId + '/' + startDate + '/' + endDate);
  }

  uploadPurchaseKohlFrisch(purchaseVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/UploadPurchaseKohlFrisch';

    const zero: number = 0;

    var body = JSON.stringify(purchaseVo);
    body = this.replaceAll(body, "Store Number", "StoreNumber");
    body = this.replaceAll(body, "Store Name", "StoreName");
    body = this.replaceAll(body, "Invoice/Credit Note Date", "Day");
    body = this.replaceAll(body, "Invoice/Credit Note Number", "Invoice");
    body = this.replaceAll(body, "Week Number", "WeekNumber");
    body = this.replaceAll(body, "Transaction Type", "TransationType");
    body = this.replaceAll(body, "Item Number", "ItemNumber");


    body = this.replaceAll(body, "Din/NPN Number", "Din");
    body = this.replaceAll(body, "UPC", "Upc");
    body = this.replaceAll(body, "Item Description", "Description");
    body = this.replaceAll(body, "Pack Size", "PackSize");
    body = this.replaceAll(body, "Supplier Name", "SupplierName");
    body = this.replaceAll(body, "Item Category Group Name", "ItemCategory");
    body = this.replaceAll(body, "Generic/Branded", "GenericBranded");

    body = this.replaceAll(body, "Line Quantity", "Quantity");
    body = this.replaceAll(body, "Unit Price", "UnitPrice");
    body = this.replaceAll(body, "Line Value", "TotalPrice");

    body = this.replaceAll(body, "PST Tax Amount", "TaxPst");
    body = this.replaceAll(body, "GST Tax Amount", "TaxGst");
    body = this.replaceAll(body, "HST Tax Amount", "TaxHst");


    body = this.replaceAll(body, '"Quantity": ""', '"Quantity": 0');
    body = this.replaceAll(body, '"UnitPrice": ""', '"UnitPrice": 0');
    body = this.replaceAll(body, '"Quantity":""', '"Quantity":0');
    body = this.replaceAll(body, '"UnitPrice":""', '"UnitPrice":0');

    return this.http.post<any>(url, body, { 'headers': headers });
  }

  submitNarcoticReconciliation(narcoticReconciliationId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/SubmitNarcoticReconciliation/' + narcoticReconciliationId);
  }




  createNarcoticReconciliationPurchaseOtherItem(narcoticReconciliationPurchaseOtherItemVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/CreateNarcoticReconciliationPurchaseOtherItem/';
    return this.http.post<any>(url, narcoticReconciliationPurchaseOtherItemVo, { 'headers': headers });
  }

  getNarcoticReconciliationPurchaseOtherItemVosBy(pharmacyId, startDate, endDate): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationPurchaseOtherItemVosBy/' + pharmacyId+ '/' + startDate + '/' + endDate);
  }

  getNarcoticReconciliationPurchaseOtherItemVoBy(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationPurchaseOtherItemVoBy/' + id);
  }

  deleteNarcoticReconciliationPurchaseOtherItemVo(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/DeleteNarcoticReconciliationPurchaseOtherItemVo/' + id);
  }

  updateNarcoticReconciliationPurchaseOtherItemVo(narcoticReconciliationPurchaseOtherItemVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/UpdateNarcoticReconciliationPurchaseOtherItemVo/';
    return this.http.post<any>(url, narcoticReconciliationPurchaseOtherItemVo, { 'headers': headers });
  }

  createNarcoticReconciliationDestructionItem(narcoticReconciliationDestructionItemVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/CreateNarcoticReconciliationDestructionItem/';
    return this.http.post<any>(url, narcoticReconciliationDestructionItemVo, { 'headers': headers });
  }

  getNarcoticReconciliationDestructionItemVosBy(pharmacyId, startDate, endDate): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationDestructionItemVosBy/' + pharmacyId + '/' + startDate + '/' + endDate);
  }

  getNarcoticReconciliationDestructionItemVoBy(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationDestructionItemVoBy/' + id);
  }

  deleteNarcoticReconciliationDestructionItemVo(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/DeleteNarcoticReconciliationDestructionItemVo/' + id);
  }

  updateNarcoticReconciliationDestructionItemVo(narcoticReconciliationDestructionItemVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/NarcoticReconciliation/UpdateNarcoticReconciliationDestructionItemVo/';
    return this.http.post<any>(url, narcoticReconciliationDestructionItemVo, { 'headers': headers });
  }

  getNarcoticReconciliationDestructionMethodTypes(): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/NarcoticReconciliation/GetNarcoticReconciliationDestructionMethodTypes');
  }
}


