import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ViewportScroller } from '@angular/common';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-drug',
  templateUrl: './drug.component.html',
  styleUrls: ['./drug.component.css']
})
export class DrugComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private scroll: ViewportScroller) { }

  drugs: any[];
  drug: any;
  showErrorMessage: boolean;
  errorMessage: string;
  showLoadingMessage: boolean;
  drugSelected: boolean;
  myControl = new FormControl('');
  filteredMedications: Observable<any>;
  toHighlight: string = '';
  searchedDrugName: string;
  searchOptionId: any;
  drugId: number;
  drugPacks: any[];
  drugBrandName: string;
  drugGenericName: string;
  drugDin: string;
  drugFormId: number;
  drugForm: string;
  drugStrength: string;
  drugPackInput: string;
  drugPack: string;
  drugSchedule: string;
  dosageForms: any[];
  isNarcotic: boolean;
  lastUpdateDate: Date;

  @ViewChild('closeNewModal', { static: false }) closeNewModal: ElementRef;
  @ViewChild('closeEditModal', { static: false }) closeEditModal: ElementRef;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.drugId = 0;
    this.drugFormId = null;
    this.searchOptionId = 0;
    this.drugs = [];
    this.drug = {};
    this.drugPacks = [];
    this.drugPackInput = '';
    this.loadDosageForms();
    this.lastUpdateDate = new Date();
  }

  loadAllDrugs() {
    if (this.searchOptionId == 1) {
      this.searchedDrugName = '';
      this.showLoadingMessage = true;
      this.sharedService.getDrugs().subscribe(data => {
        this.drugs = data;
        this.showLoadingMessage = false;
      },
        err => this.showLoadingMessage = false);
    } else if (this.searchOptionId == 0) {
      this.drugs = [];
    }
  }

  loadDosageForms() {
    this.sharedService.getDrugDosageForms()
      .subscribe(result => {
        this.dosageForms = result;
      });

  }

  onSelectDosageForm() {
    this.drugForm = this.dosageForms.find(f => f.id == this.drugFormId).title;
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    this.scroll.scrollToPosition([0, 0]);
  }

  clearFormInput() {
    this.drugBrandName = '';
    this.drugGenericName = '';
    this.drugDin = '';
    this.drugFormId = null;
    this.drugStrength = '';
    this.drugSchedule = '';
    this.drugPackInput = '';
    this.isNarcotic = false;
    this.drugPacks = [];
    this.clearErrorMessage();
  }

  loadDrugModel(): any {
    let drugModel = {
      id: this.drugId,
      brandName: this.drugBrandName,
      genericName: this.drugGenericName,
      din: this.drugDin,
      form: this.drugForm,
      strength: this.drugStrength,
      schedule: this.drugSchedule,
      drugPackVos: this.drugPacks,
      isNarcotic: this.isNarcotic,
      lastUpdateDate: this.lastUpdateDate
    }
    return drugModel;
  }

  createDrug() {
    var validationResult = this.drugFormValidation();
    if (validationResult == "") {
      let drugVo = this.loadDrugModel();

      this.sharedService.createDrugs(drugVo).subscribe
        (resultVo => {
          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
          } else {
            this.loadErrorMessage(resultVo.message);
          }
        });
      this.closeNewModal.nativeElement.click();
      this.clearFormInput();
    } else {
      this.loadErrorMessage(validationResult);
    }
  }

  editDrug(drugId) {
    this.sharedService.getDrugById(drugId).subscribe(data => {
      this.showLoadingMessage = true;
      this.drug = data;
      this.loadEditForm();
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  updateDrug() {
    var validationResult = this.drugFormValidation();
    if (validationResult == "") {
      let drugVo = this.loadDrugModel();
      if (this.drugId != 0) {
        this.sharedService.updateDrug(drugVo, this.drugId).subscribe
          (resultVo => {
            if (resultVo.success) {
              this.sharedService.showSuccessMessage = resultVo.success;
            } else {
              this.loadErrorMessage(resultVo.message);
            }
          });
        this.closeEditModal.nativeElement.click();
        this.clearFormInput();
      }
    } else {
      this.loadErrorMessage(validationResult);
    }
  }

  loadEditForm() {
    this.drugId = this.drug.id;
    this.drugBrandName = this.drug.brandName;
    this.drugGenericName = this.drug.genericName;
    this.drugDin = this.drug.din;
    this.drugForm = this.drug.form;
    this.drugStrength = this.drug.strength;
    this.isNarcotic = this.drug.isNarcotic;
    this.drugSchedule = this.drug.schedule;
    this.drugPacks = this.drug.drugPackVos;
  }

  addNewDrugPackSize() {
    if (this.drugPackInput.trim().length > 0) {
      this.drugPacks.push({
        id: 0,
        drugId: 0,
        pharmacyId: 0,
        size: Number(this.drugPackInput)
      });
      this.drugPackInput = '';
    }
  }

  onRemoveDrugPackSize(drugPackIndex) {
    this.drugPacks.splice(drugPackIndex, 1);
    this.drugPack = this.drugPacks.join(", ");
  }

  loadFilteredMedications() {
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;
      return this.sharedService.getDrugsByDefaultPharmacyId(value)
        .pipe(
          map(response => response.filter(option => {

            if (texts.length == 1)
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase()) && option.searchDrug.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }
  }

  drugFormValidation(): string {
    var errors = [];
    var duplicatePackMessage = "";
    var message = "";

    if (this.drugBrandName == '' || this.drugBrandName == null)
      errors.push('Brand Name')

    if (this.drugGenericName == '' || this.drugGenericName == null)
      errors.push('Generic Name')

    if (this.drugDin == '' || this.drugDin == null)
      errors.push('DIN')

    if (this.drugForm == '' || this.drugForm == null)
      errors.push('Dosage Form')

    if (this.drugStrength == '' || this.drugStrength == null)
      errors.push('Strength')

    if (this.drugPacks.length > 0) {
      const uniqueValues = new Set(this.drugPacks.map(v => v.size));

      if (uniqueValues.size < this.drugPacks.length) {
        duplicatePackMessage = 'Duplicated Pack sizes.'
      }
    }

    if (errors.length == 1)
      message = "The field " + errors.join(", ") + " is mandatory."

    else if (errors.length > 1) {
      message = "The fields " + errors.join(" and ") + " are mandatories."
    }

    return (duplicatePackMessage.length > 0) ? message + " " + duplicatePackMessage : message;

  }
}
