import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { SettingsService } from '../../../services/settings.service';
import { SharedService } from '../../../services/shared.service';
import { ITrainingContentItemVo } from "../../../interfaces/training/itraining-content-item-vo";
import { ITrainingAssessmentQuestionVo } from "../../../interfaces/training/itraining-assessment-question-vo";
import { ITrainningAssessmentAnswerVo } from "../../../interfaces/training/itrainning-assessment-answer-vo";
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-training-create',
  templateUrl: './training-create.component.html',
  styleUrls: ['./training-create.component.css']
})

export class SettingsTrainingCreateComponent implements OnInit {
  @ViewChild('trainingNameInputField', { static: false }) trainingNameInputField: ElementRef;
  @ViewChild('evaluationQuestionInputField', { static: false }) evaluationQuestionInputField: ElementRef;
  @ViewChild('evaluationAnswerInputField', { static: false }) evaluationAnswerInputField: ElementRef;

  constructor(private sharedService: SharedService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private router: Router) { }

  private zoom: number;
  public zoomText: string;
  public contents: any[] = [];
  private pdfViewer: string;
  private totalSlides: number;
  private generalInformationAssessmentAttemptLimit: number;
  private generalInformationAssessmentMinimunScore: number;
  private pharmacyFormControl = new FormControl('');

  private trainingVo: any = {};
  private trainingAssessmentQuestionVos: ITrainingAssessmentQuestionVo[] = [];
  private trainningAssessmentAnswerVos: ITrainningAssessmentAnswerVo[] = [];
  private trainingContentItemVos: ITrainingContentItemVo[] = [];

  private isCorrectAnswer: boolean;
  private evaluationInputAnswer: string;
  private evaluationInputQuestion: string;

  public showErrorMessage: boolean;
  public errorMessage: string;
  private generalInformationErrorMessages: string[];
  private isGeneralInformationFormValidated: boolean;
  private contentErrorMessages: string[];
  private isContentFormValidated: boolean;
  private assessmentErrorMessages: string[];
  private isAssessmentFormValidated: boolean;
  public successTrainingSubmissionMessage: string;
  public pharmacies: any[];

  private trainingId: number = 0;
  private trainingContentId: number = 0;
  private trainingAssessmentId: number = 0;
  private countPdfLoading: number = 0;
  public showLoadingMessage: boolean;
  public trainingPageTitle: string = 'CREATE';
  private resetTrainingCheckBox: boolean;
  private userTrainingsToReset: any[] = [];
  public existentUserTraningsInProgress: number;
  public existentUserTraningsConcluded: number;
  public isFieldsValidated: boolean;


  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.trainingVo.name = "";
    this.trainingVo.awardBy = "";
    this.trainingVo.pharmacyIds = [];
    this.loadFields();
  }

  private loadFields() {
    this.trainingVo.trainingContentVos = [];
    this.trainingVo.trainingAssessmentVos = [];

    this.evaluationInputQuestion = "";
    this.evaluationInputAnswer = "";
    this.loadPharmacies();
    this.pdfViewer = "";
    this.successTrainingSubmissionMessage = "All fields were validated."
    this.generalInformationAssessmentMinimunScore = null;
    this.generalInformationAssessmentAttemptLimit = null;
    this.trainingVo.hasAssessment = false;
    this.zoom = 1.0;
    this.trainingAssessmentQuestionVos = [];
    this.trainningAssessmentAnswerVos = [];
    this.trainingContentItemVos = [];
    this.updateZoomText();

    setTimeout(() => this.trainingNameInputField.nativeElement.focus(), 500);

    this.trainingId = Number(this.route.snapshot.paramMap.get('trainingId'));

    if (this.trainingId > 0) {
      this.trainingPageTitle = 'UPDATE';
      this.loadTraining();
    }

  }

  private loadTraining() {
    this.showLoadingMessage = true;
    this.settingsService.getTrainingVoBy(this.trainingId).subscribe(
      trainingVo => {
        this.trainingVo = trainingVo;
        this.loadFormByModel();
        this.showLoadingMessage = false;
      },
      err => this.showLoadingMessage = false
    );
  }

  public saveTraining() {
    this.settingsService.saveTraining(this.loadModelByForm()).subscribe
      (resultVo => {
        if (resultVo.success) {
          this.sharedService.showSuccessMessage = resultVo.success;
          this.redirectToSettingsTraningIndex();
        } else {
          this.loadErrorMessage(resultVo.message);
        }
      });
  }

  public openResetTrainingModal() {
    this.resetTrainingCheckBox = false;
    this.showLoadingMessage = true;
    this.settingsService.getExistentUserTrainingsToResetBy(this.trainingId).subscribe(
      userTrainingsToReset => {
        this.userTrainingsToReset = userTrainingsToReset;
        this.existentUserTraningsInProgress = this.userTrainingsToReset.filter(inProgress => inProgress.trainingProgressStatusId == 2).length;
        this.existentUserTraningsConcluded = this.userTrainingsToReset.filter(inProgress => inProgress.trainingProgressStatusId == 4).length;
        this.showLoadingMessage = false;
      }, err => this.showLoadingMessage = false
    );
  }

  private loadModelByForm(): any {
    if (this.trainingId > 0) {
      this.trainingVo.trainingContentVos[0].pdfFile64 = this.pdfViewer.substr(28);
      this.trainingVo.trainingContentVos[0].trainingContentItemVos = this.trainingContentItemVos;

      this.trainingVo.trainingAssessmentVos = [];
      this.trainingVo.resetUserTraining = this.resetTrainingCheckBox;
    }
    else {
      this.trainingVo.trainingContentVos.push({
        pdfFile64: this.pdfViewer.substr(28),
        trainingContentItemVos: this.trainingContentItemVos,
        resetUserTraining: false,
        trainingAssessmentVos: []
      })
    }

    if (this.trainingVo.hasAssessment) {
      this.trainingVo.trainingAssessmentVos.push(
        {
          id: this.trainingAssessmentId,
          trainingId: this.trainingId,
          attemptLimit: this.generalInformationAssessmentAttemptLimit,
          minimunScore: this.generalInformationAssessmentMinimunScore,
          trainingAssessmentQuestionVos: this.trainingAssessmentQuestionVos
        });
    }

    return this.trainingVo;
  }

  private loadFormByModel() {

    if (this.trainingVo.hasContent) {
      var trainingContentVo = this.trainingVo.trainingContentVos.find(tc => tc.trainingId == this.trainingId);
      this.trainingContentId = trainingContentVo.id;
      this.pdfViewer = 'data:application/pdf;base64,' + trainingContentVo.pdfFile64;
      this.trainingContentItemVos = trainingContentVo.trainingContentItemVos;
    }

    if (this.trainingVo.hasAssessment) {
      var trainingAssessmentVo = this.trainingVo.trainingAssessmentVos.find(ta => ta.trainingId == this.trainingId);
      this.trainingAssessmentId = trainingAssessmentVo.id;
      this.generalInformationAssessmentAttemptLimit = trainingAssessmentVo.attemptLimit;
      this.generalInformationAssessmentMinimunScore = trainingAssessmentVo.minimunScore;
      this.trainingAssessmentQuestionVos = trainingAssessmentVo.trainingAssessmentQuestionVos;
    }
  }

  private loadPharmacies() {
    this.sharedService.getPharmacies().subscribe(
      data => this.pharmacies = data);
  }

  private validateAssessmentQuestion(): string {
    this.clearErrorMessage();
    var errors: string[] = [];
    var errorMessage = "";

    if (this.evaluationInputQuestion.trim().length == 0)
      errors.push("Input Question is empty")

    if (this.trainningAssessmentAnswerVos.length < 2)
      errors.push("Answer list must have more than one item.")

    if (errors.length > 0)
      errorMessage = errors.join(" - ");

    return errorMessage;

  }

  private validateGeneralInformationFormFields(): boolean {
    this.generalInformationErrorMessages = [];
    this.isGeneralInformationFormValidated = true;


    if (this.trainingVo.name.trim().length == 0)
      this.generalInformationErrorMessages.push('Training Name');

    if (this.trainingVo.awardBy.trim().length == 0)
      this.generalInformationErrorMessages.push('Award By')

    if (this.trainingVo.pharmacyIds.length == 0)
      this.generalInformationErrorMessages.push('Pharmacy')

    if (this.generalInformationErrorMessages.length > 0)
      this.isGeneralInformationFormValidated = false;

    return this.isGeneralInformationFormValidated;

  }

  private validateContentFormFields(): boolean {
    this.contentErrorMessages = [];
    this.isContentFormValidated = true;

    if (this.pdfViewer.trim().length == 0)
      this.contentErrorMessages.push('PDF File');

    if (this.contentErrorMessages.length > 0)
      this.isContentFormValidated = false;

    return this.isContentFormValidated;
  }

  private validateAssessmentFormFields(): boolean {
    this.assessmentErrorMessages = [];
    this.isAssessmentFormValidated = true;

    if (this.trainingVo.hasAssessment && this.trainingAssessmentQuestionVos.length == 0)
      this.assessmentErrorMessages.push('Since "Include Assessment" is selected, you must include at least one question for the training evaluation.');

    if (this.trainingVo.hasAssessment && this.hasQuestionNotAnswered())
      this.assessmentErrorMessages.push('There is at least one question with no answer.');

    if (this.assessmentErrorMessages.length > 0)
      this.isAssessmentFormValidated = false;

    return this.isAssessmentFormValidated;
  }

  private hasQuestionNotAnswered(): boolean {
    return this.trainingAssessmentQuestionVos
      .map(q => q.trainningAssessmentAnswerVos
        .some(a => a.isCorrect)
      )
      .some(a => a == false);
  }

  private validateFields() {
    this.isFieldsValidated = false;

    this.validateGeneralInformationFormFields();
    this.validateContentFormFields();
    this.validateAssessmentFormFields();

    this.isFieldsValidated = this.isGeneralInformationFormValidated && this.isContentFormValidated && this.isAssessmentFormValidated;
  }

  public zoomIn() {
    this.zoom += 0.1;
    this.updateZoomText();
  }

  public zoomOut() {
    this.zoom -= 0.1;
    this.updateZoomText();
  }

  private updateZoomText() {
    this.zoomText = Math.round(this.zoom * 100) + "%";
  }

  public uploadFile(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      var pdfBase64File = event.target.result;
      this.pdfViewer = pdfBase64File;
      this.zoom = 1.0;
    };
  }

  public afterLoadPdfComplete(pdf: PDFDocumentProxy) {
    this.totalSlides = pdf.numPages;
    this.loadTrainingContentItemVosAfterLoadPdf();
  }

  public addTrainingAssessmentQuestionVo() {
    if (this.validateAssessmentQuestion() == "") {
      this.trainingAssessmentQuestionVos.push({
        id: 0,
        trainingAssessmentId: this.trainingAssessmentId,
        name: this.evaluationInputQuestion,
        trainningAssessmentAnswerVos: this.trainningAssessmentAnswerVos
      });

      this.clearEvaluationQuestionInputField();
    }
    else {
      this.loadErrorMessage(this.validateAssessmentQuestion());
    }

  }

  public deleteTrainingAssessmentQuestionVo(index) {
    this.trainingAssessmentQuestionVos.splice(index, 1);
  }

  public addTrainningAssessmentAnswerVos() {
    this.clearErrorMessage();
    if (this.evaluationInputAnswer != "") {
      this.trainningAssessmentAnswerVos.push(
        {
          id: 0,
          trainingAssessmentQuestionId: 0,
          name: this.evaluationInputAnswer,
          isCorrect: this.isCorrectAnswer,
          letter: String.fromCharCode(65 + this.trainningAssessmentAnswerVos.length).toUpperCase()
        });

      this.clearEvaluationAnswerInputFields();
    }
    else {
      this.loadErrorMessage("Input Answer field is empty.")
    }
  }

  public deleteTrainningAssessmentAnswerVos(index) {
    this.trainningAssessmentAnswerVos.splice(index, 1);
  }

  private loadTrainingContentItemVosAfterLoadPdf() {

    if (!this.trainingVo.hasContent || this.countPdfLoading > 0) {
      this.trainingContentItemVos = [];
      for (let i = 1; i <= this.totalSlides; i++) {
        this.trainingContentItemVos.push({
          id: 0,
          trainingContentId: this.trainingContentId > 0 ? this.trainingContentId : 0,
          name: "Slide Default Title " + i
        });
      }
    }

    this.countPdfLoading++;
  }

  private redirectToSettingsTraningIndex() {
    this.router.navigate(['settings/training']);
  }

  public onClickGeneralInformationTab() {
    setTimeout(() => this.trainingNameInputField.nativeElement.focus(), 500);
  }

  public onClickAssessmentTab() {
    this.clearEvaluationQuestionInputField();
  }

  public onClickValidationTab() {
    this.validateFields();
  }

  public focusOnAnswerInputField() {
    this.evaluationAnswerInputField.nativeElement.focus();
  }

  private clearEvaluationQuestionInputField() {
    setTimeout(() => this.evaluationQuestionInputField.nativeElement.focus(), 500);
    this.evaluationInputQuestion = "";
    this.trainningAssessmentAnswerVos = [];
    this.clearEvaluationAnswerInputFields();
  }

  private clearEvaluationAnswerInputFields() {
    this.evaluationInputAnswer = "";
    this.isCorrectAnswer = false;
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
  }

  private loadErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }
}
