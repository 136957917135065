import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITrainingVo } from '../interfaces/training/itraining-vo';

@Injectable({
  providedIn: 'root'
})
export class UserTrainingService {

  public ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  createUserTrainingVo(userTrainingVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/training/CreateUserTraining';
    const body = JSON.stringify(userTrainingVo);

    return this.http.post<any>(url, userTrainingVo, { 'headers': headers });
  }

  updateUserTrainingVo(userTrainingVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/training/UpdateUserTraining';
    const body = JSON.stringify(userTrainingVo);

    return this.http.post<any>(url, userTrainingVo, { 'headers': headers });
  }

  getTrainingVoBy(trainingId: number): Observable<ITrainingVo> {
    return this.http.get<ITrainingVo>(this.ApiUrl + 'api/training/GetTrainingVoBy/' + trainingId);
  }

  getUserTrainingVoBy(userTrainingId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/training/GetUserTrainingVoBy/' + userTrainingId);
  }

  getUserTrainingIndexVos(pharmacyId, userId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/training/getUserTrainingIndexVos/' + pharmacyId + '/' + userId);
  }

  createUserTrainingAssessmentVoBy(userTrainingId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/training/CreateUserTrainingAssessment/' + userTrainingId);
  }

  getUserTrainingAssessmentVoBy(userTrainingAssessmentId: number): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/training/GetUserTrainingAssessmentVoBy/' + userTrainingAssessmentId);
  }


  submitUserTrainingAssessmentVo(userTrainingAssessmentVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/training/SubmitUserTrainingAssessmentVo';
    const body = JSON.stringify(userTrainingAssessmentVo);

    return this.http.post<any>(url, userTrainingAssessmentVo, { 'headers': headers });
  }

}
