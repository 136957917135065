import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-patient-primary-care',
  templateUrl: './patient-primary-care.component.html',
  styleUrls: ['./patient-primary-care.component.css']
})
export class PatientPrimaryCareComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private medsCheckStatusDraft: string = 'Draft';
  private primaryCareProviders: any[];
  private primaryCareControl = new FormControl();
  private observablePrimaryCares: Observable<any[]>;
  private toHighlight: string = '';

  ngOnInit() { }

  private clearPrimaryCareProviders() {
    this.primaryCareProviders = [];
  }

  private loadFilteredPrimaryCares() {
    this.primaryCareProviders = [];
    this.observablePrimaryCares = this.primaryCareControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;
      return this.medsCheckService.getDoctorsBy(value)
        .pipe(
          map(response =>
            response.filter(option => {
              this.primaryCareProviders.push(option);
              if (texts.length == 1) 
                return option.lastName.toLowerCase().includes(texts[0].toLowerCase());
              else 
                return option.lastName.toLowerCase().includes(texts[0].toLowerCase()) && option.lastName.toLowerCase().includes(texts[1].toLowerCase());
            })));
    }
  }

  private getPrimaryCareProvider(doctorId) {
    var selectedDoctor = this.primaryCareProviders.find(p => p.id == doctorId);
    this.medsCheckService.medsCheckVo.patientVo.doctorVo = selectedDoctor;
  }

}
