import { Component, ViewChild, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { NarcoticReconciliationService } from '../../services/narcotic-reconciliation.service';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatRadioChange, MatRadioButton } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-narcotic-reconciliation-destruction-item',
  templateUrl: './narcotic-reconciliation-destruction-item.component.html',
  styleUrls: ['./narcotic-reconciliation-destruction-item.component.css']
})

export class NarcoticReconciliationDestructionItemComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal: ElementRef;
  @ViewChild('closeDeleteConfirmationModal', { static: false }) closeDeleteConfirmationModal: ElementRef;

  constructor(private sharedService: SharedService,
    private narcoticReconciliationService: NarcoticReconciliationService, private datepipe: DatePipe) { }

  @Output()
  change: EventEmitter<MatRadioChange>

  narcoticReconciliationDestructionItemVo: any = {};
  narcoticReconciliationDestructionItemVos: any[] = [];
  destructionMethodTypes: any[] = [];

  filteredDrugs: Observable<any[]>;
  myDrugControl = new FormControl('');
  drug: any = {};
  toHighlight: string = '';
  destroyedDate: any;
  drugPackId: number;
  quantity: number;
  reason: string;
  notes: string;
  methodId: any;
  methodIdDestroyedByPharmacy: number = 1;
  hasDrugPack: boolean;
  isPacksizeChecked: boolean;
  isUpdateAction: boolean;
  isDefaultDrugPackMessage: boolean;
  showErrorMessage: boolean;
  showModalErrorMessage: boolean;
  modalErrorMessage: string;
  errorMessage: string;
  maxDate: any;
  startDate: any;
  endDate: any;
  drugName: string;

  ngOnInit() {
    this.clearDrugInputField();
    this.loadDate();
    this.loadNarcoticReconciliationDestructionItemVos();
    this.loadNarcoticReconciliationDestructionMethodTypes();
  }

  loadNarcoticReconciliationDestructionMethodTypes() {
    this.narcoticReconciliationService.getNarcoticReconciliationDestructionMethodTypes().subscribe(
      data => this.destructionMethodTypes = data);
  }

  onChangeDestructionMethodType(id) {
    this.methodId = id;
  }

  loadNarcoticReconciliationDestructionItemVos() {
    var dateStart = this.datepipe.transform(this.startDate, 'yyyy-MM-dd');
    var dateEnd = this.datepipe.transform(this.endDate, 'yyyy-MM-dd');

    this.narcoticReconciliationService.getNarcoticReconciliationDestructionItemVosBy(this.sharedService.pharmacyId, dateStart, dateEnd).subscribe(
      data => this.narcoticReconciliationDestructionItemVos = data);
  }

  hasDruPack(): boolean {
    return this.hasDrugPack = this.drug.drugPackVos.length > 0
  }

  loadDrugs() {
    this.filteredDrugs = this.myDrugControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filterDrug(val)
      })
    )
  }

  private _filterDrug(value: string): Observable<any[]> {
    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;
      return this.sharedService.getDrugsByDefaultPharmacyId(value)
        .pipe(
          map(response => response.filter(option => {
            if (texts.length == 1)
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.searchDrug.toLowerCase().includes(texts[0].toLowerCase()) && option.searchDrug.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }

  }

  getDrugById(drugId, isUpdateAction: boolean) {
    this.isDefaultDrugPackMessage = false;
    this.hasDrugPack = false;
    this.sharedService.getDrugById(drugId).subscribe(drug => {
      this.drug = drug;
      this.drugName = this.drug.searchDrug;

      if (isUpdateAction) {
        this.loadFormByModel();
      } else if (this.drug.drugPackVos.length > 0 && !this.isPacksizeChecked) {
        this.hasDrugPack = true;
        this.drug.drugPackVos[0].isDrugPackChecked = true;
        this.drugPackId = this.drug.drugPackVos[0].id;
        this.isPacksizeChecked = true;
      }
    });
  }

  onChangeDrugPack(drugPack: MatRadioChange) {
    let drugPackRadioButton: MatRadioButton = drugPack.source;
    this.drugPackId = drugPackRadioButton.value;
    this.isPacksizeChecked = drugPackRadioButton.checked;
  }

  loadModelByForm(): any {
    var validationMessage = this.validateFormFields();
    var destructionItemVo: any;

    if (validationMessage == "") {
      destructionItemVo = {
        id: 0,
        userId: this.sharedService.getUserId(),
        drugId: this.drug.id,
        drugPackId: parseInt(this.drugPackId.toString()),
        pharmacyId: this.sharedService.pharmacyId,
        methodId: this.methodId,
        destroyedDate: this.destroyedDate,
        quantity: this.quantity,
        notes: this.notes,
        reason: this.reason,
        createdDate: new Date()
      }

      if (this.isUpdateAction)
        destructionItemVo.id = this.narcoticReconciliationDestructionItemVo.id

      return destructionItemVo;
    }
    else {
      this.loadModalErrorMessage(validationMessage);
    }
  }

  validateFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.destroyedDate == null)
      errors.push('Invoice Date');

    if (this.drug.id == 0 || this.drugName == '')
      errors.push('Drug');

    if (this.drug.drugPackVos.length == 0 || !this.isPacksizeChecked)
      errors.push('Packsize');

    if (this.quantity == null)
      errors.push('Quantity');

    if (this.notes == '' || this.notes == null)
      errors.push('Notes');

    if (this.reason == '' || this.reason == null)
      errors.push('Reason');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  cleanFormFields() {
    this.destroyedDate = new Date();
    this.quantity = null;
    this.notes = '';
    this.reason = '';
    this.methodId = this.methodIdDestroyedByPharmacy;
    this.clearDrugInputField();
    this.clearModalErrorMessage();
  }

  clearDrugInputField() {
    this.drug = { id: 0 };
    this.drug.drugPackVos = [];
    this.hasDrugPack = false;
    this.isPacksizeChecked = false;
    this.narcoticReconciliationDestructionItemVo = {};
    this.drugName = '';
  }

  openNarcoticReconciliationDestructionItemRecord() {
    this.isDefaultDrugPackMessage = true;
    this.isUpdateAction = false;
    this.hasDrugPack = false;
    this.cleanFormFields();
  }

  addNarcoticReconciliationDestructionItemRecordVo() {
    var narcoticReconciliationDestructionItemVo = this.loadModelByForm();
    this.narcoticReconciliationService.createNarcoticReconciliationDestructionItem(narcoticReconciliationDestructionItemVo).subscribe
      (resultVo => {
        if (resultVo.success) {
          this.closeModal.nativeElement.click();
          this.sharedService.showSuccessMessage;
          this.loadNarcoticReconciliationDestructionItemVos();
        } else {
          this.loadModalErrorMessage(resultVo.message);
        }
      });
  }

  openNarcoticReconciliationDestructionItemDeleteConfirmation(id) {
    this.narcoticReconciliationService.getNarcoticReconciliationDestructionItemVoBy(id).subscribe(
      data => this.narcoticReconciliationDestructionItemVo = data);
  }

  deleteNarcoticReconciliationDestructionItemVo(id) {
    this.narcoticReconciliationService.deleteNarcoticReconciliationDestructionItemVo(id).subscribe(
      resultVo => {
        if (resultVo.success) {
          this.loadNarcoticReconciliationDestructionItemVos();
          this.closeDeleteConfirmationModal.nativeElement.click();
          this.sharedService.showSuccessMessage;
        } else {
          this.loadModalErrorMessage(resultVo.message);
        }
      });
  }

  editNarcoticReconciliationDestructionItemVo(id) {
    this.clearModalErrorMessage();
    this.isUpdateAction = true;
    this.isDefaultDrugPackMessage = false;
    this.hasDrugPack = false;
    this.narcoticReconciliationService.getNarcoticReconciliationDestructionItemVoBy(id).subscribe(
      data => {
        this.clearDrugInputField();
        this.narcoticReconciliationDestructionItemVo = data;
        this.drugPackId = this.narcoticReconciliationDestructionItemVo.drugPackId;
        this.getDrugById(this.narcoticReconciliationDestructionItemVo.drugId, this.isUpdateAction);
      });
  }

  loadFormByModel() {
    this.destroyedDate = this.narcoticReconciliationDestructionItemVo.destroyedDate;
    this.reason = this.narcoticReconciliationDestructionItemVo.reason;
    this.notes = this.narcoticReconciliationDestructionItemVo.notes;
    this.quantity = this.narcoticReconciliationDestructionItemVo.quantity;
    this.methodId = this.narcoticReconciliationDestructionItemVo.methodId;

    if (this.drug.drugPackVos.length > 0) {
      this.hasDrugPack = true;

      for (var i = 0; i < this.drug.drugPackVos.length; i++) {
        if (this.drug.drugPackVos[i].id == this.narcoticReconciliationDestructionItemVo.drugPackId) {
          this.drug.drugPackVos[i].isDrugPackChecked = true;
          this.isPacksizeChecked = true;
        }
      }
    }
  }

  updateNarcoticReconciliationDestructionItemVo() {
    var narcoticReconciliationDestructionItemVo = this.loadModelByForm();
    this.narcoticReconciliationService.updateNarcoticReconciliationDestructionItemVo(narcoticReconciliationDestructionItemVo).subscribe
      (resultVo => {
        if (resultVo.success) {
          this.closeModal.nativeElement.click();
          this.sharedService.showSuccessMessage;
          this.loadNarcoticReconciliationDestructionItemVos();
        } else {
          this.loadModalErrorMessage(resultVo.message);
        }
      });
  }

  onActionChangeDate() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadNarcoticReconciliationDestructionItemVos();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  onActionChangedestroyedDate(value) {
    this.destroyedDate = value;
  }

  loadDate() {
    this.maxDate = new Date();
    this.startDate = new Date();
    this.endDate = new Date();
    this.destroyedDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 30);
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.endDate < this.startDate)
      errors.push("The End Date must be greater then Start Date!");

    else {
      if (this.startDate == null)
        errors.push("Start date field is empty");

      if (this.endDate == null)
        errors.push("End date field is empty");
    }
    return errors;
  }

  loadModalErrorMessage(message: string) {
    this.showModalErrorMessage = true;
    this.modalErrorMessage = message;
  }

  clearModalErrorMessage() {
    this.showModalErrorMessage = false;
  }

  loadErrorMessage(message: string) {
    this.showErrorMessage = true;
    this.errorMessage = message;
  }

  saveNarcoticReconciliationDestructionItemVo() {
    this.isUpdateAction ? this.updateNarcoticReconciliationDestructionItemVo() : this.addNarcoticReconciliationDestructionItemRecordVo();
  }

}
