import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.css']
})
export class FollowUpComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private medsCheckStatusDraft = 'Draft';
  private toHighlight: string;
  private primaryCares: any[];
  private healthcareProviderControl = new FormControl('', [Validators.required]);
  private filteredHealthcareProvider: Observable<any>;
  private healthCareProviderLastName: string;
  private healthCareProviderFirstName: string;
  private healthCareProviderPhone: string;
  private healthCareProviderEmail: string;
  private healthCareProviderFax: string;
  private healthCareProviderSpecialty: string;
  private showErrorMessage: boolean;
  private errorMessage: string;

  ngOnInit() {
  }

  private cleanHealthCareProvidersField() {
    this.healthCareProviderLastName = "";
    this.healthCareProviderFirstName = "";
    this.healthCareProviderEmail = "";
    this.healthCareProviderPhone = "";
    this.healthCareProviderFax = "";
    this.healthCareProviderSpecialty = "";
  }

  private addHealthCareProviders() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateHealthCareProviderFormFields();

    if (resultFieldsValidation == "") {

      var newHealthCareProviderItem = {
        firstName: this.healthCareProviderFirstName,
        lastName: this.healthCareProviderLastName,
        phone: this.healthCareProviderPhone,
        email: this.healthCareProviderEmail,
        fax: this.healthCareProviderFax,
        healthRelatedSpecialty: this.healthCareProviderSpecialty,
        medsCheckPlanForFollowUpId: this.medsCheckService.medsCheckVo.medsCheckPlanForFollowUpVo.id,
        pharmacyId: this.sharedService.pharmacyId
      }

      if (this.medsCheckService.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos == null)
        this.medsCheckService.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos = [];

      this.medsCheckService.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos.push(newHealthCareProviderItem);
      this.cleanHealthCareProvidersField();
    }
    else {
      return this.loadErrorMessage(resultFieldsValidation);
    }
  }

  private getHealthcareProvider(healthcareProviderId) {
    var selectedDoctor = this.primaryCares.find(p => p.id == healthcareProviderId);
    this.healthCareProviderLastName = selectedDoctor.lastName;
    this.healthCareProviderFirstName = selectedDoctor.firstName;
    this.healthCareProviderSpecialty = selectedDoctor.designation;
    this.healthCareProviderEmail = selectedDoctor.email;
    this.healthCareProviderPhone = selectedDoctor.telephone;
    this.healthCareProviderFax = selectedDoctor.fax;
  }

  private validateHealthCareProviderFormFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.healthCareProviderLastName.trim().length == 0)
      errors.push('Last Name');

    if (this.healthCareProviderFirstName.trim().length == 0)
      errors.push('First Name');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }

    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  private deleteHealthCareProviders(index) {
    this.medsCheckService.medsCheckVo.medsCheckPlanForFollowUpVo.medsCheckPlanForFollowUpItemVos.splice(index, 1);
  }

  private loadFilteredHealthcareProviders() {
    this.primaryCares = [];
    this.filteredHealthcareProvider = this.healthcareProviderControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;
      return this.medsCheckService.getDoctorsBy(value)
        .pipe(
          map(response =>
            response.filter(option => {
              this.primaryCares.push(option);
              if (texts.length == 1) {
                return option.lastName.toLowerCase().includes(texts[0].toLowerCase());
              }
              else {
                return option.lastName.toLowerCase().includes(texts[0].toLowerCase()) && option.lastName.toLowerCase().includes(texts[1].toLowerCase());
              }
            })));
    }
  }

  private loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  private clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

}
