import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEprescriber } from '.././interfaces/ieprescriber';
import { IEprescriberReviewVo } from '.././interfaces/ieprescriber-review-vo';

@Injectable({
  providedIn: 'root'
})
export class EprescriberService {

  private ApiUrl = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getEprescribersPending(pharmacyId, facilityId, patientId, userId, facilityUnitId, dateStart, dateEnd): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/eprescriber/GetEprescribersPending/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + userId + '/' + facilityUnitId + '/' + dateStart + '/' + dateEnd);
  }

  getEprescribers(pharmacyId, facilityId, patientId, userId, statusId, facilityUnitId, dateStart, dateEnd): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/eprescriber/GetEprescribers/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + userId + '/' + statusId + '/' + facilityUnitId + '/' + dateStart + '/' + dateEnd);
  }

  getPrescriptionDtos(pharmacyId, patientId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/Integration/GetPrescriptionDtos/' + pharmacyId + '/' + patientId);
  }

  removeEprescriber(eprescriberId, userId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/eprescriber/RemoveEprescriber/' + eprescriberId + '/' + userId);
  }

  saveAsDraftEprescriber(eprescriber: IEprescriber): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/eprescriber/SaveAsDraftEprescriber';
    const body = JSON.stringify(eprescriber);

    return this.http.post<any>(url, eprescriber, { 'headers': headers });
  }

  proceedEprescriber(eprescriber: IEprescriber): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/eprescriber/ProceedEprescriber';
    const body = JSON.stringify(eprescriber);

    return this.http.post<any>(url, eprescriber, { 'headers': headers });
  }

  approveEprescriber(eprescriberReviewVo: IEprescriberReviewVo): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/eprescriber/ApproveEprescriber';
    const body = JSON.stringify(eprescriberReviewVo);

    return this.http.post<any>(url, eprescriberReviewVo, { 'headers': headers });
  }

  getEprescriberById(eprescriberId: number, userId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/eprescriber/GetEprescriberById/' + eprescriberId + '/' + userId);
  }

  getDrugDosageForms(pharmacyId): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/drug/GetDrugDosageForms/' + pharmacyId);
  }

  isEprescriberReviewCompleted(eprescriberId): Observable<boolean> {
    return this.http.get<boolean>(this.ApiUrl + 'api/eprescriber/isEprescriberReviewCompleted/' + eprescriberId);
  }
}
