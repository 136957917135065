import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-patient-lifestyle',
  templateUrl: './patient-lifestyle.component.html',
  styleUrls: ['./patient-lifestyle.component.css']
})
export class PatientLifestyleComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private medsCheckLifestyleNoneAll: boolean;
  private medsCheckStatusDraft: string = 'Draft';

  ngOnInit() {
  }

  private onLifestyleNoneAll(checked) {
    this.medsCheckLifestyleNoneAll = true;
    for (let i = 0; i < this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos.length; i++) {
      if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].title === "Other (Specify)")
        continue;

      this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].chosen = checked;

      if (checked) {
        if (this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].title === "Smoking Cessation Status") {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].notes = "N/A";
        } else {
          this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].notes = "None";
        }
      } else {
        this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[i].notes = "";
      }
    }
  }

  private onLifestyleItemIsChecked(checked, index) {
    this.medsCheckLifestyleNoneAll = false;
    if (!checked) {
      this.medsCheckService.medsCheckVo.medsCheckQuestionVo.medsCheckQuestionLifestyleVo.medsCheckQuestionLifestyleItemVos[index].notes = "";
    }
  }

  private onLifestyleItemChange() {
    this.medsCheckLifestyleNoneAll = false;
  }

}
