import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { SharedService } from '../services/shared.service';
import { SettingsService } from '../services/settings.service';
import { NarcoticReconciliationService } from '../services/narcotic-reconciliation.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-narcotic-reconciliation',
  templateUrl: './narcotic-reconciliation.component.html',
  styleUrls: ['./narcotic-reconciliation.component.css']
})

export class NarcoticReconciliationComponent implements OnInit {

  @ViewChild('closeDateRangeModal', { static: false }) closeDateRangeModal: ElementRef;
  @ViewChild('closeInitialCountDateModal', { static: false }) closeInitialCountDateModal: ElementRef;

  constructor(private router: Router,
    private settingsService: SettingsService,
    private sharedService: SharedService,
    private pipeDate: DatePipe,
    private http: HttpClient,
    private narcoticReconciliationService: NarcoticReconciliationService,
    @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  private ApiUrl = "";
  directory: string = "Purchased";
  rangeDateId: number;
  supplierId: number;
  fileName: string;
  modalTitle: string;
  empyString: string = "";
  dateStart: any;
  dateEnd: any;
  showDateErrorMessage: boolean;
  dateErrorMessage: string;
  narcotics: any[] = [];
  showLoadingMessage: boolean;
  showErrorMessage: boolean;
  errorMessage: string;
  resultVo: any;
  initialCounts: any[] = [];
  initialDateOptions: any[] = [];
  initialCountDateModalErrorMessage: string;
  showInitialCountDateErrorMessage: boolean;
  initialCountDate: any;
  todayDate: any;
  narcoticReconciliationStatusId: number;
  narcoticReconciliationInitialCountStatusId: number;
  narcoticReconciliationId: number;
  saveInitialCountClicked: boolean;
  initialCountId: number;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadFilterDate();
    this.rangeDateId = 0;
    this.supplierId = 0;
    this.narcoticReconciliationStatusId = 1;
    this.narcoticReconciliationInitialCountStatusId = 1;
    this.todayDate = new Date();
    this.loadNarcoticReconciliations();
    this.loadInitialCounts();
    this.loadInitialDateOptions();
    this.showDeleteNarcoticReconciliationMessage = false;  
  }

  loadNarcoticReconciliations() {
    this.narcoticReconciliationService.getNarcoticReconciliation(this.sharedService.pharmacyId, this.narcoticReconciliationStatusId).subscribe(result => {
      this.narcotics = result;
    });
  }

  loadAllNarcoticReconciliations() {
    this.narcoticReconciliationStatusId = 0;
    this.loadNarcoticReconciliations();
  }

  loadInitialCounts() {
    this.showLoadingMessage = true;
    
    this.narcoticReconciliationService.getInitialCountDates(this.sharedService.pharmacyId, this.narcoticReconciliationInitialCountStatusId).subscribe(
      data => {
        this.initialCounts = data;
        this.showLoadingMessage = false;
      },
      err => this.showLoadingMessage = false);
  }

  loadInitialDateOptions() {
    this.showLoadingMessage = true;

    this.narcoticReconciliationService.getInitialCountDatesAvailableOptions(this.sharedService.pharmacyId).subscribe(
      data => {
        this.initialDateOptions = data;
        this.showLoadingMessage = false;
      },
      err => this.showLoadingMessage = false);
  }

  onActionChangeNarcoticReconciliationStatus() {
    this.loadNarcoticReconciliations();
  }

  onChangeNarcoticReconciliationInitialCountStatus() {
    this.loadInitialCounts();
  }

  loadFilterDate() {
    this.loadInitialDateOptions();
    this.dateEnd = new Date();
    this.dateStart = null;
    this.initialCountDate = new Date();
  }

  loadDateErrorMessage() {
    this.showDateErrorMessage = true;
    this.dateErrorMessage = "Please, choose a Start Date";
  }

  clearDateErrorMessage() {
    this.showDateErrorMessage = false;
  }

  validateFilteredDate(dateEnd, dateStart): string {
    var dateErrorMessage = "The End Date must be greater then Start Date!";
    if (dateEnd > dateStart)
      return this.empyString

    return dateErrorMessage;
  }

  onCreateNarcoticReconciliation() {
    var newNarcoticRecinciliation = {
      facilityId: this.sharedService.facilityId,
      pharmacyId: this.sharedService.pharmacyId,
      createdBy: this.sharedService.getUserId(),
      startDate: this.dateStart,
      endDate: this.dateEnd
    }

    if (newNarcoticRecinciliation.startDate != null) {
      this.showLoadingMessage = true;
      this.narcoticReconciliationService.createNarcoticReconciliation(newNarcoticRecinciliation).subscribe(result => {
        if (result.success) {
          this.loadNarcoticReconciliations();
          this.showLoadingMessage = false;
        }
      });      
      this.closeDateRangeModal.nativeElement.click();
    } else {
      this.loadDateErrorMessage();
    }

  }

  openNarcoticConciliationItemBy(id) {    
    this.router.navigate(['narcotic-reconciliation-item/' + id]);
  }

  onCreateInitialCount() {
    this.saveInitialCountClicked = true;
    let validationResult = this.validateInitialCountDate();
    let id: number = 0;

    if (validationResult == "") {
      this.narcoticReconciliationService.createInitialCount(this.loadInitialCountDateModel()).subscribe
        (resultCreateVo => {
          this.resultVo = resultCreateVo;
          id = resultCreateVo.id;       

          this.closeInitialCountDateModal.nativeElement.click();
          this.saveInitialCountClicked = false;
          this.router.navigate(['initial-count/' + id]);
        });
    }
    else {
      this.loadInitialCountDateModalErrorMessage(validationResult);
    }
  }

  validateInitialCountDate(): string {
    let errors: string = "";

    if (!this.initialCountDate && this.initialCountDate == "")
      errors = 'Invalid date field';

    if (this.initialCounts.some(d => this.pipeDate.transform(d.countDate, 'dd/MM/yyyy') == this.pipeDate.transform(this.initialCountDate, 'dd/MM/yyyy')))
      errors = 'The selected date already exist';

    return errors;
  }  

  loadInitialCountDateModel() {
    let initialCountDateModel = {
      id: 0,
      createdDate: new Date(),
      countDate: this.initialCountDate,
      createdById: this.sharedService.getUserId(),
      pharmacyId: this.sharedService.pharmacyId
    }
    return initialCountDateModel;
  }

  editInitialCount(id) {
    this.router.navigate(['initial-count/' + id]);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: string) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  loadInitialCountDateModalErrorMessage(message: string) {
    this.showInitialCountDateErrorMessage = true;
    this.initialCountDateModalErrorMessage = message.toString();
  }

  clearInitialCountDateModalErrorMessage() {
    this.initialCountDateModalErrorMessage = '';
    this.showInitialCountDateErrorMessage = false;
  }

  deleteNarcoticConciliationConfirmation(narcoticReconciliationId) {
    this.narcoticReconciliationId = narcoticReconciliationId
  }

  deleteNarcoticConciliation() {
    this.narcoticReconciliationService.deleteNarcoticConciliation(this.narcoticReconciliationId).subscribe(
      result => {
        if (result.success) {
          this.sharedService.showSuccessMessage;
          this.loadNarcoticReconciliations();
          this.loadInitialCounts();
        } else {
          this.loadErrorMessage(result.message);
        }        
      }, err => this.showLoadingMessage = false);
  }

  deleteInitialCountConfirmation(initialCount) {

    this.initialCountId = initialCount.id;
    this.narcoticReconciliationId = initialCount.narcoticReconciliationId;
    this.showDeleteNarcoticReconciliationMessage = false;  

    let narcoticReconciliationDateRange = '';
    let hasNarcoticReconciliation = this.narcotics.some(n => n.id == this.narcoticReconciliationId);
    if (hasNarcoticReconciliation) {
      narcoticReconciliationDateRange = this.narcotics.find(n => n.id == this.narcoticReconciliationId).dateRange;
      this.showDeleteNarcoticReconciliationMessage = true;     
    }
    this.narcoticReconciliationDateRange = narcoticReconciliationDateRange;
      
  }

  showDeleteNarcoticReconciliationMessage: boolean;
  narcoticReconciliationDateRange: string = '';

  deleteNarcoticReconciliationInitialCount() {
    if (!this.showDeleteNarcoticReconciliationMessage)
      this.narcoticReconciliationId = 0;

    this.narcoticReconciliationService.deleteNarcoticConciliationInitialCount(this.narcoticReconciliationId, this.initialCountId).subscribe(
      result => {
        if (result.success) {
          this.sharedService.showSuccessMessage;
          this.loadNarcoticReconciliations();
          this.loadInitialCounts();
        } else {
          this.loadErrorMessage(result.message);
        }
      }, err => this.showLoadingMessage = false);
  }
}


