import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';

@Component({
  selector: 'app-self-monitoring-blood-glucose',
  templateUrl: './self-monitoring-blood-glucose.component.html',
  styleUrls: ['./self-monitoring-blood-glucose.component.css']
})
export class SelfMonitoringBloodGlucoseComponent implements OnInit {

  constructor(private medsCheckService: MedscheckService) { }

  ngOnInit() {
  }
}
