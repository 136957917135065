import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private ApiUrl = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getKrollInvoice(pharmacyId, facility, patientId, startDate, endDate): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/invoice/GetKrollInvoice/' + pharmacyId + '/' + facility + '/' + patientId + '/' + startDate + '/' + endDate);
  }

  getInvoice(pharmacyId, facility, patientId, startDate, endDate): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/invoice/GetInvoice/' + pharmacyId + '/' + facility + '/' + patientId + '/' + startDate + '/' + endDate);
  }

  getResidents(pharmacyId, facility, startDate, endDate): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/invoice/getResidents/' + pharmacyId + '/' + facility + '/' + startDate + '/' + endDate);
  }

  getInvoicePatient(pharmacyId, facility, startDate, endDate, lastInvoiceNumber): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/invoice/GetInvoicePatient/' + pharmacyId + '/' + facility + '/' + startDate + '/' + endDate + '/' + lastInvoiceNumber);
  }

  public save(invoiceDto, userId): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/invoice/save/' + userId;
    const body = JSON.stringify(invoiceDto);

    return this.http.post<any>(url, invoiceDto, { 'headers': headers });
  }

  getInvoices(pharmacyId, facility, patientId, invoiceNumber): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/invoice/GetInvoices/' + pharmacyId + '/' + facility + '/' + patientId + '/' + invoiceNumber);
  }

  deleteInvoice(invoiceId, userId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/invoice/DeleteInvoice/' + invoiceId + '/' + userId);
  }

}
