import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.css']
})
export class SuccessMessageComponent implements OnInit {

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    if (this.sharedService.showSuccessMessage) {
      setTimeout(() => this.sharedService.showSuccessMessage = false, 5000);
    }
  }
}
