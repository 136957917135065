import { Component, OnInit } from '@angular/core';
import { MedscheckService } from '../../../services/medscheck.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-patient-caregiver',
  templateUrl: './patient-caregiver.component.html',
  styleUrls: ['./patient-caregiver.component.css']
})
export class PatientCaregiverComponent implements OnInit {

  constructor(private sharedService: SharedService, private medsCheckService: MedscheckService) { }

  private isPatientCaregiverFormEmpty: boolean;

  ngOnInit() {
    this.checkPatientCaregiver();
  }

  private checkPatientCaregiver() {
    this.isPatientCaregiverFormEmpty = (this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.id == 0 ||
      (this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.lastName == "" &&
      this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.firstName == "" &&
      this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.phone == "" &&
      this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.email == "" &&
      this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.notes == "")
    );
  }

  private onChangePatientCaregiverCheck() {
    if (this.isPatientCaregiverFormEmpty) {
      this.clearPatientCaregiver();
    }
  }

  private clearPatientCaregiver() {
    this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.lastName = "";
    this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.firstName = "";
    this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.phone = "";
    this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.email = "";
    this.medsCheckService.medsCheckVo.patientVo.patientCaregiverVo.notes = "";
  }

}
