import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrugRecordBookService {
  private ApiUrl = "";
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  GetDrugRecord(pharmacyId, facilityId, patientId, dateStart, dateEnd): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/DrugRecordBook/GetDrugRecord/' + pharmacyId + '/' + facilityId + '/' + patientId + '/' + dateStart + '/' + dateEnd);
  }

  GetReceiving(pharmacyId, facilityId, facilityUnit, dateStart, dateEnd, drugRecordBookItemStatusId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/DrugRecordBook/GetReceiving/' + pharmacyId + '/' + facilityId + '/' + facilityUnit + '/' + dateStart + '/' + dateEnd + '/' + drugRecordBookItemStatusId);
  }

  ChangeStatus(pharmacyId, facilityId, userId, drugRecordBookItemTypeId, externalId, createdDate, drugRecordBookItemStatusId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/DrugRecordBook/ChangeStatus/' + pharmacyId + '/' + facilityId + '/' + userId + '/' + drugRecordBookItemTypeId + '/' + externalId + '/' + createdDate + '/' + drugRecordBookItemStatusId);
  }

  GetEprescriberId(externalId, drugRecordBookItemTypeId): Observable<number> {
    return this.http.get<any>(this.ApiUrl + 'api/DrugRecordBook/GetEprescriberId/' + externalId + '/' + drugRecordBookItemTypeId);
  }
}
