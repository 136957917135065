import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { EprescriberService } from '../services/eprescriber.service';
import { PrescriptionReviewService } from '../services/prescription-review.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { PipeTransform, Pipe } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReorderService } from '../services/reorder.service';
import { startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IncidentReportService } from '../services/incident-report.service';
import { AuthorizeService } from './../../api-authorization/authorize.service';
import { ApplicationPaths } from './../../api-authorization/api-authorization.constants';
import { DatePipe } from '@angular/common';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  //Autocomplete element
  myControl = new FormControl();

  constructor(private service: SharedService,
    private eprescriberService: EprescriberService,
    private router: Router,
    private prescriptionReviewService: PrescriptionReviewService,
    private authorizeService: AuthorizeService,
    private incidentReport: IncidentReportService,
    private reorderService: ReorderService,
    private datepipe: DatePipe,
    private renderer: Renderer2) {

    this.service.loadFacilityIdCalled$.subscribe(
      () => {
        this.loadDashboards();
      }
    );
  }

  @ViewChild('closeCreateCustomReorderModal', { static: false }) closeCreateCustomReorderModal;
  @ViewChild('closeTermsUse', { static: false }) closeTermsUse;
  @ViewChild('openTermsUse', { static: false }) openTermsUse!: ElementRef;
  @ViewChild('openPasswordExpiredModal', { static: false }) openPasswordExpiredModal!: ElementRef;
  
  parentPageName: string = "home";
  eprescriberStatusDraft: string = "Draft";
  eprescriberStatusPendingApproval: string = "Pending Approval";
  residents: any = [];
  patientId: number;
  eprescribers: any = [];
  prescriptionsReview: any = [];
  status: any;
  isTheUserNurseOrPhysician: boolean;
  toHighlight: string = '';
  incidentReports: any = [];
  reorders: any[];
  dateStartIncidentReport: any;
  dateEndIncidentReport: any;
  dateStartReorder: any;
  dateEndReorder: any;
  showErrorMessageEprescriber: boolean;
  errorMessageEprescriber: string;
  isRedirectToIndex: boolean;
  showLoadingMessage: boolean = true;
  dateStartEprescriber: any;
  dateEndEprescriber: any;
  eprescriberStatusId: any;
  incidentReportStatusId: any;
  showModal: boolean = false;

  months: any = [];
  monthId: any = 0;
  years: any = [];
  yearId: any = 0;


  ngOnInit(): void {
    this.AuthenticatedValidation();
    this.service.reloadUserPrivileges();

    this.loadDashboards();

    setTimeout(() => {
      if (this.service.userPrivilegeVo != null) {

        if (!this.service.userPrivilegeVo.acceptedTermsUse)
          this.renderer.selectRootElement(this.openTermsUse.nativeElement).click();

        if (this.service.userPrivilegeVo.passwordExpired)
          this.renderer.selectRootElement(this.openPasswordExpiredModal.nativeElement).click(); 
      }

    }, 2000);
  }

  loadDashboards() {
    if (this.service.isFacility) {
      this.eprescriberStatusId = 1;
      this.incidentReportStatusId = 1;
      this.patientId = 0;
      this.status = 1;
      this.service.facilityUnitId = 0

      this.loadUserRoleCheck();
      this.loadFilteredResidents();
      this.loadDates();

      this.loadResidents();
      this.loadPrescriptions();
      this.loadIncidentReportPending();
      this.loadReorders();



      this.loadMonths();
      this.loadYear();
      this.monthId = (new Date().getMonth()) + 1;
      this.yearId = new Date().getFullYear();
    }

    this.showLoadingMessage = false;
  }

  loadDates() {
    var dateEnd = new Date();
    var dateStart = new Date();
    dateStart.setDate(dateStart.getDate() - 30);

    this.dateStartIncidentReport = dateStart;
    this.dateEndIncidentReport = dateEnd;

    this.dateStartEprescriber = new Date();
    this.dateStartEprescriber.setDate(this.dateStartEprescriber.getDate() - 60);
    this.dateEndEprescriber = dateEnd;

    this.dateEndReorder = new Date();
    this.dateStartReorder = new Date();
    this.dateStartReorder.setDate(this.dateStartReorder.getDate() - 30);

    if (this.monthId == null || this.monthId == 0)
      this.monthId = (new Date().getMonth()) + 1;

    if (this.yearId == null || this.yearId == 0)
      this.yearId = new Date().getFullYear();
  }

  clearErrorMessageEprescriber() {
    this.showErrorMessageEprescriber = false;
    this.errorMessageEprescriber = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessageEprescriber = true;
    this.errorMessageEprescriber = message.toString();
  }

  AuthenticatedValidation() {
    var isAuthenticated: boolean;
    this.authorizeService.isAuthenticated().subscribe(data => { isAuthenticated = data; });
    if (!isAuthenticated) {
      this.router.navigate(ApplicationPaths.LoginPathComponents);
    }
  }

  loadUserRoleCheck() {
    if (this.service.getUserId() != null)
      this.service.isTheUserNurseOrPhysician().subscribe(data => { this.isTheUserNurseOrPhysician = data; });
  }

  //Autocomplete Residents element

  filteredResidents: Observable<any>;
  loadFilteredResidents() {

    this.filteredResidents = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {

    if (value == "") {
      this.patientId = 0;
      this.loadEprescribers();
    }

    this.toHighlight = value;

    return this.getData()
      .pipe(
        map(response => response.filter(option => {
          return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        }))
      )

  }

  getResidents(value) {
    var resident = this.residents.find(f => f.name == value);
    this.patientId = resident.patientId;
    this.loadEprescribers();
  }
  getData() {
    return of(this.residents);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadReorders() {
    if (this.service.userPrivilegeVo != null && this.service.userPrivilegeVo.hasReorderDashboardAccess) {
      this.reorderService.reorderStatusId = 1;

      var dateStart = this.datepipe.transform(this.dateStartReorder, 'yyyy-MM-dd');
      var dateEnd = this.datepipe.transform(this.dateEndReorder, 'yyyy-MM-dd');

      this.reorderService.getReordersByStatusId(this.service.pharmacyId, this.service.facilityId, this.reorderService.reorderStatusId, dateStart, dateEnd)
        .subscribe(data => {
          this.reorders = data;
        });
    }
  }

  loadResidents() {
    this.service.getResidentsByFacilityId().subscribe(data => {
      this.residents = data;
    });

  }

  loadEprescribers() {
    if (this.service.userPrivilegeVo != null && this.service.userPrivilegeVo.hasEprescriberDashboardAccess) {
      var dateStart = this.datepipe.transform(this.dateStartEprescriber, 'yyyy-MM-dd');
      var dateEnd = this.datepipe.transform(this.dateEndEprescriber, 'yyyy-MM-dd');
      this.showLoadingMessage = true;
      this.eprescriberService.getEprescribersPending(this.service.pharmacyId, this.service.facilityId, this.patientId, this.service.getUserId(), this.service.facilityUnitId, dateStart, dateEnd)
        .subscribe(data => {
          this.eprescribers = data;
          this.showLoadingMessage = false;
        },
          err => this.showLoadingMessage = false);
    }
  }

  loadIncidentReportPending() {
    if (this.service.userPrivilegeVo != null && this.service.userPrivilegeVo.hasIncidentReportDashboardAccess) {
      var patientId = 0;
      var dateStart = this.datepipe.transform(this.dateStartIncidentReport, 'yyyy-MM-dd');
      var dateEnd = this.datepipe.transform(this.dateEndIncidentReport, 'yyyy-MM-dd');

      this.incidentReport.getIncidentReportsByStatusId(this.service.getUserId(), this.service.facilityId, this.service.pharmacyId, this.incidentReportStatusId, this.service.facilityUnitId, patientId, dateStart, dateEnd)
        .subscribe(incidentReportIndexVos => {
          this.incidentReports = incidentReportIndexVos.filter(ir => ir.isEnabledForCurrentUser);
        });
    }
  }

  openPrescriptionReview(id: any, patientId: any) {
    this.router.navigate(['prescription-review/review/' + patientId + '/' + id]);
  }

  openIncidentReport(id) {
    this.router.navigate(['/incident-report/review/' + id]);
  }

  loadPrescriptions() {
    if (this.service.userPrivilegeVo != null && this.service.userPrivilegeVo.hasPrescriptionReviewDashboardAccess) {
      var date = this.pad(this.yearId, 2) + "-" + this.pad(this.monthId, 2) + "-01";
      this.prescriptionReviewService.GetPrescriptionReviewPendingBy(this.service.pharmacyId, this.service.facilityId, this.service.getUserId(), date)
        .subscribe(prescriptionReviewVos => {
          this.prescriptionsReview = prescriptionReviewVos.filter(pr => pr.isEnabledForCurrentUser || (this.service.userPrivilegeVo.isPharmacist && pr.isApprovedByPhysician));
        });
    }
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  openReviewEprescriber(id) {
    this.setEprescriberPreviousPage();
    this.router.navigate(['eprescriber/approval/' + id]);
  }

  private setEprescriberPreviousPage() {
    localStorage.setItem('previousPage', 'home');
  }

  openNewEprescriber() {
    this.showErrorMessageEprescriber = false;
    var eprescriberTypeId = '1';
    if (this.service.facilityId > 0 && this.patientId > 0) {
      this.setEprescriberPreviousPage();
      this.router.navigate(['eprescriber/create/' + this.service.pharmacyId + '/' + this.patientId + '/' + eprescriberTypeId]);
    } else {
      this.loadErrorMessage("Resident input field is empty!");
    }
  }

  openNewEprescriberByFile() {
    this.showErrorMessageEprescriber = false;
    var eprescriberTypeId = '2';
    if (this.service.facilityId > 0 && this.patientId > 0) {
      this.setEprescriberPreviousPage();
      this.router.navigate(['eprescriber/create/' + this.service.pharmacyId + '/' + this.patientId + '/' + eprescriberTypeId]);
    } else {
      this.loadErrorMessage("Resident input field is empty!");
    }
  }

  openNewIncidentReport() {
    if (this.service.facilityId > 0) {
      this.router.navigate(['/incident-report/create']);
    }
  }

  openEditEprescriber(patientId, eprescriberId, eprescriberTypeId) {
    this.setEprescriberPreviousPage();
    this.router.navigate(['eprescriber/create/' + this.service.pharmacyId + '/' + patientId + '/' + eprescriberTypeId + '/' + eprescriberId]);
  }

  changeIsRedirectToIndex() {
    this.isRedirectToIndex = false;
  }

  submitCustomReorder() {
    this.closeCreateCustomReorderModal.nativeElement.click();
    this.loadReorders();
    this.router.navigate(['/']);
  }


  loadYear() {
    if (this.years.length == 0) {
      var date = new Date();
      this.years.push({ id: date.getFullYear() - 2 });
      this.years.push({ id: date.getFullYear() - 1 });
      this.years.push({ id: date.getFullYear() });
    }
  }

  loadMonths() {
    if (this.months.length == 0) {
      this.months.push({ id: 1, text: "January" });
      this.months.push({ id: 2, text: "February" });
      this.months.push({ id: 3, text: "March" });
      this.months.push({ id: 4, text: "April" });
      this.months.push({ id: 5, text: "May" });
      this.months.push({ id: 6, text: "June" });
      this.months.push({ id: 7, text: "July" });
      this.months.push({ id: 8, text: "August" });
      this.months.push({ id: 9, text: "September" });
      this.months.push({ id: 10, text: "October" });
      this.months.push({ id: 11, text: "November" });
      this.months.push({ id: 12, text: "December" });
    }
  }

  onActionChangeMonth() {
    this.loadPrescriptions();
  }

  onActionChangeYear() {
    this.loadPrescriptions();
  }

  termsUseAgree() {
    this.service.acceptedTermsUse().subscribe(resultVo => {
      if (resultVo.success)
        this.service.loadUserPrivileges();

      this.closeTermsUse.nativeElement.click();
    });
  }

  redirectToChangePassword() {
    const url = this.service.ApiUrl + "Identity/Account/Manage/ChangePassword";
    window.location.href = url;
  }

}

//Highlight text in autocomplete options
@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
