import { Component, OnInit } from '@angular/core';
import { ReorderService } from '../../services/reorder.service';
import { SharedService } from '../../services/shared.service';
import { IincidentReportCreate } from '../../interfaces/iincident-report-create'
import { IreorderCreateVo } from '../../interfaces/ireorder-create-vo';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ReorderViewComponent implements OnInit {

  reorderId: number;
  reorder: IreorderCreateVo;

  constructor(private reorderService: ReorderService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.reorderId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.reorder = {} as IreorderCreateVo;
    this.loadFields();
  }

  loadFields() {
    this.reorderService.getReorderById(this.reorderId).subscribe(data => {
      this.reorder = data;
    });
  }

  onActionShipped() {
    this.reorderService.changeReoderStatusToShipped(this.reorderId).subscribe(
      success => {
        this.sharedService.showSuccessMessage = success;
        this.router.navigate(['/reorder/']);
      });

  }

}
