import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { FormControl } from '@angular/forms';
import { Observable, EMPTY, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class SettingsUsersComponent implements OnInit {

  actionValue: string;
  facilityId: number;
  users: any[];
  userVo: any = {};
  roles: any[];
  usersFilter: any[];
  userIpAddressHistoricVos: any[] = [];
  userIpAddressVos: any[] = [];
  actionText: string;
  ipAddressVo: any = {};
  ipAddress: string = "";
  roleId: number;
  actionDefault = "0";
  actionCreate = "1";
  actionResetPassword = "3";
  actionRestricAccess = "4";
  actionDelete = "2";
  errorMessage: string;
  showErrorMessage: boolean;

  constructor(private settingsService: SettingsService,
    private router: Router,
    private sharedService: SharedService) { }

  @ViewChild('openRestricAccessButton', { static: false }) openRestricAccessButton;
  @ViewChild('closeRestricAccessButton', { static: false }) closeRestricAccessButton;
  @ViewChild('inputIpAddress', { static: false }) inputIpAddress;
  @ViewChild('openConfirmationButton', { static: false }) openConfirmationButton;
  @ViewChild('closeConfirmationButton', { static: false }) closeConfirmationButton;
  @ViewChild('openDeleteIpAddressConfirmationButton', { static: false }) openDeleteIpAddressConfirmationButton;
  @ViewChild('closeDeleteIpAddressConfirmationButton', { static: false }) closeDeleteIpAddressConfirmationButton;

  ngOnInit() {
    this.facilityId = 0;
    this.roleId = 0;
    this.userVo.id = "";
    this.loadRoles();
    this.loadUsers();
    this.loadUsersOptions();
    this.sharedService.reloadUserPrivileges();
  }

  loadRoles() {
    this.settingsService.getRoles().subscribe(data => { this.roles = data; });
  }

  loadUsers() {
    this.actionValue = this.actionDefault;
    this.userFilter();
    this.settingsService.getUsers(this.sharedService.pharmacyId, this.facilityId, this.roleId, this.sharedService.getUserId(), this.userVo.id)
      .subscribe(data => {
        this.users = data;
      });
  }

  onActionChange(userVo: any) {
    this.userVo = userVo;

    switch (this.actionValue) {
      case this.actionCreate:
        this.router.navigate(['settings/users/create/' + this.userVo.id]);
        break;
      case this.actionResetPassword:
        this.actionText = "Reset Password";
        this.openConfirmationButton.nativeElement.click();
        break;
      case this.actionDelete:
        this.actionText = "Delete";
        this.openConfirmationButton.nativeElement.click();
        break;
      case this.actionRestricAccess:
        this.actionText = "Restric Access";
        this.loadUserIpAddresses();
        this.loadUserIpAddressHistoricVos(this.userVo.id);
        this.openRestricAccessButton.nativeElement.click();
        setTimeout(() => this.inputIpAddress.nativeElement.focus(), 500);
        break;
      default:
    }
  }

  userFilter() {
    this.settingsService.getUsers(this.sharedService.pharmacyId, this.facilityId, this.roleId, this.sharedService.getUserId(), this.userVo.id).subscribe(
      data => {
        this.usersFilter = data;
      });
  }

  removeUser() {
    this.settingsService.removeUser(this.userVo.id).subscribe(resultVo => {
      this.sharedService.showSuccessMessage = resultVo.success;
      this.closeConfirmationButton.nativeElement.click();
      this.userVo.id= "";
      this.loadUsers();
      window.scroll(0, 0);
    });
  }

  resetUserPassword(userId) {
    this.settingsService.resetUserPassword(userId).subscribe(resultVo => {
      this.sharedService.showSuccessMessage = resultVo.success;
      this.closeConfirmationButton.nativeElement.click();
      this.userVo.id = "";
      this.loadUsers();
      window.scroll(0, 0);
    });
  }

  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadUsersOptions() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    
    if (value == "") {
      this.userVo.id = "";
      this.userFilter();
    }

    this.toHighlight = value;

    var data = this.getData();
    if (data != null) {
      return data
        .pipe(
          map(response => response.filter(option => {
            return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        );
    }
    else
      return EMPTY;
  }

  getPosts(value) {
    this.users = this.usersFilter.filter(f => f.name == value);
  }

  getData() {
    if (this.usersFilter == null || this.usersFilter.length == 0) {
      this.settingsService.getUsers(this.sharedService.pharmacyId, this.facilityId, this.roleId, this.sharedService.getUserId(), this.userVo.id).subscribe(data => {
        this.usersFilter = data;
        return of(this.usersFilter);
      });
    } else {
      return of(this.usersFilter);
    }
  }

  loadUserIpAddressHistoricVos(userId: string) {
    this.settingsService.getUserIpAddressHistoricVos(userId).subscribe(data => {
      this.userIpAddressHistoricVos = data;
    });
  }

  onEnterRestricAccess(value) {
    if (value == "")
      this.loadErrorMessage("The input field can not be empty.");

    else {
      this.settingsService.createUserIpAddress(this.userVo.id, value).subscribe(
        resultVo => {
          if (resultVo) {
            this.sharedService.showSuccessMessage;
            this.loadUserIpAddresses();
            this.ipAddress = "";
            this.inputIpAddress.nativeElement.focus();
          }
          else
            this.loadErrorMessage(resultVo.message);
        });
    }
  }

  loadUserIpAddresses() {
    this.settingsService.getUserIpAddressVos(this.userVo.id).subscribe(data => {
      this.userIpAddressVos = data;
    });
  }

  confirmUserAction() {
    switch (this.actionValue) {
      case this.actionResetPassword:
        this.resetUserPassword(this.userVo.id);
        break;
      case this.actionDelete:
        this.removeUser();
        break;
      default:
    }
  }

  openDeleteIpAddressConfirmationModal(ipAddressVo: any) {
    this.ipAddressVo = ipAddressVo;
    this.openDeleteIpAddressConfirmationButton.nativeElement.click();
    this.closeRestricAccessButton.nativeElement.click();
  }

  deleteIpAddress() {
    this.settingsService.deleteUserIpAddress(this.ipAddressVo.id).subscribe(
      resultVo => {
        if (resultVo) {
          this.sharedService.showSuccessMessage;
          this.closeDeleteIpAddressConfirmationButton.nativeElement.click(); 
          this.openRestricAccessButton.nativeElement.click();
          this.loadUserIpAddresses();
        }
        else
          this.loadErrorMessage(resultVo.message);
      });
  }

  closeConfirmationModal() {
    this.actionValue = this.actionDefault;
    this.closeConfirmationButton.nativeElement.click();
  }

  closeRestricAccessModal() {
    this.actionValue = this.actionDefault;
    this.closeRestricAccessButton.nativeElement.click();
  }

  closeDeleteIpAddressConfirmationModal() {
    this.actionValue = this.actionDefault;
    this.openRestricAccessButton.nativeElement.click();
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
  }

}

//Highlight text in autocomplete options

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
