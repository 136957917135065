import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { EprescriberService } from '../services/eprescriber.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { EMPTY, Observable, of } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-eprescriber',
  templateUrl: './eprescriber.component.html',
  styleUrls: ['./eprescriber.component.css']
})
export class EprescriberComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private eprescriberService: EprescriberService,
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe) { }

  residents: any = [];
  eprescribers: any = [];
  patientId: number;
  condition: boolean;
  showSuccessMessage: boolean;
  statusId: any;
  removeMessage: any;
  eprescriberId: any;
  dateStartEprescriber: any;
  dateEndEprescriber: any;
  errorMessage: string;
  showErrorMessage: boolean;
  showLoadingMessage: boolean;

  showModalBox: boolean;


  @ViewChild('closebutton', { static: false }) closebutton;

  ngOnInit(): void {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
    this.condition = false;
    this.showSuccessMessage = false;
    this.statusId = 1;


    if (this.sharedService.facilityId > 0) {
      this.loadFields();
    }
    this.triggerSucessMessage();

    this.loadFilteredOptions();
    this.loadResidentList();
    this.showModalBox = false;
  }

  loadDate() {
    this.dateEndEprescriber = new Date();
    this.dateStartEprescriber = new Date();
    this.dateStartEprescriber.setDate(this.dateStartEprescriber.getDate() - 14);
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEndEprescriber < this.dateStartEprescriber)
      errors.push("The End Date must be greater then Start Date!")

    return errors;
  }

  onActionChangeDate() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadEprescribers();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }

  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  triggerSucessMessage() {
    var result = this.route.snapshot.paramMap.get('status');
    if (result != null) {
      this.showSuccessMessage = true;
      window.scroll(0, 0);
    }
  }

  loadFields() {
    this.loadResidents();
    this.patientId = 0;
    this.loadEprescribers();
  }

  loadResidentList() {
    this.patientId = 0;
    this.condition = false;
    this.showSuccessMessage = false;
  }

  onActionChangeStatus() {
    this.loadEprescribers();
    this.condition = false;
    this.showSuccessMessage = false;
  }

  onActionFacilityUnit() {
    this.loadResidents();
    this.loadEprescribers();
  }

  public loadResidents = () => {
    this.sharedService.getResidentsByFacilityId().subscribe(data => {
      this.residents = data;
    });
  }

  loadEprescribers() {
    this.showLoadingMessage = true;
    var dateStart = this.datepipe.transform(this.dateStartEprescriber, 'yyyy-MM-dd');
    var dateEnd = this.datepipe.transform(this.dateEndEprescriber, 'yyyy-MM-dd');

    this.eprescriberService.getEprescribers(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, this.sharedService.getUserId(), this.statusId, this.sharedService.facilityUnitId, dateStart, dateEnd)
      .subscribe(data => {
        this.eprescribers = data;
        this.showLoadingMessage = false;
      },
        err => this.showLoadingMessage = false);
  }

  openNewEprescriber() {
    this.showSuccessMessage = false;
    var eprescriberTypeId = '1';
    if (this.sharedService.facilityId > 0 && this.patientId > 0) {
      this.setPreviousPage();
      this.router.navigate(['eprescriber/create/' + this.sharedService.pharmacyId + '/' + this.patientId + '/' + eprescriberTypeId]);
    } else {
      this.condition = true;
      window.scroll(0, 0);
    }
  }

  openNewEprescriberByFile() {
    this.showSuccessMessage = false;
    var eprescriberTypeId = '2';
    if (this.sharedService.facilityId > 0 && this.patientId > 0) {
      this.setPreviousPage();
      this.router.navigate(['eprescriber/create/' + this.sharedService.pharmacyId + '/' + this.patientId + '/' + eprescriberTypeId]);
    } else {
      this.condition = true;
      window.scroll(0, 0);
    }
  }

  openApproval(id) {
    this.showSuccessMessage = false;
    this.setPreviousPage();
    this.router.navigate(['eprescriber/approval/' + id]);
  }


  openEdit(patientId, eprescriberId, eprescriberTypeId) {
    this.showSuccessMessage = false;
    this.setPreviousPage();
    this.router.navigate(['eprescriber/create/' + this.sharedService.pharmacyId + '/' + patientId + '/' + eprescriberTypeId + '/' + eprescriberId]);
  }

  setPreviousPage() {
    localStorage.setItem('previousPage', 'eprescriber');
  }

  setEprescriberId(id) {
    this.eprescriberId = id;
  }

  removeEprescriber() {
    var id = this.eprescriberId;
    this.eprescriberService.removeEprescriber(id, this.sharedService.getUserId()).subscribe(data => {
      this.showSuccessMessage = data;
      this.closebutton.nativeElement.click();
      this.loadEprescribers();
      window.scroll(0, 0);
    });
  }

  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.getData();

    if (value == "") {
      this.patientId = 0;
      //this.loadEprescribers();
    }

    this.toHighlight = value;

    var data = this.getData();
    if (data != null)
      return this.getData()
        .pipe(
          map(response => response.filter(option => {
            return option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        )
    else
      return EMPTY;

  }

  getPosts(value) {
    var resident = this.residents.find(f => f.name == value);
    this.patientId = resident.patientId;
    this.loadEprescribers();
    this.condition = false;
    this.showSuccessMessage = false;

  }
  getData() {
    if (this.residents == null || this.residents.length == 0) {
      this.sharedService.getResidentsByFacilityId().subscribe(data => {
        this.residents = data;
        return of(this.residents);
      });
    } else {
      return of(this.residents);
    }
  }



}

//Highlight text in autocomplete options

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}

