import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';


@Component({
  selector: 'app-policy-procedure',
  templateUrl: './policy-procedure.component.html',
  styleUrls: ['./policy-procedure.component.css']
})
export class FaqPolicyProcedureComponent implements OnInit {

  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
  }

}
