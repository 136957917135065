import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-annual-ltc',
  templateUrl: './annual-ltc.component.html',
  styleUrls: ['./annual-ltc.component.css']
})
export class AnnualLtcComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private route: ActivatedRoute) { }

  showErrorMessage: boolean;
  errorMessage: string;
  patientId: string;
  patientVo: any;
  reportTitle: string = "MEDSCHECK ANNUAL LTC";

  @ViewChild('tabQuestions', { static: false }) tabQuestions: ElementRef;
  @ViewChild('tabDiabetes', { static: false }) tabDiabetes: ElementRef;
  @ViewChild('tabMedicalHistory', { static: false }) tabMedicalHistory: ElementRef;
  @ViewChild('tabNonPrescriptions', { static: false }) tabNonPrescriptions: ElementRef;
  @ViewChild('tabRxOtherpharmacies', { static: false }) tabRxOtherpharmacies: ElementRef;
  @ViewChild('tabIssues', { static: false }) tabIssues: ElementRef;
  @ViewChild('tabTopics', { static: false }) tabTopics: ElementRef;
  @ViewChild('tabChecklist', { static: false }) tabChecklist: ElementRef;

  ngOnInit() {
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.loadPatientInformation();
  }

  loadPatientInformation() {
    this.sharedService.getPatientVo(this.patientId).subscribe(data => {
      this.patientVo = data;
    });
  }

  nextClick(event) {
    var currentTabId = event.currentTarget.id;

    switch (currentTabId) {
      case 'btn-next-tab-questions':
        this.tabDiabetes.nativeElement.click();
        break;
      case 'btn-next-tab-diabetes':
        this.tabMedicalHistory.nativeElement.click();
        break;
      case 'btn-next-tab-medical-history':
        this.tabNonPrescriptions.nativeElement.click();
        break;
      case 'btn-next-tab-non-prescriptions':
        this.tabRxOtherpharmacies.nativeElement.click();
        break;
      case 'btn-next-tab-rx-otherpharmacies':
        this.tabIssues.nativeElement.click();
        break;
      case 'btn-next-tab-issues':
        this.tabTopics.nativeElement.click();
        break;
      case 'btn-next-tab-topics':
        this.tabChecklist.nativeElement.click();
        break;
      default:
        this.tabQuestions.nativeElement.click();
    }

  }

  previousClick(event) {
    var currentTabId = event.currentTarget.id;

    switch (currentTabId) {
      case 'btn-previous-tab-checklist':
        this.tabTopics.nativeElement.click();
        break;
      case 'btn-previous-tab-topics':
        this.tabIssues.nativeElement.click();
        break;
      case 'btn-previous-tab-issues':
        this.tabRxOtherpharmacies.nativeElement.click();
        break;
      case 'btn-previous-tab-rx-otherpharmacies':
        this.tabNonPrescriptions.nativeElement.click();
        break;
      case 'btn-previous-tab-non-prescriptions':
        this.tabMedicalHistory.nativeElement.click();
        break;
      case 'btn-previous-tab-medical-history':
        this.tabDiabetes.nativeElement.click();
        break;
      case 'btn-previous-tab-diabetes':
        this.tabQuestions.nativeElement.click();
        break;
      default:
        this.tabChecklist.nativeElement.click();
    }
  }

}
