import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  public progress: number;
  public message: string;
  private ApiUrl = "";
  public fileExtensionAccept = ".jpg, .png, .jpeg"

  @Output() public onUploadFinished = new EventEmitter();
  @Input() public directory: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }


  ngOnInit() {
    this.SetFileExtensionAccept();    
  }

  public SetFileExtensionAccept() {
    switch (this.directory) {
      case "User":
        this.fileExtensionAccept = ".jpg, .png, .jpeg";
        break;
      case "Eprescriber":
        this.fileExtensionAccept = ".pdf";
        break;
      case "Purchased":
        this.fileExtensionAccept = ".xls, .csv";
        break;
    }
  }


  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this.http.post(this.ApiUrl + 'api/upload/UploadFile/' + this.directory, formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'File Uploaded!';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
}
