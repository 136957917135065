import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedService } from '../services/shared.service';
import { ShippingService } from '../services/shipping.service';
import { Observable, of } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {
  dateEnd: any;
  dateStart: any;
  shippingStatusId: number = 1;
  shippingTypeId: number = 0;
  shippingId: number = 0;

  shippings: any[] = [];
  showErrorMessage: boolean;
  errorMessage: string;


  constructor(private sharedService: SharedService,
    private shippingService: ShippingService,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private datepipe: DatePipe) { }

  @ViewChild('closebutton', { static: false }) closebutton;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
    this.loadShippings();
  }

  public onFiltersChange() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadShippings();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  loadDate() {
    this.dateEnd = new Date();
    this.dateStart = new Date();
    this.dateStart.setDate(this.dateStart.getDate() - 14);
  }

  onActionFacilityUnit() {
    this.onFiltersChange();
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEnd < this.dateStart)
      errors.push("The End Date must be greater then Start Date!")

    return errors;
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  private loadShippings() {
    var startDateText = this.datepipe.transform(this.dateStart, 'yyyy-MM-dd');
    var endDateText = this.datepipe.transform(this.dateEnd, 'yyyy-MM-dd');

    this.shippingService.getShippings(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.facilityUnitId, this.shippingStatusId, this.shippingTypeId, startDateText, endDateText).subscribe(data => {
      this.shippings = data;
    });
  }

  openNew() {
    this.router.navigate(['shipping/create/']);
  }

  edit(id) {
    this.router.navigate(['shipping/create/' + id]);
  }

  setId(id) {
    this.shippingId = id;
  }

  remove() {
    this.shippingService.remove(this.shippingId, this.sharedService.getUserId()).subscribe(data => {
      this.sharedService.showTempSuccessMessage();
      this.closebutton.nativeElement.click();
      this.loadShippings();
      window.scroll(0, 0);
    });
  }

  view(id) {
    this.router.navigate([]).then(result => { window.open('report/Shipping/' + id + '/' + 'Shipping', '_blank'); });
  }

}
