import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { NarcoticReconciliationService } from '../../services/narcotic-reconciliation.service';
import { ActivatedRoute } from '@angular/router';
import { createOutput } from '@angular/compiler/src/core';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ViewportScroller, DatePipe } from '@angular/common';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-initial-count',
  templateUrl: './initial-count.component.html',
  styleUrls: ['./initial-count.component.css']
})
export class InitialCountComponent implements OnInit {

  @ViewChild('endPage', { static: false }) _endPage: ElementRef;
  @ViewChild('inputDrug', { static: true }) _inputDrug: ElementRef;

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }
  constructor(private sharedService: SharedService, private scroll: ViewportScroller,
    private narcoticReconciliationService: NarcoticReconciliationService, private route: ActivatedRoute) { }

  initialCountDateVos: any[] = [];
  showLoadingMessage: boolean;
  narcoticReconciliationInitialCount: any;
  narcoticReconciliationInitialCountId: number;
  resultVo: any;
  myControl = new FormControl('');
  InitialCountDrugFilter: Observable<any[]>;
  pageYoffset = 0;
  isDinSortingAscending: boolean = false;
  isDrugSortingAscending: boolean = false;
  isDifferenceSortingAscending: boolean = false;
  searchDrugInput: string;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadNarcoticReconciliationInitialCount();
    this._inputDrug.nativeElement.focus();
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  scrollToBottom() {
    this._endPage.nativeElement.scrollIntoView();
  }

  onEnterInitialCountInput() {
    this._inputDrug.nativeElement.focus();
    this.searchDrugInput = '';
  }

  loadNarcoticReconciliationInitialCount() {
    this.showLoadingMessage = true;
    this.narcoticReconciliationInitialCountId = Number.parseInt(this.route.snapshot.paramMap.get('id'));
    this.narcoticReconciliationService.getNarcoticReconciliationInitialCountBy(this.narcoticReconciliationInitialCountId).subscribe(data => {
      this.narcoticReconciliationInitialCount = data;
      this.loadFilteredMedications();
      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  onInitialCountItemChange(count, narcoticReconciliationItemId: number) {
    let currentInitialCountItem = Number(count);
    this.narcoticReconciliationService.updateNarcoticReconciliationInitialCountItemBy(narcoticReconciliationItemId, currentInitialCountItem).subscribe(result => {
      this.resultVo = result;
    });
  }
  
  loadFilteredMedications() {
    this.searchDrugInput = '';
    this.InitialCountDrugFilter = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
      return this.narcoticReconciliationInitialCount.initialCountItemVos.filter(option => option.searchDrug.toLowerCase().includes(filterValue));
  }

  sortNarcoticReconciliationInitialCountBy(filterType) {
    this.searchDrugInput = '';
    this.InitialCountDrugFilter = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._sort(filterType)),
    );
  }

  private _sort(filterType): string[] {
    if (filterType == "sort-din") {
      this.isDinSortingAscending = !this.isDinSortingAscending;
      if (this.isDinSortingAscending)
        return this.narcoticReconciliationInitialCount.initialCountItemVos.sort((a, b) => a.drugDin - b.drugDin);
      return this.narcoticReconciliationInitialCount.initialCountItemVos.sort((a, b) => b.drugDin - a.drugDin);
    }

    if (filterType == "sort-drug") {
      this.isDrugSortingAscending = !this.isDrugSortingAscending;
      return this.narcoticReconciliationInitialCount.initialCountItemVos.sort((first, last) => {
        const drugFirst = first.drugBrandName.toUpperCase();
        const drugLast = last.drugBrandName.toUpperCase();
        if (this.isDrugSortingAscending)
          return drugFirst < drugLast ? -1 : 1;

        if (!this.isDrugSortingAscending)
          return drugFirst < drugLast ? 1 : -1;
      });
    }
  }
}
