import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PosService {
  private ApiUrl = "";

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.ApiUrl = baseUrl;
  }

  getPosClients(pharmacyId, patientId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/pos/GetPosClients/');
  }

  getSalesPaymentReport(pharmacyId, startDate, endDate): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/pos/GetSalesPaymentReport/' + pharmacyId + '/' + startDate + '/' + endDate);
  }

  getTokens(): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/pos/GetTokens');
  }

  getBackups(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/pos/GetBackups');
  }

  getBackup(id): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/pos/GetBackup/' + id);
  }

  resetTokenComputerKey(posClientKey): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/pos/ResetTokenComputerKey/' + posClientKey);
  }

  createToken(client): Observable<any> {
    var tokenDto = { Client: client };
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/pos/CreateToken';
    return this.http.post<any>(url, tokenDto, { 'headers': headers });
  }
}
