import { Component, OnInit, ViewChild } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { PipeTransform, Pipe } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { PosService } from '../../services/pos.service';
import { InvoiceService } from '../../services/invoice.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class InvoiceCreateComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private posService: PosService,
    private router: Router,
    private invoiceService: InvoiceService,
    private datepipe: DatePipe) { }

  @ViewChild('RemoveConfirmModalClosebutton', { static: false }) closebutton;

  showErrorMessage: boolean;
  errorMessage: string;
  invoiceKroll: any;
  invoice: any;
  invoiceItemPlans: any = [];
  invoiceItem: any = {};
  invoiceItemPlanDtos: any = [];
  searchStartDate: any;
  searchEndDate: any;
  showLoadingMessage: boolean;
  residents: any = [];
  patientId: number;
  rxNum: any;
  invoiceItemPays: number;
  invoiceItemTaxable: boolean;
  newInvoiceItem: any = {};
  hasIaPlan: boolean;
  errorMessageAddModal: string;
  showErrorMessageAddModal: boolean = false;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
    this.loadResidents();
  }

  loadDate() {
    this.searchStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    this.searchEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.getDate(this.searchEndDate) < this.getDate(this.searchStartDate))
      errors.push("The End Date must be greater then Start Date!")

    if (this.patientId == 0)
      errors.push("Please, Select the Resident first.")

    return errors;
  }

  getDate(date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  loadFields() {
    this.showLoadingMessage = true;
    var dateStartCoverted = Date.parse(this.searchStartDate.toString());
    var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');

    var dateEndCoverted = Date.parse(this.searchEndDate.toString());
    var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

    this.invoiceService.getKrollInvoice(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, dateStart, dateEnd).subscribe(data => {
      if (data != null)
        this.invoiceKroll = data;
      else
        this.loadErrorMessage("Oops! The selected date range is quite extensive. Please narrow it down for more accurate results.");

      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);


    this.invoiceService.getInvoice(this.sharedService.pharmacyId, this.sharedService.facilityId, this.patientId, dateStart, dateEnd).subscribe(data => {
      if (data != null)
        this.invoice = data;
      else
        this.loadErrorMessage("Oops! The selected date range is quite extensive. Please narrow it down for more accurate results.");

      this.showLoadingMessage = false;
    },
      err => this.showLoadingMessage = false);
  }

  search() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadFields();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  loadResidents() {
    if (this.searchStartDate <= this.searchEndDate) {
      var dateStartCoverted = Date.parse(this.searchStartDate.toString());
      var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');

      var dateEndCoverted = Date.parse(this.searchEndDate.toString());
      var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

      this.invoiceService.getResidents(this.sharedService.pharmacyId, this.sharedService.facilityId, dateStart, dateEnd).subscribe(data => {
        this.residents = data;
      });
    } else {
      this.residents = [];
    }
  }


  //Autocomplete Residents element

  myControl = new FormControl();
  filteredOptions: Observable<any>;
  toHighlight: string = '';

  loadFilteredOptions() {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val || '')
      })
    )
  }

  private _filter(value: string): Observable<any[]> {
    this.getData();

    if (value == "") {
      this.patientId = 0;
    }

    this.toHighlight = value;

    var data = this.getData();
    if (data != null)
      return this.getData()
        .pipe(
          map(response => response.filter(option => {
            return option.fullName.toLowerCase().indexOf(value.toLowerCase()) >= 0
          }))
        )
    else
      return EMPTY;

  }

  getPosts(value) {
    var resident = this.residents.find(f => f.fullName == value);
    this.patientId = resident.id;

  }
  getData() {
    if (this.residents == null || this.residents.length == 0) {
      this.loadResidents();
      return of(this.residents);
    } else {
      return of(this.residents);
    }
  }

  public exportToPdf() {
    this.showErrorMessage = false;
    if (this.invoice.invoiceNumber != null && this.invoice.invoiceNumber != "") {
      this.invoiceService.save(this.invoice, this.sharedService.getUserId()).subscribe(resultVo => {
        if (resultVo != null && resultVo.success)
          this.router.navigate([]).then(result => { window.open('report/invoice/' + resultVo.id + '/' + 'Invoice', '_blank'); });
      });
    }
    else {
      this.loadErrorMessage("Invalid Invoice Number!");
    }
  }

  public removeEprescriber() {
    this.invoice.invoiceItemDtos = this.invoice.invoiceItemDtos.filter(i => i.rxNum !== this.rxNum);

    this.closebutton.nativeElement.click();
    this.loadSummary();
  }

  public openDetailsModal(rxNum) {
    this.loadInvoiceItem(rxNum);
    this.invoiceItemPlanDtos = this.invoiceItem.invoiceItemPlanDtos;
  }

  private loadInvoiceItem(rxNum: any) {
    this.rxNum = rxNum;
    this.invoiceItem = this.invoice.invoiceItemDtos.find(i => i.rxNum == this.rxNum);
  }

  public openRemoveModal(rxNum) {
    this.loadInvoiceItem(rxNum);
  }

  public openEditModal(rxNum) {
    this.loadInvoiceItem(rxNum);
    this.invoiceItemPays = this.invoiceItem.pays;
    this.invoiceItemTaxable = this.invoiceItem.taxable;
  }

  public saveInvoiceItemPays() {
    this.invoiceItem.pays = Number(this.invoiceItemPays);
    this.invoiceItem.taxable = Boolean(this.invoiceItemTaxable);

    this.invoiceItem.reason = "Edited by " + this.sharedService.userPrivilegeVo.fullName;
    this.invoiceItem.reasonCode = 8;

    this.loadSummary();
  }


  private loadSummary() {
    this.invoice.subTotal = Number(parseFloat(this.subTotal.toString()).toFixed(2));
    this.invoice.subTotalTaxableItems = Number(parseFloat(this.subTotalTaxableItems.toString()).toFixed(2));
    this.invoice.subTotalNonTaxableItems = Number(parseFloat(this.subTotalNonTaxableItems.toString()).toFixed(2));
    this.invoice.hst = Number(parseFloat(this.hst.toString()).toFixed(2));
    this.invoice.total = Number(parseFloat(this.total.toString()).toFixed(2));
  }

  public openAddInvoiceItemModal() {
    this.newInvoiceItem = {};
  }

  public saveNewInvoiceItem() {
    if (this.ValidateFieldsNewInvoice()) {
      this.showErrorMessageAddModal = true;
      this.errorMessageAddModal = "All fields are mandatory!";
    } else {
      this.clearErrorMessageAddModal();
      this.newInvoiceItem.reason = "Edited by " + this.sharedService.userPrivilegeVo.fullName;
      this.newInvoiceItem.reasonCode = 8;
      this.invoice.invoiceItemDtos.push(this.newInvoiceItem);
      this.loadSummary();
      document.getElementById("closeAddModalButton").click();
    }
  }

  private ValidateFieldsNewInvoice() {
    return this.newInvoiceItem.rxNum == null ||
      this.newInvoiceItem.fillDate == null ||
      this.newInvoiceItem.quantity == null ||
      this.newInvoiceItem.drug == null ||
      this.newInvoiceItem.din == null ||
      this.newInvoiceItem.cost == null ||
      this.newInvoiceItem.fee == null ||
      this.newInvoiceItem.total == null ||
      this.newInvoiceItem.pays == null;
  }

  clearErrorMessageAddModal() {
    this.showErrorMessageAddModal = false;
    this.errorMessageAddModal = "";
  }

  get subTotal(): number {
    if (this.invoice.invoiceItemDtos) {
      return this.invoice.invoiceItemDtos.reduce((acc, item) => Number(acc) + Number(item.pays), 0);
    } else {
      return 0;
    }
  }

  get subTotalTaxableItems(): number {
    if (this.invoice.invoiceItemDtos) {
      const taxableItems = this.invoice.invoiceItemDtos.filter(item => Boolean(item.taxable));
      return taxableItems.reduce((acc, item) => Number(acc) + Number(item.pays), 0);
    } else {
      return 0;
    }
  }

  get subTotalNonTaxableItems(): number {
    if (this.invoice.invoiceItemDtos) {
      const nonTaxableItems = this.invoice.invoiceItemDtos.filter(item => !Boolean(item.taxable));
      return nonTaxableItems.reduce((acc, item) => Number(acc) + Number(item.pays), 0);
    } else {
      return 0;
    }
  }

  get hst(): number {
    if (this.invoice.invoiceItemDtos && !this.hasIaPlan) {
      const taxableItems = this.invoice.invoiceItemDtos.filter(item => Boolean(item.taxable));
      const paysSum = taxableItems.reduce((acc, item) => Number(acc) + Number(item.pays), 0);
      return Math.round(Number(paysSum) * 0.13 * 100) / 100;
    } else {
      return 0;
    }
  }

  get total(): number {
    return this.subTotal + this.hst;
  }





}

//Highlight text in autocomplete options
@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text && typeof search === 'string' && typeof text === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/{}()*x+?.\\^$|]/g, '\\$&')
        .split(' ')
        .filter(t => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search ? text.replace(regex, match => `<strong class="highlight">${match}</strong>`) : text;
    }
    return text;
  }
}
