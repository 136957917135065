import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-facility-unit',
  templateUrl: './facility-unit.component.html',
  styleUrls: ['./facility-unit.component.css']
})
export class FacilityUnitComponent implements OnInit {

  @Output() public onFacilityUnitChanged = new EventEmitter();

  facilityUnits: any[] = [];
  facilityUnitId: any = 0;
  constructor(private sharedService: SharedService) { }

  ngOnInit() {
    this.loadFacilityUnits();
    if (this.sharedService.facilityUnitId == null) {
      this.sharedService.facilityUnitId = 0;
      this.onFacilityUnitChanged.emit();
    }
  }

  onActionFacilityUnit() {
    this.onFacilityUnitChanged.emit();
  }

  loadFacilityUnits() {
    this.sharedService.getFacilityUnits()
      .subscribe(data => {
        if (data[0] != null) {
          this.facilityUnits = data;
          this.facilityUnitId = data[0].id;
        }
      });
  }

}
