import { Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { IncidentReportService } from '../../services/incident-report.service';
import { IincidentReportCreate } from '../../interfaces/iincident-report-create';
import { IincidentReportMedications } from '../../interfaces/iincident-report-medications';
import { IincidentReportNotifications } from '../../interfaces/iincident-report-notifications';
import { SettingsService } from '../../services/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})

export class IncidentReportCreateComponent implements OnInit {
  residents: any[];
  incidentReportId: number;
  incidentReportCreate: any = {};
  staffInvolveds: string[] = [];
  staffDiscovereds: string[] = [];
  incidentReportMedications: any[] = [];
  incidentReportNotifications: any[] = [];
  descriptionTypes: any[];
  causeGroups: any[];
  outcomes: any[];
  originsIncident: any[];
  riskFactors: any[];

  hypoglycemiaTypes: any[];
  checkedHypoglycemiaTypeIds: any[] = [];

  routeAdministrationTypes: any[];
  checkedRouteAdministrationTypeIds: any[] = [];

  checkedRiskFactorsIds: any[] = [];
  checkedOutcomesIds: any[] = [];
  checkedOriginsIncidentIds: any[] = [];
  checkedDescriptionIds: any[] = [];
  checkedCauseIds: any[] = [];
  notifiedName: string;
  notifiedDate: string;
  notifiedTime: string;
  notifiedType: number = 0;
  notificationtypes: any[];

  drugs: any[] = [];
  disableTimeInvolved: boolean = true;
  disableTimeDiscovered: boolean = true;;
  disableTimeNotified: boolean = true;
  isResidentValid: boolean;
  showStaffInvolvedErrorMessage: boolean;
  errorStaffInvolvedMessage: string = "";
  showStaffInvolvedDateErrorMessage: boolean;
  errorStaffInvolvedDateMessage: string = "";
  showStaffDiscoveredErrorMessage: boolean;
  errorStaffDiscoveredMessage: string = "";
  showStaffDiscoveredDateErrorMessage: boolean;
  errorStaffDiscoveredDateMessage: string = "";
  showNotifiedErrorMessage: boolean;
  errorNotifiedMessage: string;
  showMedicationErrorMessage: boolean;
  errorMedicationMessage: string;
  showErrorMessage: boolean;
  errorMessage: string;
  discoveredTimeValidation: boolean;
  notifiedTimeValidation: boolean;

  medicationName: string;
  strength: string;
  dosage: string = "";
  medicationControl = new FormControl('', [Validators.required]);
  filteredMedications: Observable<any[]>;
  medicationHighlight: string = '';

  constructor(private sharedService: SharedService,
    private incidentReport: IncidentReportService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private authorizeService: AuthorizeService,
    private scroll: ViewportScroller) { }

  @ViewChild('inputDiscovered', { static: false }) inputDiscovered: ElementRef;
  @ViewChild('inputInvolved', { static: false }) inputInvolved: ElementRef;
  @ViewChild('inputMedication', { static: false }) inputMedication: ElementRef;
  @ViewChild('inputStrength', { static: false }) inputStrength: ElementRef;
  @ViewChild('inputDosage', { static: false }) inputDosage: ElementRef;
  @ViewChild('roleTypeCheck', { static: false }) roleTypeCheck: ElementRef;
  @ViewChild('closePrintOrderModalButton', { static: false }) closePrintOrderModalButton;
  @ViewChild('openPrintOrderModal', { static: false }) openPrintOrderModalbutton;


  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadInitialValues();
    this.loadResidents();
    this.loadNotificationTypes();
    this.loadChecklists();
    this.cleanNotifiedFormFields();
  }

  loadInitialValues() {
    this.incidentReportCreate.patientId = 0;
    this.incidentReportCreate.incidentDescription = "";
    this.incidentReportCreate.incidentSource = "";
    this.incidentReportCreate.additionalInformation = "";
  }

  loadResidents() {
    this.sharedService.getResidentsByFacilityId().subscribe(data => { this.residents = data; });
  }

  loadNotificationTypes() {
    this.incidentReport.getNotificationTypes().subscribe(data => {
      this.notificationtypes = data;
    });
  }

  loadChecklists() {
    this.incidentReport.getDescriptionType().subscribe(data => {
      this.descriptionTypes = data;
    });

    this.incidentReport.getOutcomeType().subscribe(data => {
      this.outcomes = data;
    });

    this.incidentReport.getOriginIncident().subscribe(data => {
      this.originsIncident = data;
    });

    this.incidentReport.getRiskFactors().subscribe(data => {
      this.riskFactors = data;
    });

    this.incidentReport.getCaseTypeGroups().subscribe(data => {
      this.causeGroups = data;
    });

    this.incidentReport.getHypoglycemiaTypes().subscribe(data => {
      this.hypoglycemiaTypes = data;
    });

    this.incidentReport.getRouteAdministrationTypes().subscribe(data => {
      this.routeAdministrationTypes = data;
    });
  }

  cleanNotifiedFormFields() {
    this.notifiedName = "";
    this.notifiedDate = "";
    this.notifiedTime = "";
    this.notifiedType = 0;
  }

  loadDrugs(searchText: string) {
    this.sharedService.getDrugsBy(searchText).subscribe(data => {
      this.drugs = data
    });
  }

  addStaffInvolved() {
    this.clearStaffInvolvedErrorMessage();
    var messageDuplicate: string = "Staff has already been selected";
    var messageEmpty: string = "Please select at least one staff";
    var user = this.inputInvolved.nativeElement.value;

    if (user != "") {
      for (var i = 0; i <= this.staffInvolveds.length; i++) {
        if (this.staffInvolveds[i] == user) {
          return this.loadStaffInvolvedErrorMessage(messageDuplicate);
        }
      }
      this.staffInvolveds.push(
        this.inputInvolved.nativeElement.value);
      this.inputInvolved.nativeElement.value = "";
    }
    else {
      return this.loadStaffInvolvedErrorMessage(messageEmpty);
    }
  }

  onChangeOccurredDate(date) {
    this.clearStaffInvolvedDateErrorMessage();

    var message: string = "Selected date is greater than today";
    var dateSelected = new Date(date);
    var today = new Date();
    if (dateSelected.getTime() > today.getTime()) {
      this.disableTimeInvolved = true;
      return this.loadStaffInvolvedDateErrorMessage(message);
    }
    else {
      this.disableTimeInvolved = false;
    }
  }

  addStaffDiscovered() {
    this.clearStaffDiscoveredErrorMessage();
    var messageDuplicate: string = "Staff has already been selected";
    var messageEmpty: string = "Please select at least one staff";
    var user = this.inputDiscovered.nativeElement.value;

    if (user != "") {
      for (var i = 0; i <= this.staffDiscovereds.length; i++) {
        if (this.staffDiscovereds[i] == user) {
          return this.loadStaffDiscoveredErrorMessage(messageDuplicate);
        }
      }
      this.staffDiscovereds.push(
        this.inputDiscovered.nativeElement.value);
      this.inputDiscovered.nativeElement.value = "";
    }
    else {
      return this.loadStaffDiscoveredErrorMessage(messageEmpty);
    }
  }

  validateDate(startDate, endDate): string {
    var errors: string[] = [];
    var message: string = "";
    var dateSelected = new Date(endDate);
    var today = new Date();

    if (endDate < startDate) {
      errors.push("earlier than Incident Occurred date");
    }

    if (dateSelected.getTime() > today.getTime()) {
      errors.push("greater than today");
      console.log(today.getTime());
    }

    if (errors.length == 1) {
      message = "Selected date is " + errors.join(", ") + " !"
    }

    else if (errors.length > 1) {
      message = "Selected date is " + errors.join(" and ") + " !"
    }
    return message;
  }

  onChangeResident() {
    this.isResidentValid = true;
  }

  actionOnChangeDateDiscovered() {
    this.clearStaffDiscoveredDateErrorMessage();

    var resultFieldsValidation = this.validateDate(this.incidentReportCreate.occurredDate, this.incidentReportCreate.discoveredDate);
    if (this.incidentReportCreate.occurredDate == undefined) {
      var message = "Occurred data is empty!";
      this.loadStaffDiscoveredDateErrorMessage(message);
    }
    else {
      if (resultFieldsValidation == "") {
        this.disableTimeDiscovered = false;
      }
      else {
        this.disableTimeDiscovered = true;
        return this.loadStaffDiscoveredDateErrorMessage(resultFieldsValidation);
      }
    }
  }

  actionOnChangeDateNotified(date) {
    this.clearNotifiedDateErrorMessage();

    var resultFieldsValidation = this.validateDate(this.incidentReportCreate.discoveredDate, date);
    if (this.incidentReportCreate.discoveredDate == undefined) {
      var message = "Discovered Date data is empty!";
      this.loadNotifiedDateErrorMessage(message);
    }
    else {
      if (resultFieldsValidation == "") {
        this.disableTimeNotified = false;
      }
      else {
        this.disableTimeNotified = true;
        return this.loadNotifiedDateErrorMessage(resultFieldsValidation);
      }
    }
  }

  onChangeTimeDiscovered() {
    this.clearStaffDiscoveredDateErrorMessage();

    var message = "Discovered Time must be greater than Occurred Time!";

    if (this.incidentReportCreate.occurredTime == undefined) {
      this.discoveredTimeValidation = false;
      this.loadStaffDiscoveredDateErrorMessage(message);
      this.incidentReportCreate.discoveredTime = "";
    }

    if (this.incidentReportCreate.occurredDate == this.incidentReportCreate.discoveredDate) {

      var startTimeHour = Number(this.incidentReportCreate.occurredTime.substr(0, 2));
      var endTimeHour = Number(this.incidentReportCreate.discoveredTime.substr(0, 2));

      if (endTimeHour < startTimeHour) {
        this.discoveredTimeValidation = false;
        this.loadStaffDiscoveredDateErrorMessage(message);
      } else

        if (endTimeHour == startTimeHour) {
          var startTimeMinute = Number(this.incidentReportCreate.occurredTime.substr(3, 2));
          var endTimeMinute = Number(this.incidentReportCreate.discoveredTime.substr(3, 2));

          if (endTimeMinute < startTimeMinute) {
            this.discoveredTimeValidation = false;
            this.loadStaffDiscoveredDateErrorMessage(message);
          }
          else {
            this.discoveredTimeValidation = true;
          }
        }
        else {
          this.discoveredTimeValidation = true;
        }
    }
    else {
      this.discoveredTimeValidation = true;
    }

  }


  onChangeTimeNotified() {
    this.clearNotifiedErrorMessage();

    var message = "Notified Time must be greater than Discovered Time!";

    if (this.incidentReportCreate.discoveredTime == undefined) {
      this.notifiedTimeValidation = false;
      this.loadNotifiedErrorMessage(message);
      this.notifiedTime = "";
    }

    if (this.notifiedDate == this.incidentReportCreate.discoveredDate) {

      var startTimeHour = Number(this.incidentReportCreate.discoveredTime.substr(0, 2));
      var endTimeHour = Number(this.notifiedTime.substr(0, 2));

      if (endTimeHour < startTimeHour) {
        this.notifiedTimeValidation = false;
        this.loadNotifiedErrorMessage(message);
      } else

        if (endTimeHour == startTimeHour) {
          var startTimeMinute = Number(this.incidentReportCreate.discoveredTime.substr(3, 2));
          var endTimeMinute = Number(this.notifiedTime.substr(3, 2));

          if (endTimeMinute < startTimeMinute) {
            this.notifiedTimeValidation = false;
            this.loadNotifiedErrorMessage(message);
          }
          else {
            this.notifiedTimeValidation = true;
          }
        }
        else {
          this.notifiedTimeValidation = true;
        }
    }
    else {
      this.notifiedTimeValidation = true;
    }

  }

  addNotification() {
    this.showNotifiedErrorMessage = false;
    var resultFieldsValidation = this.validateNotifiedFields();
    var notifiedTypeTitle = this.notificationtypes.find(nt => nt.id == this.notifiedType);
    if (resultFieldsValidation == "") {
      var notification: IincidentReportNotifications = {
        name: this.notifiedName,
        type: this.notifiedType,
        typeTitle: notifiedTypeTitle.title,
        date: this.notifiedDate,
        time: this.notifiedTime
      }
      this.incidentReportNotifications.push(notification);
      this.cleanNotifiedFormFields();
    }
    else {
      this.loadNotifiedErrorMessage(resultFieldsValidation);
    }
  }

  addMedication() {
    this.showMedicationErrorMessage = false;
    var resultFieldsValidation = this.validateAddMedicationFields();
    var medicationText = this.inputMedication.nativeElement.value;
    if (resultFieldsValidation == "") {
      var medication: IincidentReportMedications = {
        medication: medicationText,
        strength: this.strength,
        dose: this.dosage
      }
      this.incidentReportMedications.push(medication);
      this.medicationName = this.inputMedication.nativeElement.value = "";
      this.strength = "";
      this.dosage = "";
    }
    else {
      this.loadMedicationErrorMessage(resultFieldsValidation);
    }
  }

  validateNotifiedFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.notifiedName == "")
      errors.push('Name');

    if (this.notifiedDate == "")
      errors.push('Date');

    if (this.roleTypeCheck.nativeElement.value == "")
      errors.push('Role');

    if (this.notifiedTime == "")
      errors.push('Time');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  validateAddMedicationFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.inputMedication.nativeElement.value == "")
      errors.push('Drug Name');

    if (this.inputStrength.nativeElement.value == "")
      errors.push('Strength');

    if (this.dosage == "")
      errors.push('Dosage');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  validateIncidentReportCreateFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.incidentReportCreate.patientId == 0) {
      errors.push('Resident');
      this.isResidentValid = false;
    }

    if (this.staffInvolveds.length == 0 || this.incidentReportCreate.occurredDate == "" || this.incidentReportCreate.occurredTime == "")
      errors.push('Involved Staff');

    if (this.staffDiscovereds.length == 0 || this.incidentReportCreate.discoveredDate == "" || this.incidentReportCreate.discoveredTime == "")
      errors.push('Incident Discovered by');

    if (this.incidentReportNotifications.length == 0)
      errors.push('Reported To');

    if (this.incidentReportMedications.length == 0)
      errors.push('Medication');

    if (this.incidentReportCreate.incidentDescription.trim().length === 0)
      errors.push('Incident description');

    if (this.incidentReportCreate.incidentSource.trim().length === 0)
      errors.push('Source or cause of the incident');

    if (this.incidentReportCreate.homeResponseAction == null || this.incidentReportCreate.homeResponseAction.trim().length === 0)
      errors.push('Home Level Response/Action');

    if (this.checkedOriginsIncidentIds.length == 0)
      errors.push('Origin of Incident');

    if (this.checkedOutcomesIds.length == 0)
      errors.push('Resident Outcome');

    if (this.checkedDescriptionIds.length == 0)
      errors.push('Type of Incident');

    if (this.checkedRiskFactorsIds.length == 0)
      errors.push('Contributing Factors');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  loadFilteredMedications() {
    this.filteredMedications = this.medicationControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }

  private _filter(value: string): Observable<any[]> {

    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.medicationHighlight = value;

      return this.sharedService.getDrugsBy(value)
        .pipe(
          map(response => response.filter(option => {

            if (texts.length == 1)
              return option.name.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.name.toLowerCase().includes(texts[0].toLowerCase()) && option.name.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }
    return EMPTY;
  }

  getMedications(value) {
    (async () => {
      this.getDrugs(value).subscribe(data => {
        this.drugs = data;

        var findInDrugs = this.drugs.find(d => d.name == value);
        this.inputStrength.nativeElement.value = this.strength = findInDrugs.strength;
        this.inputMedication.nativeElement.value = this.medicationName = findInDrugs.nameWithoutStrength;
        this.inputDosage = this.dosage = findInDrugs.form;
      });
    })();
  }

  getDrugs(value): Observable<any[]> {
    return this.sharedService.getDrugsBy(value);
  }

  onChangeDescription() {
    this.checkedDescriptionIds = []
    this.descriptionTypes.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedDescriptionIds.push(value.id);
      }
    });
  }

  onChangeOutcomes() {
    this.checkedOutcomesIds = []
    this.outcomes.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedOutcomesIds.push(value.id);
      }
    });
  }

  onChangeOriginIncident() {
    this.checkedOriginsIncidentIds = []
    this.originsIncident.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedOriginsIncidentIds.push(value.id);
      }
    });
  }

  onChangeRouteAdministration() {
    this.checkedRouteAdministrationTypeIds = []
    this.routeAdministrationTypes.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedRouteAdministrationTypeIds.push(value.id);
      }
    });
  }

  onChangeHypoglycemiaType() {
    this.checkedHypoglycemiaTypeIds = []
    this.hypoglycemiaTypes.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedHypoglycemiaTypeIds.push(value.id);
      }
    });
  }

  onChangeRiskFactors() {
    this.checkedRiskFactorsIds = []
    this.riskFactors.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedRiskFactorsIds.push(value.id);
      }
    });
  }

  onChangeCauses() {
    this.checkedCauseIds = []

    this.causeGroups.forEach((group, index) => {
      group.causeTypeVos.forEach((value, index) => {
        if (value.isChecked) {
          this.checkedCauseIds.push(value.id);
        }
      });
    });
  }

  createIncidentReport() {
    this.clearErrorMessage();
    var resultFieldsValidation = this.validateIncidentReportCreateFields();
    if (resultFieldsValidation == "") {
      this.incidentReportCreate.pharmacyId = this.sharedService.pharmacyId;
      this.incidentReportCreate.facilityId = this.sharedService.facilityId;
      this.incidentReportCreate.staffInvolveds = this.staffInvolveds;
      this.incidentReportCreate.staffDiscovereds = this.staffDiscovereds;
      this.incidentReportCreate.incidentReportMedicationsVos = this.incidentReportMedications;
      this.incidentReportCreate.incidentReportNotificationsVos = this.incidentReportNotifications;
      this.incidentReportCreate.createdBy = this.sharedService.getUserId();
      this.incidentReportCreate.descriptionIds = this.checkedDescriptionIds;
      this.incidentReportCreate.outcomeIds = this.checkedOutcomesIds;
      this.incidentReportCreate.originIncidentIds = this.checkedOriginsIncidentIds;
      this.incidentReportCreate.riskFactorIds = this.checkedRiskFactorsIds;
      this.incidentReportCreate.causesIds = this.checkedCauseIds;
      this.incidentReportCreate.routeAdministrationTypeIds = this.checkedRouteAdministrationTypeIds;
      this.incidentReportCreate.hypoglycemiaTypeIds = this.checkedHypoglycemiaTypeIds;

      this.incidentReport.createIncidentReport(this.incidentReportCreate).subscribe
        (incidentReportId => {
          if (incidentReportId > 0) {
            this.sharedService.showSuccessMessage = true;
            this.incidentReportId = incidentReportId;
            this.openPrintOrderModalbutton.nativeElement.click();            
          }
          else
            window.scroll(0, 0);
        });
    }
    else {
      this.loadErrorMessage(resultFieldsValidation);
    }
  }

  redirectToIndex() {
    this.closePrintOrderModalButton.nativeElement.click();
    if (this.sharedService.userPrivilegeVo.hasIncidentReportViewAccess)
      this.router.navigate(['incident-report']);
    else
      this.router.navigate(['/']);
  }

  deleteNotification(name) {
    for (let i = 0; i < this.incidentReportNotifications.length; ++i) {
      if (this.incidentReportNotifications[i].name === name)
        this.incidentReportNotifications.splice(i, 1);
    }
  }

  deleteMedication(medication) {
    for (let i = 0; i < this.incidentReportMedications.length; ++i) {
      if (this.incidentReportMedications[i].medication === medication)
        this.incidentReportMedications.splice(i, 1);
    }
  }

  deleteStaffInvolved(staffInvolved) {
    for (let i = 0; i < this.staffInvolveds.length; ++i) {
      if (this.staffInvolveds[i] === staffInvolved)
        this.staffInvolveds.splice(i, 1);
    }
  }

  deleteStaffDiscovered(staffDiscovered) {
    for (let i = 0; i < this.staffDiscovereds.length; ++i) {
      if (this.staffDiscovereds[i] === staffDiscovered)
        this.staffDiscovereds.splice(i, 1);
    }
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    this.scroll.scrollToPosition([0, 0]);
  }

  clearStaffInvolvedErrorMessage() {
    this.showStaffInvolvedErrorMessage = false;
    this.errorStaffInvolvedMessage = "";
  }

  loadStaffInvolvedErrorMessage(message: any) {
    this.showStaffInvolvedErrorMessage = true;
    this.errorStaffInvolvedMessage = message.toString();
  }

  clearStaffInvolvedDateErrorMessage() {
    this.showStaffInvolvedDateErrorMessage = false;
    this.errorStaffInvolvedDateMessage = "";
  }

  loadStaffInvolvedDateErrorMessage(message: any) {
    this.showStaffInvolvedDateErrorMessage = true;
    this.errorStaffInvolvedDateMessage = message.toString();
  }

  clearStaffDiscoveredErrorMessage() {
    this.showStaffDiscoveredErrorMessage = false;
    this.errorStaffDiscoveredMessage = "";
  }

  loadStaffDiscoveredErrorMessage(message: any) {
    this.showStaffDiscoveredErrorMessage = true;
    this.errorStaffDiscoveredMessage = message.toString();
  }

  clearStaffDiscoveredDateErrorMessage() {
    this.showStaffDiscoveredDateErrorMessage = false;
    this.errorStaffDiscoveredDateMessage = "";
  }

  loadStaffDiscoveredDateErrorMessage(message: any) {
    this.showStaffDiscoveredDateErrorMessage = true;
    this.errorStaffDiscoveredDateMessage = message.toString();
  }

  clearNotifiedErrorMessage() {
    this.showNotifiedErrorMessage = false;
    this.errorNotifiedMessage = "";
  }

  loadNotifiedErrorMessage(message: any) {
    this.showNotifiedErrorMessage = true;
    this.errorNotifiedMessage = message.toString();
  }

  clearNotifiedDateErrorMessage() {
    this.showNotifiedErrorMessage = false;
    this.errorNotifiedMessage = "";
  }

  loadNotifiedDateErrorMessage(message: any) {
    this.showNotifiedErrorMessage = true;
    this.errorNotifiedMessage = message.toString();
  }

  clearMedicationErrorMessage() {
    this.showMedicationErrorMessage = false;
    this.errorMedicationMessage = "";
  }

  loadMedicationErrorMessage(message: any) {
    this.showMedicationErrorMessage = true;
    this.errorMedicationMessage = message.toString();
  }

  print() {
    this.exportToPdf();
    this.closePrintOrderModalButton.nativeElement.click();
    this.redirectToIndex();
  }

  public exportToPdf() {
    var pageTitle = "IncidentReport";
    this.router.navigate([]).then(result => { window.open('report/IncidentReportView/' + this.sharedService.getUserId() + '/' + this.incidentReportId + '/' + pageTitle, '_blank'); });
  }
}
