import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { EprescriberService } from '../../services/eprescriber.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IEprescriber } from '../../interfaces/ieprescriber';
import { IEprescriberItem } from '../../interfaces/ieprescriber-item';
import { IEprescriberReviewMedicationCheckVo } from '../../interfaces/ieprescriber-review-medication-check-vo';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormControl, Validators } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap, filter, last, flatMap } from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})

export class EprescriberCreateComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private eprescriberService: EprescriberService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private spinnerService: Ng4LoadingSpinnerService,
    private http: HttpClient) { }

  public showLoadingMessage: boolean = false;

  patientAllergiesDietVo: any;
  patientId: string;
  eprescriberTypeId: string;
  prescription: any;
  prescriptions: any[];

  eprescriberItems: IEprescriberItem[] = [];
  ieprescriberReviewMedicationCheckVos: IEprescriberReviewMedicationCheckVo[];

  eprescriberItemIndex: number = 0;
  eprescriberImage: string;
  eprescriberPath: string;
  directory: string;
  eprescriberFileVo: any;

  public prescriberName: string = "";
  title: string;
  index: number;
  rxNum: string;
  status: string;
  strength: string;
  drugId: number;
  nameWithoutStrength: string;
  sig: string;
  indication: string;
  notes: string;
  endDateTypeId: number;
  endDateDuration: number;
  endDateDurationTypeId: number;

  quantity: number;
  dosageFormId: number;
  dosageForm: string;
  dosageForms: any[];
  refill: number;
  daysInterval: number;
  isMedicationNarcotic: boolean;
  totalQty: string;

  MarTar: boolean;
  DrugRecordBook: boolean;
  ProductFromEdb: boolean;
  DirectionsChangedSticker: boolean;
  DcMedsPulled: boolean;
  TransmittedProgressNotes: boolean;
  CarePlan: boolean;
  Diet: boolean;
  Lab: boolean;
  XrayUltrasound: boolean;
  Referral: boolean;
  NotifyPOA: boolean;
  CopyAddedToChart: boolean;

  drugs: any;

  rDelivery: string;
  cbTelephoneOrder: boolean;

  showErrorMessage: boolean;
  errorMessage: string;

  showErrorMessageAddPrescription: boolean;
  errorMessageAddPrescription: string;

  showDurationFields: boolean;
  production: boolean;

  patientVo: any = {};

  validateQuantity: boolean;

  eprescriberTypeItem: string = "1";
  eprescriberTypeFile: string = "2";
  reportTitle: string = "EPRESCRIBER";
  eprescriberMedicationTypeHeight: number = 7;
  eprescriberMedicationTypeWeight: number = 6;
  eprescriberMedicationTypeCreatinineCr: number = 8;

  @ViewChild('inputStrength', { static: false }) inputStrength: ElementRef;
  @ViewChild('inputMedication', { static: false }) inputMedication: ElementRef;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.eprescriberTypeId = this.route.snapshot.paramMap.get('eprescriberTypeId');

    this.loadModel();
    this.ieprescriberReviewMedicationCheckVos = [];
    this.checkIfIsNurse();
    this.eprescriberFileVo = {};
    this.cleanFormFields();

    if (this.route.snapshot.paramMap.has('eprescriberId')) {
      this.loadFormByModel();
    }
  }

  loadFormByModel() {
    this.eprescriberService.getEprescriberById(this.getEprescriberId(), this.sharedService.getUserId()).subscribe(eprescriberVo => {
      this.cbTelephoneOrder = eprescriberVo.telephoneOrder;
      this.patientVo.fullName = eprescriberVo.patientName;
      this.rDelivery = eprescriberVo.deliveryTypeId;
      this.title = eprescriberVo.title;
      this.prescriberName = eprescriberVo.prescriberName


      this.loadFormByEprescriberItem(eprescriberVo);
      if (eprescriberVo.eprescriberFileVo != null) {
        this.eprescriberFileVo = eprescriberVo.eprescriberFileVo;
        if (this.eprescriberFileVo != null && eprescriberVo.eprescriberFileVos != null)
          this.eprescriberImage = eprescriberVo.eprescriberFileVos[0].pdfBase64File;
      }

    });
  }

  getEprescriberId() {
    var eprescriberId = 0;
    if (this.route.snapshot.paramMap.has('eprescriberId')) {
      eprescriberId = Number.parseInt(this.route.snapshot.paramMap.get('eprescriberId'));
    }
    return eprescriberId;
  }

  cleanFormFields() {
    this.index = 0;
    this.rxNum = "";
    this.status = "Create";
    this.strength = "";
    this.sig = "";
    this.indication = "";
    this.notes = "";
    this.endDateTypeId = 1;
    this.endDateDuration = null;
    this.endDateDurationTypeId = 1;
    this.showDurationFields = false;
    this.isMedicationNarcotic = false;
    this.quantity = 0;
    this.refill = 0;
    this.totalQty = "";
    this.daysInterval = null;
    this.validateQuantity = false;
    this.nameWithoutStrength = "";

    if (this.inputMedication != null && this.inputStrength != null) {
      this.inputMedication.nativeElement.value = "";
      this.inputStrength.nativeElement.value = "";
    }
    this.clearErrorMessageAddPrescription();
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.eprescriberFileVo.pdfBase64File = event.target.result;
      this.eprescriberFileVo.fileName = file.name;
      this.eprescriberImage = this.eprescriberFileVo.pdfBase64File
    };
  }

  loadModel() {
    this.sharedService.getPatientVo(this.patientId).subscribe(data => {
      this.patientVo = data;
      this.title = "Prescription for " + this.patientVo.fullName;
      this.loadDosageForms();
    });

    this.sharedService.getPatientAllergiesDietVoByPatientId(this.patientId).subscribe(data => { this.patientAllergiesDietVo = data; });

    if (this.eprescriberTypeId == '1') {
      this.eprescriberService.getPrescriptionDtos(this.sharedService.pharmacyId, this.patientId).subscribe(data => {
        this.prescriptions = data;
      });
    }
  }

  checkIfIsNurse() {
    (this.sharedService.userPrivilegeVo != null && this.sharedService.userPrivilegeVo.isNurse) ? this.cbTelephoneOrder = true : this.cbTelephoneOrder = false;

  }

  checkBoxChanged(id) {
    var exist: boolean = false;
    if (this.ieprescriberReviewMedicationCheckVos.length == 0) {
      this.ieprescriberReviewMedicationCheckVos.push({ EprescriberMedicationCheckId: Number.parseInt(id) })
    }
    else {
      for (let i = 0; i < this.ieprescriberReviewMedicationCheckVos.length; ++i) {
        if (this.ieprescriberReviewMedicationCheckVos[i].EprescriberMedicationCheckId == id) {
          this.ieprescriberReviewMedicationCheckVos.splice(i, 1);
          exist = true;
        }
      }
      if (exist == false) {
        this.ieprescriberReviewMedicationCheckVos.push({ EprescriberMedicationCheckId: Number.parseInt(id) });
      }
    }
    exist = false;
  }

  onActionChangePrecription(eprescriberItemStatusId, rxNum: string, med: string) {
    this.cleanFormFields();
    this.prescription = this.prescriptions.find(x => x.rxNum == rxNum);


    var eprescriberItemStatusModified = "2";
    var eprescriberItemStatusDiscontinued = "4";
    var eprescriberItemStatusOnHold = "3";

    switch (eprescriberItemStatusId) {

      case eprescriberItemStatusModified: {
        this.index = this.getEprescriberItemIndex();
        this.rxNum = rxNum.toString();
        this.sig = this.prescription.sig;
        this.status = "Modify";
        this.inputMedication.nativeElement.value = this.prescription.drugBrandName + " - " + this.prescription.drugGenericName;
        this.nameWithoutStrength = this.prescription.drugBrandName + " - " + this.prescription.drugGenericName;
        this.inputStrength.nativeElement.value = this.prescription.drugStrength;
        this.drugId = this.prescription.drugId;

        this.isMedicationNarcotic = this.prescription.isNarcotic;
        this.dosageFormId = this.prescription.drugDosageFormId;
        var findInDosageForms = this.dosageForms.find(d => d.id == this.dosageFormId);
        this.dosageForm = findInDosageForms.title;
        this.onQuantityChange();

        break;
      }

      case eprescriberItemStatusDiscontinued: {
        var prescription = this.eprescriberItems.find(ei => ei.RxNum != "" && ei.RxNum == rxNum);

        if (prescription != null) {
          prescription.Status = "Discontinued";
          prescription.Medication = this.prescription.drugBrandName + " - " + this.prescription.drugGenericName;
          prescription.Directions = this.prescription.sig;
          prescription.Indication = ""
          prescription.RxNum = rxNum.toString();
          prescription.DrugId = this.prescription.drugId;
          prescription.DrugDosageFormId = this.prescription.drugDosageFormId;
        }
        else {
          this.eprescriberItems.push({
            Index: this.getEprescriberItemIndex(),
            EprescriberId: this.getEprescriberId(),
            Type: "Prescription",
            EprescriberItemTypeId: this.sharedService.getOrderTypeId("Prescription"),
            Status: "Discontinued",
            EprescriberItemStatusId: this.sharedService.getOrderStatusId("Discontinued"),
            Medication: this.prescription.drugBrandName + " - " + this.prescription.drugGenericName,
            Strength: this.prescription.drugStrength,
            Directions: this.prescription.sig,
            Indication: "",
            RxNum: rxNum.toString(),
            Notes: this.notes,
            EprescriberItemEndDateTypeId: null,
            EprescriberItemEndDateDuration: null,
            EprescriberItemEndDateDurationTypeId: null,
            DaysInterval: null,
            PharmacyId: this.sharedService.pharmacyId,
            DrugId: this.prescription.drugId,
            DrugDosageFormId: this.prescription.drugDosageFormId,
            DrugDosageFormTitle: "",
            Quantity: null,
            Refill: null,
            totalQty: null
          });
        }
        break;
      }

      case eprescriberItemStatusOnHold: {
        this.index = this.getEprescriberItemIndex();
        this.rxNum = rxNum;
        this.inputMedication.nativeElement.value = this.prescription.drugBrandName + " - " + this.prescription.drugGenericName;
        this.nameWithoutStrength = this.prescription.drugBrandName + " - " + this.prescription.drugGenericName;
        this.inputStrength.nativeElement.value = this.prescription.drugStrength;
        this.drugId = this.prescription.drugId;
        this.sig = this.prescription.sig;
        this.status = "Hold";

        this.isMedicationNarcotic = this.prescription.isNarcotic;
        this.dosageFormId = this.prescription.drugDosageFormId;
        var findInDosageForms = this.dosageForms.find(d => d.id == this.dosageFormId);
        this.dosageForm = findInDosageForms.title;
        this.onQuantityChange();

        break;
      }
    }
  }

  validateOrderModify(type, patientInfo) {
    var message: string = "";
    if (patientInfo == "" || patientInfo == null)
      message = "The input field " + type + " is empty";
    return message;
  }

  loadCreatinineCrCl() {
    this.patientVo.creatinineCrCl = ((140 - this.patientVo.age) * parseFloat(this.patientVo.weight) * this.patientVo.creatinineCrClConstant / parseFloat(this.patientVo.creatinineCr)).toFixed(2);
  }

  orderModify(type, text) {
    var resultFieldsValidation = this.validateOrderModify(type, text);
    if (resultFieldsValidation == "") {

      var patientInfoExistent = this.eprescriberItems.find(ei => ei.Type == type);
      if (patientInfoExistent != null) {
        patientInfoExistent.Directions = String(text);
      }
      else {
        this.eprescriberItems.push(
          {
            Index: 0,
            EprescriberId: this.getEprescriberId(),
            Type: type,
            EprescriberItemTypeId: this.sharedService.getOrderTypeId(type),
            Status: "Modified",
            EprescriberItemStatusId: this.sharedService.getOrderStatusId("Modified"),
            Medication: "",
            Strength: "",
            Directions: String(text),
            Indication: "",
            RxNum: "",
            Notes: this.notes,
            EprescriberItemEndDateTypeId: null,
            EprescriberItemEndDateDuration: null,
            EprescriberItemEndDateDurationTypeId: null,
            DaysInterval: null,
            PharmacyId: this.sharedService.pharmacyId,
            DrugId: null,
            DrugDosageFormId: null,
            DrugDosageFormTitle: "",
            Quantity: null,
            Refill: null,
            totalQty: null
          });
      }
    }
    else {
      this.loadErrorMessage(resultFieldsValidation);
    }
    this.loadCreatinineCrCl();
    this.cleanFormFields();
  }

  addPrescription() {

    var resultFieldsValidation = this.validateAddPrescriptionFields();
    if (resultFieldsValidation == "") {

      this.strength = this.inputStrength.nativeElement.value;

      if (this.drugId != null && this.nameWithoutStrength != this.inputMedication.nativeElement.value) {
        this.drugId = null;
      }

      var prescription = this.eprescriberItems.find(ei => (ei.RxNum != "" && ei.RxNum == this.rxNum) || (ei.Index > 0 && ei.Index == this.index));
      if (prescription != null) {

        prescription.Status = this.getNewStatus();
        prescription.Medication = this.inputMedication.nativeElement.value;
        prescription.Strength = this.strength;
        prescription.Directions = this.sig;
        prescription.Indication = this.indication;
        prescription.RxNum = this.rxNum.toString();
        prescription.Quantity = this.quantity;
        prescription.DrugDosageFormId = this.dosageFormId;
        prescription.DrugDosageFormTitle = this.dosageForm;
        prescription.Refill = this.refill;
        prescription.totalQty = this.totalQty;
        prescription.EprescriberItemEndDateTypeId = Number.parseInt(this.endDateTypeId.toString());

        if (this.endDateDuration != null) {
          prescription.EprescriberItemEndDateDuration = Number.parseInt(this.endDateDuration.toString());
          prescription.EprescriberItemEndDateDurationTypeId = Number.parseInt(this.endDateDurationTypeId.toString());
        }
      }
      else {
        var item: IEprescriberItem = {
          Index: this.getEprescriberItemIndex(),
          EprescriberId: this.getEprescriberId(),
          Type: 'Prescription',
          EprescriberItemTypeId: this.sharedService.getOrderTypeId('Prescription'),
          Status: this.getNewStatus(),
          EprescriberItemStatusId: this.sharedService.getOrderStatusId(this.getNewStatus()),
          Medication: this.inputMedication.nativeElement.value,
          Strength: this.strength,
          Directions: this.sig,
          Indication: this.indication,
          RxNum: this.rxNum.toString(),
          Notes: this.notes,
          EprescriberItemEndDateTypeId: Number.parseInt(this.endDateTypeId.toString()),
          EprescriberItemEndDateDuration: null,
          EprescriberItemEndDateDurationTypeId: null,
          DaysInterval: null,
          PharmacyId: this.sharedService.pharmacyId,
          DrugId: this.drugId,
          DrugDosageFormId: null,
          DrugDosageFormTitle: "",
          Quantity: null,
          Refill: null,
          totalQty: null
        }

        if (this.endDateDuration != null) {
          item.EprescriberItemEndDateDuration = Number.parseInt(this.endDateDuration.toString());
          item.EprescriberItemEndDateDurationTypeId = Number.parseInt(this.endDateDurationTypeId.toString());
        }

        if (this.quantity != null && this.dosageFormId != null && this.refill != null) {
          item.DrugDosageFormId = this.dosageFormId;
          item.DrugDosageFormTitle = this.dosageForm;
          item.Quantity = this.quantity;
          item.Refill = this.refill;
          item.totalQty = this.totalQty;
        }

        if (this.daysInterval)
          item.DaysInterval = this.daysInterval;

        this.eprescriberItems.push(item);

      }
      this.cleanFormFields();
    } else {
      this.loadErrorMessageAddPrescription(resultFieldsValidation);
    }
  }

  getNewStatus(): string {
    switch (this.status) {
      case "Modify":
        return "Modified";
      default:
        return this.status;
    }
  }

  editNewPrescriptionsRow(eprescriberItem: IEprescriberItem) {
    this.index = eprescriberItem.Index;
    this.rxNum = eprescriberItem.RxNum;
    this.status = eprescriberItem.Status;
    this.sig = eprescriberItem.Directions;
    this.inputMedication.nativeElement.value = eprescriberItem.Medication;
    this.strength = eprescriberItem.Strength;
    this.indication = eprescriberItem.Indication;
    this.notes = eprescriberItem.Notes;
    this.endDateTypeId = eprescriberItem.EprescriberItemEndDateTypeId;

    if (eprescriberItem.EprescriberItemEndDateDuration != null) {
      this.onChangeEndDate(eprescriberItem.EprescriberItemEndDateTypeId)
      this.endDateDuration = eprescriberItem.EprescriberItemEndDateDuration;
      this.endDateDurationTypeId = eprescriberItem.EprescriberItemEndDateDurationTypeId;
    }

    if (this.patientVo.isFacilityTypeRetail) {
      this.quantity = eprescriberItem.Quantity;
      this.refill = eprescriberItem.Refill;
      this.totalQty = eprescriberItem.totalQty;
    }

    this.onQuantityChange();
  }

  deleteNewPrescriptionsRow(type, directions, rxNum: string) {
    for (let i = 0; i < this.eprescriberItems.length; ++i) {
      if (rxNum != "") {
        if (this.eprescriberItems[i].RxNum === rxNum)
          this.eprescriberItems.splice(i, 1);
      } else {
        if (this.eprescriberItems[i].Type === type && this.eprescriberItems[i].Directions === directions)
          this.eprescriberItems.splice(i, 1);
      }
    }
  }

  saveAsDraft() {
    try {
      this.clearErrorMessage()
      this.spinnerService.show();

      var eprescriber: IEprescriber = this.loadModelByForm();

      this.eprescriberService.saveAsDraftEprescriber(eprescriber).subscribe
        (resultVo => {

          if (resultVo.success) {
            this.sharedService.showSuccessMessage = resultVo.success;
            this.redirectToEprescriber();
          } else {
            this.loadErrorMessage(resultVo.message);
          }

        });

    } catch (e) {
      this.loadErrorMessage(e);
    }
    this.spinnerService.hide();
  }

  proceed() {
    try {
      this.clearErrorMessage()
      this.spinnerService.show();
      var resultFieldsValidation = this.validateFields();

      var eprescriber: IEprescriber = this.loadModelByForm();

      if (resultFieldsValidation == "") {
        this.eprescriberService.proceedEprescriber(eprescriber).subscribe
          (resultVo => {

            if (resultVo.success) {
              this.sharedService.showTempSuccessMessage();
              this.sharedService.loadReceivingAlert();
              this.redirectToEprescriber();
            } else {
              this.loadErrorMessage(resultVo.message);
            }

          });
      }
      else {
        this.loadErrorMessage(resultFieldsValidation);
      }
    } catch (e) {
      this.loadErrorMessage(e);
    }
    this.spinnerService.hide();
  }

  redirectToEprescriber() {
    this.spinnerService.hide();
    switch (localStorage.getItem('previousPage')) {
      case "home":
        this.router.navigate(['/']);
        break;
      case "profile":
        this.router.navigate(['patient/profile/' + this.patientId]);
        break;

      default:
        this.router.navigate(['eprescriber/1']);
    }

    if (localStorage.getItem('previousPage').toLowerCase() == 'home')
      this.router.navigate(['/']);
    else
      this.router.navigate(['eprescriber/1']);
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessageAddPrescription(message: any) {
    this.showErrorMessageAddPrescription = true;
    this.errorMessageAddPrescription = message.toString();
  }

  clearErrorMessageAddPrescription() {
    this.showErrorMessageAddPrescription = false;
    this.errorMessageAddPrescription = "";
  }

  validateFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.title == null || this.title == "")
      errors.push('Title');

    if (this.rDelivery == null || this.rDelivery == "")
      errors.push('Delivery');

    if (this.sharedService.facilityId == null || this.sharedService.facilityId == 0
      || this.patientId == ""
      || this.sharedService.pharmacyId == null || this.sharedService.pharmacyId == 0)
      errors.push('Invalid request, please try close and open it again.');

    if (this.sharedService.userPrivilegeVo.isNurse == true && this.ieprescriberReviewMedicationCheckVos.length == 0 && !this.cbTelephoneOrder)
      errors.push('First Review');

    if ((this.eprescriberFileVo.prescriberFirstName == null || this.eprescriberFileVo.prescriberFirstName == "") && (this.eprescriberTypeId == "2"))
      errors.push('First name');

    if ((this.eprescriberFileVo.prescriberLastName == null || this.eprescriberFileVo.prescriberLastName == "") && (this.eprescriberTypeId == "2"))
      errors.push('Last name');

    if ((this.eprescriberItems == null || this.eprescriberItems.length == 0) && (this.eprescriberTypeId == "1"))
      errors.push('Prescriptions list');

    if ((this.eprescriberImage == null || this.eprescriberImage == "") && (this.eprescriberTypeId == "2"))
      errors.push('File');

    if (this.eprescriberTypeId == '1' && this.sharedService.userPrivilegeVo.isNurse && this.cbTelephoneOrder && this.prescriberName == "")
      errors.push('Prescriber');

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }

    return message;
  }

  validateAddPrescriptionFields(): string {
    var errors: string[] = [];
    var message: string = "";

    if (this.inputMedication.nativeElement.value == "")
      errors.push('Medication');

    if (this.status == "Create" && this.isEmptyOrSpaces(this.strength))
      errors.push('Strength');

    if ((this.quantity == null || this.quantity == 0) && this.patientVo.isFacilityTypeRetail && this.isNotEprescriberItemStatusOnHold())
      errors.push('Quantity');

    if (this.isEmptyOrSpaces(this.sig))
      errors.push('Directions');

    if ((this.daysInterval == null || this.daysInterval == 0) && this.patientVo.isFacilityTypeRetail && this.isMedicationNarcotic)
      errors.push("Days Interval");

    if (errors.length == 1) {
      message = "The field " + errors.join(", ") + " is mandatory."
    }
    else if (errors.length > 1) {
      message = "The fields " + errors.join(", ") + " are mandatories."
    }
    return message;
  }

  isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }

  isNotEprescriberItemStatusOnHold(): boolean {
    return this.status != "Hold";
  }

  loadFormByEprescriberItem(eprescriberVo) {
    if (eprescriberVo.eprescriberItemVos != null) {
      for (let i = 0; i < eprescriberVo.eprescriberItemVos.length; i++) {
        var notes = eprescriberVo.eprescriberItemVos[i].notes;
        if (notes == null)
          notes = "";

        this.eprescriberItems.push({
          Index: 0,
          EprescriberId: eprescriberVo.id,
          Type: eprescriberVo.eprescriberItemVos[i].type,
          EprescriberItemTypeId: eprescriberVo.eprescriberItemVos[i].eprescriberItemTypeId,
          Status: eprescriberVo.eprescriberItemVos[i].status,
          EprescriberItemStatusId: this.sharedService.getOrderStatusId(eprescriberVo.eprescriberItemVos[i].status),
          Medication: eprescriberVo.eprescriberItemVos[i].medication,
          Strength: eprescriberVo.eprescriberItemVos[i].strength,
          Directions: eprescriberVo.eprescriberItemVos[i].directions,
          Indication: eprescriberVo.eprescriberItemVos[i].indication,
          RxNum: eprescriberVo.eprescriberItemVos[i].rxNum,
          Notes: notes,
          EprescriberItemEndDateTypeId: eprescriberVo.eprescriberItemVos[i].eprescriberItemEndDateTypeId,
          EprescriberItemEndDateDuration: eprescriberVo.eprescriberItemVos[i].eprescriberItemEndDateDuration,
          EprescriberItemEndDateDurationTypeId: eprescriberVo.eprescriberItemVos[i].eprescriberItemEndDateDurationTypeId,
          DaysInterval: eprescriberVo.eprescriberItemVos[i].daysInterval,
          PharmacyId: eprescriberVo.eprescriberItemVos[i].pharmacyId,
          DrugId: eprescriberVo.eprescriberItemVos[i].drugId,
          DrugDosageFormId: eprescriberVo.eprescriberItemVos[i].drugDosageFormId,
          DrugDosageFormTitle: eprescriberVo.eprescriberItemVos[i].drugDosageForm,
          Quantity: eprescriberVo.eprescriberItemVos[i].quantity,
          Refill: eprescriberVo.eprescriberItemVos[i].refill,
          totalQty: eprescriberVo.eprescriberItemVos[i].totalQty
        });
      }
    }
  }

  loadModelByForm(): IEprescriber {

    var model: IEprescriber = {
      facilityId: this.sharedService.facilityId,
      patientId: Number.parseInt(this.patientId),
      PharmacyId: this.sharedService.pharmacyId,
      telephoneOrder: this.cbTelephoneOrder,
      patientName: this.patientVo.fullName,
      EprescriberItems: this.eprescriberItems,
      EprescriberFile: this.eprescriberFileVo,
      CreatedBy: this.sharedService.getUserId(),
      EprescriberStatusId: 1,
      EprescriberDeliveryTypeId: Number.parseInt(this.rDelivery),
      eprescriberReviewMedicationCheckVos: this.ieprescriberReviewMedicationCheckVos,
      Title: this.title,
      PrescriberName: this.prescriberName,
      enabledForCurrentUserReview: false,
      nurseReviwer: false,
      isTheCurrentUserNurse: false,
      id: 0,
      EprescriberTypeId: Number.parseInt(this.eprescriberTypeId)
    }
    if (Object.keys(this.eprescriberFileVo).length > 0 && this.eprescriberFileVo.prescriberLicense != null) {
      this.eprescriberFileVo.prescriberLicense = this.eprescriberFileVo.prescriberLicense.toString();
      this.eprescriberFileVo.eprescriberBase64File = this.eprescriberImage
    }

    if (this.route.snapshot.paramMap.has('eprescriberId'))
      model.id = Number.parseInt(this.route.snapshot.paramMap.get('eprescriberId'));

    return model;
  }

  onChangeEndDate(endDateId) {
    this.showDurationFields = endDateId == "2";
  }

  //Autocomplete Medication element
  myControl = new FormControl('', [Validators.required]);
  filteredMedications: Observable<any[]>;
  toHighlight: string = '';


  loadFilteredMedications() {
    this.drugId = null;
    this.filteredMedications = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filter(val)
      })
    )
  }

  private _filter(value: string): Observable<any[]> {

    if (value.length >= 3) {
      var texts: string[] = value.split(' ');
      this.toHighlight = value;

      return this.sharedService.getDrugsBy(value)
        .pipe(
          map(response => response.filter(option => {

            if (texts.length == 1)
              return option.name.toLowerCase().includes(texts[0].toLowerCase())
            else
              return option.name.toLowerCase().includes(texts[0].toLowerCase()) && option.name.toLowerCase().includes(texts[1].toLowerCase())
          })));
    }
    return EMPTY;
  }

  getMedications(value) {
    (async () => {
      this.getDrugs(value).subscribe(data => {
        this.drugs = data;

        var findInDrugs = this.drugs.find(d => d.name == value);
        this.inputStrength.nativeElement.value = this.strength = findInDrugs.strength;
        this.inputMedication.nativeElement.value = findInDrugs.nameWithoutStrength;
        this.nameWithoutStrength = findInDrugs.nameWithoutStrength;
        this.drugId = findInDrugs.id;
        this.isMedicationNarcotic = findInDrugs.isNarcotic;

        this.dosageForm = findInDrugs.form;
        var findInDosageForms = this.dosageForms.find(d => d.title == this.dosageForm);
        this.dosageFormId = findInDosageForms.id;

        this.onQuantityChange();
      });
    })();
  }

  getDrugs(value): Observable<any[]> {
    return this.sharedService.getDrugsBy(value);
  }

  onDosageFormChange(target) {
    let selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.dosageForm = selectElementText;

    this.onQuantityChange();
  }

  onQuantityChange() {
    this.quantityValidation();

    this.totalQty = (this.quantity * (this.refill + 1)).toString();

    if (this.dosageForm != null)
      this.totalQty += " " + this.dosageForm;
  }

  quantityValidation() {
    this.validateQuantity = this.quantity != 0;
  }

  loadDosageForms() {
    if (this.patientVo.isFacilityTypeRetail) {
      this.eprescriberService.getDrugDosageForms(this.sharedService.pharmacyId)
        .subscribe(result => {
          this.dosageForms = result;
          this.dosageFormId = 278; //table
          this.dosageForm = "TAB (Tablet)";
          this.quantity = 0;
          this.refill = 0;
          this.onQuantityChange();
        });
    }
  }

  getEprescriberItemIndex(): number {
    this.eprescriberItemIndex++;

    return this.eprescriberItemIndex;
  }
}

