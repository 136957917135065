import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { NarcoticReconciliationService } from '../../services/narcotic-reconciliation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { createFalse } from 'typescript';

@Component({
  selector: 'app-narcotic-reconciliation-item-detail',
  templateUrl: './narcotic-reconciliation-item-detail.component.html',
  styleUrls: ['./narcotic-reconciliation-item-detail.component.css']
})
export class NarcoticReconciliationItemDetailComponent implements OnInit {

  constructor(private sharedService: SharedService,
              private route: ActivatedRoute,
              private router: Router,
              private narcoticReconciliationService: NarcoticReconciliationService,
  ) { }

  narcoticReconciliationId: number;
  drugPackId: number;
  narcoticReconciliationItemId: number;
  narcoticReconciliationItemDetail: any;
  totalDispensed: number = 0;
  totalPurchased: number = 0;
  totalDestroyed: number = 0;
  countDispensed: number = 0;
  countPurchased: number = 0;
  hasPurchasedItems: boolean = false;
  hasDispensedItems: boolean = false;
  hasDestroyedItems: boolean = false;
  showLoadingMessage: boolean;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadNarcoticReconciliationItemDetail();    
  }

  redirectToNarcoticReconciliationItem() {
    if (this.narcoticReconciliationItemId != null)
      this.router.navigateByUrl('/narcotic-reconciliation-item/' + this.narcoticReconciliationId);     
  }

  loadNarcoticReconciliationItemDetail() {
    this.showLoadingMessage = true;
    this.narcoticReconciliationId = Number.parseInt(this.route.snapshot.paramMap.get('narcoticReconciliationId'));
    this.narcoticReconciliationItemId = Number.parseInt(this.route.snapshot.paramMap.get('narcoticReconciliationItemId'));
        
    this.narcoticReconciliationService.getNarcoticReconciliationItemDetailById(this.sharedService.pharmacyId, this.narcoticReconciliationItemId).subscribe(
      data => {
        this.narcoticReconciliationItemDetail = data;
        this.loadTotalHighlights();
        this.loadNarcoticReconciliationDifferenceCountStyle();
        this.showLoadingMessage = false;
      }, err => this.showLoadingMessage = false);
  }


  loadTotalHighlights() {
    if (this.narcoticReconciliationItemDetail.narcoticReconciliationDestructionItemVos != null && this.narcoticReconciliationItemDetail.narcoticReconciliationDestructionItemVos.length > 0) {
      this.hasDestroyedItems = true;
      this.totalDestroyed = this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.destruction;
    }
    if (this.narcoticReconciliationItemDetail.dispenserKrollItemVos != null && this.narcoticReconciliationItemDetail.dispenserKrollItemVos.length > 0) {
      this.hasDispensedItems = true;
      this.totalDispensed = this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.dispensedKroll;
      this.countDispensed = this.narcoticReconciliationItemDetail.dispenserKrollItemVos.length;
    }
    if (this.narcoticReconciliationItemDetail.purchaseItemVos != null && this.narcoticReconciliationItemDetail.purchaseItemVos.length > 0) {
      this.hasPurchasedItems = true;
      this.totalPurchased = this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.purchasedTotal;
      this.countPurchased = this.narcoticReconciliationItemDetail.purchaseItemVos.length;
      this.matchingPurchasePackSizes();
    }    
  }

  matchingPurchasePackSizes() {
    for (var i = 0; i < this.narcoticReconciliationItemDetail.purchaseItemVos.length; i++) {
      if (this.narcoticReconciliationItemDetail.purchaseItemVos[i].packSize == 0)
        this.narcoticReconciliationItemDetail.purchaseItemVos[i].packSize = this.narcoticReconciliationItemDetail.purchaseItemVos[i].matchPackSize;
    }
  }

  loadNarcoticReconciliationDifferenceCountStyle() {
    if (this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.difference == 0) {
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activePositiveDifferenceClass = false;
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activeNegativeDifferenceClass = false;
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activeNoDifferenceClass = true;
    }
    if (this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.difference < 0) {
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activePositiveDifferenceClass = false;
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activeNegativeDifferenceClass = true;
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activeNoDifferenceClass = false;
    }
    if (this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.difference > 0) {
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activePositiveDifferenceClass = true;
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activeNegativeDifferenceClass = false;
      this.narcoticReconciliationItemDetail.narcoticReconciliationItemVo.activeNoDifferenceClass = false;
    }
  }
}
