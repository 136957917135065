import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { ITrainingVo } from '../../interfaces/training/itraining-vo';
import { SharedService } from '../../services/shared.service';
import { UserTrainingService } from '../../services/user-training.service';

@Component({
  selector: 'app-user-training-item',
  templateUrl: './user-training-item.component.html',
  styleUrls: ['./user-training-item.component.css']
})
export class UserTrainingItemComponent implements OnInit {

  constructor(private sharedService: SharedService,
    private route: ActivatedRoute,
    private trainingService: UserTrainingService,
    private router: Router) { }

  errorMessage: string;
  showErrorMessage: boolean;

  trainingId: number;
  trainingVo: ITrainingVo;
  trainingName: string;
  trainingFilePath: string;
  userTrainingId: number;
  userTrainingVo: any;
  userTrainingProgressStatusId: number;
  userId: string;
  firstSlide: number = 1;
  currentSlide: number;
  lastVisitSlide: number;
  totalSlides: number;
  slides: any[];
  userProgress: number;
  zoom: number;
  zoomText: string;
  isTrainingContentConcluded: boolean;
  showLoadingMessage: boolean;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadFields();
    this.loadTrainingContent();
  }

  loadFields() {
    this.zoom = 1.0;
    this.slides = [];
    this.lastVisitSlide = this.firstSlide;
    this.userId = this.sharedService.getUserId();
    this.trainingId = Number.parseInt(this.route.snapshot.paramMap.get('trainingId'));
    this.userTrainingId = Number.parseInt(this.route.snapshot.paramMap.get('userTrainingId'));
    this.updateZoomText();
  }

  loadTrainingContent() {
    this.showLoadingMessage = true;
    this.trainingService.getTrainingVoBy(this.trainingId).subscribe(
      data => {
        this.trainingVo = data;
        this.totalSlides = this.trainingVo.trainingContentVos[0].trainingContentItemVos.length;
        this.loadPdfViewer();
        this.loadUserTrainingVo();
        this.showLoadingMessage = false;
      }, err => this.showLoadingMessage = false
    );
  }

  loadPdfViewer() {
    this.trainingFilePath = "data:application/pdf;base64," + this.trainingVo.trainingContentVos[0].pdfFile64;
  }

  loadUserTrainingVo() {
    this.trainingService.getUserTrainingVoBy(this.userTrainingId).subscribe(
      data => {
        this.userTrainingVo = data;

        if (this.userTrainingVo.userTrainingContentVos.length > 0) {
          this.currentSlide = this.userTrainingVo.userTrainingContentVos[0].currentSlide;
          this.loadSlides();
          this.updateUserProgress();
        }
        else
          this.loadErrorMessage("Ops! Something went wrong.");
      });
  }

  loadUserTrainingVoForUpdate(): any {
    var userTrainingVo = {
      id: this.userTrainingId,
      userId: this.userId,
      trainingId: this.trainingId,
      totalSlides: this.totalSlides,
      userTrainingContentVos: []
    };

    userTrainingVo.userTrainingContentVos.push({
      currentSlide: this.lastVisitSlide,
      isTrainingContentConcluded: this.isTrainingContentConcluded
    })

    return userTrainingVo;
  }

  submitUserTrainingVo() {
    this.trainingService.updateUserTrainingVo(this.loadUserTrainingVoForUpdate()).subscribe(
      resultVo => {
        if (resultVo.success) {
          this.sharedService.showSuccessMessage;
          this.router.navigate(['training']);
        }
        else
          this.loadErrorMessage(resultVo.message);
      });
  }

  updateUserProgress() {
    if (this.currentSlide > this.lastVisitSlide)
      this.lastVisitSlide = this.currentSlide;
    this.userProgress = Math.round((this.lastVisitSlide * 100) / this.totalSlides);
    this.slides.find(p => p.id == this.currentSlide).isSlideUnlocked = true;
    this.isTrainingContentConcluded = !this.slides.some(p => p.isSlideUnlocked == false);
  }

  loadSlides() {
    for (let i = 0; i < this.totalSlides; i++) {
      this.slides.push(
        {
          id: i + 1,
          title: this.trainingVo.trainingContentVos[0].trainingContentItemVos[i].name,
          isSlideUnlocked: i < this.currentSlide
        });
    }
  }

  nextSlide() {
    if ((this.currentSlide + 1) <= this.totalSlides) {
      this.currentSlide += 1;
      this.updateUserProgress();
    }
  }

  previousSlide() {
    if ((this.currentSlide - 1) > 0) {
      this.currentSlide -= 1;
    }
  }

  zoomIn() {
    this.zoom += 0.2;
    this.updateZoomText();
  }

  zoomOut() {
    this.zoom -= 0.2;
    this.updateZoomText();
  }

  updateZoomText() {
    this.zoomText = Math.round(this.zoom * 100) + "%";
  }

  selectSlide(slideId: number) {
    if (this.slides.find(p => p.id == slideId).isSlideUnlocked) {
      this.currentSlide = slideId;
    }
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
  }

  loadErrorMessage(message: string) {
    this.errorMessage = message;
    this.showErrorMessage = true;
  }

  exportToPdf() {
    var byteCharacters = atob(this.trainingVo.trainingContentVos[0].pdfFile64);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}
