import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedService } from '../services/shared.service';
import { DrugRecordBookService } from '../services/drug-record-book.service';
import { Observable, of } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-receiving',
  templateUrl: './receiving.component.html',
  styleUrls: ['./receiving.component.css']
})
export class ReceivingComponent implements OnInit {
  dateEnd: any;
  getDateEnd: string;
  dateStart: any;
  getDateStart: string;
  drugRecordBookItems: any[] = [];
  dd: string;
  mm: string;
  yyyy: string;
  mmS: string;
  ddS: string;
  yyyyS: string;
  showErrorMessage: boolean;
  errorMessage: string;
  public drugRecordBookItemStatusId: number = 1;
  drugRecordBookItemTypeId: number;
  externalId: number;
  createdDate: Date;


  constructor(private sharedService: SharedService,
    private drugBookRecordService: DrugRecordBookService,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private datepipe: DatePipe) { }

  @ViewChild('closebutton', { static: false }) closebutton;

  ngOnInit() {
    this.sharedService.reloadUserPrivileges();
    this.loadDate();
    this.loadDrugRecordBook();
  }

  public onFiltersChange() {
    this.showErrorMessage = false;
    var resultFieldsValidation = this.validateDate();
    if (resultFieldsValidation.length == 0) {
      this.loadDrugRecordBook();
    }
    else {
      this.loadErrorMessage(resultFieldsValidation.join(" "));
    }
  }

  public chagenStatusToReceived(drugRecordBookItemTypeId, externalId, createdDate) {
    var statusReceived = 2;
    this.ChangeStatus(drugRecordBookItemTypeId, externalId, createdDate, statusReceived)
  }

  public setDrugRecordBookItemId(drugRecordBookItemTypeId, externalId, createdDate) {
    this.drugRecordBookItemTypeId = drugRecordBookItemTypeId;
    this.externalId = externalId;
    this.createdDate = createdDate;
  }

  public chagenStatusToNoDeliveryRequired() {
    var statusReceived = 3;
    this.ChangeStatus(this.drugRecordBookItemTypeId, this.externalId, this.createdDate, statusReceived)
    this.closebutton.nativeElement.click();
  }

  private ChangeStatus(drugRecordBookItemTypeId, externalId, createdDate, drugRecordBookItemStatusId) {
    var createdDateCoverted = new Date(Date.parse(createdDate));
    var createdDateText = this.datepipe.transform(createdDateCoverted, 'yyyy-MM-dd');
    this.drugBookRecordService.ChangeStatus(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.getUserId(), drugRecordBookItemTypeId, externalId, createdDateText, drugRecordBookItemStatusId).subscribe(result => {
      if (result.success) {
        this.sharedService.showLoadingMessage = true;
        this.loadDrugRecordBook();
        this.sharedService.loadReceivingAlert();
      } else {
        this.errorMessage = result.message;
        this.showErrorMessage = true;
      }
    });
  }

  loadDate() {
    this.dateEnd = new Date();
    this.dateStart = new Date();
    this.dateStart.setDate(this.dateStart.getDate() - 30);
  }

  validateDate(): string[] {
    var errors: string[] = [];
    if (this.dateEnd < this.dateStart)
      errors.push("The End Date must be greater then Start Date!")

    return errors;
  }

  clearErrorMessage() {
    this.showErrorMessage = false;
    this.errorMessage = "";
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  private loadDrugRecordBook() {
    var startDateText = this.datepipe.transform(this.dateStart, 'yyyy-MM-dd');
    var endDateText = this.datepipe.transform(this.dateEnd, 'yyyy-MM-dd');

    this.drugBookRecordService.GetReceiving(this.sharedService.pharmacyId, this.sharedService.facilityId, this.sharedService.facilityUnitId, startDateText, endDateText, this.drugRecordBookItemStatusId).subscribe(data => {
      this.drugRecordBookItems = data;
    });
  }

  openEprescriberPdf(externalId, drugRecordBookItemTypeId) {
    this.drugBookRecordService.GetEprescriberId(externalId, drugRecordBookItemTypeId).subscribe(ePrescriberId => {
      if (ePrescriberId > 0) {
        var pageTitle = "ePrescriberReport";
        this.router.navigate([]).then(result => { window.open('report/eprescriber/' + ePrescriberId + '/' + pageTitle, '_blank'); });
      } else {
        this.errorMessage = "ePrescriber not found!";
        this.showErrorMessage = true;
      }
    });
  }
}
