import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ReorderService } from '../../services/reorder.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-pharmacist-review',
  templateUrl: './pharmacist-review.component.html',
  styleUrls: ['./pharmacist-review.component.css']
})
export class PharmacistReviewComponent implements OnInit {
  showErrorMessage: boolean;
  errorMessage: string;
  reorderId: number;

  constructor(private sharedService: SharedService,
    private router: Router,
    private reorderService: ReorderService,
    private datepipe: DatePipe) { }

  @Input() public reorders: any[];
  @Input() public dateStartReorder: any;
  @Input() public dateEndReorder: any;
  @ViewChild('closebutton', { static: false }) closebutton;

  showSuccessMessage: boolean;
  condition: any;

  ngOnInit() {
    this.showSuccessMessage = false;
  }

  loadReorders() {
    if (this.reorderService.reorderStatusId == null)
      this.reorderService.reorderStatusId = 1;

    var dateStartCoverted = new Date(Date.parse(this.dateStartReorder.toString()));
    var dateStart = this.datepipe.transform(dateStartCoverted, 'yyyy-MM-dd');
    var dateEndCoverted = new Date(Date.parse(this.dateEndReorder.toString()));
    var dateEnd = this.datepipe.transform(dateEndCoverted, 'yyyy-MM-dd');

    this.reorderService.getReordersByStatusId(this.sharedService.pharmacyId, this.sharedService.facilityId, this.reorderService.reorderStatusId, dateStart, dateEnd)
      .subscribe(data => {
        this.reorders = data;
      });
  }

  openReorder(id) {
    this.router.navigate(['/reorder/view/' + id]);
  }

  setReorderId(id) {
    this.reorderId = id;
  }

  deleteReorder() {
    this.reorderService.deleteReorder(this.reorderId, this.sharedService.getUserId())
      .subscribe(resultVo => {

        if (resultVo.success) {
          this.showSuccessMessage = true;
          window.scroll(0, 0);
          this.loadReorders();
        } else {
          this.loadErrorMessage(resultVo.message);
        }
      });

    this.closebutton.nativeElement.click();
  }

  loadErrorMessage(message: any) {
    this.showErrorMessage = true;
    this.errorMessage = message.toString();
    window.scroll(0, 0);
  }

  onActionShipped(id) {
    this.reorderService.changeReoderStatusToShipped(id).subscribe(
      success => {
        this.sharedService.showSuccessMessage = success;
        this.loadReorders();
      });
  }
}
