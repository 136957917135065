import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, EMPTY, of } from 'rxjs';
import { tap } from 'rxjs/operators'
import { AuthorizeService } from './../../api-authorization/authorize.service';
import { AuthorizeGuard } from './../../api-authorization/authorize.guard';
import { browserRefresh } from '../app.component';
import { Router } from '@angular/router';
import { IuserIpAddressHistoryVo } from '../interfaces/iuser-ip-address-history-vo';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  get pharmacyId(): number {
    return Number.parseInt(localStorage.getItem('pharmacyId'));
  }

  get pharmacy(): string {
    return localStorage.getItem('pharmacy');
  };

  get isAdelaidePharmacy(): boolean {
    return this.pharmacyId === 3 || this.pharmacyId === 26;
  };

  get facilityId(): number {
    return Number.parseInt(localStorage.getItem('facilityId'));
  };

  get facilityTypeId(): number {
    return Number.parseInt(localStorage.getItem('facilityTypeId'));
  };

  get isIpAddressRestriction(): boolean {
    if (localStorage.getItem('isIpAddressRestriction') != null)
      return localStorage.getItem('isIpAddressRestriction').toLowerCase() == 'true';
    else
      return false;
  }

  public facilityUnitId: number;
  public ApiUrl = "";
  public facilities: any[];
  public userPrivilegeVo: any;
  public showLoadingMessage: boolean = true;
  public filteredOptions: Observable<any>;

  get showReceivingAlert(): boolean {
    if (localStorage.getItem('showReceivingAlert') != null)
      return localStorage.getItem('showReceivingAlert').toLowerCase() == 'true';
    else
      return false;
  }

  get totalPendingReceive(): any {
    if (localStorage.getItem('totalPendingReceive') != null)
      return localStorage.getItem('totalPendingReceive');
    else
      return 0;
  }

  private loadFacilityIdTrigger = new Subject<any>();
  public loadFacilityIdCalled$ = this.loadFacilityIdTrigger.asObservable();

  public showSuccessMessage: boolean;

  public get isNonFacility(): boolean { return this.facilityId == 0; };
  public get isFacility(): boolean { return this.facilityId != 0; };

  public isFacilityPosOnly: boolean = false;
  public hasPharmacyPos: boolean = false;
  private defaultPharmacyId: number = 0;

  public pharmacyConnectionPing: boolean;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private authorizeService: AuthorizeService,
    private authorizeGuard: AuthorizeGuard,
    private router: Router) {
    this.ApiUrl = baseUrl;
  }

  getPharmacies(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/base/GetPharmaciesBy/' + this.getUserId());
  }

  loadFacilities() {
    this.getFacilitiesByPharmacyId().subscribe(result => {
      this.facilities = result;
      this.filteredOptions = of(this.facilities);
    }, error => console.error(error));
  }

  public loadUserPrivileges() {
    var userId = this.getUserId();
    if (userId != null)
      return this.http.get<any[]>(this.ApiUrl + 'api/base/GetUserPrivilegeVo/' + userId).subscribe(data => {
        this.userPrivilegeVo = data;
      }, error => console.error(error));
  }

  public reloadUserPrivileges() {
    this.redirectToHomeWhenBrowserRefresh();
    if (this.userPrivilegeVo == null && this.authorizeService.isAuthenticated()) {
      this.loadUserPrivileges();
    }
  }

  getFacilitiesByPharmacyId(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/base/GetFacilitiesBy/' + this.getUserId() + '/' + this.pharmacyId);
  }

  getResidentsByFacilityId(): Observable<any[]> {
    if (this.pharmacyId != null && this.facilityId != null && this.facilityUnitId != null)
      return this.http.get<any>(this.ApiUrl + 'api/base/GetResidentsByFacilityId/' + this.pharmacyId + '/' + this.facilityId + '/' + this.facilityUnitId);
    else
      return EMPTY;
  }

  getResidentById(patientId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/base/GetResidentById/' + this.pharmacyId + '/' + patientId);
  }

  getPatientAllergiesDietVoByPatientId(patientId): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/base/GetPatientAllergiesDietVoByPatientId/' + this.pharmacyId + '/' + patientId);
  }

  getFacilityUnits(): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/base/GetFacilityUnits/' + this.facilityId);
  }

  getStatistics(): Observable<any[]> {
    return this.http.get<any>(this.ApiUrl + 'api/base/GetStatistics/');
  }

  getUserId() {
    var user: any;
    this.authorizeService.getUser().subscribe(u => user = u);
    this.authorizeGuard.canActivate
    if (user != null)
      return user.sub;
    else
      return null;
  }

  getData(id): Observable<any[]> {
    return of(this.facilities);
  }

  getDrugs(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/drug/GetDrugs/' + this.defaultPharmacyId);
  }

  createDrugs(drugVo) {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/drug/CreateDrugBy/' + this.defaultPharmacyId;
    const body = JSON.stringify(drugVo);

    return this.http.post<any>(url, drugVo, { 'headers': headers });
  }

  getDrugsBy(searchText: string): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/drug/GetDrugsBy/' + this.pharmacyId + '/' + searchText);
  }

  getDrugByDefaultPharmacyId(searchText: string): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/drug/GetDrugByDefaultPharmacyId/' + this.defaultPharmacyId + '/' + searchText);
  }

  getDrugById(drugId: number): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/drug/GetDrugById/' + drugId + '/' + this.defaultPharmacyId);
  }

  updateDrug(drugVo, drugId: number) {
    const headers = { 'content-type': 'application/json' };
    const url = this.ApiUrl + 'api/drug/UpdateDrugById/' + drugId + '/' + this.defaultPharmacyId;
    const body = JSON.stringify(drugVo);

    return this.http.post<any>(url, drugVo, { 'headers': headers });
  }

  getDrugsByDefaultPharmacyId(searchText: string): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/drug/GetDrugsBy/' + this.defaultPharmacyId + '/' + searchText);
  }

  getDrugDosageForms(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiUrl + 'api/drug/GetDrugDosageForms/' + this.defaultPharmacyId);
  }

  isTheUserNurseOrPhysician() {
    return this.http.get<any>(this.ApiUrl + 'api/base/IsTheUserNurseOrPhysician/' + this.getUserId());
  }

  getOrderTypeId(type): number {
    switch (type) {
      case "Prescription":
        return 1;
      case "Allergy":
        return 2;
      case "Diet":
        return 3;
      case "Conditions":
        return 4;
      case "Notes":
        return 5;
      case "Weight":
        return 6;
      case "Height":
        return 7;
      case "CreatinineCr":
        return 8;
      case "CreatinineCrCl":
        return 9;
      default:
        return 0;
    }
  }

  getOrderStatusId(status): number {
    switch (status) {
      case "Create":
      case "Created":
        return 1;
      case "Modified":
        return 2;
      case "Hold":
      case "On Hold":
        return 3;
      case "Discontinued":
        return 4;
      default:
        return 0;
    }
  }

  getPatientVo(patientId): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/base/GetPatientVo/' + this.pharmacyId + '/' + patientId);
  }

  loadPharmacy(pharmacy) {
    localStorage.setItem('pharmacy', pharmacy);
  }

  loadPharmacyId(pharmacyId) {
    localStorage.setItem('pharmacyId', pharmacyId.toString());
    this.loadFacilities();
  }

  loadFacilityId(facilityId) {
    localStorage.setItem('facilityId', facilityId.toString());

    this.loadPharmacyConnectionPing();
    this.loadFacilityIdTrigger.next();
  }

  loadIsFacilityPosOnly(facilityTypeId) {
    this.isFacilityPosOnly = facilityTypeId == 3;
  }

  loadHasPharmacyPos(hasPos) {
    this.hasPharmacyPos = hasPos;
  }

  redirectToHomeWhenBrowserRefresh() {
    if (browserRefresh) {
      this.router.navigate(['/']);
    }
  }

  loadPharmacyConnectionPing() {
    this.pharmacyConnectionPing = null;
    this.http.get<any>(this.ApiUrl + 'api/Integration/pingPharmacy/' + this.pharmacyId).subscribe(result => {
      this.pharmacyConnectionPing = result;
    });
  }

  private getUserIpAddress(): Observable<any> {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  createUserIpAddressHistory() {
    this.getUserIpAddress().subscribe((res: any) => {
      var userIpAddressHistoryVo = { UserId: this.getUserId(), IpAddress: res.ip } as IuserIpAddressHistoryVo;
      const headers = { 'content-type': 'application/json' };
      const url = this.ApiUrl + 'api/base/CreateUserIpAddressHistory';
      this.http.post<any>(url, userIpAddressHistoryVo, { 'headers': headers }).toPromise();
    });
  }

  loadIsIpAddressRestriction() {
    this.getUserIpAddress().subscribe(res => {
      this.http.get<boolean>(this.ApiUrl + 'api/base/IsIpAddressRestriction/' + this.getUserId() + '/' + res.ip).subscribe((data: any) => {
        localStorage.setItem('isIpAddressRestriction', data);
      });
    });
  }

  showTempSuccessMessage() {
    this.showSuccessMessage = true;
    setTimeout(() => this.showSuccessMessage = false, 5000);
  }

  public loadReceivingAlert() {
    if (this.userPrivilegeVo.hasReceivingAccess) {
      this.GetReceivingAlert().subscribe(receivingAlertVo => {
        localStorage.setItem('showReceivingAlert', receivingAlertVo.hasPending);
        localStorage.setItem('totalPendingReceive', receivingAlertVo.totalPending);
      });
    }
  }

  private GetReceivingAlert(): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/DrugRecordBook/GetReceivingAlert/' + this.pharmacyId + '/' + this.facilityId);
  }

  public acceptedTermsUse(): Observable<any> {
    return this.http.get<any>(this.ApiUrl + 'api/settings/AcceptedTermsUse/' + this.getUserId());
  }
}
